import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Box, Heading, Spinner, Divider, Switch, FormControl, FormLabel } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import DynamicPropertyEditor from '../../../editors/DynamicBindingsEditor';
import { type ComponentBlueprint, type ComponentInstance } from '@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema';
import ViewgraphComponentInstance from '../../../editors/ViewgraphEditor/ViewgraphComponentInstance';

interface ComponentPreviewProps {
  keypath: string;
}

const ComponentPreview: React.FC<ComponentPreviewProps> = ({ keypath }) => {
  const { getFragment } = useAppDescriptorStore();
  const [showBorder, setShowBorder] = useState(true);

  const componentBlueprint = getFragment(keypath) as ComponentBlueprint | undefined;
  
  const previewInstance = useMemo(() => {
    if (componentBlueprint?.previewInstance) {
      return {
        ...componentBlueprint.previewInstance,
        blueprintName: componentBlueprint.name
      };
    }
    return undefined;
  }, [componentBlueprint, componentBlueprint?.previewInstance]);

  if (!componentBlueprint) {
    return <Spinner />;
  }

  return (
    <Box>
      <Heading size="md">Component Preview</Heading>
      <Divider my={4} />
      <Divider my={4} />
      <FormControl display="flex" alignItems="center" my={4}>
        <FormLabel htmlFor="show-border" mb="0" mr={2}>
          Show border around component
        </FormLabel>
        <Switch
          id="show-border"
          isChecked={showBorder}
          onChange={(e) => setShowBorder(e.target.checked)}
        />
      </FormControl>
      <Box
        border={showBorder ? "1px solid" : "none"}
        borderColor={showBorder ? "blue.500" : "transparent"}
      >
        {previewInstance && (
          <ViewgraphComponentInstance 
            componentInstance={previewInstance} 
          />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(ComponentPreview);