import React, { useCallback } from "react";
import { Box, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { SetPageVariableDirective } from "@/runtime-js/src/directives/setPageVariable";
import { DirectiveEditor } from "../DirectiveEditor";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface SetPageVariableEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function SetPageVariableEditor({ keypath, flowgraphContext }: SetPageVariableEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as SetPageVariableDirective;

  const handleVariableChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newName = event.target.value;
      setFragment(keypath, {
        ...directive,
        config: { ...directive.config, name: newName },
      });
    },
    [keypath, directive, setFragment]
  );

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  // Assuming page variables are stored in declaredVariables
  const pageVariables = Array.from(flowgraphContext.declaredVariables);

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="variableName" fontSize="sm">
          Variable to Set
        </FormLabel>
        <Select
          id="variableName"
          value={directive.config.name}
          onChange={handleVariableChange}
        >
          {pageVariables.map((variable) => (
            <option key={variable} value={variable}>
              {variable}
            </option>
          ))}
        </Select>
      </FormControl>
      <Box mt={2}>
        <FormLabel fontSize="sm">New Value</FormLabel>
        <DirectiveEditor
          keypath={`${keypath}.config.value`}
          flowgraphContext={flowgraphContext}
        />
      </Box>
    </Box>
  );
}