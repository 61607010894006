import createDescriptorComponent from '../../../createDescriptorComponent';
import { DescriptorRecordField } from '../../../../editors/DynamicField';
import { Column } from '../../../../editors/CollectionEditor';

const fields: DescriptorRecordField[] = [
  { name: 'Name', keypath: 'name', component: 'Text' },
  { name: 'Description', keypath: 'description', component: 'TextArea' },
  { name: 'Test Code', keypath: 'testCode', component: 'CodeEditor', language: 'ruby' },
];

const columns: Column[] = [
  { name: 'Name', render: (row) => row.name },
  { name: 'Description', render: (row) => row.description },
];

const Tests = createDescriptorComponent({
  name: 'Test',
  getKeypath: (params) => `/essentials/dataModels/${params.dataModelId}/tests`,
  recordIdKey: 'testId',
  fields,
  columns,
  addButtonText: 'Add test',
  parentName: 'Tests'
});

export default Tests;