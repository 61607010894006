import type {
  FlowFunctionDescriptor,
  FlowFunctionParameter,
} from "./flowFunctionStore";
import type { TypedValue } from "./types/typedValue";
import type { AnyDirective } from "./directives/directiveTypes";
import { executeDirectiveFunctionForDirectiveType } from "./directives/directiveFactory";

interface ExecutionLogEntry {
  event: string;
  timestamp: number;
  details?: Record<string, unknown>;
}

interface Variable {
  name: string;
  type: string;
  value: TypedValue;
  mutable: boolean;
  mutationCount: number;
}

interface DirectiveExecutionState {
  directive: AnyDirective;
  returnValue?: TypedValue;
  error?: Error;
  status: "ready" | "executing" | "paused" | "succeeded" | "errored";
  executionLog: ExecutionLogEntry[];
}

interface FlowFunctionExecutionContextState {
  flowFunction: FlowFunctionDescriptor | null;
  resolvedArguments: Record<string, TypedValue>;
  externalFunctions: FlowFunctionDescriptor[];
  variables: Record<string, Variable>;
  executionLog: DirectiveExecutionState[];
  returnValue: TypedValue | undefined;
  status: "ready" | "executing" | "paused" | "succeeded" | "errored";
  iteratorVariables: Record<string, TypedValue>;
  startTime: number | undefined;
  endTime: number | undefined;
}

interface FlowFunctionExecutionContextOptions {
  resolvedArguments?: Record<string, TypedValue>;
  variables?: Record<string, Variable>;
  externalFunctions?: FlowFunctionDescriptor[];
}

export class FlowFunctionExecutionContext {
  private state: FlowFunctionExecutionContextState;

  constructor(
    flowFunction: FlowFunctionDescriptor,
    {
      resolvedArguments,
      variables,
      externalFunctions,
    }: FlowFunctionExecutionContextOptions,
  ) {
    this.state = {
      flowFunction: flowFunction,
      resolvedArguments: this.resolveArguments(
        flowFunction,
        resolvedArguments ?? {},
      ),
      externalFunctions: externalFunctions ?? [],
      variables: variables ?? {},
      executionLog: [],
      returnValue: undefined,
      status: "ready",
      iteratorVariables: {},
      startTime: undefined,
      endTime: undefined,
    };
  }

  private resolveArguments(
    flowFunction: FlowFunctionDescriptor,
    providedArguments: Record<string, TypedValue>,
  ): Record<string, TypedValue> {
    const resolvedArguments: Record<string, TypedValue> = {};

    if (Object.keys(providedArguments).length === 0) {
      return {};
    }

    if (!flowFunction.parameters) {
      return providedArguments;
    }

    if (flowFunction.parameters) {
      for (const param of flowFunction.parameters) {
        if (
          Object.prototype.hasOwnProperty.call(providedArguments, param.name)
        ) {
          resolvedArguments[param.name] = providedArguments[param.name];
        } else if ("default" in param) {
          resolvedArguments[param.name] = {
            type: param.type,
            value: (param as FlowFunctionParameter & { default: unknown })
              .default,
          };
        } else {
          throw new Error(
            `Required parameter '${param.name}' is missing and has no default value for function '${flowFunction.name}'.`,
          );
        }
      }
    }

    return resolvedArguments;
  }

  setFlowFunction(flowFunction: FlowFunctionDescriptor) {
    this.state.flowFunction = flowFunction;
  }

  setExternalFunctions(externalFunctions: FlowFunctionDescriptor[]) {
    this.state.externalFunctions = externalFunctions;
  }

  getExternalFunction(name: string): FlowFunctionDescriptor | undefined {
    return this.state.externalFunctions.find(
      (functionDeclaration) => functionDeclaration.name === name,
    );
  }

  getExternalFunctions(): FlowFunctionDescriptor[] {
    return this.state.externalFunctions;
  }

  getStatus(): FlowFunctionExecutionContextState["status"] {
    return this.state.status;
  }

  getArgument(name: string): TypedValue {
    return this.state.resolvedArguments[name];
  }

  getArguments(): Record<string, TypedValue> {
    return this.state.resolvedArguments;
  }

  declareVariable(name: string, type: string, initialValue: TypedValue) {
    this.state.variables[name] = {
      name,
      type,
      value: initialValue,
      mutable: true,
      mutationCount: 0,
    };
  }

  setVariable(name: string, value: TypedValue) {
    if (!this.state.variables[name]) {
      throw new Error(`Variable ${name} not declared`);
    }
    const variable = this.state.variables[name];
    if (!variable.mutable) {
      throw new Error(`Variable ${name} is not mutable`);
    }
    variable.value = value;
    variable.mutationCount++;
  }

  getVariable(name: string): TypedValue | undefined {
    const variable = this.state.variables[name];
    if (!variable) {
      return undefined;
    }
    return variable.value;
  }

  getVariables(): Record<string, Variable> {
    return this.state.variables;
  }

  getVariableTypedValue(name: string): TypedValue {
    const variable = this.state.variables[name];
    if (!variable) {
      throw new Error(`Variable '${name}' not found`);
    }
    return variable.value;
  }

  setReturnValue(value: TypedValue) {
    this.state.returnValue = value;
  }

  setStatus(status: FlowFunctionExecutionContextState["status"]) {
    this.state.status = status;
  }

  setIteratorVariable(name: string, value: TypedValue) {
    this.state.iteratorVariables[name] = value;
  }

  getIteratorVariable(name: string): TypedValue {
    const variable = this.state.iteratorVariables[name];
    if (!variable) {
      throw new Error(`Iterator variable '${name}' not found`);
    }
    return variable;
  }

  async executeFunction(): Promise<TypedValue> {
    this.setStatus("executing");
    this.state.startTime = Date.now();
    const flowgraph = this.state.flowFunction?.flowgraph || [];
    for (let i = 0; i < flowgraph.length; i++) {
      const directive = flowgraph[i];
      const directiveState: DirectiveExecutionState = {
        directive,
        status: "ready",
        executionLog: [],
      };
      this.state.executionLog.push(directiveState);

      try {
        const result = await this.executeDirective(directive);
        directiveState.status = "succeeded";
        directiveState.returnValue = result;
        directiveState.executionLog.push({
          event: "executed",
          timestamp: Date.now(),
        });

        this.setReturnValue(result);

        if (directive.directiveType === "return") {
          this.setStatus("succeeded");
          return result;
        }
      } catch (error) {
        directiveState.status = "errored";
        directiveState.error = error as Error;
        directiveState.executionLog.push({
          event: "error",
          timestamp: Date.now(),
          details: { error: (error as Error).message },
        });
        this.setStatus("errored");
        this.state.endTime = Date.now();
        throw error;
      }
    }

    this.setStatus("succeeded");
    this.state.endTime = Date.now();
    return this.state.returnValue!;
  }

  async executeDirective(
    directive: AnyDirective | TypedValue,
  ): Promise<TypedValue> {
    if (this.isTypedValue(directive)) {
      return directive;
    }

    if (!directive || !directive.directiveType) {
      throw new Error(`Invalid directive: ${JSON.stringify(directive)}`);
    }

    const execute = executeDirectiveFunctionForDirectiveType(
      directive.directiveType,
    );

    if (!execute) {
      throw new Error(
        `No execution function found for directive type: ${directive.directiveType}`,
      );
    }

    const result = await execute(directive.config, this);

    if (this.isTypedValue(result)) {
      return result;
    } else {
      console.warn(
        `Directive ${directive.directiveType} did not return a TypedValue. Wrapping the result.`,
      );
      return {
        type: typeof result,
        value: result,
      };
    }
  }

  public isTypedValue(value: any): value is TypedValue {
    return (
      value !== null &&
      typeof value === "object" &&
      "type" in value &&
      "value" in value
    );
  }

  getElapsedTime(): number {
    if (!this.state.startTime) {
      throw new Error("Start time not set");
    }
    return this.state.endTime
      ? this.state.endTime - this.state.startTime
      : Date.now() - this.state.startTime;
  }

  // Method to persist the current state
  persistState(): string {
    return JSON.stringify(this.state);
  }

  // Method to load the state from a persisted string
  loadState(state: string) {
    this.state = JSON.parse(state);
  }
}
function isFunctionLikeDirective(flowFunction: FlowFunctionDescriptor) {
  throw new Error("Function not implemented.");
}
