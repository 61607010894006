import React from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react";

// ... add necessary imports and type definitions ...

export const IncompatibleDirectiveAlert: React.FC<IncompatibleDirectiveAlertProps> = ({
  isOpen,
  onClose,
  selectedIncompatibleItem,
  selectDirective,
}) => {
  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Incompatible Directive
          </AlertDialogHeader>
          <AlertDialogBody>
            This directive may not be compatible with the expected type. Are you sure you want to use it?
            {selectedIncompatibleItem?.compatibility?.warning && (
              <Text color="red.500" mt={2}>{selectedIncompatibleItem.compatibility.warning}</Text>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={() => {
              if (selectedIncompatibleItem) {
                selectDirective(selectedIncompatibleItem);
              }
              onClose();
            }} ml={3}>
              Use Anyway
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};