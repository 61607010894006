import React, { useState, useEffect, ErrorInfo } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  Input,
  Textarea,
  Select,
  Grid,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { FiRefreshCcw } from "react-icons/fi";
import {
  ComponentInstance,
  ComponentBlueprint,
} from "@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema";
import PropertyEditor from "./PropertyEditor/PropertyEditor";
import ListPropertyEditor from "./ListPropertyEditor";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import ReactionEditor from "./ReactionEditor";
import { ComponentEventsPanel } from "./ComponentEventsPanel";
import { StyleEditor } from "./PropertyEditor/StyleEditor";
import { ContainerStructure } from "@/bundles/DescriptorEditor/schemas/essentials/pagesSchema";
import JsonEditor from "./JSONEditor";

interface ComponentPropertiesPanelProps {
  selectedComponentId: string | null;
  selectedComponentKeypath: string | null;
  availableComponentBlueprints: ComponentBlueprint[];
  onDeleteComponent: (componentId: string) => void;
  onPropertyChange: (
    propertyName: string,
    value: any,
    componentId: string
  ) => void;
}

interface DataVariable {
  id: string;
  name: string;
  type: string;
  dataModel: string;
  description: string;
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const findComponentInSlots = (
  slots: Record<string, { $children: ComponentInstance[] }>,
  id: string
): string | null => {
  for (const [slotName, slot] of Object.entries(slots)) {
    for (const child of slot.$children || []) {
      if (child.id === id) {
        return `${selectedComponentKeypath}/$slots/${slotName}/$children/id:${child.id}`;
      }
      if (child.$slots) {
        const nestedPath = findComponentInSlots(child.$slots, id);
        if (nestedPath) return nestedPath;
      }
    }
  }
  return null;
};

const findComponentKeypath = (
  container: ContainerStructure,
  componentId: string,
  baseKeypath: string
): string | null => {
  const componentIndex = container.components?.findIndex(
    (c) => c.id === componentId
  );
  if (componentIndex !== -1) {
    return `${baseKeypath}/components/id:${componentId}`;
  }

  for (const component of container.components || []) {
    if (component.$slots) {
      for (const [slotName, slot] of Object.entries(component.$slots)) {
        const slotChild = slot.$children?.find((c) => c.id === componentId);
        if (slotChild) {
          return `${baseKeypath}/components/id:${component.id}/$slots/${slotName}/$children/id:${componentId}`;
        }
      }
    }
  }

  for (const [index, subcontainer] of (
    container.subcontainers || []
  ).entries()) {
    const found = findComponentKeypath(
      subcontainer,
      componentId,
      `${baseKeypath}/subcontainers/id:${subcontainer.id}`
    );
    if (found) return found;
  }

  return null;
};

const ComponentPropertiesPanel: React.FC<ComponentPropertiesPanelProps> = ({
  selectedComponentId,
  selectedComponentKeypath,
  availableComponentBlueprints,
  onDeleteComponent,
  onPropertyChange,
}) => {
  const { getFragment, setFragment, getDataVariables } =
    useAppDescriptorStore();
  const { pageId } = useParams();
  const [pageVariables, setPageVariables] = useState<string[]>([]);
  const [editorMode, setEditorMode] = useState<
    "properties" | "layout" | "style" | "events"
  >("properties");

  const dataModels = getFragment("essentials/dataModels") || [];

  const getModelFields = (modelName: string) => {
    const model = dataModels.find((m) => m.name === modelName);
    return model?.fields || [];
  };

  useEffect(() => {
    const initLogic = getFragment("initializationLogic.flowgraph");
    if (initLogic) {
      const variables = extractDeclaredVariables(initLogic);
      setPageVariables(variables);
    }
  }, [getFragment]);

  const getComponentKeypath = () => {
    const component = getFragment(
      selectedComponentKeypath
    ) as ComponentInstance;
    if (!component) {
      // Check if the component is nested in slots
      const parentComponent = getFragment(
        selectedComponentKeypath.split("/$slots")[0]
      ) as ComponentInstance;
      if (parentComponent?.$slots) {
        const nestedKeypath = findComponentInSlots(
          parentComponent.$slots,
          selectedComponentId
        );
        if (nestedKeypath) return nestedKeypath;
      }
    }
    return selectedComponentKeypath;
  };

  const actualKeypath = getComponentKeypath();
  const selectedComponent = getFragment(actualKeypath) as
    | ComponentInstance
    | undefined;

  if (!selectedComponent) {
    return <Box p={4}>Selected component not found: {actualKeypath}</Box>;
  }

  const componentBlueprint = availableComponentBlueprints.find(
    (bp) => bp.name === selectedComponent.blueprintName
  );

  if (!componentBlueprint) {
    return (
      <Box p={4}>
        Component blueprint not found for: {selectedComponent.blueprintName}
      </Box>
    );
  }

  const handleDomIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFragment(`${actualKeypath}/domId`, e.target.value);
  };

  const handlePropertyChange = (propertyName: string, newValue: any) => {
    if (!selectedComponentId) {
      console.error("No component selected");
      return;
    }

    onPropertyChange(propertyName, newValue, selectedComponentId);
  };

  const handleStyleChange = (newClasses: string) => {
    if (!selectedComponentId) return;

    const componentKeypath = findComponentKeypath(
      structure,
      selectedComponentId,
      baseKeypath
    );

    if (componentKeypath) {
      setFragment(`${componentKeypath}/styles/classes`, newClasses);

      // Trigger preview refresh
      const event = new CustomEvent("refreshComponentPreview", {
        detail: { componentId: selectedComponentId },
      });
      window.dispatchEvent(event);
    }
  };

  const getComponentProperties = () => {
    // Special handling for HTML elements
    if (componentBlueprint.category === "HTML Elements") {
      const commonProps = [
        {
          name: "className",
          dataType: "string",
          description: "CSS class names",
        },
        {
          name: "style",
          dataType: "object",
          description: "Inline styles",
        },
        {
          name: "children",
          dataType: "string",
          description: "Child content",
        },
      ];

      // Add element-specific properties
      switch (componentBlueprint.name) {
        case "input":
          return [
            ...commonProps,
            {
              name: "type",
              dataType: "string",
              description: "Input type (text, number, etc.)",
            },
            {
              name: "placeholder",
              dataType: "string",
              description: "Placeholder text",
            },
            {
              name: "value",
              dataType: "string",
              description: "Input value",
            },
          ];
        case "img":
          return [
            ...commonProps,
            {
              name: "src",
              dataType: "string",
              description: "Image source URL",
            },
            {
              name: "alt",
              dataType: "string",
              description: "Alternative text",
            },
          ];
        case "a":
          return [
            ...commonProps,
            {
              name: "href",
              dataType: "string",
              description: "Link URL",
            },
            {
              name: "target",
              dataType: "string",
              description: "Target (_blank, _self, etc.)",
            },
          ];
        case "button":
          return [
            ...commonProps,
            {
              name: "type",
              dataType: "string",
              description: "Button type (button, submit, reset)",
            },
            {
              name: "disabled",
              dataType: "boolean",
              description: "Whether the button is disabled",
            },
          ];
        default:
          return commonProps;
      }
    }

    if (componentBlueprint.properties) {
      return componentBlueprint.properties;
    }
    if (componentBlueprint.props) {
      return componentBlueprint.props;
    }
    return [];
  };

  const renderPropertyBinding = (property: any) => {
    const propertyType = property.schema?.type || property.dataType;
    const propertyName = property.name;
    let binding = selectedComponent.propertiesBindings?.[propertyName];
    let currentValue = binding?.config?.value ?? binding?.value ?? binding;

    if (typeof currentValue === "object") {
      currentValue = "";
    }

    if (
      propertyType === "String" ||
      propertyType === "string" ||
      propertyType === "Number" ||
      propertyType === "number" ||
      propertyType === "Boolean" ||
      propertyType === "boolean"
    ) {
      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          <Input
            value={currentValue || ""}
            onChange={(e) =>
              handlePropertyChange(propertyName, {
                directiveType: "literalValue",
                config: {
                  type: propertyType,
                  value: e.target.value,
                },
              })
            }
            placeholder={`Enter ${propertyName}`}
          />
        </Box>
      );
    }

    if (
      componentBlueprint.category === "HTML Elements" &&
      currentValue === undefined
    ) {
      currentValue = selectedComponent.props?.[propertyName];
    }

    if (
      propertyName === "style" &&
      componentBlueprint.category === "HTML Elements"
    ) {
      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          <JsonEditor
            value={currentValue || {}}
            onChange={(newValue) =>
              handlePropertyChange(propertyName, {
                directiveType: "literalValue",
                config: {
                  type: "object",
                  value: newValue,
                },
              })
            }
          />
        </Box>
      );
    }

    // Special handling for HTML element boolean properties
    if (
      propertyType === "boolean" &&
      componentBlueprint.category === "HTML Elements"
    ) {
      return (
        <Box key={propertyName}>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">{propertyName}</FormLabel>
            <Checkbox
              isChecked={Boolean(currentValue)}
              onChange={(e) =>
                handlePropertyChange(propertyName, {
                  directiveType: "literalValue",
                  config: {
                    type: "boolean",
                    value: e.target.checked,
                  },
                })
              }
            />
          </FormControl>
        </Box>
      );
    }

    if (
      (propertyType === "string" || propertyName === "children") &&
      componentBlueprint.category === "HTML Elements"
    ) {
      const isMultiline = propertyName === "children";
      const InputComponent = isMultiline ? Textarea : Input;

      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          <InputComponent
            value={currentValue || ""}
            onChange={(e) =>
              handlePropertyChange(propertyName, {
                directiveType: "literalValue",
                config: {
                  type: "string",
                  value: e.target.value,
                },
              })
            }
            placeholder={`Enter ${propertyName}`}
          />
        </Box>
      );
    }

    if (propertyName === "data" && componentBlueprint.name === "Table_Simple") {
      const pageVariables = getDataVariables().filter(
        (v) => v.type === "Collection"
      );

      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          {pageVariables.length > 0 ? (
            <Select
              value={currentValue?.variableName || ""}
              onChange={(e) => {
                handlePropertyChange(propertyName, {
                  directiveType: "getPageVariable",
                  config: {
                    type: "Collection",
                    variableName: e.target.value,
                  },
                });
              }}
              placeholder="Select a data variable"
            >
              {pageVariables.map((variable) => (
                <option key={variable.id} value={variable.name}>
                  {variable.name} ({variable.dataModel})
                </option>
              ))}
            </Select>
          ) : (
            <Text color="gray.500" fontSize="sm">
              No collection variables available. Define them in the Data
              Variables section.
            </Text>
          )}
        </Box>
      );
    }

    if (propertyType === "_types.ActiveRecord") {
      const dataVariables = getDataVariables();
      const availableVariables = dataVariables;

      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          {availableVariables.length > 0 ? (
            <Select
              value={currentValue?.id || ""}
              onChange={(e) => {
                const selectedVariable = dataVariables.find(
                  (v) => v.id === e.target.value
                );
                handlePropertyChange(propertyName, {
                  id: selectedVariable?.id,
                  type: selectedVariable?.type,
                  dataModel: selectedVariable?.dataModel,
                });
              }}
              placeholder="Select a data variable"
            >
              {availableVariables.map((variable) => (
                <option key={variable.id} value={variable.id}>
                  {variable.name} - {variable.type} ({variable.dataModel})
                </option>
              ))}
            </Select>
          ) : (
            <Text color="gray.500" fontSize="sm">
              No data variables available. Define them in the Data Variables
              section.
            </Text>
          )}
        </Box>
      );
    }

    if (propertyType === "Object" || propertyType === "_types.Object") {
      // Special handling for schema property
      if (propertyName === "schema") {
        const currentValue = componentBlueprint.properties
          ? selectedComponent.propertiesBindings?.[propertyName]?.config
              ?.value || {}
          : selectedComponent.props?.[propertyName] || {};

        return (
          <Box key={propertyName}>
            <Text fontWeight="bold" mb={1}>
              {propertyName}
            </Text>
            <VStack spacing={3} align="stretch">
              <FormControl>
                <FormLabel fontSize="sm">Type</FormLabel>
                <Select
                  value={currentValue.type || "Record"}
                  onChange={(e) => {
                    const newValue = { ...currentValue, type: e.target.value };
                    handlePropertyChange(propertyName, newValue);
                  }}
                >
                  <option value="Record">Record</option>
                  <option value="Collection">Collection</option>
                </Select>
              </FormControl>

              {currentValue.type === "Record" && (
                <>
                  <FormControl>
                    <FormLabel fontSize="sm">Data Model</FormLabel>
                    <Select
                      value={currentValue.dataModel || ""}
                      onChange={(e) => {
                        const newValue = {
                          ...currentValue,
                          dataModel: e.target.value,
                        };
                        handlePropertyChange(propertyName, newValue);
                      }}
                    >
                      {dataModels.map((model) => (
                        <option key={model.id} value={model.name}>
                          {model.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize="sm">Included Fields</FormLabel>
                    <CheckboxGroup
                      value={currentValue.includedFields || []}
                      onChange={(values) => {
                        const newValue = {
                          ...currentValue,
                          includedFields: values,
                        };
                        handlePropertyChange(propertyName, newValue);
                      }}
                    >
                      <VStack align="start">
                        {getModelFields(currentValue.dataModel).map((field) => (
                          <Checkbox key={field.name} value={field.name}>
                            {field.name}
                          </Checkbox>
                        ))}
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                </>
              )}
            </VStack>
          </Box>
        );
      }

      // Default object editor for other object properties
      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          <JsonEditor
            value={currentValue || {}}
            onChange={(newValue) =>
              handlePropertyChange(propertyName, newValue)
            }
          />
        </Box>
      );
    }

    if (propertyType === "_types.List" || propertyType === "Array") {
      const isTableColumns =
        propertyName === "columns" &&
        componentBlueprint.name === "Table_Simple";
      const isDataCardFields =
        propertyName === "fields" &&
        componentBlueprint.name === "DataDisplay_DataCard";

      if (propertyName === "fields") {
        const fields = Array.isArray(currentValue?.value)
          ? currentValue.value
          : [];
        return (
          <Box key={propertyName}>
            <Text fontWeight="bold" mb={1}>
              {propertyName}
            </Text>
            <VStack spacing={2} align="stretch">
              {fields.map((field: any, index: number) => (
                <Box
                  key={index}
                  p={2}
                  borderWidth="1px"
                  borderRadius="md"
                  position="relative"
                >
                  <Grid templateColumns="1fr 1fr" gap={2}>
                    <Box>
                      <Text fontSize="sm" color="gray.600">
                        Label
                      </Text>
                      <Input
                        value={field.label || ""}
                        onChange={(e) => {
                          const newFields = [...fields];
                          newFields[index] = {
                            ...field,
                            label: e.target.value,
                          };
                          handlePropertyChange(propertyName, newFields);
                        }}
                      />
                    </Box>
                    <Box>
                      <Text fontSize="sm" color="gray.600">
                        Keypath
                      </Text>
                      <Input
                        value={field.keypath || ""}
                        onChange={(e) => {
                          const newFields = [...fields];
                          newFields[index] = {
                            ...field,
                            keypath: e.target.value,
                          };
                          handlePropertyChange(propertyName, newFields);
                        }}
                      />
                    </Box>
                    <Box>
                      <Text fontSize="sm" color="gray.600">
                        Placeholder
                      </Text>
                      <Input
                        value={field.placeholder || ""}
                        onChange={(e) => {
                          const newFields = [...fields];
                          newFields[index] = {
                            ...field,
                            placeholder: e.target.value,
                          };
                          handlePropertyChange(propertyName, newFields);
                        }}
                      />
                    </Box>
                  </Grid>
                  <IconButton
                    aria-label="Delete field"
                    icon={<DeleteIcon />}
                    size="sm"
                    position="absolute"
                    right="-8px"
                    top="-8px"
                    colorScheme="red"
                    onClick={() => {
                      const newFields = fields.filter(
                        (_: any, i: number) => i !== index
                      );
                      handlePropertyChange(propertyName, newFields);
                    }}
                  />
                </Box>
              ))}
              <Button
                size="sm"
                onClick={() => {
                  const newFields = [
                    ...fields,
                    { label: "", keypath: "", placeholder: "" },
                  ];
                  handlePropertyChange(propertyName, newFields);
                }}
              >
                Add Field
              </Button>
            </VStack>
          </Box>
        );
      }

      if (isTableColumns) {
        const columns = Array.isArray(currentValue?.value)
          ? currentValue.value
          : [];

        return (
          <Box key={propertyName}>
            <Text fontWeight="bold" mb={1}>
              {propertyName}
            </Text>
            <VStack spacing={2} align="stretch">
              {columns.map((column: any, index: number) => (
                <Box
                  key={index}
                  p={2}
                  borderWidth="1px"
                  borderRadius="md"
                  position="relative"
                >
                  <Grid templateColumns="1fr 1fr" gap={2}>
                    {/* Label Input */}
                    <Box>
                      <Text fontSize="sm" color="gray.600">
                        Label
                      </Text>
                      <Input
                        value={column.label || ""}
                        onChange={(e) => {
                          const newColumns = [...columns];
                          newColumns[index] = {
                            ...column,
                            label: e.target.value,
                          };
                          handlePropertyChange(propertyName, {
                            directiveType: "literalValue",
                            config: {
                              type: "Array",
                              value: newColumns,
                            },
                          });
                        }}
                      />
                    </Box>
                    {/* Data Key Input */}
                    <Box>
                      <Text fontSize="sm" color="gray.600">
                        Data Key
                      </Text>
                      <Input
                        value={column.dataKey || ""}
                        onChange={(e) => {
                          const newColumns = [...columns];
                          newColumns[index] = {
                            ...column,
                            dataKey: e.target.value,
                          };
                          handlePropertyChange(propertyName, {
                            directiveType: "literalValue",
                            config: {
                              type: "Array",
                              value: newColumns,
                            },
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* Delete Column Button */}
                  <IconButton
                    aria-label="Delete column"
                    icon={<DeleteIcon />}
                    size="sm"
                    position="absolute"
                    right="-8px"
                    top="-8px"
                    colorScheme="red"
                    onClick={() => {
                      const newColumns = columns.filter(
                        (_: any, i: number) => i !== index
                      );
                      handlePropertyChange(propertyName, {
                        directiveType: "literalValue",
                        config: {
                          type: "Array",
                          value: newColumns,
                        },
                      });
                    }}
                  />
                </Box>
              ))}
              {/* Add Column Button */}
              <Button
                size="sm"
                onClick={() => {
                  const newColumns = [...columns, { label: "", dataKey: "" }];
                  handlePropertyChange(propertyName, {
                    directiveType: "literalValue",
                    config: {
                      type: "Array",
                      value: newColumns,
                    },
                  });
                }}
              >
                Add Column
              </Button>
            </VStack>
          </Box>
        );
      }

      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          <ListPropertyEditor
            schema={property}
            keypath={
              componentBlueprint.properties
                ? `${actualKeypath}/propertiesBindings/${propertyName}`
                : `${actualKeypath}/props/${propertyName}`
            }
            value={Array.isArray(currentValue?.value) ? currentValue.value : []}
          />
        </Box>
      );
    }

    if (propertyType === "Collection") {
      const pageVariables = getDataVariables().filter(
        (v) => v.type === "Collection"
      );

      return (
        <Box key={propertyName}>
          <Text fontWeight="bold" mb={1}>
            {propertyName}
          </Text>
          {pageVariables.length > 0 ? (
            <Select
              value={currentValue?.variableName || ""}
              onChange={(e) => {
                handlePropertyChange(propertyName, {
                  directiveType: "getPageVariable",
                  config: {
                    type: "Collection",
                    variableName: e.target.value,
                  },
                });
              }}
              placeholder="Select a data variable"
            >
              {pageVariables.map((variable) => (
                <option key={variable.id} value={variable.name}>
                  {variable.name} ({variable.dataModel})
                </option>
              ))}
            </Select>
          ) : (
            <Text color="gray.500" fontSize="sm">
              No collection variables available. Define them in the Data
              Variables section.
            </Text>
          )}
        </Box>
      );
    }

    return (
      <Box key={propertyName}>
        <Text fontWeight="bold" mb={1}>
          {propertyName}
        </Text>
        <PropertyEditor
          schema={property}
          keypath={
            componentBlueprint.properties
              ? `${actualKeypath}/propertiesBindings/${propertyName}`
              : `${actualKeypath}/props/${propertyName}`
          }
          value={currentValue}
          onChange={(newValue) => handlePropertyChange(propertyName, newValue)}
        />
      </Box>
    );
  };

  const renderReactionEditor = (event: any) => {
    return (
      <ErrorBoundary key={event.id}>
        <ReactionEditor
          event={event}
          selectedComponentKeypath={actualKeypath}
          selectedComponent={selectedComponent}
          pageId={pageId}
          getPageMethods={getPageMethods}
        />
      </ErrorBoundary>
    );
  };

  return (
    <Box
      p={4}
      borderLeft="1px"
      borderColor="gray.200"
      height="100%"
      overflowY="auto"
    >
      <VStack spacing={4} align="stretch">
        <Flex justify="space-between" align="center">
          <Heading size="md">{componentBlueprint.name} Properties</Heading>
          <IconButton
            aria-label="Refresh Preview"
            icon={<FiRefreshCcw />}
            size="sm"
            onClick={() => {
              const event = new CustomEvent("refreshComponentPreview", {
                detail: { componentId: selectedComponentId },
              });
              window.dispatchEvent(event);
            }}
          />
        </Flex>

        <Heading size="sm">Properties</Heading>
        {getComponentProperties().length > 0 ? (
          <VStack spacing={4} align="stretch">
            {getComponentProperties().map(renderPropertyBinding)}
          </VStack>
        ) : (
          <Text>No editable properties for this component.</Text>
        )}

        <Heading size="sm">Events</Heading>
        <ComponentEventsPanel
          selectedComponent={selectedComponent}
          componentKeypath={actualKeypath}
        />

        <Box>
          <Text fontWeight="bold" mb={1}>
            DOM ID
          </Text>
          <Input
            placeholder="DOM ID"
            value={selectedComponent.domId || ""}
            onChange={handleDomIdChange}
          />
        </Box>

        {editorMode === "style" ? (
          <Box p={4}>
            <StyleEditor
              styleClasses={
                selectedComponent?.styles?.classes ||
                blueprint?.viewgraph?.nodeTree?.$styleClasses ||
                ""
              }
              onChange={handleStyleChange}
            />
          </Box>
        ) : (
          <></>
        )}
      </VStack>
    </Box>
  );
};

function extractDeclaredVariables(initLogic: any): string[] {
  return [];
}

export default ComponentPropertiesPanel;
