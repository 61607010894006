import React, { useState, useEffect, useRef } from "react";
import { useAppDescriptorStore } from "../../../stores/appDescriptorStore";
import { ComponentInstance } from "./ViewgraphEditor";
import DOMPurify from "dompurify";

interface ViewgraphComponentInstanceProps {
  componentInstance: ComponentInstance;
  selectedComponentIds: string[];
  containerKeypath?: string;
  onSelectComponent: (componentId: string) => void;
}

const ViewgraphComponentInstance: React.FC<ViewgraphComponentInstanceProps> = ({
  componentInstance,
  selectedComponentIds,
  containerKeypath,
  onSelectComponent,
}) => {
  const { availableComponentBlueprints, setFragment } = useAppDescriptorStore();
  const [previewHtml, setPreviewHtml] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const refreshTimeoutRef = useRef<NodeJS.Timeout>();
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const DOUBLE_CLICK_DELAY = 250; // milliseconds

  const blueprint = availableComponentBlueprints.find(
    (bp) => bp.name === componentInstance.blueprintName
  );

  const transformBlueprintForRequest = (blueprint: any) => {
    return {
      name: blueprint.name,
      displayName: blueprint.displayName,
      description: blueprint.description,
      category: blueprint.category,
      props: blueprint.properties?.map((prop: any) => ({
        name: prop.name,
        schema: prop.schema ?? { type: prop.dataType },
        description: prop.description,
      })),
      emittedEvents: blueprint.emittedEvents,
      viewgraph: {
        ...blueprint.viewgraph,
        nodeTree: {
          ...blueprint.viewgraph?.nodeTree,
          $styleClasses:
            componentInstance.styles?.classes ||
            blueprint.viewgraph?.nodeTree?.$styleClasses,
        },
      },
    };
  };

  const transformPropsForRequest = (
    propertiesBindings: Record<string, any>
  ) => {
    const result: Record<string, any> = {};

    Object.entries(propertiesBindings || {}).forEach(([key, binding]) => {
      if (binding && typeof binding === "object") {
        // Handle binding objects with config
        if ("config" in binding && binding.config?.value !== undefined) {
          const type = binding.config.type;
          const value = binding.config.value;

          // For Array and Object types, include type and value
          if (
            type === "Array" ||
            type === "Object" ||
            type === "_types.List" ||
            type === "_types.Dictionary"
          ) {
            result[key] = value; // Send just the value for arrays and objects
          } else {
            // For primitive types, send the raw value
            result[key] = value;
          }
        } else {
          // If it's a direct value object (no config wrapper)
          result[key] = binding;
        }
      } else {
        // For primitive values
        result[key] = binding;
      }
    });

    return result;
  };

  const refreshPreview = async () => {
    if (!blueprint || !containerKeypath) return;

    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }

    const JSONData = JSON.stringify({
      component: transformBlueprintForRequest(blueprint),
      props: transformPropsForRequest(componentInstance.propertiesBindings),
    });

    console.log("Refreshing Preview for:", componentInstance.id, JSONData);

    refreshTimeoutRef.current = setTimeout(async () => {
      const previewUrl =
        process.env.RAILS_ENV === "production"
          ? "https://kos-runtime-839881b6cd73.herokuapp.com/kasey_os/preview_component"
          : "http://localhost:4000/kasey_os/preview_component";

      try {
        setError(null);
        const response = await fetch(previewUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSONData,
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch preview: ${response.statusText}`);
        }

        const data = await response.json();
        if (data.html) {
          const previewPath = containerKeypath.includes("$slots")
            ? `${containerKeypath}/renderedPreview`
            : `${containerKeypath}/components/id:${componentInstance.id}/renderedPreview`;

          setFragment(previewPath, data.html);
          setPreviewHtml(data.html);
        } else {
          throw new Error("No HTML in preview response");
        }
      } catch (error) {
        console.error("Error refreshing preview:", error);
        setError(error instanceof Error ? error.message : "Unknown error");
        setPreviewHtml(generatePreviewHtml());
      }
    }, 1000);
  };

  useEffect(() => {
    const handleRefresh = (event: CustomEvent) => {
      if (event.detail.componentId === componentInstance.id) {
        refreshPreview();
      }
    };

    window.addEventListener(
      "refreshComponentPreview",
      handleRefresh as EventListener
    );
    return () => {
      window.removeEventListener(
        "refreshComponentPreview",
        handleRefresh as EventListener
      );
    };
  }, [componentInstance.id, refreshPreview]);

  useEffect(() => {
    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, []);

  if (!blueprint) {
    return (
      <div className="p-4 bg-gray-100 rounded">
        Component blueprint not found: {componentInstance.blueprintName}
      </div>
    );
  }

  const generatePreviewHtml = () => {
    if (!blueprint.previewHtml) {
      return `<div class="p-4 bg-gray-100 rounded">
        Preview not available for ${componentInstance.blueprintName}
      </div>`;
    }

    try {
      let previewHtml = blueprint.previewHtml;

      if (componentInstance.propertiesBindings) {
        Object.entries(componentInstance.propertiesBindings).forEach(
          ([key, binding]) => {
            let value = binding?.config?.value ?? binding;
            if (typeof value === "object") {
              value = JSON.stringify(value);
            }
            const regex = new RegExp(`\\$\\{${key}\\}`, "g");
            previewHtml = previewHtml.replace(regex, String(value));
          }
        );
      }

      return DOMPurify.sanitize(previewHtml, {
        FORBID_ATTR: ["onload", "onerror", "onclick", "onmouseover"],
        FORBID_TAGS: ["script", "style"],
        ADD_ATTR: ["data-sanitized"],
      });
    } catch (error) {
      console.error("Error generating preview HTML:", error);
      return `<div class="p-4 bg-red-100 rounded">
        Error generating preview for ${componentInstance.blueprintName}
      </div>`;
    }
  };

  const getPreviewHtml = () => {
    if (componentInstance.renderedPreview) {
      return componentInstance.renderedPreview;
    }
    return generatePreviewHtml();
  };

  // TODO: This is a temporary solution to get the layout classes. This is available from RENDERING the blueprint.
  const getLayoutClasses = (blueprintName: string): string => {
    switch (blueprintName) {
      case "Layout_TwoColumns":
        return "flex flex-row";
      case "Layout_CenteredWithinViewport":
        return "min-h-screen bg-gray-50";
      case "Layout_SpreadHorizontally":
        return "flex justify-between items-center w-full py-4";
      case "Layout_Stack":
        return "flex flex-col gap-4";
      default:
        return "";
    }
  };

  const getSlotClasses = (blueprintName: string, slotName: string): string => {
    if (blueprintName === "Layout_TwoColumns") {
      return "w-1/2 px-4";
    }
    if (
      blueprintName === "Layout_CenteredWithinViewport" &&
      slotName === "content"
    ) {
      return "mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 py-10";
    }
    return "";
  };

  const renderSlotContent = (
    slots: Record<string, { $children: ComponentInstance[] }>
  ) => {
    const isLayoutComponent =
      componentInstance.blueprintName.startsWith("Layout_");

    if (isLayoutComponent) {
      return (
        <div className={getLayoutClasses(componentInstance.blueprintName)}>
          {Object.entries(slots).map(([slotName, slot]) => (
            <div
              key={slotName}
              className={`slot-content ${getSlotClasses(
                componentInstance.blueprintName,
                slotName
              )}`}
            >
              {slot.$children?.map((child) => {
                const childPath = containerKeypath
                  ? containerKeypath.includes("$slots")
                    ? `${containerKeypath}/$slots/${slotName}/$children/id:${child.id}`
                    : `${containerKeypath}/components/id:${componentInstance.id}/$slots/${slotName}/$children/id:${child.id}`
                  : `components/id:${componentInstance.id}/$slots/${slotName}/$children/id:${child.id}`;

                return (
                  <ViewgraphComponentInstance
                    key={child.id}
                    componentInstance={child}
                    selectedComponentIds={selectedComponentIds}
                    containerKeypath={childPath}
                    onSelectComponent={onSelectComponent}
                  />
                );
              })}
            </div>
          ))}
        </div>
      );
    }

    // Original slot rendering for non-layout components
    return Object.entries(slots).map(([slotName, slot]) => (
      <div
        key={slotName}
        className="slot-content"
        style={{
          border: "1px dashed #e2e8f0",
          minHeight: "2rem",
        }}
      >
        {slot.$children?.map((child) => {
          const childPath = containerKeypath
            ? containerKeypath.includes("$slots")
              ? `${containerKeypath}/$slots/${slotName}/$children/id:${child.id}`
              : `${containerKeypath}/components/id:${componentInstance.id}/$slots/${slotName}/$children/id:${child.id}`
            : `components/id:${componentInstance.id}/$slots/${slotName}/$children/id:${child.id}`;

          return (
            <ViewgraphComponentInstance
              key={child.id}
              componentInstance={child}
              selectedComponentIds={selectedComponentIds}
              containerKeypath={childPath}
              onSelectComponent={onSelectComponent}
            />
          );
        })}
      </div>
    ));
  };

  const handleClick = () => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = null;
      onSelectComponent(componentInstance.id);
    } else {
      clickTimeoutRef.current = setTimeout(() => {
        onSelectComponent(componentInstance.id);

        clickTimeoutRef.current = null;
      }, DOUBLE_CLICK_DELAY);
    }
  };

  return (
    <div
      className={`relative p-2 rounded ${
        selectedComponentIds.includes(componentInstance.id)
          ? "ring-2 ring-blue-500"
          : ""
      }`}
      style={{
        display: "inline-block",
        width: componentInstance.blueprintName.startsWith("Layout_")
          ? "100%"
          : "auto",
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
    >
      {error && (
        <div className="text-red-500 text-sm mb-2">
          Error loading new preview. Reload to reset.
        </div>
      )}

      {/* First render nested components */}
      {componentInstance.$slots && renderSlotContent(componentInstance.$slots)}

      {/* Then render the parent component's preview */}
      <div
        className="preview-content"
        dangerouslySetInnerHTML={{
          __html: getPreviewHtml(),
        }}
      />
    </div>
  );
};

export default ViewgraphComponentInstance;
