import React from "react";
import {
  Box,
  Button,
  VStack,
  Flex,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { DeleteIcon, ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { DirectiveContainer } from "../DirectiveContainer";
import { DirectiveListEditor } from "../DirectiveListEditor";
import { ExecuteBranchesDirective } from "@/runtime-js/src/directives/executeBranches";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface ExecuteBranchesEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export const ExecuteBranchesEditor: React.FC<ExecuteBranchesEditorProps> = ({
  keypath,
  flowgraphContext,
}) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(
    keypath,
  ) as ExecuteBranchesDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const addBranch = () => {
    const newBranch = {
      name: `Branch ${directive.config.branches.length + 1}`,
      condition: undefined,
      flowgraph: [],
    };
    const newBranches = [...directive.config.branches, newBranch];
    setFragment(keypath + "/config/branches", newBranches);
  };

  const deleteBranch = (index: number) => {
    const newBranches = directive.config.branches.filter((_, i) => i !== index);
    setFragment(keypath + "/config/branches", newBranches);
  };

  const reorderBranch = (oldIndex: number, newIndex: number) => {
    const clampedNewIndex = Math.max(
      0,
      Math.min(newIndex, directive.config.branches.length - 1),
    );
    const newBranches = [...directive.config.branches];
    const [movedBranch] = newBranches.splice(oldIndex, 1);
    newBranches.splice(clampedNewIndex, 0, movedBranch);
    setFragment(keypath + "/config/branches", newBranches);
  };

  const unsetCondition = (index: number) => {
    const newBranches = directive.config.branches.map((branch, i) =>
      i === index ? { ...branch, condition: undefined } : branch,
    );
    setFragment(keypath + "/config/branches", newBranches);
  };

  const updateConfig = (key: string, value: number) => {
    const newValue = value === -1 ? undefined : value;
    setFragment(keypath + "/config/" + key, newValue);

    // Reset requiredCompletionCount if it's no longer valid
    if (key === "maxBranchesToExecute") {
      const currentRequired = directive.config.requiredCompletionCount;
      if (
        currentRequired &&
        (newValue === undefined || currentRequired > newValue)
      ) {
        setFragment(
          keypath + "/config/requiredCompletionCount",
          undefined,
        );
      }
    }
  };

  const updateBranchName = (index: number, newName: string) => {
    const newBranches = directive.config.branches.map((branch, i) =>
      i === index ? { ...branch, name: newName } : branch,
    );
    setFragment(keypath + "/config/branches", newBranches);
  };

  const addCondition = (index: number) => {
    const newBranches = directive.config.branches.map((branch, i) =>
      i === index
        ? {
            ...branch,
            condition: {
              directiveType: "literalValue",
              config: { type: "_types.Boolean", value: true },
            },
          }
        : branch,
    );
    setFragment(keypath + "/config/branches", newBranches);
  };

  const showRequiredCompletionCount =
    (directive.config.maxBranchesToExecute === undefined &&
      directive.config.branches.length > 1) ||
    (directive.config.maxBranchesToExecute !== undefined &&
      directive.config.maxBranchesToExecute > 1);

  return (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel fontSize="sm" htmlFor="maxBranchesToExecute">
          Number of branches to execute in parallel:
        </FormLabel>
        <Select
          id="maxBranchesToExecute"
          value={directive.config.maxBranchesToExecute ?? -1} // can't be || as maxBranchesToExecute=0 is a valid case
          onChange={(e) =>
            updateConfig("maxBranchesToExecute", +e.target.value)
          }
        >
          <option value="-1">All branches</option>
          {[...Array(directive.config.branches.length)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </Select>
      </FormControl>
      {showRequiredCompletionCount && (
        <FormControl>
          <FormLabel fontSize="sm" htmlFor="requiredCompletionCount">
            Number of branches that must complete before proceeding:
          </FormLabel>
          <Select
            id="requiredCompletionCount"
            value={directive.config.requiredCompletionCount ?? -1}
            onChange={(e) =>
              updateConfig("requiredCompletionCount", +e.target.value)
            }
          >
            <option value="-1">All branches</option>
            {[
              ...Array(
                directive.config.maxBranchesToExecute ||
                  directive.config.branches.length,
              ),
            ].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
      <Accordion allowMultiple defaultIndex={[0]}>
        {directive.config.branches.map((branch, index) => (
          <AccordionItem key={index}>
            <Flex justify="space-between" align="center" mb={2}>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {branch.name}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <Flex>
                <IconButton
                  aria-label="Move branch up"
                  icon={<ArrowUpIcon />}
                  size="sm"
                  onClick={() => reorderBranch(index, index - 1)}
                  mr={1}
                />
                <IconButton
                  aria-label="Move branch down"
                  icon={<ArrowDownIcon />}
                  size="sm"
                  onClick={() => reorderBranch(index, index + 1)}
                  mr={1}
                />
                <IconButton
                  aria-label="Delete branch"
                  icon={<DeleteIcon />}
                  size="sm"
                  onClick={() => deleteBranch(index)}
                />
              </Flex>
            </Flex>

            <AccordionPanel pb={4} gap={8}>
              <FormControl mb={2}>
                <FormLabel htmlFor={`branchName-${index}`}>
                  Branch Name:
                </FormLabel>
                <Input
                  id={`branchName-${index}`}
                  value={branch.name}
                  onChange={(e) => updateBranchName(index, e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Condition:</FormLabel>
                {!branch.condition && (
                  <Button
                    onClick={() => addCondition(index)}
                    colorScheme="blue"
                    size="sm"
                  >
                    Add condition
                  </Button>
                )}
                {branch.condition && (
                  <DirectiveContainer
                    keypath={`${keypath}/config/branches/${index}/condition`}
                    onRemoveDirective={() => unsetCondition(index)}
                    flowgraphContext={{
                      ...flowgraphContext,
                      declaredVariables: new Set(),
                    }}
                  />
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Flowgraph:</FormLabel>
                <DirectiveListEditor
                  keypath={`${keypath}/config/branches/${index}/flowgraph`}
                />
              </FormControl>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Button onClick={addBranch} colorScheme="blue">
        Add Branch
      </Button>
    </VStack>
  );
};
