import React from 'react';
import { Select, FormControl, FormErrorMessage } from '@chakra-ui/react';

interface PageSelectorProps {
  value: string;
  onChange: (pageId: string) => void;
  pages: any[];
  isInvalid: boolean;
}

export const PageSelector: React.FC<PageSelectorProps> = ({ value, onChange, pages, isInvalid }) => {
  return (
    <FormControl isInvalid={isInvalid}>
      <Select value={value} onChange={(e) => onChange(e.target.value)}>
        {pages.map((page) => (
          <option key={page.id} value={page.id}>
            {page.name}
          </option>
        ))}
      </Select>
      {isInvalid && <FormErrorMessage>Invalid page selected</FormErrorMessage>}
    </FormControl>
  );
};