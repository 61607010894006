import React from 'react';
import { Box } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import ComponentBlueprintsIndex from './ComponentBlueprintsIndex';
import ComponentBlueprintEditor from './ComponentBlueprintEditor';

const ComponentBlueprints: React.FC = () => {
  return (
    <Box p={4}>
      <Routes>
        <Route index element={<ComponentBlueprintsIndex />} />
        <Route path=":componentBlueprintId" element={<ComponentBlueprintEditor />} />
      </Routes>
    </Box>
  );
};

export default ComponentBlueprints;