import { type Directive, isDirective } from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";

export type NativeCodeDirective = Directive<"nativeCode", NativeCodeConfig>;

export interface NativeCodeConfig {
  javascript: { code: string };
  returns: { type: string };
}

export function isNativeCodeDirective(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directive: any,
): directive is NativeCodeDirective {
  const nativeCodeConfigKeys: (keyof NativeCodeConfig)[] = [
    "javascript",
    "returns",
  ];
  return isDirective(directive, "nativeCode", nativeCodeConfigKeys);
}

const execute = async (
  config: NativeCodeConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { javascript, returns } = config;

  if (!javascript || typeof javascript !== "object" || !javascript.code) {
    throw new Error(
      "No valid JavaScript code provided for native code directive",
    );
  }

  const wrappedCode = `
    return (async function(__arguments) {
      ${javascript.code}
    })(__arguments);
  `;

  const func = new Function("__arguments", wrappedCode);
  const __arguments = executionContext.getArguments();

  try {
    const rawResult = await func(__arguments);
    if (
      typeof rawResult === "object" &&
      "type" in rawResult &&
      "value" in rawResult
    ) {
      return rawResult as TypedValue;
    } else {
      return {
        type: returns.type,
        value: rawResult,
      };
    }
  } catch (error) {
    console.error(`Error executing native code: ${error}`);
    throw error;
  }
};

function updateJavaScriptCode(
  directive: NativeCodeDirective,
  code: string,
): void {
  if (!isNativeCodeDirective(directive)) {
    throw new Error("Directive is not a nativeCode directive");
  }
  directive.config.javascript.code = code;
}

function updateReturnsType(directive: NativeCodeDirective, type: string): void {
  if (!isNativeCodeDirective(directive)) {
    throw new Error("Directive is not a nativeCode directive");
  }
  directive.config.returns.type = type;
}

export const nativeCode = {
  execute,
  availableActions: {
    updateJavaScriptCode,
    updateReturnsType,
  },
  defaultConfig: () =>
    ({
      javascript: { code: "" },
      returns: { type: "_types.String" },
    }) as NativeCodeDirective["config"],
};
