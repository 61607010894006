import React from 'react';
import { Box, Flex, Heading, VStack } from '@chakra-ui/react';
import Breadcrumbs from '../layout/Breadcrumbs';

interface CollectionHeaderProps {
  title: string;
  description?: string;
  keypath: string;
}

const CollectionHeader: React.FC<CollectionHeaderProps> = ({ title, description, keypath }) => {
  return (
    <Box mb={6} mt={4}>
      <Flex justifyContent="space-between" alignItems="start" mb={2}>
        <VStack align="stretch" spacing={0}>
          <Heading size="lg" mb={2}>{title}</Heading>
          {description && <Box color="gray.600">{description}</Box>}
        </VStack>
        <Box mt={2}>
          <Breadcrumbs keypath={keypath} />
        </Box>
      </Flex>
    </Box>
  );
};

export default CollectionHeader;