import React, { useState, useMemo, useEffect } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, VStack, Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Flex, IconButton, Text } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { DataModel, DataModelAssociation } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';

const associationTypes = ['belongs_to', 'has_one', 'has_many', 'has_many_through'] as const;
const dependentBehaviors = ['none', 'destroy', 'nullify', 'delete_all', 'restrict_with_error', 'restrict_with_exception'] as const;

const Associations: React.FC<{ keypath: string }> = ({ keypath }) => {
  const { getFragment, getModelByKeypath, addRecordToCollectionFragment, removeRecordFromCollectionFragment } = useAppDescriptorStore();
  const associations = getFragment(keypath) as DataModelAssociation[] || [];
  const model = getModelByKeypath(keypath) as DataModel;
  const currentModelId = model.id;
  
  const availableModels = useMemo(() => {
    const models = (getFragment('/essentials/dataModels') as DataModel[]) || [];
    return models
      .filter((m) => m.id !== currentModelId)
      .map((model) => ({ id: model.id, name: model.name }));
  }, [getFragment, currentModelId]);

  const getModelNameById = (modelId: string) => {
    const model = availableModels.find(m => m.id === modelId);
    return model ? model.name : 'Unknown Model';
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedType, setSelectedType] = useState<typeof associationTypes[number]>(associationTypes[0]);
  const [selectedTargetModel, setSelectedTargetModel] = useState(availableModels[0]?.id || '');
  const [selectedDependentBehavior, setSelectedDependentBehavior] = useState<typeof dependentBehaviors[number]>('none');
  const [isValidSelection, setIsValidSelection] = useState(true);
  const [showDependentBehavior, setShowDependentBehavior] = useState(true);

  useEffect(() => {
    setShowDependentBehavior(selectedType !== 'belongs_to');
  }, [selectedType]);

  useEffect(() => {
    const isDuplicate = associations.some(
      (assoc) => assoc.associationType === selectedType && assoc.targetModelId === selectedTargetModel
    );
    setIsValidSelection(!isDuplicate);
  }, [selectedType, selectedTargetModel, associations]);

  const addAssociation = () => {
    if (selectedType && selectedTargetModel) {
      // Check if the association already exists
      const existingAssociation = associations.find(
        (assoc) => assoc.associationType === selectedType && assoc.targetModelId === selectedTargetModel
      );

      if (existingAssociation) {
        alert(`An association of type "${selectedType}" with the target model already exists.`);
        return;
      }

      const newAssociation = {
        id: `${selectedType}-${selectedTargetModel}`, // Unique ID
        associationType: selectedType,
        targetModelId: selectedTargetModel,
        dependentRecordsBehaviorOnDestroy: selectedType === 'belongs_to' ? 'N/A' : selectedDependentBehavior,
      };
      addRecordToCollectionFragment(keypath, newAssociation);
      onClose();
    }
  };

  const removeAssociation = (associationId: string) => {
    if (window.confirm('Are you sure you want to remove this association?')) {
      removeRecordFromCollectionFragment(keypath, associationId);
    }
  };

  // Reset state when modal opens
  const handleOpenModal = () => {
    setSelectedType(associationTypes[0]);
    setSelectedTargetModel(availableModels[0]?.id || '');
    setSelectedDependentBehavior('none');
    onOpen();
  };

  return (
    <VStack spacing={6} align="stretch" maxWidth="800px" mx="auto">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="lg">Associations</Heading>
        <Button leftIcon={<FiPlus />} onClick={handleOpenModal}>Add association</Button>
      </Flex>

      {associations.length === 0 ? (
        <Text>No associations defined for this model. Click "Add association" to create one.</Text>
      ) : (
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Target Model</Th>
              <Th>Dependent Behavior</Th>
              <Th width="40px"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {associations.map((association) => (
              <Tr key={association.id}>
                <Td>{association.associationType}</Td>
                <Td>{getModelNameById(association.targetModelId)}</Td>
                <Td>{association.associationType === 'belongs_to' ? 'N/A' : association.dependentRecordsBehaviorOnDestroy}</Td>
                <Td width="40px">
                  <IconButton
                    aria-label="Remove association"
                    icon={<FiTrash2 />}
                    size="sm"
                    onClick={() => removeAssociation(association.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Association</ModalHeader>
          <ModalBody>
            <Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value as typeof associationTypes[number])}
              mb={4}
            >
              {associationTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            <Select
              value={selectedTargetModel}
              onChange={(e) => setSelectedTargetModel(e.target.value)}
              mb={4}
            >
              {availableModels.map((model) => (
                <option key={model.id} value={model.id}>
                  {model.name}
                </option>
              ))}
            </Select>
            {showDependentBehavior && (
              <>
                <Heading size="sm" mb={2}>Dependent records' behavior on parent destroy</Heading>
                <Select
                  value={selectedDependentBehavior}
                  onChange={(e) => setSelectedDependentBehavior(e.target.value as typeof dependentBehaviors[number])}
                >
                  {dependentBehaviors.map((behavior) => (
                    <option key={behavior} value={behavior}>
                      {behavior}
                    </option>
                  ))}
                </Select>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={addAssociation}
              isDisabled={!selectedTargetModel || !isValidSelection}
            >
              Add
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Associations;