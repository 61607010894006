import React, { useState } from "react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  Tooltip,
  VStack,
  Button,
  Select,
} from "@chakra-ui/react";
import {
  IconMinus,
  IconTrash,
  IconServer,
  IconChevronUp,
  IconChevronDown,
  IconPlus,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { ConditionalNode, ServiceCallChain } from "../types";
import { PropertiesPanel } from "./PropertiesPanel";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface ServiceCallNodeProps {
  node: ConditionalNode;
  onDeleteNode: (nodeId: string) => void;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  onSelectField: (nodeId: string, field: string) => void;
  isSelected: boolean;
  onFieldUpdate: (nodeId: string, field: string, value: string | any) => void;
  onUpdateNode: (nodeId: string, updatedNode: ConditionalNode) => void;
  availableVariables: string[];
  handleClosePropertiesPanel: () => void;
  availableDataVariables: { id: string; name: string }[];
}

export function ServiceCallNode({
  node,
  onDeleteNode,
  onReorder,
  isFirst,
  isLast,
  onSelectField,
  isSelected,
  onFieldUpdate,
  onUpdateNode,
  availableVariables,
  handleClosePropertiesPanel,
  availableDataVariables,
}: ServiceCallNodeProps) {
  const { getFragment } = useAppDescriptorStore();
  const [isChaining, setIsChaining] = useState(false);
  const [editingChainIndex, setEditingChainIndex] = useState<number | null>(
    null
  );

  const dataServices = getFragment("/essentials/dataServices") || [];
  const availableServiceMethods = dataServices.flatMap((service: any) =>
    service.methods.map((method: any) => ({
      serviceName: service.name,
      methodName: method.name,
    }))
  );

  const serviceName = node.parameters?.serviceName || "";
  const methodName = node.parameters?.methodName || "";

  const handleChainClick = () => {
    const updatedNode = {
      ...node,
      chains: [
        ...(node.chains || []),
        {
          serviceName: "",
          methodName: "",
          parameters: {
            returnVariable: "",
          },
        } as ServiceCallChain,
      ],
    };
    onUpdateNode(node.id, updatedNode);
    setIsChaining(true);
  };

  const handleChainServiceMethodChange = (
    newServiceName: string,
    newMethodName: string,
    chainIndex: number
  ) => {
    if (!node.chains) return;

    const updatedChains = [...node.chains];
    updatedChains[chainIndex] = {
      ...updatedChains[chainIndex],
      serviceName: newServiceName,
      methodName: newMethodName,
      parameters: {
        returnVariable: "",
      },
    };

    const updatedNode = {
      ...node,
      chains: updatedChains,
    };
    onUpdateNode(node.id, updatedNode);
  };

  const handleChainParameterChange = (paramName: string, value: string) => {
    if (!node.chains) return;

    const updatedNode = {
      ...node,
      chains: {
        ...node.chains,
        parameters: {
          ...("parameters" in node.chains ? node.chains.parameters : {}),
          [paramName]: value,
        },
      },
    };
    onUpdateNode(node.id, updatedNode);
  };

  const handleServiceMethodSelect = (value: string, chainIndex?: number) => {
    const [newServiceName, newMethodName] = value.split(".");

    if (chainIndex !== undefined) {
      const updatedChains = [...(node.chains || [])];
      updatedChains[chainIndex] = {
        ...updatedChains[chainIndex],
        serviceName: newServiceName,
        methodName: newMethodName,
        parameters: {
          ...(updatedChains[chainIndex]?.parameters || {}),
          returnVariable:
            updatedChains[chainIndex]?.parameters?.returnVariable || "",
        },
      };

      const updatedNode = {
        ...node,
        chains: updatedChains,
      };
      onUpdateNode(node.id, updatedNode);
    } else {
      const updatedParameters = {
        ...node.parameters,
        serviceName: newServiceName,
        methodName: newMethodName,
      };
      onFieldUpdate(node.id, "parameters", updatedParameters);
    }
  };

  return (
    <Box position="relative" role="group">
      <Box
        borderWidth={1}
        borderColor={colors.blueDark.blue12}
        borderRadius="md"
        bg="#202124"
        width="350px"
        overflow="hidden"
        boxShadow={isSelected ? "0 0 10px rgba(66, 153, 225, 0.6)" : "none"}
        _hover={{ boxShadow: "0 0 10px rgba(66, 153, 225, 0.3)" }}
      >
        <HStack
          p={3}
          bg={`${colors.blueDark.blue12}20`}
          justifyContent="space-between"
        >
          <HStack>
            <IconButton
              icon={<IconMinus size={16} />}
              aria-label="Collapse"
              size="sm"
              variant="ghost"
              color={colors.grayDark.gray12}
            />
            <Text fontWeight="medium" color="white">
              Service Call
            </Text>
          </HStack>
          <HStack>
            {!isFirst && (
              <Tooltip label="Move Up" aria-label="Move Up Tooltip">
                <IconButton
                  icon={<IconChevronUp size={16} />}
                  aria-label="Move Up"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("up")}
                />
              </Tooltip>
            )}
            {!isLast && (
              <Tooltip label="Move Down" aria-label="Move Down Tooltip">
                <IconButton
                  icon={<IconChevronDown size={16} />}
                  aria-label="Move Down"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("down")}
                />
              </Tooltip>
            )}
            <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
              <IconButton
                icon={<IconTrash size={16} />}
                aria-label="Delete Node"
                size="sm"
                variant="ghost"
                color={colors.red.red6}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteNode(node.id);
                }}
                _hover={{ bg: colors.red.red3 }}
              />
            </Tooltip>
          </HStack>
        </HStack>

        <VStack align="stretch" p={3} bg="#303134" spacing={2}>
          <HStack>
            <IconServer size={16} color={colors.blueDark.blue12} />
            <Text color="white" fontWeight="medium">
              Service Call
            </Text>
          </HStack>

          <Box
            p={2}
            bg="#3c4043"
            cursor="pointer"
            onClick={() => onSelectField(node.id, "serviceName")}
            _hover={{ bg: "#4a4d51" }}
            borderRadius="md"
          >
            <HStack justifyContent="space-between">
              <Text color="white" fontWeight="medium">
                {serviceName || "(Select Service)"}
              </Text>
              <Text color={colors.grayDark.gray11}>Service Name</Text>
            </HStack>
          </Box>

          <Box
            p={2}
            bg="#3c4043"
            cursor="pointer"
            onClick={() => onSelectField(node.id, "methodName")}
            _hover={{ bg: "#4a4d51" }}
            borderRadius="md"
          >
            <HStack justifyContent="space-between">
              <Text color="white" fontWeight="medium">
                {methodName || "(Select Method)"}
              </Text>
              <Text color={colors.grayDark.gray11}>Method Name</Text>
            </HStack>
          </Box>
        </VStack>

        {node.chains?.map((chain, index) => (
          <React.Fragment key={index}>
            <Box
              borderTopWidth={1}
              borderColor={colors.blueDark.blue12}
              mx={3}
              my={2}
              opacity={0.5}
            />
            <VStack align="stretch" p={3} bg="#303134" spacing={2} role="group">
              <HStack width="100%" justifyContent="space-between">
                <Text fontWeight="medium" color="white">
                  Chain Service Call {index + 1}:
                </Text>
                <IconButton
                  icon={<IconTrash size={16} />}
                  aria-label="Remove Chain"
                  size="sm"
                  variant="ghost"
                  color={colors.red.red6}
                  opacity={0}
                  _groupHover={{ opacity: 1 }}
                  onClick={() => {
                    const updatedChains = node.chains?.filter(
                      (_, i) => i !== index
                    );
                    const updatedNode = {
                      ...node,
                      chains: updatedChains,
                    };
                    onUpdateNode(node.id, updatedNode);
                  }}
                />
              </HStack>

              <Box
                p={2}
                bg="#3c4043"
                cursor="pointer"
                onClick={() => {
                  setIsChaining(true);
                  onSelectField(node.id, "name");
                  // Store the current chain index
                  setEditingChainIndex(index);
                }}
                _hover={{ bg: "#4a4d51" }}
                borderRadius="md"
              >
                <HStack justifyContent="space-between">
                  <Text color="white" fontWeight="medium">
                    {chain.serviceName || "(Select Service)"}
                  </Text>
                  <Text color={colors.grayDark.gray11}>Service Name</Text>
                </HStack>
              </Box>

              <Box
                p={2}
                bg="#3c4043"
                cursor="pointer"
                onClick={() => onSelectField(node.id, "chainMethodName")}
                _hover={{ bg: "#4a4d51" }}
                borderRadius="md"
              >
                <HStack justifyContent="space-between">
                  <Text color="white" fontWeight="medium">
                    {chain.methodName || "(Select Method)"}
                  </Text>
                  <Text color={colors.grayDark.gray11}>Method Name</Text>
                </HStack>
              </Box>
            </VStack>
          </React.Fragment>
        ))}
      </Box>

      <Button
        leftIcon={<IconPlus size={14} />}
        size="sm"
        position="absolute"
        bottom="-8"
        right="0"
        opacity="0"
        _groupHover={{ opacity: 1 }}
        bg="#202124"
        color="white"
        _hover={{ bg: "#303134" }}
        borderWidth={1}
        borderColor={colors.blueDark.blue12}
        onClick={handleChainClick}
      >
        Chain
      </Button>

      {isSelected && (
        <PropertiesPanel
          selectedField={{ nodeId: node.id, field: "name" }}
          fieldValue={
            isChaining && editingChainIndex !== null
              ? `${node.chains?.[editingChainIndex]?.serviceName || ""}.${
                  node.chains?.[editingChainIndex]?.methodName || ""
                }`
              : `${serviceName}.${methodName}`
          }
          onClose={() => {
            handleClosePropertiesPanel();
            setIsChaining(false);
            setEditingChainIndex(null);
          }}
          onFieldUpdate={(nodeId, field, value) => {
            if (field === "name") {
              handleServiceMethodSelect(value as string, editingChainIndex);
            }
            handleClosePropertiesPanel();
            setIsChaining(false);
            setEditingChainIndex(null);
          }}
          availableVariables={availableVariables}
          availableServiceMethods={availableServiceMethods}
          availableDataVariables={availableDataVariables}
        />
      )}
    </Box>
  );
}
