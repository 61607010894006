import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Heading, Button, VStack, Badge, Box } from '@chakra-ui/react';
import { DataModelField, DataModelValidation } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';

interface FieldsPerValidationProps {
  fieldsPerValidation: Record<string, DataModelField[]>;
  openMassEditModal: (validationName: string, args: Record<string, any>) => void;
  openAddValidationModal: () => void;
}

const FieldsPerValidation: React.FC<FieldsPerValidationProps> = ({
  fieldsPerValidation,
  openMassEditModal,
  openAddValidationModal,
}) => {
  const getUniqueValidations = (validationName: string, fields: DataModelField[]): DataModelValidation[] => {
    const uniqueValidations: DataModelValidation[] = [];
    fields.forEach(field => {
      const validation = field.validations?.find(v => v.validationFunctionName === validationName);
      if (validation) {
        const existingValidation = uniqueValidations.find(v => 
          JSON.stringify(v.arguments) === JSON.stringify(validation.arguments)
        );
        if (!existingValidation) {
          uniqueValidations.push(validation);
        }
      }
    });
    return uniqueValidations;
  };

  return (
    <Box>
      <Heading as="h2" size="md" mb={4}>Fields per validation</Heading>
      <Button colorScheme="blue" size="sm" onClick={openAddValidationModal} mb={4}>
        Add New Validation
      </Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Validation</Th>
            <Th>Arguments</Th>
            <Th>Fields</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(fieldsPerValidation).map(([validationName, fields]) => {
            const uniqueValidations = getUniqueValidations(validationName, fields);
            return uniqueValidations.map((validation, index) => (
              <Tr key={`${validationName}-${index}`}>
                <Td verticalAlign="top">{validationName}</Td>
                <Td verticalAlign="top">
                  {Object.entries(validation.arguments || {}).map(([key, value]) => (
                    <div key={key}>{`${key}: ${JSON.stringify(value)}`}</div>
                  ))}
                </Td>
                <Td verticalAlign="top">
                  <VStack align="start" spacing={2}>
                    {fields.filter(field => 
                      JSON.stringify(field.validations?.find(v => v.validationFunctionName === validationName)?.arguments) === 
                      JSON.stringify(validation.arguments)
                    ).map(field => (
                      <Badge key={field.id} colorScheme="green" p={1} borderRadius="md">
                        {field.name}
                      </Badge>
                    ))}
                  </VStack>
                </Td>
                <Td verticalAlign="top">
                  <Button colorScheme="blue" size="sm" onClick={() => openMassEditModal(validationName, validation.arguments || {})}>
                    Mass edit fields
                  </Button>
                </Td>
              </Tr>
            ));
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default FieldsPerValidation;