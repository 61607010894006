import {
  type Directive,
  type NestableDirective,
  type AnyDirective,
  isDirective,
} from "./directiveTypes";
import type { DataType } from "../types/dataType";
import type { TypedValue } from "../types/typedValue";
import {
  getVariableNames,
  getDefaultVariable,
  searchAnyDirective,
} from "../flowFunctionStore";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";

export type DeclareVariableDirective = Directive<
  "declareVariable",
  DeclareVariableConfig
>;

export interface DeclareVariableConfig {
  name: string;
  type: DataType;
  initialValue?: NestableDirective;
}

export function isDeclareVariableDirective(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directive: any,
): directive is DeclareVariableDirective {
  const declareVariableConfigKeys: (keyof DeclareVariableConfig)[] = [
    "name",
    "type",
    "initialValue",
  ];
  return isDirective(directive, "declareVariable", declareVariableConfigKeys);
}

const execute = async (
  config: DeclareVariableConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { name, type, initialValue } = config;

  if (executionContext.getVariable(name)) {
    throw new Error(`Variable '${name}' already exists`);
  }

  let resolvedInitialValue = {
    type: type,
    value: undefined,
  } as TypedValue;

  if (initialValue) {
    resolvedInitialValue =
      await executionContext.executeDirective(initialValue);
  }

  executionContext.declareVariable(name, type, resolvedInitialValue);

  return executionContext.getVariableTypedValue(name);
};

function searchDirective(
  directive: DeclareVariableDirective,
  id: string,
): AnyDirective | undefined {
  if (directive.id === id) {
    return directive;
  }

  const initialValue = directive.config.initialValue as AnyDirective;
  if (initialValue) {
    const found = searchAnyDirective([initialValue], id);
    if (found) {
      return found;
    }
  }

  return undefined;
}

function updateName(directive: DeclareVariableDirective, name: string) {
  const variableNames = getVariableNames();
  const oldName = directive.config.name;

  if (variableNames.includes(name) && name !== oldName) {
    throw new Error(`Variable name '${name}' is already in use`);
  }
  directive.config.name = name;
}

function updateType(directive: DeclareVariableDirective, type: DataType) {
  directive.config.type = type;
  directive.config.initialValue = undefined;
}

function updateInitialValue(
  directive: DeclareVariableDirective,
  initialValue: NestableDirective,
) {
  directive.config.initialValue = initialValue;
}

export const declareVariable = {
  execute,
  availableActions: {
    updateName,
    updateType,
    updateInitialValue,
  },
  defaultConfig: () =>
    ({
      name: getDefaultVariable()?.name || "",
      type: "_types.String",
      initialValue: undefined,
    }) as DeclareVariableConfig,
  searchDirective,
};
