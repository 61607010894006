import React, { useState, useEffect } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, VStack, Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { FiPlus, FiTrash2, FiMenu, FiInfo } from 'react-icons/fi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DataModelCallback, modelLifecycleEvents } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';

const lifecycleEventDescriptions: Record<string, string> = {
  'before_validation': 'Triggered before validation of the model.',
  'after_validation': 'Triggered after validation of the model.',
  'before_save': 'Triggered before saving the model (for both create and update).',
  'after_save': 'Triggered after saving the model (for both create and update).',
  'before_create': 'Triggered before creating a new record.',
  'after_create': 'Triggered after creating a new record.',
  'before_update': 'Triggered before updating an existing record.',
  'after_update': 'Triggered after updating an existing record.',
  'before_destroy': 'Triggered before destroying a record.',
  'after_destroy': 'Triggered after destroying a record.',
  'after_commit': 'Triggered after the database transaction is committed.',
  'after_rollback': 'Triggered after the database transaction is rolled back.'
};

const Callbacks: React.FC<{ keypath: string }> = ({ keypath }) => {
  const lifecycleEvents = modelLifecycleEvents;
  const { getFragment, setFragment, getModelByKeypath, addRecordToCollectionFragment, removeRecordFromCollectionFragment } = useAppDescriptorStore();
  const callbacks: DataModelCallback[] = getFragment(keypath) as DataModelCallback[] || [];
  const model = getModelByKeypath(keypath);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEvent, setSelectedEvent] = useState<DataModelCallback['lifecycleEvent']>(lifecycleEvents[0]);
  const [selectedMethodId, setSelectedMethodId] = useState('');

  const availableMethods = getAvailableMethods();

  useEffect(() => {
    if (availableMethods.length > 0) {
      setSelectedMethodId(availableMethods[0].id);
    }
  }, [isOpen]);

  const addCallback = () => {
    if (selectedEvent && selectedMethodId) {
      const newCallback: Partial<DataModelCallback> = {
        lifecycleEvent: selectedEvent as DataModelCallback['lifecycleEvent'],
        methodId: selectedMethodId
      };
      addRecordToCollectionFragment(keypath, newCallback as DataModelCallback);
      onClose();
    }
  };

  const removeCallback = (callbackId: string) => {
    if (window.confirm('Are you sure you want to remove this callback?')) {
      removeRecordFromCollectionFragment(keypath, callbackId);
    }
  };

  const getMethodData = (methodId: string) => {
    return model?.methods?.find(method => method.id === methodId);
  };

  function getAvailableMethods() {
    return model?.methods?.filter(method => !method.parameters?.length) || [];
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(callbacks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFragment(keypath, items);
  };

  const groupedCallbacks = callbacks.reduce((acc, callback) => {
    if (!acc[callback.lifecycleEvent]) {
      acc[callback.lifecycleEvent] = [];
    }
    acc[callback.lifecycleEvent].push(callback);
    return acc;
  }, {} as Record<string, DataModelCallback[]>);

  const eventsWithCallbacks = Object.keys(groupedCallbacks);

  return (
    <VStack spacing={6} align="stretch" maxWidth="800px" mx="auto">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="lg">Callbacks</Heading>
        <Button leftIcon={<FiPlus />} onClick={onOpen}>Add callback</Button>
      </Flex>

      {eventsWithCallbacks.length === 0 ? (
        <Text>No callbacks defined for this model. Click "Add callback" to create one.</Text>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          {eventsWithCallbacks.map((event) => (
            <Box key={event}>
              <Flex alignItems="center" mb={2}>
                <Heading size="md">{event}</Heading>
                <Tooltip label={lifecycleEventDescriptions[event]}>
                  <IconButton
                    aria-label="Event info"
                    icon={<FiInfo />}
                    size="sm"
                    variant="ghost"
                    ml={2}
                  />
                </Tooltip>
              </Flex>
              <Droppable droppableId={event}>
                {(provided) => (
                  <Table variant="simple" size="sm" {...provided.droppableProps} ref={provided.innerRef}>
                    <Thead>
                      <Tr>
                        <Th width="40px"></Th>
                        <Th>Method Name</Th>
                        <Th>Description</Th>
                        <Th width="40px"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {groupedCallbacks[event].map((callback, index) => {
                        const method = getMethodData(callback.methodId);
                        return (
                          <Draggable key={callback.id} draggableId={callback.id} index={index}>
                            {(provided, snapshot) => (
                              <Tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  background: snapshot.isDragging ? 'white' : 'inherit',
                                }}
                              >
                                <Td width="40px">
                                  <Box {...provided.dragHandleProps} cursor="move">
                                    <FiMenu />
                                  </Box>
                                </Td>
                                <Td>{method?.name || 'Unknown method'}</Td>
                                <Td>{method?.description || ''}</Td>
                                <Td width="40px">
                                  <IconButton
                                    aria-label="Remove callback"
                                    icon={<FiTrash2 />}
                                    size="sm"
                                    onClick={() => removeCallback(callback.id)}
                                  />
                                </Td>
                              </Tr>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Tbody>
                  </Table>
                )}
              </Droppable>
            </Box>
          ))}
        </DragDropContext>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Callback Method</ModalHeader>
          <ModalBody>
            <Select
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value as DataModelCallback['lifecycleEvent'])}
              mb={4}
            >
              {lifecycleEvents.map((event) => (
                <option key={event} value={event}>
                  {event}
                </option>
              ))}
            </Select>
            <Text mb={4}>{lifecycleEventDescriptions[selectedEvent]}</Text>
            <Select
              value={selectedMethodId}
              onChange={(e) => setSelectedMethodId(e.target.value)}
            >
              {availableMethods.map((method) => (
                <option key={method.id} value={method.id}>
                  {method.name}
                </option>
              ))}
            </Select>
            {availableMethods.length === 0 && (
              <Text mt={4}>No methods without parameters defined for this model. Please add methods first.</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={addCallback} isDisabled={!selectedMethodId}>
              Add
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Callbacks;