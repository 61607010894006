import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Role } from "@/bundles/DescriptorEditor/schemas/usersAndSecurity/userGroupsSchema";

interface RoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Role) => void;
  initialData?: Role | null;
}

const RoleModal: React.FC<RoleModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
}) => {
  const { register, handleSubmit, reset } = useForm<Role>({
    defaultValues: initialData || {
      name: "",
      description: "",
      permissions: [],
    },
  });

  const onSubmitForm = (data: Role) => {
    const sanitizedData = {
      ...data,
      permissions: data.permissions
        ? data.permissions
            .split(",")
            .map((p: string) => p.trim())
            .filter(Boolean)
        : [],
    };
    onSubmit(sanitizedData);
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ModalHeader>{initialData ? "Edit Role" : "Add Role"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input {...register("name", { required: true })} />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea {...register("description")} />
              </FormControl>
              <FormControl>
                <FormLabel>Permissions</FormLabel>
                <Input
                  {...register("permissions")}
                  defaultValue={initialData?.permissions?.join(", ") || ""}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit">
              {initialData ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default RoleModal;
