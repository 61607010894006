export const getHttpMethod = (action: string) => {
  switch (action) {
    case 'index':
    case 'show':
    case 'new':
    case 'edit':
      return 'GET';
    case 'create':
      return 'POST';
    case 'update':
      return 'PUT';
    case 'destroy':
      return 'DELETE';
    default:
      return 'GET';
  }
};

export const getColorScheme = (method: string) => {
  switch (method) {
    case 'GET':
      return 'green';
    case 'POST':
      return 'blue';
    case 'PUT':
      return 'orange';
    case 'DELETE':
      return 'red';
    default:
      return 'gray';
  }
};