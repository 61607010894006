import React from 'react'
import { Box, Divider, FormControl, FormLabel } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { WhileLoopDirective } from "@/runtime-js/src/directives/whileLoop";
import { DirectiveContainer } from "../DirectiveContainer";
import { DirectiveListEditor } from "../DirectiveListEditor";

interface WhileLoopEditorProps {
  keypath: string;
}

export function WhileLoopEditor({ keypath }: WhileLoopEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as WhileLoopDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="condition" fontSize="sm">
          Condition
        </FormLabel>
        <DirectiveContainer
          keypath={`${keypath}.config.condition`}
          onRemoveDirective={() => {}}
          declaredVariables={new Set()}
        />
      </FormControl>
      <Divider my={4} borderColor="whiteAlpha.400" borderStyle="dotted" />
      <FormControl>
        <FormLabel htmlFor="loopExecution" fontSize="sm">
          Loop execution
        </FormLabel>
        <DirectiveListEditor keypath={`${keypath}.config.loopingFlowgraph`} />
      </FormControl>
    </Box>
  );
}
