import React, { useMemo, useState } from 'react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { DataModelField } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';
import { coreValidationFunctions, DataModelValidation, ValidationFunction } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';
import { Box, Heading, Flex, Text, Switch } from '@chakra-ui/react';
import ValidationsPerField from './Validations/ValidationsPerField';
import FieldsPerValidation from './Validations/FieldsPerValidation';
import AddValidationModal from './Validations/AddValidationModal';
import EditValidationModal from './Validations/EditValidationModal';
import MassEditModal from './Validations/MassEditModal';

// keypath is /essentials/dataModels/modelId/fields
const Validations = ({ keypath }: { keypath: string }) => {
  const { getFragment, addRecordToCollectionFragment, updateRecordInCollectionFragment, removeRecordFromCollectionFragment } = useAppDescriptorStore();
  const fields = getFragment(keypath) as DataModelField[] || [];
  const [viewMode, setViewMode] = useState<'perField' | 'perValidation'>('perField');
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isMassEditModalOpen, setIsMassEditModalOpen] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState('');
  const [editingValidation, setEditingValidation] = useState<DataModelValidation | null>(null);
  const [massEditValidation, setMassEditValidation] = useState<string>('');

  const fieldsWithValidations = useMemo(() => {
    return fields.filter((field: DataModelField) => field.validations && field.validations.length > 0);
  }, [fields]);

  const fieldsWithoutValidations = useMemo(() => {
    return fields.filter((field: DataModelField) => !field.validations || field.validations.length === 0);
  }, [fields]);

  const fieldsPerValidation = useMemo(() => {
    const validationMap: Record<string, DataModelField[]> = {};
    fields.forEach(field => {
      field.validations?.forEach(validation => {
        if (!validationMap[validation.validationFunctionName]) {
          validationMap[validation.validationFunctionName] = [];
        }
        validationMap[validation.validationFunctionName].push(field);
      });
    });
    return validationMap;
  }, [fields]);

  const openNewModal = (fieldId: string) => {
    setSelectedFieldId(fieldId);
    setIsNewModalOpen(true);
  };

  const openEditModal = (fieldId: string, validation: DataModelValidation) => {
    setSelectedFieldId(fieldId);
    setEditingValidation(validation);
    setIsEditModalOpen(true);
  };

  const openMassEditModal = (validationName: string) => {
    setMassEditValidation(validationName);
    setIsMassEditModalOpen(true);
  };

  const closeModal = () => {
    setIsNewModalOpen(false);
    setIsEditModalOpen(false);
    setIsMassEditModalOpen(false);
    setSelectedFieldId('');
    setEditingValidation(null);
    setMassEditValidation('');
  };

  const handleAddValidation = (newValidation: DataModelValidation) => {
    addRecordToCollectionFragment(`${keypath}/id:${selectedFieldId}/validations`, newValidation);
    closeModal();
  };

  const handleUpdateValidation = (updatedValidation: DataModelValidation) => {
    if (editingValidation) {
      const validationsPath = `${keypath}/id:${selectedFieldId}/validations`;
      updateRecordInCollectionFragment(validationsPath, editingValidation.id, updatedValidation);
    }
    closeModal();
  };

  const handleRemoveValidation = () => {
    if (editingValidation) {
      const validationsPath = `${keypath}/id:${selectedFieldId}/validations`;
      removeRecordFromCollectionFragment(validationsPath, editingValidation.id);
    }
    closeModal();
  };

  const handleMassEditSave = (validatedFields: DataModelField[], nonValidatedFields: DataModelField[]) => {
    validatedFields.forEach(field => {
      if (!field.validations?.some(v => v.validationFunctionName === massEditValidation)) {
        const newValidation: DataModelValidation = {
          validationFunctionName: massEditValidation,
          arguments: {}
        };
        addRecordToCollectionFragment(`${keypath}/id:${field.id}/validations`, newValidation);
      }
    });

    nonValidatedFields.forEach(field => {
      const validationsPath = `${keypath}/id:${field.id}/validations`;
      const validationToRemove = field.validations?.find(v => v.validationFunctionName === massEditValidation);
      if (validationToRemove) {
        removeRecordFromCollectionFragment(validationsPath, validationToRemove.id);
      }
    });

    closeModal();
  };

  return (
    <Box maxWidth="800px" mx="auto">
      <Flex justify="space-between" align="center" mb={4}>
        <Heading as="h1" size="lg">Validations</Heading>
        <Flex align="center">
          <Text mr={2}>Validations per field</Text>
          <Switch
            isChecked={viewMode === 'perValidation'}
            onChange={() => setViewMode(viewMode === 'perField' ? 'perValidation' : 'perField')}
          />
          <Text ml={2}>Fields per validation</Text>
        </Flex>
      </Flex>

      {viewMode === 'perField' ? (
        <ValidationsPerField
          fieldsWithValidations={fieldsWithValidations}
          fieldsWithoutValidations={fieldsWithoutValidations}
          openNewModal={openNewModal}
          openEditModal={openEditModal}
        />
      ) : (
        <FieldsPerValidation
          fieldsPerValidation={fieldsPerValidation}
          openMassEditModal={openMassEditModal}
        />
      )}

      <AddValidationModal
        isOpen={isNewModalOpen}
        onClose={closeModal}
        onAddValidation={handleAddValidation}
        selectedFieldId={selectedFieldId}
        fields={fields}
      />

      <EditValidationModal
        isOpen={isEditModalOpen}
        onClose={closeModal}
        onUpdateValidation={handleUpdateValidation}
        onRemoveValidation={handleRemoveValidation}
        editingValidation={editingValidation}
      />

      <MassEditModal
        isOpen={isMassEditModalOpen}
        onClose={closeModal}
        onSave={handleMassEditSave}
        validationName={massEditValidation}
        fields={fields}
        fieldsPerValidation={fieldsPerValidation}
      />
    </Box>
  );
};

export default Validations;
