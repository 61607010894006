import { Directive } from "./directiveTypes";
import { AnyDirective } from "./directiveTypes";

export type SetPageVariableConfig = {
  name: string;
  value: AnyDirective;
};

export type SetPageVariableDirective = Directive<
  "setPageVariable",
  SetPageVariableConfig
>;

export const setPageVariable = {
  defaultConfig: (): SetPageVariableConfig => ({
    name: "",
    value: {
      directiveType: "literalValue",
      config: { type: "_types.String", value: "" }
    },
  }),
  execute: async (
    config: SetPageVariableConfig,
    context: { setPageVariable: (name: string, value: any) => void }
  ) => {
    const value = await context.executeDirective(config.value);
    context.setPageVariable(config.name, value);
    return value;
  },
};