import { z } from "zod";
import { v4 as uuidv4 } from "uuid";
import { DescriptorCollection, DescriptorRecord } from "../descriptorUtils";
import { ValidatorFunction } from "@/bundles/DescriptorEditor/components/editors/InlineEditableText";
import { parameterSchema } from "../shared/parametersSchema";

export interface ApiEndpointVersion {
  id: string;
  description: string;
  groups: ApiEndpointGroup[];
}

export interface ApiEndpointGroup {
  name: string;
  description: string;
  path: string;
  endpoints: ApiEndpoint[];
}

export interface ApiEndpoint {
  name: string;
  description?: string;
  method: string;
  path: string;
  flowgraph: {
    sequence: any[];
    $variables: any[];
  };
}

const apiEndpointSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  method: z.string(),
  path: z.string(),
  flowgraph: z.object({
    sequence: z.array(z.any()),
    $variables: z.array(z.any()),
  }),
});

const apiEndpointGroupSchema = z.object({
  name: z.string(),
  description: z.string(),
  path: z.string(),
  endpoints: z.array(apiEndpointSchema),
});

const apiEndpointVersionSchema = z.object({
  id: z.string(),
  description: z.string(),
  groups: z.array(apiEndpointGroupSchema),
});

export const apiEndpointsSchema = z.array(apiEndpointVersionSchema);

// Update the generator functions
export const generateDefaultApiEndpointDescriptor = (
  groupPath: string,
  name: string
): ApiEndpoint => {
  return {
    name,
    description: "",
    method: "GET",
    path: `/${name.toLowerCase().replace(/\s+/g, "-")}`,
    flowgraph: {
      sequence: [],
      $variables: [],
    },
  };
};

export const generateDefaultApiEndpointGroup = (
  name: string
): ApiEndpointGroup => {
  return {
    name,
    description: "",
    path: name.toLowerCase().replace(/\s+/g, "-"),
    endpoints: [],
  };
};

export const generateDefaultApiEndpointVersion = (
  id: string = "v1",
  description: string = "Initial API version"
): ApiEndpointVersion => {
  return {
    id,
    description,
    groups: [],
  };
};

const apiNamespaceSchema = DescriptorRecord(
  z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    basePath: z.string(),
    authenticationRequired: z.boolean().default(false),
  })
);

export const apiNamespacesSchema = DescriptorCollection(apiNamespaceSchema);

export type ApiNamespace = z.infer<typeof apiNamespaceSchema>;

export const validatePath = (path: string): boolean => {
  const pathRegex = /^\/(?!.*\/\/)(?!.*\/$)[a-zA-Z0-9\-_/:]*$/;
  return pathRegex.test(path);
};

export const validatePathWithErrors: ValidatorFunction = (path: string) => {
  const errors: string[] = [];
  if (!path.startsWith("/")) {
    errors.push("Path must start with '/'");
  }
  if (path.length > 50) {
    errors.push("Path must be 50 characters or less");
  }
  if (!/^[a-zA-Z0-9\-_/:]*$/.test(path)) {
    errors.push(
      "Path can only contain letters, numbers, hyphens, underscores, colons, and forward slashes"
    );
  }
  return errors;
};

export const validateApiEndpointPath: ValidatorFunction = (
  path: string,
  groupPath: string,
  endpoints: ApiEndpoint[],
  currentEndpointId?: string,
  method?: string
) => {
  const errors = validatePathWithErrors(path);

  if (
    endpoints.some(
      (endpoint) =>
        endpoint.path === path &&
        endpoint.method === method &&
        endpoint.name !== currentEndpointId // Using name as ID for now
    )
  ) {
    errors.push(
      "Path and HTTP method combination must be unique within the API group"
    );
  }
  return errors;
};

export const generateDefaultApiNamespaceDescriptor = (
  name: string
): ApiNamespace => {
  const id = uuidv4();
  return {
    id,
    name,
    basePath: `/${name.toLowerCase().replace(/\s+/g, "-")}`,
    authenticationRequired: false,
  };
};

export const validateGroupPath: ValidatorFunction = (
  path: string,
  version: ApiEndpointVersion,
  currentGroupName?: string
) => {
  const errors = validatePathWithErrors(path);

  if (
    version.groups.some(
      (group) => group.path === path && group.name !== currentGroupName
    )
  ) {
    errors.push("Path must be unique among all groups in this version");
  }
  return errors;
};
