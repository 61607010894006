export const traverseKeypath = (obj: any, keypath: string, createMissing: boolean = false, value?: any): any => {
  const parts = keypath.split('/').filter(Boolean);
  let current = obj;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part.startsWith('id:')) {
      // Handle id: syntax
      const id = part.slice(3);
      const found = Array.isArray(current) ? current.find(item => item.id === id) : null;
      if (!found && !createMissing) {
        throw new Error(`Keypath not found: ${keypath}`);
      }
      current = found || {};
      if (createMissing && Array.isArray(current)) {
        current.push({ id });
      }
    } else if (i === parts.length - 1) {
      if (createMissing && value !== undefined) {
        current[part] = value;
      }
      return current[part];
    } else {
      if (!current[part]) {
        if (createMissing) {
          current[part] = /^\d+$/.test(parts[i + 1]) ? [] : {};
        } else {
          throw new Error(`Keypath not found: ${keypath}`);
        }
      }
      current = current[part];
    }
  }
  return current;
};

export type Operation = {
  path: string;
  op: 'add' | 'remove' | 'replace' | 'updateObject' | 'move' | 'copy' | 'test' | 'addRecord' | 'removeRecord' | 'replaceRecord' | 'updateRecord';
  value?: any;
  from?: string;
  id?: string;
  summary?: string;
};

// Custom applyPatch implementation
export function applyPatch(document: any, patch: Operation): void {
  const getValueAtPath = (obj: any, path: string) => {
    return traverseKeypath(obj, path);
  };

  const setValueAtPath = (obj: any, path: string, value: any) => {
    const pathParts = path.split('/').filter(Boolean);
    const lastPart = pathParts.pop();
    const parent = traverseKeypath(obj, pathParts.join('/'), true);
    
    if (Array.isArray(parent)) {
      if (lastPart === '-') {
        parent.push(value);
      } else {
        const index = parseInt(lastPart || '', 10);
        if (!isNaN(index)) {
          parent[index] = value;
        } else {
          parent.push(value);
        }
      }
    } else if (parent && typeof parent === 'object') {
      parent[lastPart || ''] = value;
    }
  };

  const removeValueAtPath = (obj: any, path: string) => {
    const pathParts = path.split('/').filter(Boolean);
    const lastPart = pathParts.pop();
    const parent = traverseKeypath(obj, pathParts.join('/'));
    if (Array.isArray(parent)) {
      if (lastPart === '-') {
        parent.pop();
      } else if (lastPart?.startsWith('id:')) {
        const id = lastPart.slice(3);
        const index = parent.findIndex((item: any) => item.id === id);
        if (index !== -1) parent.splice(index, 1);
      } else {
        const index = parseInt(lastPart || '', 10);
        if (!isNaN(index)) parent.splice(index, 1);
      }
    } else if (parent && typeof parent === 'object') {
      delete parent[lastPart || ''];
    }
  };

  switch (patch.op) {
    case 'add':
      if (patch.path.endsWith('/-')) {
        const arrayPath = patch.path.slice(0, -2);
        const array = getValueAtPath(document, arrayPath);
        if (Array.isArray(array)) {
          array.push(patch.value);
        } else {
          throw new Error(`Path ${arrayPath} is not an array`);
        }
      } else {
        setValueAtPath(document, patch.path, patch.value);
      }
      break;
    case 'remove':
      if (patch.path.includes('/-')) {
        const arrayPath = patch.path.split('/-')[0];
        const array = getValueAtPath(document, arrayPath);
        if (Array.isArray(array)) {
          array.pop();
        } else {
          throw new Error(`Path ${arrayPath} is not an array`);
        }
      } else {
        removeValueAtPath(document, patch.path);
      }
      break;
    case 'replace':
      setValueAtPath(document, patch.path, patch.value);
      break;
    case 'updateObject':
      const existingObject = getValueAtPath(document, patch.path);
      if (existingObject === undefined) {
        throw new Error(`Cannot update non-existent object at path ${patch.path}`);
      }
      if (typeof existingObject !== 'object') {
        throw new Error(`Path ${patch.path} is not an object`);
      }
      setValueAtPath(document, patch.path, { ...existingObject, ...patch.value });
      break;
    case 'move':
      if (!patch.from) throw new Error('from is required for move operation');
      const valueToMove = getValueAtPath(document, patch.from);
      if (valueToMove === undefined) {
        throw new Error(`Cannot move non-existent value from path ${patch.from}`);
      }
      const parentFrom = traverseKeypath(document, patch.from.substring(0, patch.from.lastIndexOf('/')));
      const keyFrom = patch.from.split('/').pop();
      if (Array.isArray(parentFrom)) {
        parentFrom.splice(parseInt(keyFrom as string), 1);
      } else {
        delete parentFrom[keyFrom as string];
      }
      const parentTo = traverseKeypath(document, patch.path.substring(0, patch.path.lastIndexOf('/')), true);
      const keyTo = patch.path.split('/').pop();
      if (keyTo === '-' && Array.isArray(parentTo)) {
        parentTo.push(valueToMove);
      } else {
        parentTo[keyTo as string] = valueToMove;
      }
      break;
    case 'copy':
      if (!patch.from) throw new Error('from is required for copy operation');
      const valueToCopy = getValueAtPath(document, patch.from);
      if (valueToCopy === undefined) {
        throw new Error(`Cannot copy non-existent value from path ${patch.from}`);
      }
      setValueAtPath(document, patch.path, JSON.parse(JSON.stringify(valueToCopy)));
      break;
    case 'test':
      const valueToTest = getValueAtPath(document, patch.path);
      if (JSON.stringify(valueToTest) !== JSON.stringify(patch.value)) {
        throw new Error(`Test failed for path ${patch.path}`);
      }
      break;
    case 'addRecord':
      if (!patch.id) throw new Error('id is required for addRecord operation');
      const collection = getValueAtPath(document, patch.path);
      if (!Array.isArray(collection)) throw new Error(`Path ${patch.path} is not an array`);
      collection.push({ id: patch.id, ...patch.value });
      break;
    case 'removeRecord':
      if (!patch.id) throw new Error('id is required for removeRecord operation');
      const collectionToRemoveFrom = getValueAtPath(document, patch.path);
      if (!Array.isArray(collectionToRemoveFrom)) throw new Error(`Path ${patch.path} is not an array`);
      const indexToRemove = collectionToRemoveFrom.findIndex((item: any) => item.id === patch.id);
      if (indexToRemove === -1) throw new Error(`Record with id ${patch.id} not found at path ${patch.path}`);
      collectionToRemoveFrom.splice(indexToRemove, 1);
      break;
    case 'replaceRecord':
      if (!patch.id) throw new Error('id is required for replaceRecord operation');
      const collectionToReplaceIn = getValueAtPath(document, patch.path);
      if (!Array.isArray(collectionToReplaceIn)) throw new Error(`Path ${patch.path} is not an array`);
      const indexToReplace = collectionToReplaceIn.findIndex((item: any) => item.id === patch.id);
      if (indexToReplace === -1) throw new Error(`Record with id ${patch.id} not found at path ${patch.path}`);
      collectionToReplaceIn[indexToReplace] = { id: patch.id, ...patch.value };
      break;
    case 'updateRecord':
      if (!patch.id) throw new Error('id is required for updateRecord operation');
      const collectionToUpdate = getValueAtPath(document, patch.path);
      if (!Array.isArray(collectionToUpdate)) throw new Error(`Path ${patch.path} is not an array`);
      const indexToUpdate = collectionToUpdate.findIndex((item: any) => item.id === patch.id);
      if (indexToUpdate === -1) throw new Error(`Record with id ${patch.id} not found at path ${patch.path}`);
      collectionToUpdate[indexToUpdate] = { ...collectionToUpdate[indexToUpdate], ...patch.value };
      break;
    default:
      throw new Error(`Operation ${patch.op} not supported`);
  }
}