import { type Directive, type AnyDirective } from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import { searchAnyDirective } from "../flowFunctionStore";
import { CancellablePromise } from "../utils/CancellablePromise";

export type ExecuteSomeInParallelDirective = Directive<
  "executeSomeInParallel",
  ExecuteSomeInParallelConfig
>;
export interface ExecuteSomeInParallelConfig {
  requiredCompletionCount: number;
  tracks: Array<{
    trackName: string;
    flowgraph: AnyDirective[];
  }>;
  timeout?: number;
  swallowErrors?: boolean;
}

interface SuccessResult {
  trackName: string;
  returnValue: TypedValue;
}

interface AbortedResult {
  trackName: string;
}

interface ErrorResult {
  trackName: string;
  error: Error;
}

type TrackResult = SuccessResult | ErrorResult | AbortedResult;

const execute = async (
  config: ExecuteSomeInParallelConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { tracks, requiredCompletionCount, timeout, swallowErrors } = config;

  const completedResults: Record<string, TypedValue> = {};
  const errors: Record<string, string> = {};
  const abortedTracks: string[] = [];
  let completedCount = 0;

  const pendingPromises = tracks.map((track) => {
    const { trackName, flowgraph } = track;
    return {
      trackName,
      promise: new CancellablePromise<TrackResult>(
        async (resolve, _, onCancel) => {
          try {
            let result: TypedValue | undefined;
            for (const directive of flowgraph) {
              if (onCancel.isCancelled) {
                return;
              }
              result = await executionContext.executeDirective(directive);
            }
            resolve({ trackName, returnValue: result as TypedValue });
          } catch (error) {
            resolve({
              trackName,
              error: error instanceof Error ? error : new Error(String(error)),
            });
          }
        },
      ),
    };
  });

  try {
    const timeoutPromise = new Promise<never>((_, reject) =>
      setTimeout(() => reject(new Error("Execution timed out")), timeout),
    );

    while (
      completedCount < requiredCompletionCount &&
      pendingPromises.length > 0
    ) {
      const completedPromise = await Promise.race([
        Promise.race(pendingPromises.map((p) => p.promise)),
        timeoutPromise,
      ]);

      if (completedPromise instanceof Error) {
        // Timeout occurred
        pendingPromises.forEach((p) => p.promise.cancel());
        abortedTracks.push(...pendingPromises.map((p) => p.trackName));
        break;
      }

      const index = pendingPromises.findIndex(
        (p) => p.trackName === completedPromise.trackName,
      );
      if (index !== -1) {
        pendingPromises.splice(index, 1);
      }

      if ("error" in completedPromise) {
        errors[completedPromise.trackName] = completedPromise.error.message;
        if (!swallowErrors) {
          throw completedPromise.error;
        }
      } else {
        completedResults[completedPromise.trackName] = (
          completedPromise as SuccessResult
        ).returnValue;
        completedCount++;
      }
    }

    if (completedCount < requiredCompletionCount && !swallowErrors) {
      throw new Error(
        `Not enough tracks completed successfully. Required: ${requiredCompletionCount}, Completed: ${completedCount}`,
      );
    }

    return {
      type: "_types.Dictionary",
      value: {
        completed: completedResults,
        aborted: [...abortedTracks, ...pendingPromises.map((p) => p.trackName)],
        errored: errors,
      },
    };
  } finally {
    // Ensure all promises are cancelled
    pendingPromises.forEach((p) => p.promise.cancel());
  }
};

export const searchDirective = (
  directive: ExecuteSomeInParallelDirective,
  id: string,
): AnyDirective | undefined => {
  if (directive.id === id) {
    return directive;
  }

  for (const track of directive.config.tracks) {
    for (const nestedDirective of track.flowgraph) {
      const found = searchAnyDirective([nestedDirective], id);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};

export const executeSomeInParallel = {
  execute,
  availableActions: {},
  defaultConfig: () =>
    ({
      requiredCompletionCount: 1,
      tracks: [],
    }) as ExecuteSomeInParallelConfig,
};
