import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Text } from '@chakra-ui/react';
import { DataModelValidation, ValidationFunction, coreValidationFunctions } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';
import ValidationArgumentInputs from './ValidationArgumentInputs';

interface EditValidationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdateValidation: (updatedValidation: DataModelValidation) => void;
  onRemoveValidation: () => void;
  editingValidation: DataModelValidation | null;
}

const EditValidationModal: React.FC<EditValidationModalProps> = ({
  isOpen,
  onClose,
  onUpdateValidation,
  onRemoveValidation,
  editingValidation,
}) => {
  const [validationArgs, setValidationArgs] = useState<Record<string, any>>({});
  const [validationFunction, setValidationFunction] = useState<ValidationFunction | null>(null);

  useEffect(() => {
    if (editingValidation) {
      setValidationArgs(editingValidation.arguments || {});
      const foundValidationFunction = coreValidationFunctions.find(v => v.name === editingValidation.validationFunctionName);
      setValidationFunction(foundValidationFunction || null);
    }
  }, [editingValidation]);

  const handleUpdateValidation = () => {
    if (editingValidation && validationFunction) {
      const updatedValidation: DataModelValidation = {
        ...editingValidation,
        arguments: validationArgs
      };
      onUpdateValidation(updatedValidation);
    }
  };

  if (!editingValidation || !validationFunction) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Validation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight="bold" mb={2}>Validation Function:</Text>
          <Text mb={4}>{validationFunction.name}</Text>
          {validationFunction.description && (
            <Text mb={4}>{validationFunction.description}</Text>
          )}
          <ValidationArgumentInputs
            parameters={validationFunction.parameters || []}
            args={validationArgs}
            setArgs={setValidationArgs}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleUpdateValidation}>
            Update
          </Button>
          <Button colorScheme="red" mr={3} onClick={onRemoveValidation}>
            Remove
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditValidationModal;