import { DeclareVariableEditor } from "../directiveEditors/DeclareVariableEditor";
import { SetVariableEditor } from "../directiveEditors/SetVariableEditor";
import { GetVariableEditor } from "../directiveEditors/GetVariableEditor";
import { CallFunctionEditor } from "../directiveEditors/CallFunctionEditor";
import { RenderStringTemplateEditor } from "../directiveEditors/RenderStringTemplateEditor";
import { LiteralValueEditor } from "../directiveEditors/LiteralValueEditor";
import { GetArgumentEditor } from "../directiveEditors/GetArgumentEditor";
import { GetIteratorVariableEditor } from "../directiveEditors/GetIteratorVariableEditor";
import { LoopForEachEditor } from "../directiveEditors/LoopForEachEditor";
import { NativeCodeEditor } from "../directiveEditors/NativeCodeEditor";
import { ReturnEditor } from "../directiveEditors/ReturnEditor";
import { ExecuteBranchesEditor } from "../directiveEditors/ExecuteBranchesEditor";
import { ExecuteModelMethodEditor } from "../directiveEditors/ExecuteModelMethodEditor";
import { ReactNode } from "react";
import { AnyDirective } from "@/runtime-js/src/directives/directiveTypes";
import { getFlowgraphEditorConfigurations } from "../../utils";
import { WhileLoopEditor } from "../directiveEditors/WhileLoopEditor";
import { OverRangeLoopEditor } from "../directiveEditors/OverRangeLoopEditor";
import { GetPageVariableEditor } from "../directiveEditors/GetPageVariableEditor";
import { SetPageVariableEditor } from "../directiveEditors/SetPageVariableEditor";
import { DeclarePageVariableEditor } from "../directiveEditors/DeclarePageVariableEditor";

// Define a type for DirectiveEditors
interface DirectiveEditorsType {
  [key: string]: (args: {
    keypath: string;
    directive: AnyDirective;
  }) => ReactNode;
}

// Ensure DirectiveEditors is typed correctly
export const DirectiveEditors: DirectiveEditorsType = {
  literalValue: LiteralValueEditor,
  declareVariable: DeclareVariableEditor,
  setVariable: SetVariableEditor,
  getVariable: GetVariableEditor,
  callFunction: CallFunctionEditor,
  renderStringTemplate: RenderStringTemplateEditor,
  getArgument: GetArgumentEditor,
  getIteratorVariable: GetIteratorVariableEditor,
  loopForEach: LoopForEachEditor,
  whileLoop: WhileLoopEditor,
  overRangeLoop: OverRangeLoopEditor,
  nativeCode: NativeCodeEditor,
  return: ReturnEditor,
  executeBranches: ExecuteBranchesEditor,
  executeModelMethod: ExecuteModelMethodEditor,
  getPageVariable: GetPageVariableEditor,
  setPageVariable: SetPageVariableEditor,
  declarePageVariable: DeclarePageVariableEditor,
};

export type DirectiveType =
  | "declareVariable"
  | "listStructure"
  | "literalValue"
  | "setVariable"
  | "getVariable"
  | "callFunction"
  | "renderStringTemplate"
  | "getArgument"
  | "executeBranches"
  | "loopForEach"
  | "whileLoop"
  | "overRangeLoop"
  | "executeModelMethod"
  | "getPageVariable"
  | "setPageVariable";

// Mapping of directives to icons
export const allDirectives = [
  // Variable Operations
  { id: "declareVariable", label: "Declare context variable" },
  { id: "getVariable", label: "Get context variable" },
  { id: "setVariable", label: "Set context variable" },
  { id: "getIteratorVariable", label: "Get iterator variable" },
  { id: "literalValue", label: "Literal value" },
  { id: "getPageVariable", label: "Get page variable" },
  { id: "setPageVariable", label: "Set page variable" },

  // Function Operations
  { id: "callFunction", label: "Call function" },
  { id: "getArgument", label: "Get flow function argument" },
  { id: "return", label: "Return value" },

  // Control Flow
  { id: "executeBranches", label: "Execute branches" },
  { id: "loopForEach", label: "ForEach loop" },
  { id: "whileLoop", label: "While loop" },
  { id: "overRangeLoop", label: "Over range loop" },

  // Code Execution
  { id: "nativeCode", label: "Execute native code" },

  // String Operations
  { id: "renderStringTemplate", label: "Render text template" },

  // Model Class Methods
  { id: "executeModelMethod", label: "Execute model method" },
];

export const getEnabledDirectivesInConfiguration = () => {
  const enabledDirectives =
    getFlowgraphEditorConfigurations().config.enabledDirectives;
  if (enabledDirectives.length === 0) {
    return allDirectives;
  }
  return allDirectives.filter((directive) =>
    enabledDirectives.includes(directive.id),
  );
};