import React from 'react';
import { Select } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { PropertyEditorProps } from '../PropertyEditor';

const UpdatePageEditor: React.FC<PropertyEditorProps> = ({ schema, keypath }) => {
  const { getFragment, setFragment, getPageGroupByKeypath } = useAppDescriptorStore();

  const parentPageGroup = getPageGroupByKeypath(keypath);
  const updatePages = parentPageGroup?.pages.filter(page => page.pageType === 'update') || [];

  const currentValue = getFragment(keypath)?.config?.value;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFragment(keypath, {
      directiveType: 'literalValue',
      config: {
        type: schema.dataType,
        value: event.target.value
      }
    });
  };

  return (
    <Select value={currentValue || ''} onChange={handleChange}>
      <option value="">Select an Update Page</option>
      {updatePages.map(page => (
        <option key={page.id} value={page.id}>
          {page.name}
        </option>
      ))}
    </Select>
  );
};

export default UpdatePageEditor;