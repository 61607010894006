import React from "react";
import { Box, FormControl } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { ReturnDirective } from "@/runtime-js/src/directives/return";
import { DirectiveContainer } from "../DirectiveContainer";
import { AnyDirective } from "@/runtime-js/src/directives/directiveTypes";

interface ReturnEditorProps {
  keypath: string;
}

export function ReturnEditor({ keypath }: ReturnEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as ReturnDirective;
  
  const flowgraph = getFragment(
    keypath.split("flowgraph")[0] + "flowgraph"
  ) as AnyDirective[];

  const declaredVariables = new Set(
    flowgraph
      .filter((directive) => directive.directiveType === "declareVariable")
      .map((directive) => directive.config.name),
  );

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  return (
    <Box>
      <FormControl>
        <DirectiveContainer
          keypath={`${keypath}.config.value`}
          onRemoveDirective={() => {}}
          declaredVariables={declaredVariables}
          enabledDirectives={[
            "getVariable",
            "getIteratorVariable",
            "callFunction",
            "getArgument",
            "nativeCode",
            "literalValue",
            "getPageVariable",
          ]}
        />
      </FormControl>
    </Box>
  );
}
