import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PageGroupsIndex from "./PageGroupsIndex";
import PageGroupDetail from "./PageGroupDetail";
import PageEditor from "./PageEditor";

const PageGroups: React.FC = () => {
  return (
    <Routes>
      <Route index element={<PageGroupsIndex />} />
      <Route path=":groupId" element={<PageGroupDetail />} />
      <Route path=":groupId/pages" element={<Navigate to="../" replace />} />
      <Route path=":groupId/pages/:pageId/*" element={<PageEditor />} />
    </Routes>
  );
};

export default PageGroups;
