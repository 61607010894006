import React, { useState } from "react";
import {
  Box,
  Button,
  Select,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Text,
  HStack,
} from "@chakra-ui/react";
import { IconEdit } from "@tabler/icons-react";
import { FlowgraphEditor } from "@/bundles/FlowgraphEditor/components/FlowgraphEditor";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface ComponentEventsPanelProps {
  componentId: string;
  componentKeypath: string;
}

export const ComponentEventsPanel: React.FC<ComponentEventsPanelProps> = ({
  componentId,
  componentKeypath,
}) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const eventHandlers = getFragment(`${componentKeypath}/$eventHandlers`) || [];
  const currentHandler = eventHandlers[0];

  const availableEvents = [
    // @app_descriptor.rb:215 needs to be considered when modifying this list
    { value: "click", label: "Click" },
    { value: "rowClick", label: "Row Click" },
    { value: "submit", label: "Submit" },
  ];

  const handleEventChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newHandler = {
      $on: event.target.value,
      $execute: {
        $flowgraph: { sequence: [], $variables: [] },
      },
    };
    setFragment(`${componentKeypath}/$eventHandlers`, [newHandler]);
  };

  const handleFlowgraphChange = (flowgraph: any) => {
    const newHandler = {
      $on: currentHandler.$on,
      $execute: {
        $flowgraph: flowgraph,
      },
    };
    setFragment(`${componentKeypath}/$eventHandlers`, [newHandler]);
  };

  return (
    <>
      <VStack spacing={4} align="stretch">
        <HStack spacing={4} align="flex-end">
          <Box flex={1}>
            <Select
              value={currentHandler?.$on || ""}
              onChange={handleEventChange}
              placeholder="Select an event"
            >
              {availableEvents.map((event) => (
                <option key={event.value} value={event.value}>
                  {event.label}
                </option>
              ))}
            </Select>
          </Box>
          {currentHandler && (
            <Button
              leftIcon={<IconEdit size={16} />}
              onClick={onOpen}
              colorScheme="blue"
              size="sm"
            >
              Edit Flow
            </Button>
          )}
        </HStack>
        {currentHandler && (
          <Text fontSize="sm" color="gray.500">
            Event handler configured for:{" "}
            {availableEvents.find((e) => e.value === currentHandler.$on)?.label}
          </Text>
        )}
      </VStack>

      {/* Flowgraph Editor Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent maxWidth="90vw">
          <ModalHeader>
            Edit{" "}
            {
              availableEvents.find((e) => e.value === currentHandler?.$on)
                ?.label
            }{" "}
            Event Handler
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentHandler && (
              <FlowgraphEditor
                keypath={`${componentKeypath}/$eventHandlers/${currentHandler.$on}`}
                flowgraphContext={{
                  declaredVariables: new Set(),
                  declaredPageVariables: new Set(),
                  parameters: [],
                }}
                initialFlowgraph={
                  currentHandler.$execute.$flowgraph || {
                    sequence: [],
                    $variables: [],
                  }
                }
                onFlowgraphChange={handleFlowgraphChange}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
