export const generateFlowgraph = (conditionalNodes: any[]) => {
  if (Array.isArray(conditionalNodes) && conditionalNodes[0]?.$call) {
    return {
      sequence: conditionalNodes,
      context: {
        $variables: {},
        $constants: {},
      },
    };
  }

  const flowgraph = {
    sequence: [],
    context: {
      $variables: {},
      $constants: {},
    },
  };

  const processNode = (node: any) => {
    if (!node) return;

    if (node.$call) {
      flowgraph.sequence.push(node);
      return;
    }

    if (node.name === "Event") {
      const eventNode = {
        $id: node.id,
        $call: `KaseyOS.Router.${node.parameters.eventType}`,
        $arguments: {
          page: node.parameters.page,
        },
      };

      if (node.parameters.param) {
        eventNode.$arguments.params = {
          id: {
            $call: "Context.Get",
            $arguments: {
              keypath: node.parameters.param,
            },
          },
        };
      }

      flowgraph.sequence.push(eventNode);
    } else if (node.name === "Function") {
      const functionNode = {
        $id: node.id,
        $call: node.parameters.functionName,
        $arguments: node.parameters.arguments || {},
      };
      flowgraph.sequence.push(functionNode);
    } else if (node.name === "Service Call") {
      const serviceNode = {
        $id: node.id,
        $call: "KaseyOS.DataService.Call",
        $arguments: {
          serviceName: node.parameters.serviceName,
          methodName: node.parameters.methodName,
          methodArguments: node.parameters.methodArguments || {},
        },
      };
      flowgraph.sequence.push(serviceNode);
    }

    if (node.childrenYes?.length) {
      node.childrenYes.forEach(processNode);
    }
    if (node.childrenNo?.length) {
      node.childrenNo.forEach(processNode);
    }
  };

  if (Array.isArray(conditionalNodes)) {
    conditionalNodes.forEach(processNode);
  }

  return flowgraph;
};
