import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  Select,
  FormControl,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "../../../../stores/appDescriptorStore";
import {
  RenderAction,
  PageMethod,
} from "@/bundles/DescriptorEditor/schemas/essentials/pagesSchema";
import { ComponentBlueprint } from "@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema";
import ComponentInstanceEditor from "@/bundles/DescriptorEditor/components/editors/ViewgraphEditor/ComponentInstanceEditor";
import { railsApiCall } from "@/bundles/DescriptorEditor/utils/railsApiCall";

type ActionType = "Replace" | "AppendChild" | "PrependChild" | "Remove";

interface DomIdOption {
  id: string;
  name: string;
  description: string;
  componentId: string;
}

const RenderActionsEditor: React.FC<{ keypath: string }> = ({ keypath }) => {
  const { pageId, projectId } = useParams<{
    pageId: string;
    projectId: string;
  }>();
  const {
    getFragment,
    setFragment,
    getViewgraphDomIds,
    getDeclaredPageVariables,
  } = useAppDescriptorStore();
  const [actions, setActions] = useState<RenderAction[]>([]);
  const [availableDomIds, setAvailableDomIds] = useState<DomIdOption[]>([]);
  const [pageVariables, setPageVariables] = useState<string[]>([]);
  const [componentBlueprints, setComponentBlueprints] = useState<
    ComponentBlueprint[]
  >([]);

  const fetchComponentBlueprints = useCallback(async () => {
    try {
      const { data, ok } = await railsApiCall<ComponentBlueprint[]>({
        method: "GET",
        endpoint: `/projects/${projectId}/component_blueprints`,
      });

      if (ok) {
        setComponentBlueprints(data.component_blueprints);
      } else {
        console.error("Failed to fetch component blueprints");
      }
    } catch (error) {
      console.error("Error fetching component blueprints:", error);
    }
  }, [projectId]);

  useEffect(() => {
    const pageMethod = getFragment(
      keypath.replace("/renderActions", "")
    ) as PageMethod;
    if (pageMethod && pageMethod.renderActions) {
      setActions(pageMethod.renderActions);
    }

    const pageKeypath = `/essentials/pages/${pageId}`;
    setAvailableDomIds(getViewgraphDomIds(pageId?.replace("id:", "") || ""));
    setPageVariables(
      getDeclaredPageVariables(pageId?.replace("id:", "") || "")
    );
    fetchComponentBlueprints();
  }, [
    keypath,
    pageId,
    getFragment,
    getViewgraphDomIds,
    getDeclaredPageVariables,
    fetchComponentBlueprints,
  ]);

  const handleActionChange = (index: number, field: string, value: any) => {
    const newActions = [...actions];
    newActions[index] = { ...newActions[index], [field]: value };
    setActions(newActions);
    updateRenderActions(newActions);
  };

  const addAction = () => {
    const newActions = [
      ...actions,
      { action: "Replace", targetDomId: "", componentInstance: null },
    ] as RenderAction[];
    setActions(newActions);
    updateRenderActions(newActions);
  };

  const removeAction = (index: number) => {
    const newActions = actions.filter((_, i) => i !== index);
    setActions(newActions);
    updateRenderActions(newActions);
  };

  const updateRenderActions = (newActions: RenderAction[]) => {
    setFragment(keypath, newActions);
  };

  const renderActionFields = (action: RenderAction, index: number) => {
    return (
      <>
        <FormControl>
          <FormLabel>Target DOM ID</FormLabel>
          <Select
            value={action.targetDomId || ""}
            onChange={(e) =>
              handleActionChange(index, "targetDomId", e.target.value)
            }
          >
            <option value="">Select a DOM ID</option>
            {availableDomIds.map((domId) => (
              <option key={domId.id} value={domId.id}>
                {domId.name} ({domId.id}) – {domId.description} –{" "}
                {domId.componentId}
              </option>
            ))}
          </Select>
        </FormControl>
        {action.targetDomId && (
          <Tooltip
            label={
              availableDomIds.find((d) => d.id === action.targetDomId)
                ?.description || ""
            }
          >
            <Box mt={2} fontSize="sm" color="gray.600">
              Component:{" "}
              {
                availableDomIds.find((d) => d.id === action.targetDomId)
                  ?.componentId
              }
            </Box>
          </Tooltip>
        )}
        {action.action !== "remove" && (
          <FormControl mt={2}>
            <FormLabel>New component instance</FormLabel>
            <ComponentInstanceEditor
              keypath={`${keypath}/${index}/componentInstance`}
              availableComponentBlueprints={componentBlueprints}
            />
          </FormControl>
        )}
      </>
    );
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        {actions.map((action, index) => (
          <Box key={index} borderWidth={1} borderRadius="md" p={4} bg="gray.50">
            <FormControl>
              <FormLabel>Action Type</FormLabel>
              <Select
                value={action.action}
                onChange={(e) =>
                  handleActionChange(
                    index,
                    "action",
                    e.target.value as ActionType
                  )
                }
              >
                <option value="replace">Replace</option>
                <option value="update">Update</option>
                <option value="append">Append</option>
                <option value="prepend">Prepend</option>
                <option value="remove">Remove</option>
              </Select>
            </FormControl>
            {renderActionFields(action, index)}
            <Button
              onClick={() => removeAction(index)}
              size="sm"
              colorScheme="red"
              mt={4}
            >
              Remove Action
            </Button>
          </Box>
        ))}
        <Button onClick={addAction} size="sm" colorScheme="blue">
          Add Action
        </Button>
      </VStack>
    </Box>
  );
};

export default RenderActionsEditor;
