import React, { useState, useEffect } from 'react';
import { Box, Radio, RadioGroup, Stack, Select, Button } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { FiPlus } from 'react-icons/fi';

interface ActiveCollectionEditorProps {
  schema: any;
  keypath: string;
}

const ActiveCollectionEditor: React.FC<ActiveCollectionEditorProps> = ({ schema, keypath }) => {
  const { getFragment, setFragment, getPageVariables } = useAppDescriptorStore();
  const [source, setSource] = useState<'pageVariable' | 'staticCollection'>('pageVariable');
  const [selectedModel, setSelectedModel] = useState<string>('');
  
  const dataModels = getFragment(`/essentials/dataModels`);
  const pageVariables = getPageVariables(keypath);


  const handleSourceChange = (newSource: 'pageVariable' | 'staticCollection') => {
    setSource(newSource);
    setFragment(`${keypath}/source`, newSource);
  };

  const handleModelChange = (model: string) => {
    setSelectedModel(model);
    setFragment(`${keypath}/dataModel`, model);
  };

  const handleAddItem = () => {
    // Implement logic to add a new item to the static collection
  };


  const handlePageVariableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVariable = event.target.value;
    if (selectedVariable) {
      setFragment(`${keypath}`, {
        directiveType: "getPageVariable",
        config: {
          variableName: selectedVariable
        }
      });
    }
  };

  return (
    <Box>
      <RadioGroup onChange={handleSourceChange} value={source}>
        <Stack direction="row">
          <Radio value="pageVariable">Page variable</Radio>
          <Radio value="staticCollection">Static collection</Radio>
        </Stack>
      </RadioGroup>

      {source === 'pageVariable' && (
        <Select
          placeholder="Select page variable"
          onChange={(e) => handlePageVariableChange(e)}
        >
          {pageVariables.map((variable) => (
            <option key={variable} value={variable}>{variable}</option>
          ))}
        </Select>
      )}

      {source === 'staticCollection' && (
        <>
          <Select
            placeholder="Select data model"
            value={selectedModel}
            onChange={(e) => handleModelChange(e.target.value)}
          >
            {dataModels.map((model) => (
              <option key={model} value={model}>{model}</option>
            ))}
          </Select>
          {selectedModel && (
            <Button onClick={handleAddItem} leftIcon={<FiPlus />}>
              Add Item
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default ActiveCollectionEditor;