import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { DescriptorCollection, DescriptorRecord } from "../descriptorUtils";

const propertyBindingSchema = z.record(z.string(), z.any());

const layoutSchema = z.object({
  width: z.string().optional(),
  margin: z.string().optional(),
  padding: z.string().optional(),
  flexDirection: z.string().optional(),
  justifyContent: z.string().optional(),
  alignItems: z.string().optional(),
});

const eventSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  payloadSchema: DescriptorCollection(
    z.object({
      name: z.string(),
      dataType: z.string(),
      description: z.string().optional(),
    })
  ).optional(),
});

const reactionSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("executePageMethod"),
    methodId: z.string(),
  }),
  z.object({
    type: z.literal("navigateTo"),
    route: z.string(),
  }),
]);

export interface ComponentInstance {
  id: string;
  name?: string;
  domId?: string;
  blueprintName: string;
  propertiesBindings?: Record<string, any>;
  props?: Record<string, any>;
  layout?: {
    width?: string;
    height?: string;
    margin?: string;
    padding?: string;
    flexDirection?: string;
    justifyContent?: string;
    alignItems?: string;
    verticalAlign?: string;
    textAlign?: string;
    overflow?: string;
    display?: string;
    position?: string;
    flexGrow?: string;
    flexShrink?: string;
    flexWrap?: string;
  };
  internalDomIds?: Array<{
    id: string;
    name: string;
    description: string;
  }>;
  $slots?: Record<string, { $children: ComponentInstance[] }>;
  $children?: ComponentInstance[];
  styles?: {
    classes: string;
  };
}

export const componentInstanceSchema: z.ZodType<ComponentInstance> = z.lazy(
  () => {
    return z.object({
      id: z.string(),
      name: z.string().optional(),
      domId: z.string().optional(),
      blueprintName: z.string(),
      propertiesBindings: propertyBindingSchema.optional(),
      children: DescriptorCollection(componentInstanceSchema).optional(),
      layout: layoutSchema.optional(),
      internalDomIds: z
        .array(
          z.object({
            id: z.string(),
            name: z.string(),
            description: z.string(),
          })
        )
        .optional(),
      reactions: z.record(z.string(), reactionSchema).optional(),
    });
  }
);

const propertySchema = DescriptorRecord(
  z.object({
    name: z.string(),
    dataType: z.string(),
    description: z.string().optional(),
    default: z.any().optional(),
    required: z.boolean().optional(),
  })
);

export interface ComponentBlueprint {
  id: string;
  name: string;
  displayName?: string;
  description?: string;
  properties?: Array<{
    name: string;
    dataType: string;
    description?: string;
    defaultValue?: any;
  }>;
  props?: Array<{
    name: string;
    schema: {
      type: string;
      items?: any;
    };
    description?: string;
  }>;
  previewHtml?: string;
  erbTemplate?: string;
  isModule?: boolean;
  category?: Array<string>;
  viewgraph?: {
    nodeTree: any;
  };
  emittedEvents?: Array<{
    name: string;
    description?: string;
    payloadSchema?: any;
  }>;
  examples?: Array<{
    label: string;
    description?: string;
    instance: any;
  }>;
}

export const componentBlueprintSchema: z.ZodType<ComponentBlueprint> = z.lazy(
  () => {
    return z.object({
      id: z.string(),
      name: z.string(),
      displayName: z.string().optional(),
      description: z.string().optional(),
      properties: z
        .array(
          z.object({
            name: z.string(),
            dataType: z.string(),
            description: z.string().optional(),
            defaultValue: z.any().optional(),
          })
        )
        .optional(),
      props: z
        .array(
          z.object({
            name: z.string(),
            schema: z.object({
              type: z.string(),
              items: z.any().optional(),
            }),
            description: z.string().optional(),
          })
        )
        .optional(),
      previewHtml: z.string().optional(),
      erbTemplate: z.string().optional(),
      isModule: z.boolean().optional(),
      category: z.array(z.string()).optional(),
      viewgraph: z
        .object({
          nodeTree: z.any(),
        })
        .optional(),
      emittedEvents: z
        .array(
          z.object({
            name: z.string(),
            description: z.string().optional(),
            payloadSchema: z.any().optional(),
          })
        )
        .optional(),
      examples: z
        .array(
          z.object({
            label: z.string(),
            description: z.string().optional(),
            instance: z.any(),
          })
        )
        .optional(),
    });
  }
);

export const componentBlueprintsSchema = DescriptorCollection(
  componentBlueprintSchema
);

export const generateDefaultComponentBlueprint = (
  name: string
): ComponentBlueprint => {
  return {
    id: uuidv4(),
    name,
    description: "",
    properties: [],
    props: [],
    previewHtml: `<div class="bg-gray-100 p-4 rounded-md">${name} preview</div>`,
    erbTemplate: `<div class="bg-gray-100 p-4 rounded-md"><%= @${name.toLowerCase()} %></div>`,
  };
};
