import {
  type Directive,
  type AnyDirective,
  isDirective,
} from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import { searchAnyDirective } from "../flowFunctionStore";

export type ExecuteAllInParallelDirective = Directive<
  "executeAllInParallel",
  ExecuteAllInParallelConfig
>;

export interface ExecuteAllInParallelConfig {
  tracks: Array<{
    trackName: string;
    flowgraph: AnyDirective[];
  }>;
}

const execute = async (
  config: ExecuteAllInParallelConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { tracks } = config;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trackResults: Record<string, any> = {};

  const trackPromises = tracks.map(async (track) => {
    const { trackName, flowgraph } = track;
    for (const directive of flowgraph) {
      trackResults[trackName] =
        await executionContext.executeDirective(directive);
    }
  });

  await Promise.all(trackPromises);

  return {
    type: "_types.Dictionary",
    value: trackResults,
  };
};

export const searchDirective = (
  directive: ExecuteAllInParallelDirective,
  id: string,
): AnyDirective | undefined => {
  if (directive.id === id) {
    return directive;
  }

  for (const track of directive.config.tracks) {
    for (const nestedDirective of track.flowgraph) {
      const found = searchAnyDirective([nestedDirective], id);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};

export const executeAllInParallel = {
  execute,
  availableActions: {},
  defaultConfig: () =>
    ({
      tracks: {},
    }) as ExecuteAllInParallelConfig,
};

export function isExecuteAllInParallelDirective(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directive: any,
): directive is ExecuteAllInParallelDirective {
  const executeAllInParallelConfigKeys: (keyof ExecuteAllInParallelConfig)[] = [
    "tracks",
  ];
  return isDirective(
    directive,
    "executeAllInParallel",
    executeAllInParallelConfigKeys,
  );
}
