import React from 'react';
import {
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';

interface BooleanEditorProps {
  schema: any;
  keypath: string;
}

const BooleanEditor: React.FC<BooleanEditorProps> = ({ schema, keypath }) => {
  const { getFragment, setFragment } = useAppDescriptorStore();

  const binding = getFragment(keypath);
  const value = binding?.config?.value || false;

  const handleToggle = () => {
    setFragment(`${keypath}/config/value`, !value);
  };

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel htmlFor={`switch-${keypath}`} mb="0" fontSize="sm">
        {schema.name}
      </FormLabel>
      <Switch
        id={`switch-${keypath}`}
        isChecked={value}
        onChange={handleToggle}
        size="sm"
      />
    </FormControl>
  );
};

export default BooleanEditor;