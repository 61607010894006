import {
  type AnyDirective,
  type Directive,
  isDirective,
} from "./directiveTypes";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";

export type ThrowErrorDirective = Directive<"throwError", ThrowErrorConfig>;

export interface ThrowErrorConfig {
  errorMessage: AnyDirective;
}

export function isThrowErrorDirective(
  directive: any,
): directive is ThrowErrorDirective {
  const throwErrorConfigKeys: (keyof ThrowErrorConfig)[] = ["errorMessage"];
  return isDirective(directive, "throwError", throwErrorConfigKeys);
}

const execute = async (
  config: ThrowErrorConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<void> => {
  const { errorMessage } = config;
  const resolvedErrorMessage =
    await executionContext.executeDirective(errorMessage);
  throw new Error(resolvedErrorMessage.value);
};

export const throwError = {
  execute,
  availableActions: {},
  defaultConfig: () => ({
    errorMessage: "",
  }),
};
