import React, { useCallback, useState, useRef, useEffect } from "react";
import {
  Button,
  useDisclosure,
  Box,
  ButtonGroup,
  Text,
  IconButton,
  Input,
  Heading,
  VStack,
  Select,
  Switch,
  Icon,
  HStack,
  Spinner,
  useToast,
  FormControl,
  FormLabel,
  Code,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
} from "@chakra-ui/react";

import { useParams } from "react-router-dom";

import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

import {
  ComponentBlueprint,
  ComponentInstance,
  ContainerStructure,
  Viewgraph,
} from "./ViewgraphEditor";

import ViewgraphComponentInstance from "./ViewgraphComponentInstance";

import ComponentPropertiesPanel from "./ComponentPropertiesPanel";

import ComponentLayoutEditor from "./ComponentLayoutEditor";

import {
  DeleteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  EditIcon,
} from "@chakra-ui/icons";

import { get } from "lodash";

import ComponentTree from "./ComponentTree";

import LayoutModeEditor from "./LayoutModeEditor";

import ComponentLibraryModal from "./ComponentLibraryModal";
import { FiDivide, FiPlus, FiInfo } from "react-icons/fi";

import { ComponentEventsPanel } from "./ComponentEventsPanel";
import { StyleEditor } from "./PropertyEditor/StyleEditor";
import { v4 as uuidv4 } from "uuid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface ComponentModeEditorProps {
  keypath: string;

  availableComponentBlueprints: ComponentBlueprint[];

  isPreviewMode: boolean;

  setIsPreviewMode: (isPreview: boolean) => void;

  selectedComponentIds: string[];

  setSelectedComponentIds: (ids: string[]) => void;

  structure: ContainerStructure | null;
}

interface TypedValue {
  type: string;

  value: any;
}

interface Slot {
  name: string;
  description?: string;
}

const findComponentInSlots = (
  slots: Record<string, { $children: ComponentInstance[] }>,
  componentId: string
): string | null => {
  for (const [slotName, slot] of Object.entries(slots)) {
    const slotChildIndex = slot.$children?.findIndex(
      (c) => c.id === componentId
    );
    if (slotChildIndex !== -1) {
      return `/$slots/${slotName}/$children/${slotChildIndex}`;
    }

    for (const [childIdx, child] of (slot.$children || []).entries()) {
      if (child.$slots) {
        const nestedPath = findComponentInSlots(child.$slots, componentId);
        if (nestedPath) {
          return `/$slots/${slotName}/$children/${childIdx}${nestedPath}`;
        }
      }
    }
  }
  return null;
};

const findComponentKeypath = (
  container: ContainerStructure,
  componentId: string,
  baseKeypath: string
): string | null => {
  const componentIndex = container.components.findIndex(
    (c) => c.id === componentId
  );
  if (componentIndex !== -1) {
    return `${baseKeypath}/components/${componentIndex}`;
  }

  for (const component of container.components) {
    if (component.$slots) {
      for (const [slotName, slot] of Object.entries(component.$slots)) {
        const slotChildIndex = slot.$children?.findIndex(
          (c) => c.id === componentId
        );
        if (slotChildIndex !== -1) {
          return `${baseKeypath}/components/id:${component.id}/$slots/${slotName}/$children/${slotChildIndex}`;
        }

        for (const [childIdx, child] of (slot.$children || []).entries()) {
          if (child.$slots) {
            const nestedPath = findComponentInSlots(child.$slots, componentId);
            if (nestedPath) {
              return `${baseKeypath}/components/id:${component.id}/$slots/${slotName}/$children/${childIdx}${nestedPath}`;
            }
          }
        }
      }
    }
  }

  for (const subcontainer of container.subcontainers) {
    const found = findComponentKeypath(
      subcontainer,
      componentId,
      `${baseKeypath}/subcontainers/id:${subcontainer.id}`
    );
    if (found) return found;
  }

  return null;
};

const ComponentModeEditor: React.FC<ComponentModeEditorProps> = ({
  keypath,

  availableComponentBlueprints,

  setIsPreviewMode,

  selectedComponentIds,

  setSelectedComponentIds,
}) => {
  const { pageId } = useParams<{
    projectId: string;

    pageId: string;
  }>();

  const {
    getFragment,

    setFragment,

    addRecordToCollectionFragment,

    removeRecordFromCollectionFragment,

    getPage,

    isLoading,
  } = useAppDescriptorStore();

  const [blueprintNameBuffer, setBlueprintNameBuffer] = useState<string | null>(
    null
  );

  const toast = useToast(); // Move useToast inside the component

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!pageId) {
    return <div>Page not found</div>;
  }

  // Fetch the page path from the app descriptor

  const cleanPageId = pageId.replace(/^id:/, "");

  const page = getPage(cleanPageId);

  console.log("page", page);

  if (!page) {
    return <div>Page not found</div>;
  }

  const viewgraph = getFragment(keypath) as Viewgraph;
  if (!viewgraph) {
    console.error(`Viewgraph not found at keypath: ${keypath}`);
    return null; // or a loading state
  }

  const [showBorders, setShowBorders] = useState(true);

  const [draggedOver, setDraggedOver] = useState<string | null>(null);

  const [selectedContainerKeypath, setSelectedContainerKeypath] = useState<
    string | null
  >(null);

  const [editorMode, setEditorMode] = useState<
    "properties" | "layout" | "style" | "events"
  >("properties");

  const editorRef = useRef<HTMLDivElement>(null);

  const [showNoComponentsModal, setShowNoComponentsModal] = useState(false);

  const [isLayoutModalOpen, setIsLayoutModalOpen] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedContainerId, setSelectedContainerId] = useState<string | null>(
    null
  );

  const [isSlotNameModalOpen, setIsSlotNameModalOpen] = useState(false);
  const [pendingComponent, setPendingComponent] = useState<{
    blueprintName: string;
    componentKeypath: string;
  } | null>(null);
  const [newSlotName, setNewSlotName] = useState("");

  const selectedComponentId =
    selectedComponentIds.length > 0 ? selectedComponentIds[0] : null;

  const getComponentById = (componentId: string) => {
    const componentKeypath = findComponentKeypath(
      viewgraph.containerStructure,
      componentId,
      `${keypath}/containerStructure`
    );
    if (componentKeypath) {
      return getFragment(componentKeypath) as ComponentInstance;
    }
    return null;
  };

  const selectedComponent = selectedComponentId
    ? getComponentById(selectedComponentId)
    : null;

  const selectedComponentKeypath = selectedComponentId
    ? findComponentKeypath(
        viewgraph.containerStructure,
        selectedComponentId,
        `${keypath}/containerStructure`
      )
    : null;

  const SlotNameModal = () => {
    const selectedComponent =
      selectedComponentIds.length === 1
        ? getComponentById(selectedComponentIds[0])
        : null;
    const componentBlueprint = selectedComponent
      ? availableComponentBlueprints.find(
          (bp) => bp.name === selectedComponent.blueprintName
        )
      : null;
    const availableSlots = (componentBlueprint?.slots || []) as Slot[];

    return (
      <Modal
        isOpen={isSlotNameModalOpen}
        onClose={() => setIsSlotNameModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Slot</ModalHeader>
          <ModalBody>
            {availableSlots.length > 0 ? (
              <FormControl>
                <FormLabel>Available Slots</FormLabel>
                <Select
                  value={newSlotName}
                  onChange={(e) => setNewSlotName(e.target.value)}
                  placeholder="Choose a slot"
                >
                  {availableSlots.map((slot) => (
                    <option key={slot.name} value={slot.name}>
                      {slot.name}{" "}
                      {slot.description ? `- ${slot.description}` : ""}
                    </option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Text color="red.500">
                This component does not have any available slots.
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsSlotNameModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              isDisabled={!newSlotName || availableSlots.length === 0}
              onClick={() => {
                if (pendingComponent && newSlotName) {
                  const { blueprintName, componentKeypath } = pendingComponent;

                  if (
                    !getFragment(`${componentKeypath}/$slots/${newSlotName}`)
                  ) {
                    setFragment(`${componentKeypath}/$slots`, {
                      ...(getFragment(`${componentKeypath}/$slots`) || {}),
                      [newSlotName]: {
                        $children: [],
                      },
                    });
                  }

                  const newComponentInstance: ComponentInstance = {
                    id: generateUniqueId(),
                    blueprintName,
                    propertiesBindings: {},
                    reactions: {},
                    layout: {},
                  };

                  addRecordToCollectionFragment(
                    `${componentKeypath}/$slots/${newSlotName}/$children`,
                    newComponentInstance
                  );

                  setIsSlotNameModalOpen(false);
                  setPendingComponent(null);
                  setNewSlotName("");
                  onClose();
                }
              }}
            >
              Add to Slot
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const handleAddComponent = (blueprintName: string) => {
    const selectedComponent =
      selectedComponentIds.length === 1
        ? getComponentById(selectedComponentIds[0])
        : null;

    const componentBlueprint = availableComponentBlueprints.find(
      (bp) => bp.name === blueprintName
    );

    if (!componentBlueprint) {
      console.error(`Blueprint not found: ${blueprintName}`);
      return;
    }

    if (!selectedComponent) {
      const newComponent = {
        id: uuidv4(),
        blueprintName,
        propertiesBindings: initializePropertiesBindings(componentBlueprint),
        reactions: {},
        layout: {},
      };

      setFragment(`${keypath}/containerStructure/components/-`, newComponent);
      return;
    }

    if (componentBlueprint.slots) {
      setPendingComponent({
        blueprintName,
        componentKeypath:
          findComponentKeypath(
            viewgraph.containerStructure,
            selectedComponent.id,
            `${keypath}/containerStructure`
          ) || "",
      });
      setIsSlotNameModalOpen(true);
    } else {
      const defaultSlot = Object.keys(selectedComponent.$slots || {})[0];
      if (defaultSlot) {
        const componentKeypath = findComponentKeypath(
          viewgraph.containerStructure,
          selectedComponent.id,
          `${keypath}/containerStructure`
        );
        if (componentKeypath) {
          const newComponent = {
            id: uuidv4(),
            blueprintName,
            propertiesBindings:
              initializePropertiesBindings(componentBlueprint),
            reactions: {},
            layout: {},
          };
          setFragment(
            `${componentKeypath}/$slots/${defaultSlot}/$children/-`,
            newComponent
          );
        }
      }
    }
  };

  const handleMoveComponentInTree = useCallback(
    (containerId: string, fromIndex: number, toIndex: number) => {
      const containerKeypath = findContainerKeypathById(
        viewgraph.containerStructure,
        containerId,
        `${keypath}/containerStructure`
      );
      if (containerKeypath) {
        const container = getFragment(containerKeypath) as ContainerStructure;
        if (container.components) {
          const newComponents = [...container.components];
          const [movedComponent] = newComponents.splice(fromIndex, 1);
          if (toIndex < 0) {
            // Move to previous container
            const parentContainerKeypath = findParentContainerKeypath(
              viewgraph.containerStructure,
              containerId,
              `${keypath}/containerStructure`
            );
            if (parentContainerKeypath) {
              const parentContainer = getFragment(
                parentContainerKeypath
              ) as ContainerStructure;
              parentContainer.components.splice(
                parentContainer.components.length,
                0,
                movedComponent
              );
              setFragment(
                `${parentContainerKeypath}/components`,
                parentContainer.components
              );
            }
          } else if (toIndex >= container.components.length) {
            // Move to next container
            const parentContainerKeypath = findParentContainerKeypath(
              viewgraph.containerStructure,
              containerId,
              `${keypath}/containerStructure`
            );
            if (parentContainerKeypath) {
              const parentContainer = getFragment(
                parentContainerKeypath
              ) as ContainerStructure;
              parentContainer.components.splice(0, 0, movedComponent);
              setFragment(
                `${parentContainerKeypath}/components`,
                parentContainer.components
              );
            }
          } else {
            newComponents.splice(toIndex, 0, movedComponent);
            setFragment(`${containerKeypath}/components`, newComponents);
          }
        }
      }
    },
    [viewgraph, keypath, getFragment, setFragment]
  );

  const findParentContainerKeypath = (
    container: ContainerStructure,
    containerId: string,
    currentKeypath: string
  ): string | null => {
    for (const subcontainer of container.subcontainers) {
      if (subcontainer.id === containerId) {
        return currentKeypath;
      }
      const result = findParentContainerKeypath(
        subcontainer,
        containerId,
        `${currentKeypath}/subcontainers/id:${subcontainer.id}`
      );
      if (result) return result;
    }
    return null;
  };

  const initializePropertiesBindings = (blueprint: ComponentBlueprint) => {
    const bindings: Record<string, any> = {};
    if (blueprint.properties) {
      blueprint.properties.forEach((prop) => {
        bindings[prop.name] = {
          directiveType: "literalValue",
          config: {
            type: prop.dataType,
            value: prop.defaultValue,
          },
        };
      });
    }
    return bindings;
  };

  const generateChildContainerName = (
    parentName: string,
    index: number
  ): string => {
    return `subContainer ${parentName}-${index}`;
  };

  const splitContainer = (containerKeypath: string) => {
    const container = getFragment(containerKeypath) as ContainerStructure;
    if (!container) return;

    toast({
      title: "Splitting container...",
      description: <Spinner size="sm" />,
      status: "info",
      duration: 1500,
      isClosable: true,
    });

    const parentName = container.name || "Container";

    const newContainer1: ContainerStructure = {
      id: "sub-" + generateUniqueId(),
      name: generateChildContainerName(parentName, 1),
      layoutDirection: container.layoutDirection,
      size: { value: 1, unit: "fr" },
      subcontainers: [],
      isScrollable: container.isScrollable,
      components: container.components,
      isLayoutApplied: true,
    };

    const newContainer2: ContainerStructure = {
      id: "sub-" + generateUniqueId(),
      name: generateChildContainerName(parentName, 2),
      layoutDirection: container.layoutDirection,
      size: { value: 1, unit: "fr" },
      subcontainers: [],
      isScrollable: container.isScrollable,
      components: [],
      isLayoutApplied: true,
    };

    const updatedContainer: ContainerStructure = {
      ...container,
      name: parentName,
      components: [],
      subcontainers: [newContainer1, newContainer2],
    };

    setFragment(containerKeypath, updatedContainer);

    setTimeout(() => {
      window.location.reload();
    }, 1250);
  };

  const renderContainerProperties = () => {
    if (!selectedContainerKeypath) return null;

    const container = getFragment(
      selectedContainerKeypath
    ) as ContainerStructure;

    if (!container) return null;

    return (
      <div className="p-4">
        <Heading size="md">Container Properties</Heading>
        <VStack spacing={4} align="stretch" mt={4}>
          <Box>
            <Text fontWeight="bold" mb={1}>
              Layout Direction
            </Text>
            <Select
              value={container.layoutDirection}
              onChange={(e) => {
                setFragment(
                  `${selectedContainerKeypath}/layoutDirection`,
                  e.target.value
                );
              }}
            >
              <option value="horizontal">Horizontal</option>
              <option value="vertical">Vertical</option>
            </Select>
          </Box>
          <Box>
            <Text fontWeight="bold" mb={1}>
              Is Scrollable
            </Text>
            <Switch
              isChecked={container.isScrollable}
              onChange={(e) => {
                setFragment(
                  `${selectedContainerKeypath}/isScrollable`,
                  e.target.checked
                );
              }}
            />
          </Box>
          <Box
            bg="blue.50"
            p={3}
            borderRadius="md"
            borderLeft="4px"
            borderColor="blue.400"
          >
            <HStack spacing={2}>
              <Icon as={FiInfo} color="blue.400" />
              <Text fontSize="sm" color="blue.700">
                <b>Tip:</b> You can add components to this container via the
                component tree or by pressing CMD+E on the component tree.
              </Text>
            </HStack>
          </Box>
          {/* <Box>
            <Button
              leftIcon={<Icon as={FiDivide} />}
              size="md"
              colorScheme="blue"
              width="100%"
              mt={2}
              onClick={() => {
                splitContainer(selectedContainerKeypath);
              }}
            >
              Split Container
            </Button>
          </Box> */}
        </VStack>
      </div>
    );
  };

  const getDefaultValueForType = (dataType: string): any => {
    switch (dataType) {
      case "_types.String":
        return "";
      case "_types.Number":
        return 0;
      case "_types.Boolean":
        return false;
      case "_types.List":
        return [];
      case "_types.Dictionary":
        return {};
      default:
        return null;
    }
  };

  const hasComponents = (container: ContainerStructure): boolean => {
    if (container.components && container.components.length > 0) {
      return true;
    }
    for (const subcontainer of container.subcontainers) {
      if (hasComponents(subcontainer)) {
        return true;
      }
    }
    return false;
  };

  const NoComponentsModal = () => (
    <Modal
      isOpen={showNoComponentsModal}
      onClose={() => setShowNoComponentsModal(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>No Components!</ModalHeader>
        <ModalBody>
          There are no components on the canvas. Are you sure you want to enter
          preview mode?
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              setShowNoComponentsModal(false);
              setIsPreviewMode(true);
            }}
          >
            Bypass
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => setShowNoComponentsModal(false)}
          >
            No
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target as Node)
      ) {
        // Check if the click is on a modal element

        const target = event.target as HTMLElement;

        const isModalClick = target.closest(".chakra-modal__content") !== null;

        if (!isModalClick) {
          setSelectedComponentIds([]);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getSizeStyle = (
    parentDirection: "horizontal" | "vertical",
    size?: { value: number; unit: "px" | "fr" }
  ): React.CSSProperties => {
    if (!size) return {};

    const dimension = parentDirection === "horizontal" ? "width" : "height";
    const flexProperty =
      parentDirection === "horizontal" ? "flexBasis" : "flexBasis";

    if (size.unit === "px") {
      return {
        [dimension]: `${size.value}px`,
        [flexProperty]: `${size.value}px`,
        flexGrow: 0,
        flexShrink: 0,
      };
    } else {
      return {
        [flexProperty]: `${size.value}fr`,
        flexGrow: size.value,
        flexShrink: 1,
      };
    }
  };

  const handleDrop = useCallback(
    (
      containerKeypath: string,
      componentId: string | null,
      blueprint?: ComponentBlueprint
    ) => {
      if (componentId) {
        const oldContainerKeypath = findContainerKeypathForComponent(
          viewgraph.containerStructure,
          componentId,
          `${keypath}/containerStructure`
        );

        if (oldContainerKeypath && oldContainerKeypath !== containerKeypath) {
          const existingComponent = getFragment(
            `${oldContainerKeypath}/components/id:${componentId}`
          );

          removeRecordFromCollectionFragment(
            `${oldContainerKeypath}/components`,
            componentId
          );

          addRecordToCollectionFragment(
            `${containerKeypath}/components`,
            existingComponent
          );
        }
      } else if (blueprint) {
        const newComponentInstance: ComponentInstance = {
          blueprintName: blueprint.name,
          propertiesBindings: initializePropertiesBindings(blueprint),
          reactions: {},
          layout: {},
        };

        console.log(
          "adding newComponentInstance",
          newComponentInstance,
          containerKeypath
        );

        addRecordToCollectionFragment(
          `${containerKeypath}/components`,
          newComponentInstance
        );
      }
    },
    [
      keypath,
      viewgraph,
      getFragment,
      setFragment,
      addRecordToCollectionFragment,
      removeRecordFromCollectionFragment,
    ]
  );

  const handleComponentSelect = (componentId: string) => {
    setSelectedComponentIds([componentId]);
    setSelectedContainerKeypath(null);
  };

  const moveComponent = useCallback(
    (containerKeypath: string, fromIndex: number, toIndex: number) => {
      const container = getFragment(containerKeypath) as ContainerStructure;

      if (
        fromIndex >= 0 &&
        fromIndex < container.components.length &&
        toIndex >= 0 &&
        toIndex < container.components.length
      ) {
        const newComponents = [...container.components];
        const [movedComponent] = newComponents.splice(fromIndex, 1);
        newComponents.splice(toIndex, 0, movedComponent);
        setFragment(`${containerKeypath}/components`, newComponents);
      }
    },
    [getFragment, setFragment]
  );

  const renderContainer = (containerKeypath: string) => {
    const container = getFragment(containerKeypath) as ContainerStructure;
    const isRootContainer =
      containerKeypath === `${keypath}/containerStructure`;

    console.log(
      "Rendering container:",
      container.id,
      "with direction:",
      container.layoutDirection
    );

    console.log(
      "Available Component Blueprints:",
      availableComponentBlueprints
    );

    const flexDirection =
      container.layoutDirection === "horizontal" ? "row" : "column";

    const containerStyle: React.CSSProperties = {
      display: "flex",
      flexDirection: flexDirection,
      ...getSizeStyle(container.layoutDirection, container.size),
      border:
        showBorders && !isRootContainer
          ? selectedContainerId === container.id
            ? "1px dashed #3182ce"
            : "1px dashed #ccc"
          : "none",
      transition: "background-color 0.3s, border-color 0.3s",
      backgroundColor:
        draggedOver === containerKeypath
          ? "rgba(0, 0, 255, 0.1)"
          : "transparent",
      width: isRootContainer ? "100%" : undefined,
      height: isRootContainer ? "100%" : undefined,
      padding: "8px",
      borderRadius: "4px",
      overflow: container.isScrollable ? "auto" : "hidden",
    };

    const renderComponentWithSlots = (component: ComponentInstance) => {
      return (
        <ViewgraphComponentInstance
          componentInstance={component}
          selectedComponentIds={selectedComponentIds}
          containerKeypath={containerKeypath}
          onSelectComponent={handleComponentSelect}
        />
      );
    };

    return (
      <div
        key={container.id}
        style={containerStyle}
        onClick={(e) => {
          e.stopPropagation();
          if (!isRootContainer) {
            setSelectedContainerId(container.id);
            setSelectedComponentIds([]);
            setSelectedContainerKeypath(containerKeypath);
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setDraggedOver(containerKeypath);
        }}
        onDragLeave={() => setDraggedOver(null)}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDraggedOver(null);
          const componentId = e.dataTransfer.getData("componentId");
          const blueprintName = e.dataTransfer.getData("blueprintName");
          const blueprint = blueprintName
            ? availableComponentBlueprints.find(
                (bp) => bp.name === blueprintName
              )
            : undefined;
          handleDrop(containerKeypath, componentId || null, blueprint);
        }}
        className={`cursor-pointer ${
          selectedContainerKeypath === containerKeypath
            ? "ring-2 ring-blue-500"
            : ""
        }`}
      >
        {container.components &&
          container.components.map((component) => (
            <div
              key={component.id}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("componentId", component.id);
                e.dataTransfer.setData(
                  "blueprintName",
                  component.blueprintName
                );
              }}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                handleComponentSelect(component.id);
                setSelectedContainerKeypath(null);
              }}
              className={`cursor-pointer relative mb-4`}
              style={{
                alignSelf: "flex-start",
                width: "100%",
              }}
            >
              {renderComponentWithSlots(component)}
            </div>
          ))}
        {container.subcontainers.map((subcontainer) =>
          renderContainer(
            `${containerKeypath}/subcontainers/id:${subcontainer.id}`,
            container.layoutDirection
          )
        )}
      </div>
    );
  };

  const handlePropertyChange = useCallback(
    (propertyName: string, newValue: any) => {
      if (!selectedComponentId) {
        console.error("No component selected");
        return;
      }

      const componentKeypath = findComponentKeypath(
        viewgraph.containerStructure,
        selectedComponentId,
        `${keypath}/containerStructure`
      );

      if (!componentKeypath) {
        console.error(
          `Could not find component with ID: ${selectedComponentId}`
        );
        return;
      }

      setFragment(
        `${componentKeypath}/propertiesBindings/${propertyName}`,
        newValue
      );

      const event = new CustomEvent("refreshComponentPreview", {
        detail: { componentId: selectedComponentId },
      });
      window.dispatchEvent(event);
    },
    [
      viewgraph,
      keypath,
      selectedComponentIds,
      findComponentKeypath,
      setFragment,
    ]
  );

  const getPropertyType = useCallback(
    (propertyName: string, blueprintName: string) => {
      const blueprint = availableComponentBlueprints.find(
        (bp) => bp.name === blueprintName
      );
      if (!blueprint) {
        console.error(`Could not find blueprint: ${blueprintName}`);
        return "String";
      }

      const property = blueprint.properties.find(
        (p) => p.name === propertyName
      );
      if (!property) {
        console.error(
          `Could not find property ${propertyName} in blueprint ${blueprintName}`
        );
        return "String";
      }

      return property.dataType;
    },
    [availableComponentBlueprints]
  );

  const handleLayoutChange = (
    componentId: string,
    updatedLayout: Record<string, string>
  ) => {
    const componentKeypath = findComponentKeypath(
      viewgraph.containerStructure,
      componentId,
      `${keypath}/containerStructure`
    );
    if (componentKeypath) {
      setFragment(`${componentKeypath}/layout`, updatedLayout);
      setSelectedComponentIds([...selectedComponentIds]);
    }
  };

  const findComponentById = (
    container: ContainerStructure,
    componentId: string
  ): ComponentInstance | null => {
    const findInComponents = (
      components: ComponentInstance[]
    ): ComponentInstance | null => {
      for (const component of components) {
        if (component.id === componentId) return component;

        if (component.$slots) {
          for (const slot of Object.values(component.$slots)) {
            const found = findInComponents(slot.$children || []);
            if (found) return found;
          }
        }
      }
      return null;
    };

    return findInComponents(container.components);
  };

  const handleDeleteComponents = () => {
    selectedComponentIds.forEach((componentId) => {
      const containerKeypath = findContainerKeypathForComponent(
        viewgraph.containerStructure,
        componentId,
        `${keypath}/containerStructure`
      );
      if (containerKeypath) {
        removeRecordFromCollectionFragment(
          `${containerKeypath}/components`,
          componentId
        );
      }
    });
    setSelectedComponentIds([]);
  };

  const handleToggleRootLayoutDirection = () => {
    const rootContainerKeypath = `${keypath}/containerStructure`;
    const rootContainer = getFragment(
      rootContainerKeypath
    ) as ContainerStructure;
    const newLayoutDirection =
      rootContainer.layoutDirection === "horizontal"
        ? "vertical"
        : "horizontal";

    // Update the entire viewgraph structure
    const updatedViewgraph = {
      ...viewgraph,
      containerStructure: {
        ...rootContainer,
        layoutDirection: newLayoutDirection,
      },
    };

    setFragment(keypath, updatedViewgraph);
  };

  const handleCopyBlueprintName = useCallback(() => {
    if (selectedComponentIds.length === 1) {
      const componentId = selectedComponentIds[0];
      const componentKeypath = findComponentKeypath(
        viewgraph.containerStructure,
        componentId,
        `${keypath}/containerStructure`
      );
      if (componentKeypath) {
        const component = getFragment(componentKeypath) as ComponentInstance;
        setBlueprintNameBuffer(component.blueprintName);
      }
    }
  }, [selectedComponentIds, viewgraph, keypath, getFragment]);

  const handlePasteComponent = useCallback(() => {
    if (blueprintNameBuffer && selectedContainerId) {
      handleAddComponent(blueprintNameBuffer);
    }
  }, [blueprintNameBuffer, selectedContainerId, handleAddComponent]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const isShortcutPressed = isMac
        ? event.metaKey && event.key === "e"
        : event.ctrlKey && event.key === "e";

      if (
        isShortcutPressed &&
        (selectedContainerId || selectedComponentIds.length > 0)
      ) {
        event.preventDefault();
        onOpen();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedContainerId, selectedComponentIds, onOpen]);

  const handleContainerSelect = (containerId: string) => {
    const containerKeypath = findContainerKeypathById(
      viewgraph.containerStructure,
      containerId,
      `${keypath}/containerStructure`
    );
    if (containerKeypath) {
      setSelectedContainerId(containerId);
      setSelectedContainerKeypath(containerKeypath);
      setSelectedComponentIds([]);
    }
  };

  const addComponentToSlot = (
    component: ComponentInstance,
    slotPath: string
  ) => {
    // Get the current children array
    const currentChildren = getFragment(slotPath) || [];

    // Ensure we're adding the component directly, not as an array
    const updatedChildren = [...currentChildren, component];

    // Update the fragment
    setFragment(slotPath, updatedChildren);
  };

  const removeComponentFromCurrentLocation = (componentId: string) => {
    const removeFromComponents = (components: ComponentInstance[]): boolean => {
      const index = components.findIndex((c) => c.id === componentId);
      if (index !== -1) {
        const containerKeypath = findContainerKeypathForComponent(
          viewgraph.containerStructure,
          componentId,
          `${keypath}/containerStructure`
        );
        if (containerKeypath) {
          // Get current components
          const currentComponents = getFragment(
            `${containerKeypath}/components`
          );
          // Filter out the component we want to remove
          const updatedComponents = currentComponents.filter(
            (c: ComponentInstance) => c.id !== componentId
          );
          // Update with the filtered array
          setFragment(`${containerKeypath}/components`, updatedComponents);
        }
        return true;
      }

      for (const component of components) {
        if (component.$slots) {
          for (const [slotName, slot] of Object.entries(component.$slots)) {
            const slotChildren = slot.$children || [];
            const childIndex = slotChildren.findIndex(
              (c) => c.id === componentId
            );
            if (childIndex !== -1) {
              const componentKeypath = findComponentKeypath(
                viewgraph.containerStructure,
                component.id,
                `${keypath}/containerStructure`
              );
              if (componentKeypath) {
                const currentChildren = getFragment(
                  `${componentKeypath}/$slots/${slotName}/$children`
                );
                const updatedChildren = currentChildren.filter(
                  (c: ComponentInstance) => c.id !== componentId
                );
                setFragment(
                  `${componentKeypath}/$slots/${slotName}/$children`,
                  updatedChildren
                );
                return true;
              }
            }
            if (removeFromComponents(slotChildren)) {
              return true;
            }
          }
        }
      }
      return false;
    };

    removeFromComponents(viewgraph.containerStructure.components);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex h-full overflow-hidden" ref={editorRef}>
        <div className="w-110 h-full overflow-y-auto border-r border-gray-300 flex flex-col">
          <HStack spacing={1} p={1}>
            <Button
              onClick={onOpen}
              colorScheme="blue"
              size="xs"
              isDisabled={
                !selectedContainerId && selectedComponentIds.length === 0
              }
            >
              Add Component
            </Button>
          </HStack>
          <ComponentTree
            structure={viewgraph.containerStructure}
            selectedComponentIds={selectedComponentIds}
            setSelectedComponentIds={setSelectedComponentIds}
            selectedContainerId={selectedContainerId}
            onSelectComponent={handleComponentSelect}
            onSelectContainer={handleContainerSelect}
            onMoveComponent={handleMoveComponentInTree}
            availableComponentBlueprints={availableComponentBlueprints}
            onToggleRootLayoutDirection={handleToggleRootLayoutDirection}
            onDeleteComponent={handleDeleteComponents}
            onDuplicateComponent={() => {}}
            findComponentById={(id) =>
              findComponentById(viewgraph.containerStructure, id)
            }
            removeComponentFromCurrentLocation={
              removeComponentFromCurrentLocation
            }
            addComponentToSlot={addComponentToSlot}
            findComponentKeypath={(structure, id) =>
              findComponentKeypath(
                structure,
                id,
                `${keypath}/containerStructure`
              )
            }
          />
        </div>

        <div className="flex-grow flex flex-col overflow-hidden">
          <div className="p-2 border-b border-gray-300 flex justify-between items-center">
            <Switch
              isChecked={showBorders}
              onChange={(e) => setShowBorders(e.target.checked)}
              colorScheme="blue"
            >
              Show container borders
            </Switch>

            {viewgraph.containerStructure &&
              viewgraph.containerStructure.isLayoutApplied && (
                <Button
                  leftIcon={<EditIcon />}
                  onClick={() => setIsLayoutModalOpen(true)}
                  size="sm"
                  colorScheme="teal"
                  mr={2}
                >
                  Edit Layout
                </Button>
              )}
          </div>

          <div className="flex-grow relative overflow-auto p-4">
            {viewgraph.containerStructure &&
            viewgraph.containerStructure.isLayoutApplied ? (
              <div
                className="border-2 border-gray-300 rounded-lg shadow-lg overflow-hidden"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {renderContainer(`${keypath}/containerStructure`, "vertical")}
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <div className="text-center">
                  <p className="mb-4 text-gray-600">No layout applied yet.</p>
                  <Button
                    colorScheme="blue"
                    onClick={() => setIsLayoutModalOpen(true)}
                  >
                    Edit Layout
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-96 flex-shrink-0 border-l border-gray-300 flex flex-col h-full">
          {selectedComponent ? (
            <>
              <div className="mb-4 flex justify-between items-center">
                <div className="mb-4 mt-5 ml-2 flex justify-between items-center">
                  <ButtonGroup isAttached variant="outline">
                    <Button
                      onClick={() => setEditorMode("properties")}
                      colorScheme={
                        editorMode === "properties" ? "blue" : "gray"
                      }
                      variant={
                        editorMode === "properties" ? "solid" : "outline"
                      }
                    >
                      Properties
                    </Button>
                    <Button
                      onClick={() => setEditorMode("layout")}
                      colorScheme={editorMode === "layout" ? "blue" : "gray"}
                      variant={editorMode === "layout" ? "solid" : "outline"}
                    >
                      Layout
                    </Button>
                    <Button
                      onClick={() => setEditorMode("style")}
                      colorScheme={editorMode === "style" ? "blue" : "gray"}
                      variant={editorMode === "style" ? "solid" : "outline"}
                    >
                      Style
                    </Button>
                  </ButtonGroup>
                </div>
                <IconButton
                  aria-label="Delete component"
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  size="sm"
                  onClick={() => {
                    console.log(
                      "Deleting components at keypath: ",
                      selectedContainerKeypath
                    );
                    handleDeleteComponents();
                  }}
                />
              </div>

              {editorMode === "properties" ? (
                <ComponentPropertiesPanel
                  selectedComponentId={selectedComponentId}
                  selectedComponentKeypath={selectedComponentKeypath}
                  availableComponentBlueprints={availableComponentBlueprints}
                  onDeleteComponent={handleDeleteComponents}
                  onPropertyChange={handlePropertyChange}
                />
              ) : editorMode === "layout" ? (
                <ComponentLayoutEditor
                  selectedComponent={selectedComponent}
                  blueprints={availableComponentBlueprints.reduce(
                    (acc, blueprint) => {
                      acc[blueprint.name] = blueprint;
                      return acc;
                    },
                    {} as Record<string, any>
                  )}
                  onLayoutChange={handleLayoutChange}
                />
              ) : editorMode === "style" ? (
                <Box p={4}>
                  <StyleEditor
                    styleClasses={
                      selectedComponent?.styles?.classes ||
                      availableComponentBlueprints.find(
                        (bp) => bp.name === selectedComponent?.blueprintName
                      )?.viewgraph?.nodeTree?.$styleClasses ||
                      ""
                    }
                    onChange={(newClasses) => {
                      const componentId = selectedComponentIds[0];
                      if (!componentId) return;

                      const componentKeypath = findComponentKeypath(
                        viewgraph.containerStructure,
                        componentId,
                        `${keypath}/containerStructure`
                      );

                      if (componentKeypath) {
                        setFragment(
                          `${componentKeypath}/styles/classes`,
                          newClasses
                        );

                        const event = new CustomEvent(
                          "refreshComponentPreview",
                          {
                            detail: { componentId },
                          }
                        );
                        window.dispatchEvent(event);
                      }
                    }}
                  />
                </Box>
              ) : editorMode === "events" ? (
                <ComponentEventsPanel
                  selectedComponent={selectedComponent}
                  componentKeypath={findComponentKeypath(
                    viewgraph.containerStructure,
                    selectedComponent.id,
                    `${keypath}/containerStructure`
                  )}
                />
              ) : (
                <div className="p-4 text-center text-gray-500">
                  Styles are not yet supported.
                </div>
              )}
            </>
          ) : selectedContainerKeypath ? (
            renderContainerProperties()
          ) : (
            <div className="p-4 text-center text-gray-500">
              Select a component or container to view its properties
            </div>
          )}
        </div>

        <NoComponentsModal />
        <SlotNameModal />

        <Modal
          isOpen={isLayoutModalOpen}
          onClose={() => setIsLayoutModalOpen(false)}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent maxWidth="90vw" maxHeight="90vh">
            <ModalHeader>Layout Editor</ModalHeader>
            <ModalBody overflow="auto">
              <LayoutModeEditor
                keypath={keypath}
                onLayoutChange={(layout) => {
                  setFragment(`${keypath}/containerStructure`, layout);
                  setIsLayoutModalOpen(false);
                }}
                canvasSize={{ width: 1200, height: 800 }}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setIsLayoutModalOpen(false)}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <ComponentLibraryModal
          isOpen={isOpen}
          onClose={onClose}
          componentBlueprints={availableComponentBlueprints}
          onAddComponent={handleAddComponent}
        />
      </div>
    </DndProvider>
  );
};

const findContainerKeypathForComponent = (
  container: ContainerStructure,
  componentId: string,
  currentKeypath: string
): string | null => {
  if (
    container.components &&
    container.components.some((c) => c.id === componentId)
  ) {
    return currentKeypath;
  }

  for (const subcontainer of container.subcontainers) {
    const result = findContainerKeypathForComponent(
      subcontainer,
      componentId,
      `${currentKeypath}/subcontainers/id:${subcontainer.id}`
    );
    if (result) return result;
  }

  return null;
};

const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

const findContainerKeypathById = (
  container: ContainerStructure,
  containerId: string,
  currentKeypath: string
): string | null => {
  if (container.id === containerId) {
    return currentKeypath;
  }

  for (const subcontainer of container.subcontainers) {
    const result = findContainerKeypathById(
      subcontainer,
      containerId,
      `${currentKeypath}/subcontainers/id:${subcontainer.id}`
    );
    if (result) return result;
  }

  return null;
};

export default ComponentModeEditor;
