import React, { useState, useCallback, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import DynamicField, { DescriptorRecordField } from './DynamicField';

interface NewRecordModalProps {
  isOpen: boolean;  
  onClose: () => void;
  onSubmit: (newItem: Record<string, any>) => void;
  fields: DescriptorRecordField[];
  title: string;
}

const NewRecordModal: React.FC<NewRecordModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  fields,
  title,
}) => {
  const [formData, setFormData] = useState<Record<string, any>>({});

  const handleChange = useCallback((keypath: string, value: any) => {
    setFormData((prevData) => ({ ...prevData, [keypath]: value }));
  }, []);

  const handleSubmit = () => {
    onSubmit(formData);
    setFormData({});
    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (isOpen && fields.length > 0) {
      setTimeout(() => {
        const firstInput = document.querySelector(`[name="${fields[0].keypath}"]`) as HTMLElement;
        if (firstInput) {
          firstInput.focus();
        }
      }, 0);
    }
  }, [isOpen, fields]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent onKeyDown={handleKeyDown}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {fields && fields.length > 0 && fields.map((field) => (
            <DynamicField
              key={field.keypath}
              field={field}
              value={formData[field.keypath] || ''}
              onChange={(value) => handleChange(field.keypath, value)}
            />
          ))}
          {fields && fields.length === 0 && <Text>No fields to display</Text>}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Create
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewRecordModal;