import { v4 as uuidv4 } from "uuid";
import pluralize from "pluralize";
import { generateDefaultPageDescriptor } from "@/bundles/DescriptorEditor/schemas/essentials/pagesSchema";

interface DataModel {
  id: string;
  name: string;
}

export class DefaultPageGroupGenerator {
  private dataModel: DataModel;

  constructor(dataModel: DataModel) {
    this.dataModel = dataModel;
  }

  generatePageGroup(dataModelId: string, modelName: string) {
    const pluralName = pluralize(modelName);
    const singularName = modelName;
    const basePath = `/${pluralName.toLowerCase().replace(/\s+/g, "-")}`;

    const defaultPages = [
      generateDefaultPageDescriptor({
        name: `${singularName} Index`,
        relativePath: "/",
        pageType: "index",
      }),
      generateDefaultPageDescriptor({
        name: `${singularName} Show`,
        relativePath: "/:id",
        pageType: "show",
      }),
      generateDefaultPageDescriptor({
        name: `New ${singularName}`,
        relativePath: "/new",
        pageType: "new",
      }),
    ];

    return {
      id: uuidv4(),
      name: pluralName,
      description: `Pages for managing ${pluralName.toLowerCase()}`,
      dataModelId,
      basePath,
      controllerName: `${pluralName.replace(/\s+/g, "")}Controller`,
      pages: defaultPages.map((page) => ({
        ...page,
        dataModelId,
      })),
    };
  }
}
