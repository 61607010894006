export const String = {
  id: '_types.String',
  zodType: 'string',
  railsNativeType: 'string',
}
export const Boolean = {
  id: '_types.Boolean',
  zodType: 'boolean',
  railsNativeType: 'boolean',
}
export const Number = {
  id: '_types.Number',
  zodType: 'number',
  railsNativeType: 'decimal',
}
export const Dictionary = {
  id: '_types.Dictionary',
  zodType: 'record',
  railsNativeType: 'hash',
}
export const List = {
  id: '_types.List',
  zodType: 'array',
  railsNativeType: 'array',
}

export const coreDataTypes = [String.id, Boolean.id, Number.id, Dictionary.id, List.id]