import React, { useCallback } from "react";
import { Box, FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { DeclarePageVariableDirective } from "@/runtime-js/src/directives/declarePageVariable";
import { DirectiveContainer } from "../DirectiveContainer";
import { DirectiveSelector } from "../DirectiveSelector";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface DeclarePageVariableEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function DeclarePageVariableEditor({ keypath, flowgraphContext }: DeclarePageVariableEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as DeclarePageVariableDirective;

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVariableName = event.target.value;
      setFragment(keypath, {
        ...directive,
        config: { ...directive.config, name: newVariableName },
      });
    },
    [keypath, directive, setFragment]
  );

  const handleInitialValueChange = useCallback(
    (newDirectiveType: string, newDirective: any) => {
      setFragment(keypath, {
        ...directive,
        config: { ...directive.config, initialValue: newDirective },
      });
    },
    [keypath, directive, setFragment]
  );

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="variableName" fontSize="sm">
          Variable Name
        </FormLabel>
        <Input
          id="variableName"
          value={directive.config.name}
          onChange={handleNameChange}
        />
      </FormControl>
      <FormControl mt={2}>
        <FormLabel htmlFor="variableType" fontSize="sm">
          Data Type
        </FormLabel>
        <Select
          id="variableType"
          value={directive.config.type}
          onChange={(event) => {
            setFragment(keypath, {
              ...directive,
              config: { ...directive.config, type: event.target.value },
            });
          }}
        >
          <option value="_types.ActiveCollection">Data collection instance
          </option>
          <option value="_types.ActiveRecord">Data record instance</option>
          <option value="_types.String">String</option>
          <option value="_types.Number">Number</option>
          <option value="_types.Boolean">Boolean</option>
          <option value="_types.List">List</option>
          <option value="_types.Dictionary">Dictionary</option>
        </Select>
      </FormControl>
      <Box mt={2}>
        <FormLabel fontSize="sm">Initial Value</FormLabel>
        {!directive.config.initialValue ? (
          <Box mb={2}>
            <DirectiveSelector
              currentDirectiveType={null}
              keypath={`${keypath}/config/initialValue`}
              onChange={handleInitialValueChange}
              flowgraphContext={flowgraphContext}
            />
          </Box>
        ) : (
          <DirectiveContainer
            keypath={`${keypath}/config/initialValue`}
            flowgraphContext={flowgraphContext}
            onRemoveDirective={() => handleInitialValueChange(null, undefined)}
            isNested
          />
        )}
      </Box>
    </Box>
  );
}