import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import {
  categorizeTailwindClasses,
  combineTailwindClasses,
} from "./PropertyEditor/tailwindClassParser";
import { ComponentInstance } from "@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema";
interface ComponentLayoutEditorProps {
  selectedComponent: ComponentInstance;
  onLayoutChange: (
    componentId: string,
    updatedLayout: Record<string, string>
  ) => void;
}

const ComponentLayoutEditor: React.FC<ComponentLayoutEditorProps> = ({
  selectedComponent,
  onLayoutChange,
}) => {
  const initialClasses = selectedComponent.layout?.classes || "";
  const categorizedClasses = categorizeTailwindClasses(initialClasses);

  const handleCategoryChange = (category: string, newClasses: string) => {
    const updatedCategories = {
      ...categorizedClasses,
      [category]: newClasses.split(" ").filter(Boolean),
    };
    const combinedClasses = combineTailwindClasses(updatedCategories);
    onLayoutChange(selectedComponent.id, { classes: combinedClasses });
  };

  return (
    <Box
      p={4}
      borderLeft="1px"
      borderColor="gray.200"
      height="100%"
      overflowY="auto"
    >
      <VStack spacing={4} align="stretch">
        <Heading size="md">{selectedComponent.blueprintName} Layout</Heading>
        <Accordion allowMultiple>
          {Object.entries(categorizedClasses).map(([category, classes]) => (
            <AccordionItem key={category}>
              <AccordionButton>
                <Box flex="1" textAlign="left" textTransform="capitalize">
                  {category}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Input
                  value={classes.join(" ")}
                  onChange={(e) =>
                    handleCategoryChange(category, e.target.value)
                  }
                  placeholder={`Add ${category} classes...`}
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </Box>
  );
};

export default ComponentLayoutEditor;
