import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Box, Select, HStack, Text } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface KeypathBuilderProps {
  baseOptions: string[];
  initialKeypath: string;
  onChange: (keypath: string) => void;
}

export const KeypathBuilder: React.FC<KeypathBuilderProps> = ({ baseOptions, initialKeypath, onChange }) => {
    const [keypath, setKeypath] = useState<string[]>(initialKeypath?.split(".") || []);
    const { getFragment } = useAppDescriptorStore();

    console.log("baseOptions", baseOptions);
    console.log("initialKeypath", initialKeypath);

    useEffect(() => {
      setKeypath(initialKeypath?.split(".") || []);
    }, [initialKeypath]);

    const handleKeypathChange = useCallback(
      (newKeypath: string[]) => {
        setKeypath(newKeypath);
        onChange(newKeypath.join("."));
      },
      [onChange],
    );

    const getOptionsForLevel = useCallback(
      (currentKeypath: string[]) => {
        if (currentKeypath.length === 0) return baseOptions;

        const value = getFragment(currentKeypath.join("."));
        if (typeof value === "object" && value !== null) {
          return Object.keys(value);
        }
        return [];
      },
      [getFragment, baseOptions],
    );

    const handleSelect = useCallback(
      (index: number, value: string) => {
        handleKeypathChange([...keypath.slice(0, index), value]);
      },
      [keypath, handleKeypathChange],
    );

    const keypathOptions = useMemo(
      () =>
        keypath.map((part, index) => ({
          value: part,
          options: getOptionsForLevel(keypath.slice(0, index)),
        })),
      [keypath, getOptionsForLevel],
    );

    const nextLevelOptions = useMemo(
      () => getOptionsForLevel(keypath),
      [keypath, getOptionsForLevel],
    );

    useEffect(() => {
      if (baseOptions.length === 1 && keypath.length === 0) {
        handleKeypathChange([baseOptions[0]]);
      }
    }, [baseOptions, keypath, handleKeypathChange]);

    useEffect(() => {
      handleSelect(0, keypathOptions[0]?.options?.[0] || "");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Box>
        <HStack spacing={2} align="flex-start">
          {keypathOptions.map(({ value, options }, index) => (
            <Select
              key={index}
              value={value}
              onChange={(e) => handleSelect(index, e.target.value)}
              size="sm"
            >
              {options?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          ))}
          {nextLevelOptions.length > 0 && keypath.length > 0 && (
            <Select
              placeholder="Select next"
              onChange={(e) =>
                handleKeypathChange([...keypath, e.target.value])
              }
              size="sm"
            >
              {nextLevelOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          )}
        </HStack>
        <Text mt={2} fontSize="sm">
          Current keypath: {keypath.join(".")}
        </Text>
      </Box>
    );
};
