import React from 'react';
import { Box, Button, useDisclosure, Text, Switch, Flex } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import DataTable from './DataTable';
import { useAppDescriptorStore } from '../../stores/appDescriptorStore';
import { useNavigate, useLocation } from 'react-router-dom';

export interface Column {
  name: string;
  render: (row: any) => React.ReactNode;
}

interface CollectionEditorProps {
  keypath: string;
  collectionItemType: string;
  columns: Column[];
  addButtonText?: string;
  NewItemModal?: React.ComponentType<{
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (newItem: any) => void;
  }>;
  filter?: (item: any) => boolean;
  isDataGroup?: boolean;
}

const CollectionEditor: React.FC<CollectionEditorProps> = ({
  keypath,
  columns,
  addButtonText,
  NewItemModal,
  filter,
  isDataGroup = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getFragment, setFragment, addRecordToCollectionFragment } = useAppDescriptorStore();
  const navigate = useNavigate();
  const location = useLocation();

  const collection = getFragment(keypath) as any[] || [];
  const filteredCollection = filter ? collection.filter(filter) : collection;

  const defaultPages = isDataGroup
    ? ['index', 'show', 'edit', 'new']
    : [];

  const renderDataGroupPages = () => (
    <>
      {defaultPages.map((pageName) => (
        <Box key={pageName} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Text>{pageName} page</Text>
          <Switch 
            isChecked={filteredCollection.some(page => page.name === pageName)}
            onChange={(e) => {
              if (e.target.checked) {
                addRecordToCollectionFragment(keypath, { name: pageName, type: 'default', group: filteredCollection[0].group });
              } else {
                setFragment(keypath, filteredCollection.filter(page => page.name !== pageName));
              }
            }}
          />
        </Box>
      ))}
    </>
  );

  const handleAddItem = (newItem: any) => {
    addRecordToCollectionFragment(keypath, newItem);
    onClose();
  };

  const navigateToItem = (item: any, index: number) => {
    navigate(`${location.pathname}/id:${item.id}`);
  };

  const handleDeleteItem = (index: number) => {
    setFragment(keypath, collection.filter((_, i) => i !== index));
  };

  return (
    <Box w="100%" maxW="768px" mx="auto">
      {isDataGroup && renderDataGroupPages()}
      {addButtonText && NewItemModal && (
        <Flex justifyContent="flex-end" mb={4}>
          <Button size="sm" onClick={onOpen} leftIcon={<FiPlus />}>
            {addButtonText}
          </Button>
        </Flex>
      )}
      <DataTable
        columns={columns}
        data={filteredCollection}
        onRowClick={navigateToItem}
        onDeleteRow={handleDeleteItem}
        disableDelete={isDataGroup ? (row) => defaultPages.includes(row.name) : undefined}
      />
      {NewItemModal && (
        <NewItemModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleAddItem}
        />
      )}
    </Box>
  );
};

export default CollectionEditor;