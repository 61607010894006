import React from "react";
import { ModalFooter as ChakraModalFooter, Button, HStack } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

interface ModalFooterProps {
  onClose: () => void;
  isExplanationOpen: boolean;
  setIsExplanationOpen: (isOpen: boolean) => void;
  selectedItem: DirectiveItem | null;
  selectDirective: (item: DirectiveItem) => void;
  keypath: string[];
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  onClose,
  isExplanationOpen,
  setIsExplanationOpen,
  selectedItem,
  selectDirective,
  keypath,
}) => (
  <ChakraModalFooter justifyContent="space-between">
    <HStack>
      <Button onClick={onClose}>Cancel</Button>
      {isExplanationOpen && selectedItem && (
        <Button
          colorScheme="blue"
          onClick={() => selectDirective(selectedItem)}
        >
          Use this directive
        </Button>
      )}
    </HStack>
    <Button
      onClick={() => setIsExplanationOpen(!isExplanationOpen)}
      leftIcon={<InfoIcon />}
      variant="ghost"
    >
      {isExplanationOpen ? "Hide Details" : "Show Details"}
    </Button>
  </ChakraModalFooter>
);