import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { railsApiCall } from "../utils/railsApiCall";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Textarea,
  VStack,
  useToast,
  IconButton,
  Switch,
} from "@chakra-ui/react";
import { FaMicrophone, FaStop, FaTimes, FaHistory } from "react-icons/fa";
import PatchPreview from "./PatchPreview";
import { Operation } from "../utils/bjsonPatch";
import { useAppDescriptorStore } from "../stores/appDescriptorStore";
import AudioWaveform from "./AudioWaveform";

const AIPatchGenerator: React.FC = () => {
  const [prompt, setPrompt] = useState("");
  const [patches, setPatches] = useState<Operation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPatch, setSelectedPatch] = useState<Operation | null>(null);
  const { projectId } = useParams();
  const toast = useToast();
  const applyPatches = useAppDescriptorStore((state) => state.applyPatches);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcriptionError, setTranscriptionError] = useState<string | null>(
    null
  );
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [autoApply, setAutoApply] = useState(false);
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "i" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setIsVisible((prev) => !prev);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (audioBlob && !isRecording) {
      transcribeAudio(audioBlob);
    }
  }, [audioBlob, isRecording]);

  useEffect(() => {
    if (autoSubmit && prompt.trim() !== "") {
      const debounceTimer = setTimeout(() => {
        handleSubmit();
      }, 1000); // 1 second delay

      return () => clearTimeout(debounceTimer);
    }
  }, [prompt, autoSubmit]);

  useEffect(() => {
    if (autoApply && patches.length > 0) {
      applyAllPatches();
    }
  }, [patches, autoApply]);

  if (!isVisible) return null;

  const startRecording = async () => {
    try {
      // Clear the audio blob and transcription error
      setAudioBlob(null);
      setTranscriptionError(null);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const audioChunks: BlobPart[] = [];
      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        setAudioBlob(audioBlob);
        setAudioStream(null);
      });

      mediaRecorder.start();
      setIsRecording(true);
      setAudioStream(stream);
      setTranscriptionError(null); // Clear any previous errors
    } catch (error) {
      console.error("Error accessing microphone:", error);
      toast({
        title: "Error",
        description: "Failed to access microphone",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsTranscribing(true);
    }
  };

  const cancelTranscription = () => {
    setIsTranscribing(false);
    setAudioBlob(null);
    setTranscriptionError(null);
  };

  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      const formData = new FormData();
      formData.append("audio", audioBlob, "audio.wav");

      const { data } = await railsApiCall<{ transcription: string }>({
        method: "POST",
        endpoint: `/projects/${projectId}/transcribe_audio`,
        body: formData,
        headers: {
          // Remove the Content-Type header to let the browser set it automatically with the correct boundary
        },
      });

      setPrompt(data.transcription);
      setIsTranscribing(false);
      setAudioBlob(null);
    } catch (error) {
      console.error("Transcription error:", error);
      setTranscriptionError("Failed to transcribe audio. Please try again.");
      setIsTranscribing(false);
    }
  };

  const generatePatches = async (
    prompt: string,
    projectId: string,
    keypath: string,
    audioBlob?: Blob
  ) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("prompt", prompt);
      formData.append("keypath", keypath);
      if (audioBlob) {
        formData.append("audio", audioBlob, "audio.wav");
      }

      const { data } = await railsApiCall<any>({
        method: "POST",
        endpoint: `/projects/${projectId}/generate_patches`,
        body: formData,
        headers: {
          // Remove the Content-Type header here as well
        },
      });
      return data;
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Failed to generate patches: " +
          (error instanceof Error ? error.message : String(error)),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    const keypath = window.location.pathname.split("/").splice(4).join("/");
    if (!projectId) {
      toast({
        title: "Error",
        description: "Project ID not found in URL",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const generatedPatches = await generatePatches(prompt, projectId, keypath);
    if (generatedPatches) {
      setPatches(generatedPatches.diff);
      // Add the current prompt to history
      setHistory((prevHistory) => [prompt, ...prevHistory.slice(0, 9)]);
    }
  };

  const applyPatch = async (patch: Operation) => {
    applyPatches([patch]);
    toast({
      title: "Patch Applied",
      description: "The patch has been applied successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyAllPatches = async () => {
    applyPatches(patches);
    toast({
      title: "All Patches Applied",
      description: "All patches have been applied successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleHistoryClick = (historyItem: string) => {
    setPrompt(historyItem);
  };

  return (
    <>
      {isVisible && (
        <Box
          bg="white"
          p={6}
          boxShadow="lg"
          borderTop="1px"
          borderColor="gray.200"
        >
          <Flex maxW="7xl" mx="auto" gap={6} height="256px">
            {/* History Section */}
            <Box width="200px" borderRight="1px" borderColor="gray.200" pr={4}>
              <Heading as="h3" size="md" mb={3}>
                History
              </Heading>
              <VStack
                spacing={2}
                align="stretch"
                overflowY="auto"
                height="calc(100% - 40px)"
              >
                {history.map((item, index) => (
                  <Button
                    key={index}
                    onClick={() => handleHistoryClick(item)}
                    size="sm"
                    justifyContent="flex-start"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    leftIcon={<FaHistory />}
                    width="100%"
                    height="auto"
                    py={2}
                    px={3}
                    textAlign="left"
                  >
                    <Text noOfLines={2}>
                      {item.split(" ").slice(0, 5).join(" ")}...
                    </Text>
                  </Button>
                ))}
              </VStack>
            </Box>

            {/* Generate Patches Section */}
            <Box flex={1} borderRight="1px" borderColor="gray.200" pr={6}>
              <Flex justifyContent="space-between" alignItems="center" mb={3}>
                <Heading as="h3" size="md">
                  Generate patches
                </Heading>
                <Flex alignItems="center">
                  <Switch
                    id="auto-submit"
                    isChecked={autoSubmit}
                    onChange={(e) => setAutoSubmit(e.target.checked)}
                    mr={2}
                  />
                  <Text fontSize="sm" mr={4}>
                    Auto-submit
                  </Text>
                  <Switch
                    id="auto-apply"
                    isChecked={autoApply}
                    onChange={(e) => setAutoApply(e.target.checked)}
                    mr={2}
                  />
                  <Text fontSize="sm">Auto-apply</Text>
                </Flex>
              </Flex>
              <form onSubmit={handleSubmit}>
                <VStack spacing={3} height="calc(100% - 40px)">
                  {isRecording && audioStream ? (
                    <AudioWaveform stream={audioStream} />
                  ) : (
                    <Textarea
                      value={prompt}
                      onChange={(e) => setPrompt(e.target.value)}
                      placeholder="Enter your prompt for patch generation..."
                      rows={5}
                      isDisabled={isTranscribing || isLoading}
                      resize="none"
                      flex={1}
                    />
                  )}
                  <Flex width="full" justifyContent="space-between">
                    <IconButton
                      aria-label={
                        isRecording ? "Stop Recording" : "Start Recording"
                      }
                      icon={isRecording ? <FaStop /> : <FaMicrophone />}
                      onClick={isRecording ? stopRecording : startRecording}
                      colorScheme={isRecording ? "red" : "blue"}
                      isDisabled={isTranscribing || isLoading}
                    />
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={isLoading}
                      loadingText="Generating..."
                      isDisabled={isTranscribing || isRecording}
                    >
                      Generate Patches
                    </Button>
                  </Flex>
                  {isTranscribing && (
                    <Flex
                      width="full"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text color="blue.500">Transcribing audio...</Text>
                      <IconButton
                        aria-label="Cancel Transcription"
                        icon={<FaTimes />}
                        onClick={cancelTranscription}
                        size="sm"
                        colorScheme="red"
                      />
                    </Flex>
                  )}
                  {transcriptionError && (
                    <Text color="red.500">{transcriptionError}</Text>
                  )}
                </VStack>
              </form>
            </Box>
            <Box flex={1} display="flex" flexDirection="column">
              <Heading as="h3" size="md" mb={3}>
                Generated patches
              </Heading>
              <Box overflowY="auto" flex={1} mb={3}>
                {patches.length > 0 ? (
                  <VStack spacing={3} align="stretch">
                    {patches.map((patch, index) => (
                      <Box key={index} p={3} bg="gray.50" borderRadius="md">
                        <Text fontWeight="medium">{patch.summary}</Text>
                        <Flex mt={2} gap={2}>
                          <Button
                            size="sm"
                            colorScheme="green"
                            onClick={() => applyPatch(patch)}
                          >
                            Apply Patch
                          </Button>
                          <Button
                            size="sm"
                            colorScheme="blue"
                            onClick={() => setSelectedPatch(patch)}
                          >
                            View JSON
                          </Button>
                        </Flex>
                      </Box>
                    ))}
                  </VStack>
                ) : (
                  <Text color="gray.500">No patches generated yet.</Text>
                )}
              </Box>
              {patches.length > 0 && (
                <Button colorScheme="green" onClick={applyAllPatches} mt={3}>
                  Apply all patches
                </Button>
              )}
            </Box>
          </Flex>
          {selectedPatch && (
            <PatchPreview
              patch={selectedPatch}
              onClose={() => setSelectedPatch(null)}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default AIPatchGenerator;
