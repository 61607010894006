import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  VStack,
  HStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FiTrash2, FiEdit } from "react-icons/fi";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { Role } from "@/bundles/DescriptorEditor/schemas/usersAndSecurity/userGroupsSchema";
import RoleModal from "./RoleModal";
import { v4 as uuidv4 } from "uuid";

const Roles: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [roles, setRoles] = useState<Role[]>([]);
  const [editingRole, setEditingRole] = useState<Role | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchedRoles = getFragment("/usersAndSecurity/roles") as Role[];
    const sanitizedRoles = fetchedRoles.map((role) => ({
      ...role,
      permissions: Array.isArray(role.permissions) ? role.permissions : [],
    }));
    setRoles(sanitizedRoles);
  }, [getFragment]);

  const handleAddRole = (newRole: Role) => {
    const updatedRoles = [...roles, { ...newRole, id: uuidv4() }];
    setFragment("/usersAndSecurity/roles", updatedRoles);
    setRoles(updatedRoles);
  };

  const handleUpdateRole = (updatedRole: Role) => {
    const updatedRoles = roles.map((role) =>
      role.id === updatedRole.id ? updatedRole : role
    );
    setFragment("/usersAndSecurity/roles", updatedRoles);
    setRoles(updatedRoles);
  };

  const handleDeleteRole = (id: string) => {
    const updatedRoles = roles.filter((role) => role.id !== id);
    setFragment("/usersAndSecurity/roles", updatedRoles);
    setRoles(updatedRoles);
  };

  return (
    <Box>
      <Heading as="h1" size="xl" mb={6}>
        Roles
      </Heading>
      <Button onClick={onOpen} mb={4}>
        Add Role
      </Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Permissions</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {roles.map((role) => (
            <Tr key={role.id}>
              <Td>{role.name}</Td>
              <Td>{role.description}</Td>
              <Td>
                {Array.isArray(role.permissions)
                  ? role.permissions.join(", ")
                  : ""}
              </Td>
              <Td>
                <HStack spacing={2}>
                  <IconButton
                    aria-label="Edit role"
                    icon={<FiEdit />}
                    size="sm"
                    onClick={() => {
                      setEditingRole(role);
                      onOpen();
                    }}
                  />
                  <IconButton
                    aria-label="Delete role"
                    icon={<FiTrash2 />}
                    size="sm"
                    onClick={() => handleDeleteRole(role.id)}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <RoleModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setEditingRole(null);
        }}
        onSubmit={(role) => {
          if (editingRole) {
            handleUpdateRole(role);
          } else {
            handleAddRole(role);
          }
          onClose();
          setEditingRole(null);
        }}
        initialData={editingRole}
      />
    </Box>
  );
};

export default Roles;
