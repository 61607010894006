const STYLE_CATEGORIES = {
  layout: ["flex", "grid", "block", "inline", "hidden"],
  spacing: ["p-", "m-", "gap-", "space-"],
  sizing: ["w-", "h-", "min-", "max-"],
  typography: ["text-", "font-", "tracking-", "leading-"],
  backgrounds: ["bg-"],
  borders: ["border", "rounded", "ring"],
  effects: ["shadow", "opacity", "transform"],
  states: ["hover:", "focus:", "active:", "disabled:"],
} as const;

export const categorizeTailwindClasses = (classString: string) => {
  const classes = classString.split(" ").filter(Boolean);
  const categorized: Record<string, string[]> = {};

  classes.forEach((cls) => {
    const category =
      Object.entries(STYLE_CATEGORIES).find(([_, patterns]) =>
        patterns.some((pattern) => cls.startsWith(pattern))
      )?.[0] || "other";

    if (!categorized[category]) {
      categorized[category] = [];
    }
    categorized[category].push(cls);
  });

  return categorized;
};

export const combineTailwindClasses = (
  categorized: Record<string, string[]>
) => {
  return Object.values(categorized).flat().join(" ");
};
