import { TypedAppDescriptor } from "../schemas/appDescriptorSchema";
import { v4 as uuidv4 } from "uuid";
import { dataModels } from "./dataModels";
import { pages, pageGroups } from "./pages";
import { apiEndpoints, apiNamespaces } from "./apiDefinitions";
import { applicationLayouts } from "./applicationLayouts";
import { componentBlueprints } from "./componentBlueprints";
import { navigationMenus } from "./navigationMenus";

export const seedData: TypedAppDescriptor = {
  name: "Car Index App",
  description: "A simple application for managing cars",
  essentials: {
    dataVariables: [
      {
        id: uuidv4(),
        name: "cars",
        type: "Collection",
        dataModel: "Car",
        description: "Collection of all cars",
        pageId: "index-page-id",
      },
      {
        id: uuidv4(),
        name: "car",
        type: "Record",
        dataModel: "Car",
        description: "Single car record",
        pageId: "show-page-id",
      },
    ],
    dataModels: [
      {
        id: "5",
        name: "Car",
        description: "A model to store cars",
        fields: [
          {
            id: "1",
            name: "make",
            dataType: "_types.String",
            description: "The make of the car",
          },
          {
            id: "2",
            name: "model",
            dataType: "_types.String",
            description: "The model of the car",
          },
        ],
      },
    ],
    pageGroups: [
      {
        id: "79e21bee-d53a-4879-b5b9-afba448d2fee",
        name: "Car Pages",
        description: "Pages for car management",
        basePath: "/cars",
        dataModelId: "5",
        modelName: "Car",
        pages: [
          {
            id: "index-page-id",
            name: "CarsIndex",
            pageType: "index",
            dataModelId: "5",
            description: "Render a page to list all car records.",
            relativePath: "/",
            userInterface: {
              viewgraph: {
                enabled: true,
                containerStructure: {
                  id: "root",
                  size: { unit: "fr", value: 1 },
                  isRoot: true,
                  components: [
                    {
                      id: uuidv4(),
                      blueprintName: "Layout_CenteredWithinViewport",
                      propertiesBindings: {},
                      $slots: {
                        content: {
                          $children: [
                            {
                              id: uuidv4(),
                              blueprintName: "Layout_SpreadHorizontally",
                              propertiesBindings: {},
                              $slots: {
                                leftContent: {
                                  $children: [
                                    {
                                      id: uuidv4(),
                                      blueprintName: "Typography_PageHeader",
                                      propertiesBindings: {
                                        text: {
                                          directiveType: "literalValue",
                                          config: {
                                            type: "String",
                                            value: "Cars",
                                          },
                                        },
                                      },
                                    },
                                  ],
                                },
                                rightContent: {
                                  $children: [
                                    {
                                      id: uuidv4(),
                                      blueprintName: "Button_Primary",
                                      propertiesBindings: {
                                        text: {
                                          directiveType: "literalValue",
                                          config: {
                                            type: "String",
                                            value: "New Car",
                                          },
                                        },
                                      },
                                      $eventHandlers: [
                                        {
                                          $on: "click",
                                          $execute: {
                                            $flowgraph: {
                                              sequence: [
                                                {
                                                  id: uuidv4(),
                                                  name: "Event",
                                                  parameters: {
                                                    eventType: "NavigateToPage",
                                                    page: "CarsNew",
                                                  },
                                                  childrenYes: [],
                                                  childrenNo: [],
                                                },
                                              ],
                                              $variables: [],
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                            },
                            {
                              id: uuidv4(),
                              blueprintName: "Table_Simple",
                              propertiesBindings: {
                                data: {
                                  directiveType: "$bindTo", //directiveType: "getPageVariable"
                                  config: {
                                    type: "Collection",
                                    value: "$data/cars", // variableName: "cars",
                                  },
                                },
                                columns: {
                                  directiveType: "literalValue",
                                  config: {
                                    type: "Array",
                                    value: [
                                      { dataKey: "make", label: "Make" },
                                      { dataKey: "model", label: "Model" },
                                    ],
                                  },
                                },
                              },
                              $eventHandlers: [
                                {
                                  $on: "rowClick",
                                  $execute: {
                                    $flowgraph: {
                                      sequence: [
                                        {
                                          id: uuidv4(),
                                          name: "Event",
                                          parameters: {
                                            eventType: "NavigateToPage",
                                            page: "CarsShow",
                                            param: "$payload/id", // TODO: Should be structured as parameters.id with Context.Get
                                          },
                                          childrenYes: [],
                                          childrenNo: [],
                                        },
                                      ],
                                      $variables: [],
                                    },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    },
                  ],
                  isScrollable: true,
                  subcontainers: [],
                  isLayoutApplied: true,
                  layoutDirection: "vertical",
                },
              },
            },
            initializationLogic: {
              tests: [],
              flowgraph: {
                sequence: [
                  {
                    id: uuidv4(),
                    name: "Model Method",
                    parentId: null,
                    branch: null,
                    childrenYes: [],
                    childrenNo: [],
                    parameters: {
                      modelName: "Car",
                      methodType: "RetrieveAllRecords",
                      returnVariable: "cars",
                    },
                    comparisonOperator: "",
                    functionCall: null,
                    number1: null,
                    number2: null,
                  },
                ],
                $variables: [],
              },
              parameters: [],
            },
          },
          {
            id: "show-page-id",
            name: "CarsShow",
            pageType: "show",
            dataModelId: "5",
            description: "Show details of a car record.",
            relativePath: "/:id",
            userInterface: {
              viewgraph: {
                enabled: true,
                containerStructure: {
                  id: "root",
                  size: { unit: "fr", value: 1 },
                  isRoot: true,
                  components: [
                    {
                      id: uuidv4(),
                      blueprintName: "Layout_CenteredWithinViewport",
                      propertiesBindings: {},
                      $slots: {
                        content: {
                          $children: [
                            {
                              id: uuidv4(),
                              blueprintName: "Layout_TwoColumns",
                              propertiesBindings: {},
                              $slots: {
                                leftColumn: {
                                  $children: [
                                    {
                                      id: uuidv4(),
                                      blueprintName: "DataDisplay_DataCard",
                                      propertiesBindings: {
                                        title: {
                                          directiveType: "literalValue",
                                          config: {
                                            type: "String",
                                            value: "Car details",
                                          },
                                        },
                                        fields: {
                                          directiveType: "literalValue",
                                          config: {
                                            type: "Array",
                                            value: [
                                              {
                                                label: "Make",
                                                value: {
                                                  $bindTo: "$data/car/make",
                                                },
                                              },
                                              {
                                                label: "Model",
                                                value: {
                                                  $bindTo: "$data/car/model",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      },
                                    },
                                  ],
                                },
                                rightColumn: {
                                  $children: [
                                    {
                                      id: uuidv4(),
                                      blueprintName: "KaseyOS_Comments",
                                      propertiesBindings: {
                                        recordId: {
                                          directiveType: "$bindTo", // directiveType: "getPageVariable"
                                          config: {
                                            type: "String",
                                            value: "$data/car/id", // variableName: "car.id"
                                          },
                                        },
                                        dataModel: {
                                          directiveType: "literalValue",
                                          config: {
                                            type: "String",
                                            value: "Car",
                                          },
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            },
                          ],
                        },
                      },
                    },
                  ],
                  isScrollable: true,
                  subcontainers: [],
                  isLayoutApplied: true,
                  layoutDirection: "vertical",
                },
              },
            },
            initializationLogic: {
              tests: [],
              flowgraph: {
                sequence: [
                  {
                    id: uuidv4(),
                    name: "Model Method",
                    parentId: null,
                    branch: null,
                    childrenYes: [],
                    childrenNo: [],
                    parameters: {
                      modelName: "Car",
                      methodType: "RetrieveRecord",
                      returnVariable: "car",
                      recordId: "$params/id", // Should be using Context.Get with KaseyOS/params/id
                    },
                    comparisonOperator: "",
                    functionCall: null,
                    number1: null,
                    number2: null,
                  },
                ],
                $variables: [],
              },
              parameters: [
                {
                  id: uuidv4(),
                  name: "id",
                  dataType: "_types.String",
                  description: "The ID of the car to display",
                  paramType: "path",
                },
              ],
            },
          },
          {
            id: "new-page-id",
            name: "CarsNew",
            pageType: "new",
            dataModelId: "5",
            description: "Create a new car record.",
            relativePath: "/new",
            userInterface: {
              viewgraph: {
                enabled: true,
                containerStructure: {
                  id: "root",
                  size: { unit: "fr", value: 1 },
                  isRoot: true,
                  components: [
                    {
                      id: uuidv4(),
                      blueprintName: "Form_Card",
                      propertiesBindings: {
                        schema: {
                          directiveType: "literalValue",
                          config: {
                            type: "Object",
                            value: {
                              type: "Record",
                              dataModel: "Car",
                              includedFields: ["make", "model"],
                            },
                          },
                        },
                        submitButtonText: {
                          directiveType: "literalValue",
                          config: {
                            type: "String",
                            value: "Create new car",
                          },
                        },
                      },
                      $slots: {
                        /** Should be:
                          formContent: {
                            $children: [
                              // Current implementation has components directly in formContent
                              // Should be wrapped in a div with flex styling:
                              {
                                $element: "div",
                                $styleClasses: "flex flex-col gap-4",
                                $children: [
                                  // existing Typography and Form_Field components
                                ]
                              }
                            ]
                          }
                        */
                        formContent: {
                          $children: [
                            {
                              id: uuidv4(),
                              blueprintName: "Typography_SectionHeader",
                              propertiesBindings: {
                                text: {
                                  directiveType: "literalValue",
                                  config: {
                                    type: "String",
                                    value: "New Car",
                                  },
                                },
                              },
                            },
                            {
                              id: uuidv4(),
                              blueprintName: "Form_Field_SingleLineTextInput",
                              propertiesBindings: {
                                label: {
                                  directiveType: "literalValue",
                                  config: {
                                    type: "String",
                                    value: "Make",
                                  },
                                },
                                keypath: {
                                  directiveType: "literalValue",
                                  config: {
                                    type: "String",
                                    value: "make",
                                  },
                                },
                              },
                            },
                            {
                              id: uuidv4(),
                              blueprintName: "Form_Field_SingleLineTextInput",
                              propertiesBindings: {
                                label: {
                                  directiveType: "literalValue",
                                  config: {
                                    type: "String",
                                    value: "Model",
                                  },
                                },
                                keypath: {
                                  directiveType: "literalValue",
                                  config: {
                                    type: "String",
                                    value: "model",
                                  },
                                },
                              },
                            },
                          ],
                        },
                      },
                      $eventHandlers: [
                        {
                          $on: "submit",
                          $preventDefault: true,
                          $includeFormData: true,
                          $execute: {
                            $flowgraph: {
                              sequence: [
                                {
                                  id: uuidv4(),
                                  name: "Function",
                                  parameters: {
                                    functionName: "Utility.Log",
                                    arguments: {
                                      message: {
                                        $call: "Context.Get",
                                        $arguments: {
                                          keypath: "$payload",
                                        },
                                      },
                                    },
                                  },
                                },
                                {
                                  id: uuidv4(),
                                  name: "Service Call", // Should be using KaseyOS.Service.Call
                                  parameters: {
                                    serviceName: "Cars",
                                    methodName: "create",
                                    methodArguments: {
                                      newRecord: {
                                        $call: "Context.Get",
                                        $arguments: {
                                          keypath: "$payload/formData",
                                        },
                                      },
                                    },
                                  },
                                },
                                {
                                  id: uuidv4(),
                                  name: "Event",
                                  parameters: {
                                    eventType: "NavigateToPage",
                                    page: "CarsIndex",
                                  },
                                },
                              ],
                              $variables: [],
                            },
                          },
                        },
                      ],
                    },
                  ],
                  isScrollable: true,
                  subcontainers: [],
                  isLayoutApplied: true,
                  layoutDirection: "vertical",
                },
              },
            },
            initializationLogic: {
              tests: [],
              flowgraph: {
                sequence: [],
                $variables: [],
              },
              parameters: [],
            },
          },
        ],
      },
    ],
    dataServices: [
      {
        name: "Car",
        description: "A data service for the Car model",
        methods: [
          {
            name: "create",
            description: "Create a new car",
            parameters: [
              {
                name: "newRecord",
                description: "The new car record to create",
                schema: {
                  type: "Record",
                  dataModel: "Car",
                },
              },
            ],
            flowgraph: {
              sequence: [
                {
                  id: uuidv4(),
                  name: "Model Method",
                  number1: null,
                  number2: null,
                  childrenNo: [],
                  parameters: {
                    modelName: "Car",
                    methodType: "CreateRecord",
                    useContext: true,
                    contextParam: "newRecord",
                    returnVariable: "newCar",
                  },
                  childrenYes: [],
                  functionCall: null,
                  comparisonOperator: "Greater than",
                },
              ],
              $variables: [],
            },
            parameters: [
              {
                name: "newRecord",
                schema: {
                  type: "Record",
                  dataModel: "Car",
                },
                description: "The new car record to create",
              },
            ],
            description: "Create a new car",
          },
        ],
      },
    ],
    apiEndpoints: [
      {
        id: "v1",
        description: "The first version of the Cars API",
        groups: [
          {
            name: "Cars",
            description: "API group for Cars management",
            path: "/cars",
            endpoints: [
              {
                name: "getCars",
                description: "Get a list of cars with optional filtering",
                method: "GET",
                path: "/",
                flowgraph: {
                  sequence: [
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "DataModel",
                        methodName: "RetrieveAllRecords",
                        methodArguments: {
                          dataModel: "Car",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "Controller",
                        methodName: "RespondWithJSON",
                        methodArguments: {
                          body: "$data/cars",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                  ],
                  $variables: [],
                },
              },
              {
                name: "getCar",
                description: "Get a single car by ID",
                method: "GET",
                path: "/:id",
                flowgraph: {
                  sequence: [
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "DataModel",
                        methodName: "RetrieveRecord",
                        methodArguments: {
                          dataModel: "Car",
                          id: "KaseyOS/params/id",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "Controller",
                        methodName: "RespondWithJSON",
                        methodArguments: {
                          body: "$data/car",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                  ],
                  $variables: [],
                },
              },
              {
                name: "createCar",
                description: "Create a new car",
                method: "POST",
                path: "/",
                flowgraph: {
                  sequence: [
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "DataModel",
                        methodName: "CreateRecord",
                        methodArguments: {
                          dataModel: "Car",
                          newRecord: "KaseyOS/request/body",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "Controller",
                        methodName: "RespondWithJSON",
                        methodArguments: {
                          statusCode: 201,
                          body: "$data/newCar",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                  ],
                  $variables: [],
                },
              },
              {
                name: "updateCar",
                description: "Update an existing car",
                method: "PUT",
                path: "/:id",
                flowgraph: {
                  sequence: [
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "DataModel",
                        methodName: "UpdateRecord",
                        methodArguments: {
                          dataModel: "Car",
                          id: "KaseyOS/params/id",
                          updates: "KaseyOS/request/body",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "Controller",
                        methodName: "RespondWithJSON",
                        methodArguments: {
                          body: "$data/updatedCar",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                  ],
                  $variables: [],
                },
              },
              {
                name: "deleteCar",
                description: "Delete a car",
                method: "DELETE",
                path: "/:id",
                flowgraph: {
                  sequence: [
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "DataModel",
                        methodName: "DeleteRecord",
                        methodArguments: {
                          dataModel: "Car",
                          id: "KaseyOS/params/id",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "Controller",
                        methodName: "RespondWithJSON",
                        methodArguments: {
                          statusCode: 204,
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                  ],
                  $variables: [],
                },
              },
              {
                name: "searchCars",
                description: "Search cars by make and/or model",
                method: "GET",
                path: "/search",
                flowgraph: {
                  sequence: [
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "DataModel",
                        methodName: "RetrieveAllRecords",
                        methodArguments: {
                          dataModel: "Car",
                          filters: [
                            {
                              field: "make",
                              operator: "contains",
                              value: "KaseyOS/request/query/make",
                            },
                            {
                              field: "model",
                              operator: "contains",
                              value: "KaseyOS/request/query/model",
                            },
                          ],
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                    {
                      id: uuidv4(),
                      name: "Service Call",
                      parameters: {
                        serviceName: "Controller",
                        methodName: "RespondWithJSON",
                        methodArguments: {
                          body: "$data/searchResults",
                        },
                      },
                      childrenYes: [],
                      childrenNo: [],
                    },
                  ],
                  $variables: [],
                },
              },
            ],
          },
        ],
      },
    ],
    apiNamespaces: [],
    dataTypes: [],
  },
  userInterface: {
    applicationLayouts: [
      {
        id: uuidv4(),
        name: "Default Layout",
        description: "The default application layout",
        erbTemplate: `<!DOCTYPE html>
<html>
  <head>
    <title><%= @page[:title] %></title>
    <%= csrf_meta_tags %>
    <%= csp_meta_tag %>
    <%= stylesheet_link_tag 'application', media: 'all', 'data-turbolinks-track': 'reload' %>
    <%= javascript_pack_tag 'application', 'data-turbolinks-track': 'reload' %>
    <script src="https://cdn.tailwindcss.com"></script>
  </head>
  <body class="h-full w-full bg-gray-100">
    <div class="h-full w-full max-w-[1024px] mx-auto pt-10">
      <%= content %>
    </div>
  </body>
</html>`,
        isDefault: true,
      },
    ],
    componentBlueprints: [],
  },
};
