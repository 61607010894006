import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  VStack,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Badge,
  Grid,
  GridItem,
  Table,
  Tbody,
  Tr,
  Td,
  HStack,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { FiDatabase, FiLink, FiList, FiTarget, FiEdit } from "react-icons/fi";
import InlineEditableText from "@/bundles/DescriptorEditor/components/editors/InlineEditableText";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

const Overview: React.FC = () => {
  const navigate = useNavigate();
  const { pageId, groupId, projectId } = useParams<{
    pageId: string;
    groupId: string;
    projectId: string;
  }>();
  const { getPage, getPageGroupByKeypath, getDataVariables, setFragment } =
    useAppDescriptorStore();

  console.log("Overview mounting with:", { pageId, groupId });

  if (!pageId || !groupId) {
    return (
      <Box p={4}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Missing Parameters</AlertTitle>
          <AlertDescription>
            Required parameters (pageId: {pageId}, groupId: {groupId}) are
            missing
          </AlertDescription>
        </Alert>
      </Box>
    );
  }

  const page = getPage(pageId.replace("id:", ""));
  const group = getPageGroupByKeypath(`/essentials/pageGroups/id:${groupId}`);

  console.log("Fetched data:", { page, group });

  if (!page || !group) {
    return (
      <Box p={4}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Data Not Found</AlertTitle>
          <AlertDescription>
            {!page && <div>Page not found for ID: {pageId}</div>}
            {!group && <div>Group not found for ID: {groupId}</div>}
            <Text mt={2} fontSize="sm">
              This might be due to an invalid URL or missing data in the
              descriptor.
            </Text>
          </AlertDescription>
        </Alert>
      </Box>
    );
  }

  const pageVariables = getDataVariables().filter(
    (v) => v.pageId === pageId.replace("id:", "")
  );

  const pageTypeColors = {
    index: "blue",
    show: "green",
    new: "purple",
    edit: "orange",
    create: "teal",
    update: "cyan",
    destroy: "red",
    custom: "gray",
  };

  const handlePageNameUpdate = (newName: string) => {
    if (page) {
      setFragment(
        `/essentials/pageGroups/id:${groupId}/pages/id:${pageId}/name`,
        newName
      );
    }
  };

  return (
    <Box p={4}>
      <VStack spacing={8} align="stretch">
        <Card>
          <CardHeader>
            <Flex justify="space-between" align="center">
              <Heading size="md">Page Information</Heading>
            </Flex>
          </CardHeader>
          <CardBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <GridItem>
                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Text color="gray.500" fontSize="sm">
                      Page Name
                    </Text>
                    <InlineEditableText
                      value={page.name}
                      onSave={handlePageNameUpdate}
                      placeholder="Page Name"
                      fontSize="lg"
                      fontWeight="medium"
                    />
                  </Box>
                  <Box>
                    <Text color="gray.500" fontSize="sm">
                      Page Type
                    </Text>
                    <Badge
                      colorScheme={
                        pageTypeColors[
                          page.pageType as keyof typeof pageTypeColors
                        ]
                      }
                    >
                      {page.pageType}
                    </Badge>
                  </Box>
                </VStack>
              </GridItem>
              <GridItem>
                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Text color="gray.500" fontSize="sm">
                      Page Group
                    </Text>
                    <Text fontSize="md">{group.name}</Text>
                  </Box>
                  <Box>
                    <Text color="gray.500" fontSize="sm">
                      Full Path
                    </Text>
                    <HStack spacing={1} wrap="nowrap" overflow="hidden">
                      <Icon as={FiLink} color="gray.500" flexShrink={0} />
                      <Text isTruncated>
                        {group.basePath}
                        <Text as="span" color="blue.500">
                          {page.relativePath}
                        </Text>
                      </Text>
                    </HStack>
                  </Box>
                </VStack>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>

        {/* {page.description && (
          <Card>
            <CardHeader>
              <HStack spacing={2}>
                <Icon as={FiTarget} />
                <Heading size="md">Description</Heading>
              </HStack>
            </CardHeader>
            <CardBody>
              <Text>{page.description}</Text>
            </CardBody>
          </Card>
        )} */}

        <Card>
          <CardHeader>
            <Flex justify="space-between" align="center">
              <HStack spacing={2}>
                <Icon as={FiDatabase} />
                <Heading size="md">Associated Data Model</Heading>
              </HStack>
              <IconButton
                aria-label="Edit data model"
                icon={<FiEdit />}
                variant="ghost"
                size="sm"
                onClick={() => {
                  navigate(
                    `/projects/${projectId}/editor/descriptor/essentials/pageGroups`
                  );
                }}
              />
            </Flex>
          </CardHeader>
          <CardBody>
            <Badge colorScheme="purple" fontSize="md">
              {group.modelName}
            </Badge>
          </CardBody>
        </Card>

        {pageVariables.length > 0 && (
          <Card>
            <CardHeader>
              <Flex justify="space-between" align="center">
                <HStack spacing={2}>
                  <Icon as={FiList} />
                  <Heading size="md">Data Variables</Heading>
                </HStack>
                <IconButton
                  aria-label="Edit data variables"
                  icon={<FiEdit />}
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    navigate(
                      `/projects/${projectId}/editor/descriptor/essentials/pageGroups/${groupId}/pages/${pageId}/dataVariables`
                    );
                  }}
                />
              </Flex>
            </CardHeader>
            <CardBody>
              <Table variant="simple">
                <Tbody>
                  {pageVariables.map((variable) => (
                    <Tr key={variable.id}>
                      <Td fontWeight="medium" whiteSpace="nowrap">
                        {variable.name}
                      </Td>
                      <Td whiteSpace="nowrap">
                        <Badge>{variable.type}</Badge>
                      </Td>
                      <Td>{variable.description}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </VStack>
    </Box>
  );
};

export default Overview;
