import React from "react";
import { ModalHeader as ChakraModalHeader, Button } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

interface ModalHeaderProps {
  breadcrumbs: string[];
  handleBackNavigation: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ breadcrumbs, handleBackNavigation }) => (
  <ChakraModalHeader>
    {breadcrumbs.length > 0 ? (
      <Button 
        variant="ghost"
        onClick={handleBackNavigation}
        size="lg"
        colorScheme="gray"
        color="gray.800"
        pl={0}
        leftIcon={<ChevronLeftIcon />}
        _hover={{
          color: "gray.600",
        }}  
      >
        {breadcrumbs.map((crumb, index) => (
          crumb + (index < breadcrumbs.length - 1 ? " / " : "")
        ))}
      </Button>
    ) : (
      "Select Directive"
    )}
  </ChakraModalHeader>
);