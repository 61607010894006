import React from "react";
import { VStack, HStack, Input, Button, Text } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface ListPropertyEditorProps {
  schema: any;
  keypath: string;
  value: any[];
}

const ListPropertyEditor: React.FC<ListPropertyEditorProps> = ({
  schema,
  keypath,
  value,
}) => {
  const { setFragment } = useAppDescriptorStore();

  const handleItemChange = (index: number, newValue: string) => {
    const newItems = [...value];
    newItems[index] = newValue;
    setFragment(keypath, newItems);
  };

  const addItem = () => {
    const newItems = [...value, ""];
    setFragment(keypath, newItems);
  };

  const removeItem = (index: number) => {
    const newItems = value.filter((_, i) => i !== index);
    setFragment(keypath, newItems);
  };

  return (
    <VStack align="stretch" spacing={2}>
      {value.map((item, index) => (
        <HStack key={index}>
          <Input
            value={item}
            onChange={(e) => handleItemChange(index, e.target.value)}
            placeholder={`Item ${index + 1}`}
          />
          <Button size="sm" onClick={() => removeItem(index)}>
            Remove
          </Button>
        </HStack>
      ))}
      <Button onClick={addItem}>Add Item</Button>
      {value.length === 0 && (
        <Text fontSize="sm" color="gray.500">
          No items in the list. Click "Add Item" to add one.
        </Text>
      )}
    </VStack>
  );
};

export default ListPropertyEditor;
