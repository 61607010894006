import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import functions from "../store/PidginFunctions";
import {
  getValueAtPath,
  setValueAtPath,
  removeValueAtPath,
  isValidKeypath,
} from "./keypathUtils";
import { v4 as uuidv4 } from "uuid";

const UNKNOWN_VALUE = { $unknown: true };

const initializeFunctionArguments = (
  functionName: string,
  omitFirstParam: boolean = false
): Record<string, unknown> => {
  const funcDefinition = functions.find((func) => func.name === functionName);
  if (funcDefinition && funcDefinition.parameters) {
    return Object.fromEntries(
      funcDefinition.parameters.slice(omitFirstParam ? 1 : 0).map((param) => {
        let defaultValue: unknown;

        if (param.schema.default !== undefined) {
          defaultValue = param.schema.default;
        } else if (!param.schema.required) {
          defaultValue = UNKNOWN_VALUE;
        } else {
          switch (param.schema.type) {
            case "Array":
              defaultValue = [];
              break;
            case "Object":
              defaultValue = {};
              break;
            case "String":
              defaultValue = "";
              break;
            case "Number":
              defaultValue = 0;
              break;
            case "Boolean":
              defaultValue = false;
              break;
            default:
              defaultValue = UNKNOWN_VALUE;
          }
        }

        return [param.name, defaultValue];
      })
    );
  }
  return {};
};

const generateUniqueId = () => {
  return uuidv4();
};

interface ChainedFunctionCall {
  $callChained: string;
  $arguments: Record<string, unknown>;
}

interface FunctionCall {
  $call: string;
  $arguments: Record<string, unknown>;
  $chain?: ChainedFunctionCall[];
}

interface Variable {
  $id: string;
  $name: string;
  $schema: unknown;
  $description?: string;
  $initialValue?: unknown;
}

interface Flowgraph {
  sequence: FunctionCall[];
  $variables: Variable[];
}

interface FunctionDescriptor {
  name: string;
  description: string;
  parameters: {
    name: string;
    description: string;
    schema: {
      type: string;
    };
  }[];
  editorTemplate: unknown;
  implementation: {
    js: string;
    ruby: string;
  };
  returnValue: {
    schema: {
      type: string;
    };
    description: string;
  };
  characteristics: {
    isSynchronous: boolean;
    isIdempotent: boolean;
    isDeterministic: boolean;
    hasSideEffects: boolean;
  };
  tests: {
    cases: unknown[];
  };
}

interface FlowgraphState {
  $flowgraph: Flowgraph;
  getValue: (keypath: string) => unknown;
  setValue: (keypath: string, value: unknown) => void;
  addFunctionCallToSequence: (
    keypath: string,
    functionName: string,
    index?: number
  ) => void;
  removeFunctionCallFromSequence: (keypath: string) => void;
  changeFunctionCallFunction: (
    keypath: string,
    newFunctionName: string
  ) => void;
  setArgumentToFunctionBinding: (keypath: string, functionName: string) => void;
  setArgumentToStaticValue: (keypath: string, dataType: string) => void;
  addObjectProperty: (
    keypath: string,
    propName: string,
    value: unknown
  ) => void;
  removeObjectProperty: (keypath: string) => void;
  renameObjectProperty: (keypath: string, newPropName: string) => void;
  addArrayItem: (keypath: string, item: unknown, index?: number) => void;
  removeArrayItem: (keypath: string) => void;
  reorderArrayItem: (keypath: string, toIndex: number) => void;
  addRecord: (
    keypath: string,
    recordDraft: { $id?: string; [key: string]: unknown }
  ) => void;
  updateRecord: (keypath: string, updatedFields: Partial<unknown>) => void;
  removeRecord: (keypath: string) => void;
  reorderRecord: (keypath: string, toIndex: number) => void;
  getCurrentContext: (keypath: string) => string;
  getExpectedSchema: (keypath: string) => unknown;
  getAllFunctions: () => FunctionDescriptor[];
  canFunctionBeChained: (functionName: string) => boolean;
}

const flowgraphSchema = {
  type: "Object",
  properties: {
    $flowgraph: {
      type: "Object",
      properties: {
        sequence: {
          type: "Collection",
          items: {
            type: "FunctionCall",
          },
        },
        $variables: {
          type: "Collection",
          items: {
            type: "Variable",
          },
        },
      },
    },
  },
};

const canFunctionBeChained = (functionName: string): boolean => {
  const funcDefinition = functions.find((func) => func.name === functionName);
  return !!(funcDefinition && funcDefinition.chainedEditorTemplate);
};

export const useFlowgraphStore = create<FlowgraphState>()(
  immer((set, get) => ({
    $flowgraph: {
      sequence: [],
      $variables: [],
    },
    getValue: (keypath: string) => {
      const state = get();
      return getValueAtPath(state, keypath, flowgraphSchema);
    },
    setValue: (keypath: string, value: unknown) =>
      set((state) => {
        setValueAtPath(state, keypath, value, flowgraphSchema);
      }),
    addFunctionCallToSequence: (
      keypath: string,
      functionName: string,
      index?: number
    ) =>
      set((state) => {
        const isChainSequence = keypath.endsWith("/$chain");
        const isSequence = keypath.endsWith("/sequence");

        let sequence = getValueAtPath(state, keypath, flowgraphSchema);

        if (sequence === undefined && (isChainSequence || isSequence)) {
          sequence = [];
          setValueAtPath(state, keypath, sequence, flowgraphSchema);
        }

        if (!Array.isArray(sequence)) {
          throw new Error(`Keypath does not contain an array: ${keypath}`);
        }

        const newStep = isChainSequence
          ? {
              $id: generateUniqueId(),
              $callChained: functionName,
              $arguments: initializeFunctionArguments(functionName, true),
            }
          : {
              $id: generateUniqueId(),
              $call: functionName,
              $arguments: initializeFunctionArguments(functionName),
            };

        const newStepIndex = index !== undefined ? index : sequence.length;
        sequence.splice(newStepIndex, 0, newStep);
        setValueAtPath(state, keypath, sequence, flowgraphSchema);

        return state;
      }),
    removeFunctionCallFromSequence: (keypath: string) =>
      set((state) => {
        const parts = keypath.split("/");
        const sequenceKeypath = parts.slice(0, -1).join("/");
        const sequence =
          getValueAtPath(state, sequenceKeypath, flowgraphSchema) || [];

        if (!Array.isArray(sequence)) {
          throw new Error(
            `Keypath does not contain an array: ${sequenceKeypath}`
          );
        }

        const idOrIndex = parts[parts.length - 1];
        let indexToRemove: number;

        if (idOrIndex.match(/^\d+$/)) {
          indexToRemove = parseInt(idOrIndex, 10);
        } else {
          indexToRemove = sequence.findIndex((item) => item.$id === idOrIndex);
        }

        if (indexToRemove !== -1) {
          sequence.splice(indexToRemove, 1);
          setValueAtPath(state, sequenceKeypath, sequence, flowgraphSchema);
        }

        return state;
      }),
    changeFunctionCallFunction(keypath, newFunctionName) {
      set((state) => {
        const parts = keypath.split("/");
        const lastPart = parts[parts.length - 1];

        if (lastPart === "$call" || lastPart === "$callChained") {
          setValueAtPath(state, keypath, newFunctionName, flowgraphSchema);
          const omitFirstParam = lastPart === "$callChained";
          const args = initializeFunctionArguments(
            newFunctionName,
            omitFirstParam
          );
          setValueAtPath(
            state,
            `${parts.slice(0, -1).join("/")}/$arguments`,
            args,
            flowgraphSchema
          );
        } else {
          throw new Error(`Invalid keypath to function call: ${keypath}`);
        }

        return state;
      });
    },
    setArgumentToFunctionBinding: (keypath: string, functionName: string) =>
      set((state) => {
        const newBinding = {
          $call: functionName,
          $arguments: initializeFunctionArguments(functionName),
        };
        setValueAtPath(state, keypath, newBinding, flowgraphSchema);
        return state;
      }),
    setArgumentToStaticValue: (keypath: string, dataType: string) =>
      set((state) => {
        let newValue: unknown;
        switch (dataType) {
          case "String":
            newValue = "";
            break;
          case "Number":
            newValue = 0;
            break;
          case "Boolean":
            newValue = false;
            break;
          case "Array":
            newValue = [];
            break;
          case "Object":
            newValue = {};
            break;
          default:
            newValue = null;
        }
        setValueAtPath(state, keypath, newValue, flowgraphSchema);
      }),
    addObjectProperty: (keypath: string, propName: string, value: unknown) =>
      set((state) => {
        const currentValue =
          getValueAtPath(state, keypath, flowgraphSchema) || {};
        setValueAtPath(
          state,
          keypath,
          { ...currentValue, [propName]: value },
          flowgraphSchema
        );
      }),
    removeObjectProperty: (keypath: string) =>
      set((state) => {
        const parts = keypath.split("/");
        const propName = parts.pop();
        const parentPath = parts.join("/");
        const currentValue =
          getValueAtPath(state, parentPath, flowgraphSchema) || {};
        const { [propName!]: _, ...rest } = currentValue;
        setValueAtPath(state, parentPath, rest, flowgraphSchema);
      }),
    renameObjectProperty: (keypath: string, newPropName: string) =>
      set((state) => {
        const parts = keypath.split("/");
        const parentPath = parts.slice(0, -1).join("/");
        const oldValue = getValueAtPath(state, keypath, flowgraphSchema);
        removeValueAtPath(state, keypath, flowgraphSchema);
        setValueAtPath(
          state,
          `${parentPath}/${newPropName}`,
          oldValue,
          flowgraphSchema
        );
      }),
    addArrayItem: (keypath: string, item: unknown, index?: number) =>
      set((state) => {
        let currentArray = getValueAtPath(state, keypath, flowgraphSchema);
        if (!Array.isArray(currentArray)) {
          currentArray = [];
        }
        const newItemIndex = index !== undefined ? index : currentArray.length;
        currentArray.splice(newItemIndex, 0, item);
        setValueAtPath(state, keypath, currentArray, flowgraphSchema);
      }),
    removeArrayItem: (keypath: string) =>
      set((state) => {
        const parts = keypath.split("/");
        const arrayIndex = parseInt(parts.pop() || "0", 10);
        const arrayPath = parts.join("/");
        const currentArray =
          getValueAtPath(state, arrayPath, flowgraphSchema) || [];
        setValueAtPath(
          state,
          arrayPath,
          currentArray.filter(
            (_: unknown, index: number) => index !== arrayIndex
          ),
          flowgraphSchema
        );
      }),
    reorderArrayItem: (keypath: string, toIndex: number) =>
      set((state) => {
        const parts = keypath.split("/");
        const fromIndex = parseInt(parts.pop() || "0", 10);
        const arrayPath = parts.join("/");
        const currentArray =
          getValueAtPath(state, arrayPath, flowgraphSchema) || [];
        const newArray = [...currentArray];
        const [item] = newArray.splice(fromIndex, 1);
        newArray.splice(toIndex, 0, item);
        setValueAtPath(state, arrayPath, newArray, flowgraphSchema);
      }),
    addRecord: (
      keypath: string,
      recordDraft: { $id?: string; [key: string]: unknown }
    ) =>
      set((state) => {
        let collection = getValueAtPath(state, keypath, flowgraphSchema) as {
          $id: string;
          [key: string]: unknown;
        }[];

        if (!Array.isArray(collection)) {
          collection = [];
          setValueAtPath(state, keypath, collection, flowgraphSchema);
        }

        const newId = recordDraft.$id || generateUniqueId();
        const newRecord = { ...recordDraft, $id: newId };
        setValueAtPath(
          state,
          keypath,
          [...collection, newRecord],
          flowgraphSchema
        );

        return state;
      }),
    updateRecord: (keypath: string, updatedFields: Partial<unknown>) =>
      set((state) => {
        const collectionKeypath = keypath.replace(/\/[^/]+$/, "");
        const collection = getValueAtPath(
          state,
          collectionKeypath,
          flowgraphSchema
        ) as { $id: string; [key: string]: unknown }[];

        if (!Array.isArray(collection)) {
          throw new Error(
            `Collection not found at keypath: ${collectionKeypath}`
          );
        }

        const recordId = keypath.split("/").pop();
        const updatedCollection = collection.map((record) =>
          record.$id === recordId
            ? { ...record, ...updatedFields, $id: record.$id }
            : record
        );

        setValueAtPath(
          state,
          collectionKeypath,
          updatedCollection,
          flowgraphSchema
        );
      }),
    removeRecord: (keypath: string) =>
      set((state) => {
        const collectionKeypath = keypath.replace(/\/[^/]+$/, "");
        const collection = getValueAtPath(
          state,
          collectionKeypath,
          flowgraphSchema
        ) as { $id: string; [key: string]: unknown }[];

        if (!Array.isArray(collection)) {
          throw new Error(
            `Collection not found at keypath: ${collectionKeypath}`
          );
        }

        const recordId = keypath.split("/").pop();
        const updatedCollection = collection.filter(
          (record) => record.$id !== recordId
        );

        setValueAtPath(
          state,
          collectionKeypath,
          updatedCollection,
          flowgraphSchema
        );
      }),
    reorderRecord: (keypath: string, toIndex: number) =>
      set((state) => {
        const collectionKeypath = keypath.replace(/\/[^/]+$/, "");
        const collection = getValueAtPath(
          state,
          collectionKeypath,
          flowgraphSchema
        ) as { $id: string; [key: string]: unknown }[];

        if (!Array.isArray(collection)) {
          throw new Error(
            `Collection not found at keypath: ${collectionKeypath}`
          );
        }

        const recordId = keypath.split("/").pop();
        const fromIndex = collection.findIndex(
          (record) => record.$id === recordId
        );
        if (fromIndex === -1) return;

        const [movedRecord] = collection.splice(fromIndex, 1);
        collection.splice(toIndex, 0, movedRecord);

        setValueAtPath(state, collectionKeypath, collection, flowgraphSchema);
      }),
    getCurrentContext: () => {
      // Todo: Implement logic to retrieve the current context
      return "";
    },
    getExpectedSchema: (keypath) => {
      const state = get();
      const parts = keypath.split("/");
      const argIndex = parts.indexOf("$arguments");
      if (argIndex === -1 || argIndex === parts.length - 1) {
        return {};
      }

      const functionPath = parts.slice(0, argIndex).join("/");

      if (!isValidKeypath(state, functionPath, flowgraphSchema)) {
        console.log(`Step does not exist: ${functionPath}`);
        return {};
      }

      const step = getValueAtPath(state, functionPath, flowgraphSchema);
      const functionName = step.$call;
      const argName = parts[argIndex + 1];

      if (!functionName || !argName) {
        console.log(
          `Invalid function name or argument name: ${functionName}, ${argName}`
        );
        return {};
      }

      const functionDescriptor = functions.find(
        (func) => func.name === functionName
      );
      if (!functionDescriptor || !functionDescriptor.parameters) {
        console.log(`Function descriptor not found: ${functionName}`);
        return {};
      }

      const parameter = functionDescriptor.parameters.find(
        (param) => param.name === argName
      );
      if (!parameter) {
        console.log(
          `Parameter not found: ${argName} for function ${functionName}`
        );
        return {};
      }

      return parameter.schema;
    },
    getAllFunctions: () => functions,
    canFunctionBeChained: (functionName: string) => {
      return canFunctionBeChained(functionName);
    },
  }))
);
