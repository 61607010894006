import React from "react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { Viewgraph } from "./ViewgraphEditor";

interface ContainerModeEditorProps {
  keypath: string;
}

const ContainerModeEditor: React.FC<ContainerModeEditorProps> = ({
  keypath,
}) => {
  const { getFragment } = useAppDescriptorStore();
  const viewgraph = getFragment(keypath) as Viewgraph;

  return (
    <div className="flex flex-grow h-full">
      <div className="flex-grow flex flex-col">
        <div className="p-2 border-b border-gray-300">
          <h2 className="text-lg font-semibold">Root Container</h2>
        </div>
        <div
          className="flex-grow relative"
          style={{ height: "calc(100vh - 350px)", overflow: "hidden" }}
        >
          <div className="absolute inset-0 border-2 border-gray-300 rounded-lg shadow-lg overflow-hidden">
            {viewgraph.containerStructure ? (
              <div className="w-full h-full flex flex-col">
                <div className="flex-grow p-4">
                  <p className="text-gray-600">
                    This is the root container. Components can be added directly
                    here.
                  </p>
                </div>
              </div>
            ) : (
              <div className="text-red-500">
                Error: Invalid container structure
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerModeEditor;
