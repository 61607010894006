import React, { useState } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, VStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Flex, IconButton, Text } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { FiPlus, FiTrash2, FiMenu, FiEdit } from 'react-icons/fi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InlineEditableText from '@/bundles/DescriptorEditor/components/editors/InlineEditableText';
import CodeEditor from '@/bundles/DescriptorEditor/components/editors/CodeEditor';
import ParameterEditor from '@/bundles/DescriptorEditor/components/editors/ParameterEditor';

const Scopes: React.FC<{ keypath: string }> = ({ keypath }) => {
  const {
    getFragment,
    setFragment,
    addRecordToCollectionFragment,
    removeRecordFromCollectionFragment,
  } = useAppDescriptorStore();
  const scopes = getFragment(keypath) || [];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingScope, setEditingScope] = useState(null);

  const addScope = () => {
    const newScope = {
      id: `scope_${Date.now()}`,
      name: 'New Scope',
      description: '',
      parameters: [],
      rubyImplementation: '# Ruby code here',
    };
    addRecordToCollectionFragment(keypath, newScope);
  };

  const removeScope = (scopeId: string) => {
    if (window.confirm('Are you sure you want to remove this scope?')) {
      removeRecordFromCollectionFragment(keypath, scopeId);
    }
  };

  const updateScope = (scopeId: string, key: string, value: any) => {
    setFragment(`${keypath}/id:${scopeId}/${key}`, value);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(scopes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFragment(keypath, items);
  };

  const openEditModal = (scope: any) => {
    setEditingScope(scope);
    onOpen();
  };

  return (
    <VStack spacing={6} align="stretch" maxWidth="800px" mx="auto">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="lg">Scopes</Heading>
        <Button leftIcon={<FiPlus />} onClick={addScope}>
          Add scope
        </Button>
      </Flex>

      {scopes.length === 0 ? (
        <Text>No scopes defined for this model. Click "Add scope" to create one.</Text>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="scopes">
            {(provided) => (
              <Table
                variant="simple"
                size="sm"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Thead>
                  <Tr>
                    <Th width="40px"></Th>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th width="80px"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {scopes.map((scope: any, index: number) => (
                    <Draggable key={scope.id} draggableId={scope.id} index={index}>
                      {(provided, snapshot) => (
                        <Tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            background: snapshot.isDragging ? 'white' : 'inherit',
                          }}
                        >
                          <Td width="40px">
                            <Box {...provided.dragHandleProps} cursor="move">
                              <FiMenu />
                            </Box>
                          </Td>
                          <Td>
                            <InlineEditableText
                              value={scope.name}
                              onSave={(value) => updateScope(scope.id, 'name', value)}
                            />
                          </Td>
                          <Td>
                            <InlineEditableText
                              value={scope.description}
                              onSave={(value) => updateScope(scope.id, 'description', value)}
                            />
                          </Td>
                          <Td width="80px">
                            <IconButton
                              aria-label="Edit scope"
                              icon={<FiEdit />}
                              size="sm"
                              mr={2}
                              onClick={() => openEditModal(scope)}
                            />
                            <IconButton
                              aria-label="Remove scope"
                              icon={<FiTrash2 />}
                              size="sm"
                              onClick={() => removeScope(scope.id)}
                            />
                          </Td>
                        </Tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Tbody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {/* Scope Edit Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Scope</ModalHeader>
          <ModalBody>
            {editingScope && (
              <VStack spacing={4} align="stretch">
                <Box>
                  <Text fontWeight="bold">Parameters</Text>
                  <ParameterEditor
                    keypath={`${keypath}/id:${editingScope.id}/parameters`}
                  />
                </Box>
                <Box>
                  <Text fontWeight="bold">Ruby Implementation</Text>
                  <CodeEditor
                    value={editingScope.rubyImplementation}
                    onChange={(value) =>
                      updateScope(editingScope.id, 'rubyImplementation', value)
                    }
                    language="ruby"
                    height="200px"
                  />
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Scopes;