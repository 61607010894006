import React, { useEffect } from "react";
import { VStack, HStack, Text, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import editors from "./editors";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

export interface PropertyEditorProps {
  schema: any;
  keypath: string;
  isNested?: boolean;
  onChange?: (newValue: any) => void;
}

const PropertyEditor: React.FC<PropertyEditorProps> = (props) => {
  const { schema, keypath, isNested = false, onChange } = props;
  const { getFragment, setFragment } = useAppDescriptorStore();
  const dataType = schema?.dataType?.replace("_types.", "") || "";
  const EditorComponent = editors[dataType] || DefaultPropertyEditor;

  useEffect(() => {
    const currentValue = getFragment(keypath);
    if (currentValue === undefined) {
      setFragment(keypath, {
        directiveType: "literalValue",
        config: {
          type: schema.dataType,
          value: null,
        },
      });
    }
  }, [keypath, getFragment, setFragment]);

  const handleChange = (newValue: any) => {
    setFragment(`${keypath}/config/value`, newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <VStack align="stretch" spacing={1}>
      {!isNested && (
        <HStack spacing={1} alignItems="center">
          <Text fontSize="xs" fontWeight="semibold">
            {schema.name}
          </Text>
          {schema.description && (
            <Tooltip label={schema.description} placement="top">
              <InfoIcon boxSize={2} color="gray.500" />
            </Tooltip>
          )}
        </HStack>
      )}
      <EditorComponent {...props} onChange={handleChange} />
    </VStack>
  );
};

const DefaultPropertyEditor: React.FC<PropertyEditorProps> = ({
  schema,
  keypath,
}) => (
  <div>
    Default editor for {schema.name}: {keypath}
  </div>
);

export default PropertyEditor;
