import React, { useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { HiDotsVertical, HiHome, HiInformationCircle } from "react-icons/hi";
import { useParams, useLocation } from "react-router-dom";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";
import InlineEditableText from "../editors/InlineEditableText";
import { railsApiCall } from "../../utils/railsApiCall";
import { generateFlowgraph } from "@/bundles/FlowgraphEditor/utils/generateFlowgraph";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { generateConvertedDescriptor } from "../descriptors/utils/descriptorConverter";

const Header: React.FC = () => {
  const {
    projectName,
    setProjectName,
    getFragment,
    getPage,
    getModelByKeypath,
    setFragment,
  } = useAppDescriptorStore();
  const { projectId } = useParams<{ projectId: string }>();
  const toast = useToast();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [description, setDescription] = useState<string>(
    () => (getFragment("/description") as string) || ""
  );

  const handleRename = async (newName: string) => {
    try {
      const response = await railsApiCall<{ name: string }>({
        method: "PATCH",
        endpoint: `/projects/${projectId}/rename`,
        body: { name: newName },
      });

      setProjectName(response.data.name);

      setFragment("/name", newName);
    } catch (error) {
      console.error("Failed to rename project:", error);
      toast({
        title: "Error",
        description: "Failed to rename project",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSave = async () => {
    try {
      const transformed = generateConvertedDescriptor(
        getFragment,
        getPage,
        getModelByKeypath
      );

      if (!transformed) {
        toast({
          title: "Error",
          description: "No page group found",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return false;
      }

      await railsApiCall({
        method: "POST",
        endpoint: `/projects/${projectId}/save_converted_descriptor`,
        body: { converted_descriptor: transformed },
      });

      toast({
        title: "Success",
        description: "Converted descriptor saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      return true;
    } catch (error) {
      console.error("Failed to save converted descriptor:", error);
      toast({
        title: "Error",
        description: "Failed to save converted descriptor",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const handleExport = async () => {
    const saveResult = await handleSave();
    if (saveResult) {
      try {
        const response = await railsApiCall({
          method: "GET",
          endpoint: `/projects/${projectId}/export`,
        });

        if (response.data) {
          window.open(
            `https://kos-runtime-839881b6cd73.herokuapp.com/kasey_os/set_active_app/${projectId}`,
            "_blank"
          );
        } else {
          toast({
            title: "Error",
            description: "Failed to verify saved data",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Failed to verify export data:", error);
        toast({
          title: "Error",
          description: "Failed to verify export data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleDescriptionSave = async () => {
    setFragment("/description", description);
    onClose();
    toast({
      title: "Success",
      description: "Project description updated",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      align="center"
      justify="space-between"
      borderBottom="1px solid"
      borderColor="gray.800"
      p={4}
      h="64px"
    >
      <Box>
        <IconButton
          as="a"
          href="/projects"
          aria-label="Home"
          icon={<HiHome />}
          variant="ghost"
        />
      </Box>
      <Flex align="center" gap={2}>
        <InlineEditableText
          value={projectName}
          onSave={handleRename}
          fontSize="xl"
          fontWeight="bold"
        />
        <IconButton
          size="sm"
          variant="ghost"
          onClick={onOpen}
          aria-label="Edit Description"
          icon={<HiInformationCircle />}
        />
      </Flex>
      <Flex gap={2}>
        <Button size="sm" variant="outline" onClick={handleSave}>
          Save
        </Button>
        <IconButton
          size="sm"
          variant="outline"
          onClick={handleExport}
          aria-label="Export UI"
          icon={<ExternalLinkIcon />}
        />
        <IconButton
          aria-label="Settings"
          icon={<HiDotsVertical />}
          variant="ghost"
        />
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Project Description</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter project description..."
              rows={6}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleDescriptionSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Header;
