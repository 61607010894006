import {
  type AnyDirective,
  type Directive,
  isDirective,
} from "./directiveTypes";
import type { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import type { TypedValue } from "../types/typedValue";
import {
  createDefaultDirective,
  searchAnyDirective,
} from "../flowFunctionStore";

export type RenderStringTemplateDirective = Directive<
  "renderStringTemplate",
  RenderStringTemplateConfig
>;

export interface RenderStringTemplateConfig {
  template: string;
  variables: Array<{ key: string; directive: AnyDirective }>;
}

export function isRenderStringTemplateDirective(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directive: any,
): directive is RenderStringTemplateDirective {
  const renderStringTemplateConfigKeys: (keyof RenderStringTemplateConfig)[] = [
    "template",
    "variables",
  ];
  return isDirective(
    directive,
    "renderStringTemplate",
    renderStringTemplateConfigKeys,
  );
}

const execute = async (
  config: RenderStringTemplateConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { template, variables } = config;
  const resolvedVariables: { [key: string]: TypedValue } = {};

  for (const { key, directive } of variables) {
    resolvedVariables[key] = await executionContext.executeDirective(directive);
  }

  const renderedString = template.replace(
    /{{\s*([^{}\s]+)\s*}}/g,
    (_, varName) => {
      const resolvedValue = resolvedVariables[varName];
      if (resolvedValue) {
        if (resolvedValue.type === "_types.String") {
          return resolvedValue.value;
        }
        if (resolvedValue.type === "_types.Number") {
          return resolvedValue.value.toString();
        }
        if (resolvedValue.type === "_types.Boolean") {
          return resolvedValue.value.toString();
        }

        throw new Error(
          `Non-stringifiable type for template variable ${varName}: ${resolvedValue.type}`,
        );
      }
      return "";
    },
  );

  return { type: "_types.String", value: renderedString };
};

function addVariable(directive: RenderStringTemplateDirective, key: string) {
  directive.config.variables.push({ key, directive: createDefaultDirective() });
}

function removeVariable(directive: RenderStringTemplateDirective, key: string) {
  directive.config.variables = directive.config.variables.filter(
    (variable) => variable.key !== key,
  );
}

function moveVariable(
  directive: RenderStringTemplateDirective,
  fromIndex: number,
  toIndex: number,
) {
  const [variable] = directive.config.variables.splice(fromIndex, 1);
  directive.config.variables.splice(toIndex, 0, variable);
}

function searchDirective(
  directive: RenderStringTemplateDirective,
  id: string,
): AnyDirective | undefined {
  if (directive.id === id) {
    return directive;
  }

  for (const { directive: nestedDirective } of directive.config.variables) {
    const foundDirective = searchAnyDirective([nestedDirective], id);
    if (foundDirective) {
      return foundDirective;
    }
  }

  return undefined;
}

export const renderStringTemplate = {
  execute,
  availableActions: {
    addVariable,
    removeVariable,
    moveVariable,
  },
  defaultConfig: () =>
    ({
      template: "",
      variables: [],
    }) as RenderStringTemplateConfig,
  searchDirective,
};
