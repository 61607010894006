import {
  type Directive,
  type NestableDirective,
  type AnyDirective,
} from "./directiveTypes";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";

// Define the WhileLoopDirective type
export type WhileLoopDirective = Directive<"whileLoop", WhileLoopConfig>;

// Define the WhileLoopConfig interface
export interface WhileLoopConfig {
  condition: NestableDirective;
  loopingFlowgraph: AnyDirective[];
}

// Define the execute function
const execute = async (
  config: WhileLoopConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<void> => {
  const { condition, loopingFlowgraph } = config;

  while (true) {
    // Evaluate the condition
    const conditionResult = await executionContext.executeDirective(condition);

    if (typeof conditionResult.value !== "boolean") {
      throw new Error("The condition must resolve to a boolean value");
    }

    if (!conditionResult.value) {
      break; // Exit the loop if the condition is false
    }

    // Execute the looping flowgraph
    for (const directive of loopingFlowgraph) {
      await executionContext.executeDirective(directive);
    }
  }
};

// Export the whileLoop object
export const whileLoop = {
  execute,
  availableActions: {},
  defaultConfig: () => ({
    condition: {
      directiveType: "literalValue",
      config: { type: "_types.Boolean", value: false },
    },
    loopingFlowgraph: [
      {
        directiveType: "literalValue",
        config: { type: "_types.String", value: "" },
      },
    ],
  }),
};
