import React, { useState } from 'react';
import {
  Button,
  NumberInput,
  NumberInputField,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';

interface NumberEditorProps {
  schema: any;
  keypath: string;
}

const NumberEditor: React.FC<NumberEditorProps> = ({ schema, keypath }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getFragment, setFragment } = useAppDescriptorStore();

  const binding = getFragment(keypath);
  const value = binding?.config?.value || 0;

  const handleEdit = (newValue: number) => {
    setFragment(`${keypath}/config/value`, newValue);
  };

  const InlineEditor = () => (
    <HStack>
      <NumberInput size="xs" value={value} onChange={(_, val) => handleEdit(val)}>
        <NumberInputField />
      </NumberInput>
      <Button size="xs" onClick={() => setIsModalOpen(true)}>
        Edit
      </Button>
    </HStack>
  );

  return (
    <>
      <InlineEditor />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{schema.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <NumberInput value={value} onChange={(_, val) => handleEdit(val)}>
              <NumberInputField />
            </NumberInput>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NumberEditor;