import React from "react";
import {
  Box,
  Input,
  Button,
  FormControl,
  HStack,
  useToast,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";
import { generateConvertedDescriptor } from "./utils/descriptorConverter";
import ChatSidebar from "./ChatSidebar";
import OpenAI from "openai";

interface Message {
  role: "user" | "assistant";
  content: string;
}

const ConvertedDescriptor: React.FC = () => {
  const [inputValue, setInputValue] = React.useState("");
  const [chatHistory, setChatHistory] = React.useState<Message[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { getFragment, getPage, getModelByKeypath } = useAppDescriptorStore();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    // if (!process.env.OPENAI_API_KEY) {
    //   toast({
    //     title: "Error",
    //     description: "No OpenAI API key found",
    //     status: "error",
    //     duration: 3000,
    //   });
    //   return;
    // }

    const userMessage = {
      role: "user" as const,
      content: inputValue + "\n\n" + JSON.stringify(transformed, null, 2),
    };
    setChatHistory((prev) => [...prev, userMessage]);
    setInputValue("");
    setIsLoading(true);
    onOpen();

    try {
      const openai = new OpenAI({
        apiKey: process.env.OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const completion = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content:
              "You are an AI assistant helping developers understand and modify their application descriptor. You can explain the structure and suggest changes.",
          },
          ...chatHistory,
          userMessage,
        ],
      });

      const assistantMessage = completion.choices[0].message;
      setChatHistory((prev) => [
        ...prev,
        {
          role: "assistant",
          content: assistantMessage.content || "No response",
        },
      ]);
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to get AI response",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setChatHistory([]);
    onClose();
  };

  const transformed = generateConvertedDescriptor(
    getFragment,
    getPage,
    getModelByKeypath
  );

  if (!transformed) {
    return <div>No page group found</div>;
  }

  return (
    <Box p={6}>
      {chatHistory.length === 0 && (
        <form onSubmit={handleSubmit}>
          <FormControl mb={6}>
            <HStack spacing={4}>
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Ask about the descriptor..."
                size="md"
                variant="filled"
              />
              <Button
                type="submit"
                colorScheme="blue"
                size="md"
                px={8}
                isDisabled={!inputValue.trim() || isLoading}
                isLoading={isLoading}
              >
                Send
              </Button>
            </HStack>
          </FormControl>
        </form>
      )}

      <Box borderRadius="md" bg="gray.50" p={4} overflowX="auto">
        <pre>{JSON.stringify(transformed, null, 2)}</pre>
      </Box>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>AI Chat</DrawerHeader>
          <DrawerBody>
            <ChatSidebar
              messages={chatHistory}
              onSubmit={handleSubmit}
              onReset={handleReset}
              inputValue={inputValue}
              setInputValue={setInputValue}
              isLoading={isLoading}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ConvertedDescriptor;
