import React, { useEffect } from "react";
import { ChakraProvider, Spinner, Flex } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Layout from "./components/layout/Layout";
import AppDescriptor from "./components/descriptors/AppDescriptor";
import theme from "./theme";
import {
  useAppDescriptorStore,
  useUndoRedoHotkeys,
} from "./stores/appDescriptorStore";
import { ToastProvider } from "./components/ToastProvider";

function DescriptorEditorContent() {
  return (
    <Layout>
      <Routes>
        <Route path="/descriptor/*" element={<AppDescriptor />} />
        <Route
          index
          element={<Navigate to="./descriptor/essentials/pages" replace />}
        />
        {/* Add other main routes here if needed */}
      </Routes>
    </Layout>
  );
}

function DescriptorEditorApp() {
  const { isInitialLoad, initializeAppDescriptor } = useAppDescriptorStore();
  useUndoRedoHotkeys();

  useEffect(() => {
    initializeAppDescriptor();
  }, [initializeAppDescriptor]);

  return (
    <ChakraProvider theme={theme}>
      <ToastProvider>
        {isInitialLoad ? (
          <Flex justify="center" align="center" height="100vh">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Router>
            <Routes>
              <Route
                path="/projects/:projectId/editor/*"
                element={<DescriptorEditorContent />}
              />
            </Routes>
          </Router>
        )}
      </ToastProvider>
    </ChakraProvider>
  );
}

export default DescriptorEditorApp;
