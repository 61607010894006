import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  VStack,
  Icon,
  Tag,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { FiPlus, FiDatabase, FiMoreVertical, FiTrash } from "react-icons/fi";
import { useAppDescriptorStore } from "../../../../stores/appDescriptorStore";
import DataTable from "../../../editors/DataTable";
import NewDataModelModal from "./NewDataModelModal";
import { useNavigate } from "react-router-dom";

const DataModelsIndex: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    getFragment,
    addRecordToCollectionFragment,
    removeRecordFromCollectionFragment,
  } = useAppDescriptorStore();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataModelToDelete, setDataModelToDelete] = useState<any | null>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const dataModels = getFragment("/essentials/dataModels") as any[];

  console.log("Available data models: ", dataModels);

  const columns = [
    {
      name: "Name",
      render: (row: any) => row.name || "N/A",
    },
    {
      name: "Description",
      render: (row: any) => row.description || "No description",
    },
    {
      name: "Fields",
      render: (row: any) => (
        <HStack spacing={2}>
          {row.fields && row.fields.length > 0 ? (
            row.fields.slice(0, 3).map((field: any, index: number) => (
              <Tag key={index} size="sm" colorScheme="blue">
                {field.name}
              </Tag>
            ))
          ) : (
            <Text color="gray.500">No fields</Text>
          )}
          {row.fields && row.fields.length > 3 && (
            <Tag size="sm" colorScheme="gray">
              +{row.fields.length - 3}
            </Tag>
          )}
        </HStack>
      ),
    },
    {
      name: "Actions",
      render: (row: any) => (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FiMoreVertical />}
            variant="ghost"
            size="sm"
            onClick={(e) => e.stopPropagation()}
          />
          <MenuList>
            <MenuItem
              icon={<FiTrash />}
              onClick={(e) => {
                e.stopPropagation();
                setDataModelToDelete(row);
                onOpen();
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ];

  const handleAddNewDataModel = (newItem: { name: string }) => {
    const newId = addRecordToCollectionFragment("/essentials/dataModels", {
      ...newItem,
      description: "",
      fields: [],
    });
    navigate(`./id:${newId}`);
  };

  const handleRowClick = (item: any) => {
    navigate(`./id:${item.id}`);
  };

  const handleDeleteDataModel = () => {
    if (dataModelToDelete) {
      removeRecordFromCollectionFragment(
        "/essentials/dataModels",
        dataModelToDelete.id
      );
      onClose();
      setDataModelToDelete(null);
    }
  };

  if (!dataModels || dataModels.length === 0) {
    return (
      <VStack spacing={4} align="center" justify="center" height="400px">
        <Heading as="h1" size="xl" mb={4}>
          Data models
        </Heading>
        <Icon as={FiDatabase} boxSize={12} color="gray.400" />
        <Text fontSize="xl" fontWeight="bold" color="gray.600">
          No data models yet
        </Text>
        <Text color="gray.500" textAlign="center" maxWidth="400px">
          Data models help you structure your application's data. Click the
          button below to create your first data model.
        </Text>
        <Button
          leftIcon={<FiPlus />}
          colorScheme="blue"
          onClick={() => setIsModalOpen(true)}
        >
          Add New Data Model
        </Button>
        <NewDataModelModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleAddNewDataModel}
        />
      </VStack>
    );
  }

  return (
    <Box maxWidth="container.lg" mx="auto">
      <Heading as="h1" size="xl" mb={6}>
        Data models
      </Heading>
      <DataTable
        columns={columns}
        data={dataModels}
        onRowClick={handleRowClick}
      />
      <Button leftIcon={<FiPlus />} mt={4} onClick={() => setIsModalOpen(true)}>
        Add New Data Model
      </Button>
      <NewDataModelModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddNewDataModel}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Data Model
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete the data model "
              {dataModelToDelete?.name}"? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteDataModel} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default DataModelsIndex;
