import { z } from 'zod';
import { DescriptorRecord, DescriptorCollection } from '../descriptorUtils';
import { coreDataTypes } from '../../types/coreDataTypes';
import { parametersSchema } from '../shared/parametersSchema';

const validationSchema = z.object({
  validationFunctionName: z.string(),
  arguments: z.record(z.any()).optional(),
});

const validationFunctionSchema = z.object({
  name: z.string(),
  description: z.string(),
  applicableDataTypes: z.array(z.string()),
  parameters: z.array(z.object({
    name: z.string(),
    description: z.string().optional(),
    dataType: z.string(),
    required: z.boolean().optional(),
    default: z.any().optional(),
  })).optional(),
});

const fieldSchema = DescriptorRecord(z.object({
  name: z.string(),
  dataType: z.enum(coreDataTypes as [string, ...string[]]),
  description: z.string().optional(),
  validations: DescriptorCollection(validationSchema).optional(),
}));

const methodSchema = DescriptorRecord(z.object({
  name: z.string(),
  description: z.string().optional(),
  methodType: z.enum(['instance', 'class']),
  parameters: parametersSchema,
  flowgraph: z.any().optional(),
}));

const associationSchema = DescriptorRecord(z.object({
  associationType: z.enum(['belongs_to', 'has_one', 'has_many', 'has_many_through']),
  targetModelId: z.string(),
  description: z.string().optional(),
  dependentRecordsBehaviorOnDestroy: z.enum(['none', 'destroy', 'nullify', 'delete_all', 'restrict_with_error', 'restrict_with_exception']).optional(),
}));

export const modelLifecycleEvents = [
  'before_validation', 'after_validation', 'before_save', 'after_save',
  'before_create', 'after_create', 'before_update', 'after_update',
  'before_destroy', 'after_destroy', 'after_commit', 'after_rollback'
] as const;

const callbackSchema = DescriptorRecord(z.object({
  lifecycleEvent: z.enum(modelLifecycleEvents),
  methodId: z.string(),
}));

const scopeSchema = DescriptorRecord(z.object({
  name: z.string(),
  description: z.string().optional(),
  parametersSchema: z.any().optional(),
  rubyCode: z.string().optional(),
}));

export const dataModelSchema = DescriptorRecord(z.object({
  name: z.string(),
  description: z.string().optional(),
  fields: DescriptorCollection(fieldSchema).optional(),
  methods: DescriptorCollection(methodSchema).optional(),
  associations: DescriptorCollection(associationSchema).optional(),
  callbacks: DescriptorCollection(callbackSchema).optional(),
  scopes: DescriptorCollection(scopeSchema).optional(),
}));

export const dataModelsSchema = DescriptorCollection(dataModelSchema);

export type DataModel = z.infer<typeof dataModelSchema>;

export type DataModelCallback = z.infer<typeof callbackSchema>;

export type DataModelAssociation = z.infer<typeof associationSchema>;

export type DataModelField = z.infer<typeof fieldSchema>;

export type DataModelValidation = z.infer<typeof validationSchema>;

export type ValidationFunction = z.infer<typeof validationFunctionSchema>;

export const coreValidationFunctions: ValidationFunction[] = [
  {
    name: 'presence',
    description: 'Validates that the specified field is present, i.e., not nil or empty. It uses the blank? method to check if the value is nil or an empty string.',
    applicableDataTypes: ['string', 'text', 'integer', 'float', 'decimal', 'datetime', 'date', 'time', 'boolean'],
  },
  {
    name: 'absence',
    description: 'Validates that the specified field is not present, i.e., is nil or empty. It uses the present? method to check if the value is nil or an empty string.',
    applicableDataTypes: ['string', 'text', 'integer', 'float', 'decimal', 'datetime', 'date', 'time', 'boolean'],
  },
  {
    name: 'format',
    description: 'Validates that the specified field\'s value matches the given regular expression.',
    applicableDataTypes: ['string', 'text'],
    parameters: [
      {
        name: 'with',
        description: 'The regular expression to match against.',
        dataType: '_types.String',
      },
    ],
  },
  {
    name: 'inclusion',
    description: 'Validates that the specified field\'s value is included in a given list.',
    applicableDataTypes: ['string', 'text', 'integer', 'float', 'decimal', 'datetime', 'date', 'time', 'boolean'],
    parameters: [
      {
        name: 'in',
        description: 'The list of values to check against.',
        dataType: '_types.Array',
        items: {
          dataType: 'Computed',
          formula: '$validation.field.schema',
        },
      },
    ],
  },
  {
    name: 'exclusion',
    description: 'Validates that the specified field\'s value is not included in a given list.',
    applicableDataTypes: ['string', 'text', 'integer', 'float', 'decimal', 'datetime', 'date', 'time', 'boolean'],
    parameters: [
      {
        name: 'in',
        description: 'The list of values to check against.',
        dataType: '_types.Array',
        items: {
          dataType: 'Computed',
          formula: '$validation.field.schema',
        },
      },
    ],
  },
  {
    name: 'length',
    description: 'Validates that the specified text field\'s value has a length within a given range.',
    applicableDataTypes: ['string', 'text'],
    parameters: [
      {
        name: 'minimum',
        description: 'The minimum length of the field.',
        dataType: '_types.Integer',
      },
      {
        name: 'maximum',
        description: 'The maximum length of the field.',
        dataType: '_types.Integer',
      },
    ],
  },
  {
    name: 'greater_than',
    description: 'Validates that the specified numeric field\'s value is greater than the given value.',
    applicableDataTypes: ['integer', 'float', 'decimal'],
    parameters: [
      {
        name: 'compare_with',
        description: 'The value to compare against.',
        dataType: '_types.Integer',
      },
    ],
  },
  {
    name: 'less_than',
    description: 'Validates that the specified numeric field\'s value is less than the given value.',
    applicableDataTypes: ['integer', 'float', 'decimal'],
    parameters: [
      {
        name: 'compare_with',
        description: 'The value to compare against.',
        dataType: '_types.Integer',
      },
    ],
  },
  {
    name: 'greater_than_or_equal_to',
    description: 'Validates that the specified numeric field\'s value is greater than or equal to the given value.',
    applicableDataTypes: ['integer', 'float', 'decimal'],
    parameters: [
      {
        name: 'compare_with',
        description: 'The value to compare against.',
        dataType: '_types.Integer',
      },
    ],
  },
  {
    name: 'less_than_or_equal_to',
    description: 'Validates that the specified numeric field\'s value is less than or equal to the given value.',
    applicableDataTypes: ['integer', 'float', 'decimal'],
    parameters: [
      {
        name: 'compare_with',
        description: 'The value to compare against.',
        dataType: '_types.Integer',
      },
    ],
  },
];