import {
  type Directive,
  type NestableDirective,
  type AnyDirective,
} from "./directiveTypes";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import { TypedValue } from "../types/typedValue";

export type LoopForEachDirective = Directive<"loopForEach", LoopForEachConfig>;

export interface LoopForEachConfig {
  iteratorVariables?: {
    currentItem: string;
    currentIndex: string;
    itemCount?: string;
  };
  iterable: NestableDirective | TypedValue;
  loopingFlowgraph: AnyDirective[];
}

const execute = async (
  config: LoopForEachConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { iteratorVariables, iterable, loopingFlowgraph } = config;

  let resolvedIterable: TypedValue;
  if ("directiveType" in iterable) {
    resolvedIterable = await executionContext.executeDirective(iterable);
  } else {
    resolvedIterable = iterable;
  }

  if (
    resolvedIterable.type !== "_types.List" ||
    !Array.isArray(resolvedIterable.value)
  ) {
    throw new Error("The resolved iterable is not an array");
  }

  const mergedIteratorVariables = {
    currentItem: "currentItem",
    currentIndex: "currentIndex",
    itemCount: "itemCount",
    ...iteratorVariables,
  };

  const itemCount = resolvedIterable.value.length;

  for (let index = 0; index < itemCount; index++) {
    const currentItem = resolvedIterable.value[index];

    executionContext.setIteratorVariable(
      mergedIteratorVariables.currentItem,
      currentItem,
    );

    executionContext.setIteratorVariable(mergedIteratorVariables.currentIndex, {
      type: "_types.Number",
      value: index,
    });

    if (mergedIteratorVariables.itemCount) {
      executionContext.setIteratorVariable(mergedIteratorVariables.itemCount, {
        type: "_types.Number",
        value: itemCount,
      });
    }

    for (const directive of loopingFlowgraph) {
      await executionContext.executeDirective(directive);
    }
  }

  return { type: "_types.Boolean", value: true };
};

export const loopForEach = {
  execute,
  availableActions: {},
  defaultConfig: () => ({
    iteratorVariables: {
      currentItem: "currentItem",
      currentIndex: "currentIndex",
      itemCount: "itemCount",
    },
    iterable: {
      directiveType: "literalValue",
      config: { type: "_types.List", value: [] },
    },
    loopingFlowgraph: [
      {
        directiveType: "literalValue",
        config: { type: "_types.String", value: "" },
      },
    ],
  }),
};
