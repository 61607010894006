import React from "react";
import {
  Box,
  VStack,
  Text,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import {
  categorizeTailwindClasses,
  combineTailwindClasses,
} from "./tailwindClassParser";

interface StyleEditorProps {
  styleClasses: string;
  onChange: (newClasses: string) => void;
}

export const StyleEditor: React.FC<StyleEditorProps> = ({
  styleClasses,
  onChange,
}) => {
  const categorizedClasses = categorizeTailwindClasses(styleClasses);

  const handleCategoryChange = (category: string, newClasses: string) => {
    const updatedCategories = {
      ...categorizedClasses,
      [category]: newClasses.split(" ").filter(Boolean),
    };
    onChange(combineTailwindClasses(updatedCategories));
  };

  return (
    <Box>
      <Accordion allowMultiple>
        {Object.entries(categorizedClasses).map(([category, classes]) => (
          <AccordionItem key={category}>
            <AccordionButton>
              <Box flex="1" textAlign="left" textTransform="capitalize">
                {category}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <VStack spacing={2} align="stretch">
                <Input
                  value={classes.join(" ")}
                  onChange={(e) =>
                    handleCategoryChange(category, e.target.value)
                  }
                  placeholder={`Add ${category} classes...`}
                />
                <Text fontSize="xs" color="gray.500">
                  Current: {classes.join(" ")}
                </Text>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};
