import { v4 as uuidv4 } from "uuid";
import {
  type AnyDirective,
  type NestableDirective,
  type Directive,
  isDirective,
} from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import type { DataType } from "../types/dataType";
import {
  createDefaultDirective,
  searchAnyDirective,
} from "../flowFunctionStore";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";

export type LiteralValueDirective = Directive<
  "literalValue",
  LiteralValueConfig
>;

export type LiteralValueConfig =
  | {
      type: "_types.String"
      value: string | null | undefined;
      metadata?: Record<string, any>;
    }
  | {
      type: "_types.Number";
      value: number | null | undefined;
      metadata?: Record<string, any>;
    }
  | {
      type: "_types.Boolean";
      value: boolean | null | undefined;
      metadata?: Record<string, any>;
    }
  | {
      type: "_types.Dictionary";
      value: Record<string, NestableDirective> | null | undefined;
      metadata?: Record<string, any>;
    }
  | {
      type: "_types.List";
      value: NestableDirective[] | null | undefined;
      metadata?: Record<string, any>;
    };

export function isLiteralValueDirective(
  directive: any,
): directive is LiteralValueDirective {
  const literalValueConfigKeys: (keyof LiteralValueConfig)[] = [
    "type",
    "value",
    "metadata",
  ];
  return isDirective(directive, "literalValue", literalValueConfigKeys);
}

const execute = async (
  config: LiteralValueConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { type, value } = config;

  let returnValue: TypedValue = { type: type, value: value };

  if (type === "_types.Dictionary") {
    const dictionary = value as Record<string, NestableDirective>;
    const resolvedDictionary = await Promise.all(
      Object.entries(dictionary).map(async ([key, directive]) => [
        key,
        await executionContext.executeDirective(directive),
      ]),
    );
    returnValue = { type: type, value: Object.fromEntries(resolvedDictionary) };
  }

  if (type === "_types.List") {
    const list = value as NestableDirective[];
    const resolvedList = await Promise.all(
      list.map((directive) => executionContext.executeDirective(directive)),
    );
    returnValue = { type: type, value: resolvedList };
  }

  return returnValue;
};

function getDefaultValueForType(
  type: DataType,
):
  | string
  | number
  | boolean
  | Record<string, NestableDirective>
  | NestableDirective[] {
  if (type === "_types.String") {
    return "";
  }
  if (type === "_types.Number") {
    return 0;
  }
  if (type === "_types.Boolean") {
    return false;
  }
  if (type === "_types.Dictionary") {
    return {};
  }
  if (type === "_types.List") {
    return [];
  }
  throw new Error(`Invalid type ${type} for LiteralValueDirective`);
}

function updateType(directive: LiteralValueDirective, newType: DataType) {
  directive.config.type = newType;
  directive.config.value = getDefaultValueForType(newType);
}

function updateValue(
  directive: LiteralValueDirective,
  newValue:
    | string
    | number
    | boolean
    | Record<string, NestableDirective>
    | NestableDirective[],
) {
  directive.config.value = newValue;
}

function addNewDictionaryEntry(directive: LiteralValueDirective) {
  const getNextKey = (keys: string[]): string => {
    const lastKey = keys[keys.length - 1];
    let nextKey = "";
    let carry = true;

    for (let i = lastKey.length - 1; i >= 0; i--) {
      if (carry) {
        if (lastKey[i] === "z") {
          nextKey = "a" + nextKey;
        } else {
          nextKey = String.fromCharCode(lastKey.charCodeAt(i) + 1) + nextKey;
          carry = false;
        }
      } else {
        nextKey = lastKey[i] + nextKey;
      }
    }

    if (carry) {
      nextKey = "a" + nextKey;
    }

    return nextKey;
  };

  const existingKeys = Object.keys(
    directive.config.value as Record<string, NestableDirective>,
  );
  let newKey = "a";

  if (existingKeys.length > 0) {
    newKey = getNextKey(existingKeys);
  }

  (directive.config.value as Record<string, NestableDirective>)[newKey] =
    createDefaultDirective() as NestableDirective;
}

function getDictionaryEntry(directive: LiteralValueDirective, key: string) {
  return (directive.config.value as Record<string, NestableDirective>)[key];
}

function removeDictionaryEntry(directive: LiteralValueDirective, key: string) {
  delete (directive.config.value as Record<string, NestableDirective>)[key];
}

function addNewListItem(directive: LiteralValueDirective) {
  (directive.config.value as NestableDirective[]).push(
    createDefaultDirective() as NestableDirective,
  );
}

function removeListItem(directive: LiteralValueDirective, index: number) {
  (directive.config.value as NestableDirective[]).splice(index, 1);
}

function getListItem(directive: LiteralValueDirective, index: number) {
  return (directive.config.value as NestableDirective[])[index];
}

function moveListItem(
  directive: LiteralValueDirective,
  fromIndex: number,
  toIndex: number,
) {
  const item = (directive.config.value as NestableDirective[]).splice(
    fromIndex,
    1,
  )[0];
  (directive.config.value as NestableDirective[]).splice(toIndex, 0, item);
}

function searchDirective(
  directive: LiteralValueDirective,
  id: string,
): AnyDirective | undefined {
  if (directive.id === id) {
    return directive;
  }

  const { type, value } = directive.config;
  if (type === "_types.Dictionary" && value) {
    return searchAnyDirective(Object.values(value), id);
  }

  if (type === "_types.List" && value) {
    return searchAnyDirective(value, id);
  }

  return undefined;
}

export const literalValue = {
  execute,
  availableActions: {
    updateType,
    updateValue,
    addNewDictionaryEntry,
    getDictionaryEntry,
    removeDictionaryEntry,
    addNewListItem,
    removeListItem,
    moveListItem,
    getListItem,
  },
  defaultConfig: () =>
    ({
      type: "_types.String",
      value: "",
    }) as LiteralValueConfig,
  searchDirective,
};

export const createLiteralValueDirectiveOfType = (type: DataType) => {
  return {
    id: uuidv4(),
    directiveType: "literalValue",
    config: {
      type: type,
      value: getDefaultValueForType(type),
    },
  } as LiteralValueDirective;
};
