import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon, Text } from '@chakra-ui/react';
import { useAppDescriptorStore, BreadcrumbItem as BreadcrumbItemType } from '../../stores/appDescriptorStore';
import { FiFolder, FiFile, FiBox } from 'react-icons/fi';

interface BreadcrumbsProps {
  keypath: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ keypath }) => {
  const { getBreadcrumbItems } = useAppDescriptorStore();
  const { projectId } = useParams();

  const breadcrumbItems: BreadcrumbItemType[] = getBreadcrumbItems(keypath)

  return (
    <Breadcrumb fontSize="sm" spacing="8px" separator={<Text color="gray.500">/</Text>}>
      {breadcrumbItems.map((item, index) => (
        <BreadcrumbItem key={index} isCurrentPage={item.isCurrentPage}>
          <BreadcrumbLink
            as={Link}
            to={`/projects/${projectId}/editor/descriptor${item.href}`}
            isCurrentPage={item.isCurrentPage}
            display="flex"
            alignItems="center"
          >
            {item.isCollection && <Icon as={FiFolder} mr={2} color="gray.500" />}
            {item.isRecord && <Icon as={FiFile} mr={2} color="gray.500" />}
            {item.isCategory && <Icon as={FiBox} mr={2} color="gray.500" />}
            <Text>{item.displayName}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;