import { Context, Schema } from "../types";

function traversePath(
  state: any,
  path: string,
  schema?: Schema,
  options = { createMissing: false }
): { obj: any; lastKey: string | null } {
  const keys = path.split("/").filter((k) => k !== "");
  const lastKey = keys.pop() || null;
  let obj = state;
  let currentSchema = schema;
  let currentPath = "";

  for (const key of keys) {
    currentPath = currentPath ? currentPath + "/" + key : key;
    if (Array.isArray(obj)) {
      let index: number;
      if (key.match(/^\d+$/)) {
        index = parseInt(key, 10);
      } else {
        index = obj.findIndex((item) => item.$id === key);
      }

      if (index === -1 || index >= obj.length) {
        if (options.createMissing) {
          if (currentSchema && currentSchema.items) {
            while (obj.length <= index) {
              obj.push({ $unknown: true });
            }
          } else {
            throw new Error(
              `Cannot traverse keypath: Index or ID ${key} is not found in array at ${currentPath}`
            );
          }
        } else {
          throw new Error(
            `Cannot traverse keypath: Index or ID ${key} is not found in array at ${currentPath}`
          );
        }
      }
      obj = obj[index];
    } else {
      if (!(key in obj)) {
        if (options.createMissing) {
          if (
            currentSchema &&
            currentSchema.properties &&
            currentSchema.properties[key]
          ) {
            obj[key] = {};
          } else {
            throw new Error(
              `Cannot traverse keypath: ${key} not found in object at ${currentPath}`
            );
          }
        } else {
          throw new Error(
            `Cannot traverse keypath: ${key} not found in object at ${currentPath}`
          );
        }
      }
      obj = obj[key];
    }

    if (currentSchema) {
      if (currentSchema.properties && currentSchema.properties[key]) {
        currentSchema = currentSchema.properties[key];
      } else if (currentSchema.items) {
        currentSchema = currentSchema.items;
      } else {
        currentSchema = undefined;
      }
    }
  }

  return { obj, lastKey };
}

export function getValueAtPath(state: any, path: string, schema?: Schema): any {
  const { obj, lastKey } = traversePath(state, path, schema, {
    createMissing: false,
  });
  if (lastKey === null) {
    return obj;
  }
  if (!(lastKey in obj)) {
    throw new Error(`Keypath does not exist: ${path}`);
  }
  return obj[lastKey];
}

export function setValueAtPath(
  state: any,
  path: string,
  value: any,
  schema?: Schema
): any {
  const { obj, lastKey } = traversePath(state, path, schema, {
    createMissing: true,
  });
  if (lastKey) {
    if (Array.isArray(obj) && lastKey.match(/^\d+$/)) {
      const index = parseInt(lastKey, 10);
      obj[index] = value;
    } else {
      obj[lastKey] = value;
    }
  }
  return state;
}

export function removeValueAtPath(
  state: any,
  path: string,
  schema?: Schema
): any {
  const { obj, lastKey } = traversePath(state, path, schema, {
    createMissing: false,
  });

  if (lastKey !== null) {
    if (Array.isArray(obj)) {
      let index: number;
      if (lastKey.match(/^\d+$/)) {
        // Handle numeric index
        index = parseInt(lastKey, 10);
      } else {
        // Handle $id lookup
        index = obj.findIndex((item: any) => item.$id === lastKey);
      }

      if (index !== -1 && index < obj.length) {
        obj.splice(index, 1);
      } else {
        throw new Error(
          `Item with $id or index "${lastKey}" not found in array`
        );
      }
    } else if (lastKey in obj) {
      delete obj[lastKey];
    } else {
      throw new Error(`Cannot remove value: ${path} not found`);
    }
  } else {
    throw new Error(`Cannot remove value: ${path} not found`);
  }

  return state;
}

export function isValidKeypath(
  state: any,
  path: string,
  schema?: Schema
): boolean {
  try {
    const { obj, lastKey } = traversePath(state, path, schema, {
      createMissing: false,
    });

    if (lastKey === null) {
      return true;
    }

    if (Array.isArray(obj)) {
      if (lastKey.match(/^\d+$/)) {
        // Numeric index check
        const index = parseInt(lastKey, 10);
        return index >= 0 && index < obj.length;
      } else {
        // $id check
        return obj.some((item: any) => item.$id === lastKey);
      }
    }

    return lastKey in obj;
  } catch {
    return false;
  }
}

export function getSchemaAtPath(schema: Schema, path: string): Schema {
  const keys = path.split("/").filter((k) => k !== "" && k !== "$state");
  let currentSchema = schema;

  for (const key of keys) {
    if (currentSchema.properties && key in currentSchema.properties) {
      currentSchema = currentSchema.properties[key];
    } else if (currentSchema.items) {
      // For array items, we don't need to check the index
      currentSchema = currentSchema.items;
    } else {
      throw new Error(`Cannot find schema at keypath: ${path}`);
    }
  }

  return currentSchema;
}
