import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, FormControl, FormErrorMessage, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { useAppDescriptorStore } from '../../stores/appDescriptorStore';

interface RecordNameEditorProps {
  keypath: string; // This keypath now points directly to the .name property
}

const RecordNameEditor: React.FC<RecordNameEditorProps> = ({ keypath }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [name, setName] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    const currentName = getFragment(keypath) as string;
    setName(currentName);
  }, [keypath, getFragment]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setError('');
  };

  const handleSave = () => {
    if (name.trim() === '') {
      setError('Name cannot be empty');
      return;
    }
    setFragment(keypath, name); // Directly set the name at the given keypath
    onClose();
  };

  return (
    <VStack align="flex-start" spacing={1}>
      <Text
        fontSize="x-large"
        fontWeight="bold"
        cursor="pointer"
        onClick={onOpen}
      >
        {name}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Record Name</ModalHeader>
          <ModalBody>
            <FormControl isInvalid={!!error}>
              <Input
                value={name}
                onChange={handleNameChange}
                placeholder="Enter record name"
              />
              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default RecordNameEditor;