import React, { useState, useEffect } from "react";
import { Button, useDisclosure, Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import type { DirectiveType, AnyDirective } from "@/runtime-js/src/directives/directiveTypes";
import { generateUniqueId } from "@/bundles/DescriptorEditor/utils/idUtils";
import { ModalHeader } from "./SelectDirectiveModal/ModalHeader";
import { ModalBody } from "./SelectDirectiveModal/ModalBody";
import { ModalFooter } from "./SelectDirectiveModal/ModalFooter";
import { IncompatibleDirectiveAlert } from "./SelectDirectiveModal/IncompatibleDirectiveAlert";
import { SelectDirectiveModalProps, MenuItem, DirectiveItem, CategoryItem } from "./SelectDirectiveModal/types";
import { DataModel, DataModelField } from "@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema";

const methodArgumentsDescriptor = {
  new: {},
  find: { id: { type: "field", dataType: "_types.Number" } },
  create: { payload: { type: "literalDictionary" } },
  update: { id: { type: "field", dataType: "_types.Number" }, payload: { type: "literalDictionary" } },
  destroy: { id: { type: "field", dataType: "_types.Number" } },
  find_by: { id: { type: "field", dataType: "_types.Number" } },
  where: { type: "allFields" },
  all: {},
  count: {},
  first: {},
  last: {},
  destroy_all: {}
};

export const SelectDirectiveModal: React.FC<SelectDirectiveModalProps> = ({ isOpen, onClose, menuStructure, currentDirectiveType, keypath, flowgraphContext }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentItems, setCurrentItems] = useState<MenuItem[]>(menuStructure);
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<DirectiveItem | null>(null);
  const [isExplanationOpen, setIsExplanationOpen] = useState(false);
  const { getFragment, setFragment } = useAppDescriptorStore();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const [selectedIncompatibleItem, setSelectedIncompatibleItem] = useState<DirectiveItem | null>(null);

  useEffect(() => {
    if (currentDirectiveType === 'executeModelMethod') {
      const currentDirective = getFragment(keypath) as ExecuteModelMethodDirective;
      const modelCategory = menuStructure.find(item => item.type === 'category' && item.name === 'Models') as CategoryItem;
      if (modelCategory) {
        const modelItem = modelCategory.children.find(item => item.type === 'category' && item.name === currentDirective.config.modelName) as CategoryItem;
        if (modelItem) {
          setBreadcrumbs(['Models', modelItem.name]);
          setCurrentItems(modelItem.children);
          const methodItem = modelItem.children.find(item => item.type === 'directive' && item.name === `${currentDirective.config.modelName}.${currentDirective.config.method}`) as DirectiveItem;
          if (methodItem) {
            setSelectedItem(methodItem);
          }
        }
      }
    } else if (currentDirectiveType) {
      const findDirectiveItem = (items: MenuItem[]): DirectiveItem | null => {
        for (const item of items) {
          if (item.type === 'directive' && item.directiveType === currentDirectiveType) {
            return item as DirectiveItem;
          } else if (item.type === 'category') {
            const found = findDirectiveItem(item.children);
            if (found) {
              setBreadcrumbs(prev => [...prev, item.name]);
              return found;
            }
          }
        }
        return null;
      };

      const directiveItem = findDirectiveItem(menuStructure);
      if (directiveItem) {
        setSelectedItem(directiveItem);
        setCurrentItems(menuStructure.find(item => item.type === 'category' && item.children.includes(directiveItem))?.children || menuStructure);
      }
    }
  }, [currentDirectiveType, menuStructure]);

  const filteredItems = currentItems.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleItemSelect = (item: MenuItem & { compatibility?: { compatible: boolean; warning?: string } }) => {
    console.log(item);
    if (item.type === 'category') {
      setCurrentItems(item.children);
      setBreadcrumbs([...breadcrumbs, item.name]);
      setSearchTerm(''); // Reset search term when entering a new category
    } else {
      setSelectedItem(item);
      if (item.compatibility && !item.compatibility.compatible) {
        setSelectedIncompatibleItem(item);
        onAlertOpen();
      } else {
        selectDirective(item);
      }
    }
  };

  const dataModels = useAppDescriptorStore(state => state.getFragment("/essentials/dataModels")) as DataModel[];

  const selectDirective = (item: DirectiveItem) => {
    const newDirective: AnyDirective = {
      directiveType: item.directiveType,
      config: {
        ...item.config,
        arguments: item.config.arguments || {}
      },
      id: generateUniqueId(),
    };

    if (item.directiveType === 'executeModelMethod') {
      const modelName = item.config.modelName || '';
      const method = item.config.method || '';
      const currentModel = dataModels.find((model: DataModel) => model.name === modelName);

      newDirective.config = {
        ...newDirective.config,
        modelName,
        method,
        arguments: initializeArguments(method, currentModel),
      };
    }

    console.log("MODAL: Setting fragment at keypath:", keypath, "with value:", newDirective);
    setFragment(keypath, newDirective);
    onClose();
  };

  const initializeArguments = (method: string, currentModel: DataModel | undefined) => {
    if (!method || !currentModel) return {};

    const methodDescriptor = methodArgumentsDescriptor[method as keyof typeof methodArgumentsDescriptor];
    let newArguments: Record<string, any> = {};

    if (methodDescriptor.type === "allFields") {
      newArguments = currentModel.fields.reduce((acc, field) => {
        acc[field.name] = createLiteralValueForField(field);
        return acc;
      }, {} as Record<string, any>);
    } else {
      Object.entries(methodDescriptor).forEach(([key, value]) => {
        if (value.type === "literalDictionary") {
          const fieldValues = currentModel.fields.reduce((acc, field) => {
            if (method === "update" && field.name === "id") return acc;
            acc[field.name] = createLiteralValueForField(field);
            return acc;
          }, {} as Record<string, any>);
          newArguments[key] = {
            directiveType: "literalValue",
            config: {
              type: "_types.Dictionary",
              value: fieldValues
            }
          };
        } else if (value.type === "field") {
          newArguments[key] = createLiteralValueForField({ name: key, dataType: value.dataType });
        }
      });
    }

    return newArguments;
  };

  const createLiteralValueForField = (field: DataModelField): AnyDirective => {
    return {
      directiveType: "literalValue",
      config: {
        type: field.dataType,
        value: getDefaultValueForType(field.dataType)
      }
    };
  };

  const getDefaultValueForType = (type: string) => {
    switch (type) {
      case "_types.String":
        return "";
      case "_types.Number":
        return 0;
      case "_types.Boolean":
        return false;
      case "_types.Date":
        return new Date().toISOString();
      default:
        return null;
    }
  };

  const handleBackNavigation = () => {
    if (breadcrumbs.length > 0) {
      const newBreadcrumbs = [...breadcrumbs];
      newBreadcrumbs.pop();
      setBreadcrumbs(newBreadcrumbs);

      let newItems = menuStructure;
      if (newBreadcrumbs.length > 0) {
        for (const crumb of newBreadcrumbs) {
          const category = newItems.find(item => item.type === 'category' && item.name === crumb) as CategoryItem;
          if (category) {
            newItems = category.children;
          } else {
            // If we can't find the category, reset to the root menu
            newItems = menuStructure;
            setBreadcrumbs([]);
            break;
          }
        }
      }
      setCurrentItems(newItems);
      setSearchTerm(''); // Reset search term when navigating back
      setSelectedItem(null); // Reset selected item when navigating back
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={isExplanationOpen ? "4xl" : "lg"}>
        <ModalOverlay />
        <ModalContent maxH="500px" display="flex" flexDirection="column">
          <ModalHeader
            breadcrumbs={breadcrumbs}
            handleBackNavigation={handleBackNavigation}
          />
          <ModalBody
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filteredItems={filteredItems}
            selectedItem={selectedItem}
            handleItemSelect={handleItemSelect}
            isExplanationOpen={isExplanationOpen}
            currentItems={currentItems}
          />
          <ModalFooter
            onClose={onClose}
            isExplanationOpen={isExplanationOpen}
            setIsExplanationOpen={setIsExplanationOpen}
            selectedItem={selectedItem}
            selectDirective={selectDirective}
            keypath={keypath}
          />
        </ModalContent>
      </Modal>
      <IncompatibleDirectiveAlert
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        selectedIncompatibleItem={selectedIncompatibleItem}
        selectDirective={selectDirective}
      />
    </>
  );
};