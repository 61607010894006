import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  HStack,
  Badge,
} from "@chakra-ui/react";

const AppDeployments: React.FC = () => {
  const [deployments, setDeployments] = useState<
    { id: number; status: string; deployedAt: string }[]
  >([]);
  const [deploying, setDeploying] = useState(false);

  const handleDeploy = () => {
    setDeploying(true);
    setTimeout(() => {
      const newDeployment = {
        id: deployments.length + 1,
        status: "Success",
        deployedAt: new Date().toLocaleString(),
      };
      setDeployments([...deployments, newDeployment]);
      setDeploying(false);
    }, 2000);
  };

  return (
    <Box p={4}>
      <Heading size="lg" mb={4}>
        App Deployments
      </Heading>
      <Button
        colorScheme="blue"
        onClick={handleDeploy}
        isLoading={deploying}
        mb={4}
      >
        Deploy App
      </Button>
      <VStack spacing={4} align="stretch">
        {deployments.length === 0 ? (
          <Text>No deployments yet.</Text>
        ) : (
          deployments.map((deployment) => (
            <HStack
              key={deployment.id}
              justify="space-between"
              p={4}
              borderWidth="1px"
              borderRadius="md"
            >
              <Text>Deployment #{deployment.id}</Text>
              <Badge colorScheme="green">{deployment.status}</Badge>
              <Text>{deployment.deployedAt}</Text>
            </HStack>
          ))
        )}
      </VStack>
    </Box>
  );
};

export default AppDeployments;
