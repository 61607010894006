import React from "react";
import { VStack, Text, Code } from "@chakra-ui/react";

interface ExplanationPanelProps {
  selectedItem: DirectiveItem | null;
}

export const ExplanationPanel: React.FC<ExplanationPanelProps> = ({ selectedItem }) => (
  <VStack flex={1} align="stretch" spacing={2} overflowY="auto" pl={4} borderLeft="1px solid" borderColor="gray.200">
    {selectedItem ? (
      <>
        <Text fontWeight="bold">{selectedItem.name}</Text>
        <Text>{selectedItem.description}</Text>
        {selectedItem.parameters && (
          <>
            <Text fontWeight="bold" mt={2}>Parameters:</Text>
            {selectedItem.parameters.map((param, index) => (
              <Text key={index}>
                {param.name}: {param.type} - {param.description}
              </Text>
            ))}
          </>
        )}
        {selectedItem.examples && selectedItem.examples.length > 0 && (
          <>
            <Text fontWeight="bold" mt={2}>Examples:</Text>
            {selectedItem.examples.map((example, index) => (
              <Code key={index} p={2} borderRadius="md">
                {example}
              </Code>
            ))}
          </>
        )}
      </>
    ) : (
      <Text color="gray.500" fontStyle="italic">Select an item to see details</Text>
    )}
  </VStack>
);