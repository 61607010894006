import { type Directive, isDirective } from "./directiveTypes";
import { getParameterNames } from "../flowFunctionStore";
import type { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import type { TypedValue } from "../types/typedValue";

export type GetArgumentDirective = Directive<"getArgument", GetArgumentConfig>;

export interface GetArgumentConfig {
  name: string;
}

export function isGetArgumentDirective(
  directive: any,
): directive is GetArgumentDirective {
  const getArgumentConfigKeys: (keyof GetArgumentConfig)[] = ["name"];
  return isDirective(directive, "getArgument", getArgumentConfigKeys);
}

const execute = async (
  config: GetArgumentConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { name } = config;
  const parts = name.split(".");

  let value = executionContext.getArgument(parts[0]);
  if (!value) {
    throw new Error(`Argument '${parts[0]}' does not exist`);
  }

  for (let i = 1; i < parts.length; i++) {
    if (typeof value !== "object" || value === null) {
      throw new Error(
        `Cannot access property '${parts[i]}' of ${typeof value}`,
      );
    }
    value = value[parts[i]];
    if (value === undefined) {
      throw new Error(`Property '${parts[i]}' does not exist`);
    }
  }

  return value as TypedValue;
};

function updateName(directive: GetArgumentDirective, name: string) {
  if (!isGetArgumentDirective(directive)) {
    throw new Error("Directive is not a getArgument directive");
  }
  const parameterNames = getParameterNames();
  if (!parameterNames.includes(name.split(".")[0])) {
    throw new Error(
      `Matching parameter '${name.split(".")[0]}' does not exist`,
    );
  }
  directive.config.name = name;
}

export const getArgument = {
  execute,
  availableActions: {
    updateName,
  },
  defaultConfig: () =>
    ({
      name: "",
    }) as GetArgumentConfig,
};
