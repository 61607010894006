import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  VStack,
  HStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useDisclosure,
  Input,
  Select,
  Checkbox,
  useToast,
  Text,
} from "@chakra-ui/react";
import { FiTrash2, FiEdit } from "react-icons/fi";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { UserGroup } from "@/bundles/DescriptorEditor/schemas/usersAndSecurity/userGroupsSchema";
import UserGroupModal from "./UserGroupModal";
import { v4 as uuidv4 } from "uuid";

const UserGroups: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [editingGroup, setEditingGroup] = useState<UserGroup | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const toast = useToast();

  useEffect(() => {
    const groups =
      (getFragment("/usersAndSecurity/userGroups") as UserGroup[]) || [];
    setUserGroups(groups);
  }, [getFragment, searchTerm, currentPage]);

  const handleAddGroup = (newGroup: UserGroup) => {
    const updatedGroups = [...userGroups, { ...newGroup, id: uuidv4() }];
    setFragment("/usersAndSecurity/userGroups", updatedGroups);
    setUserGroups(updatedGroups);
    toast({
      title: "User Group added.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleUpdateGroup = (updatedGroup: UserGroup) => {
    const updatedGroups = userGroups.map((group) =>
      group.id === updatedGroup.id ? updatedGroup : group
    );
    setFragment("/usersAndSecurity/userGroups", updatedGroups);
    setUserGroups(updatedGroups);
    toast({
      title: "User Group updated.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDeleteGroup = (id: string) => {
    const updatedGroups = userGroups.filter((group) => group.id !== id);
    setFragment("/usersAndSecurity/userGroups", updatedGroups);
    setUserGroups(updatedGroups);
    toast({
      title: "User Group deleted.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleBulkDelete = () => {
    selectedGroups.forEach((id) => handleDeleteGroup(id));
    setSelectedGroups([]);
    toast({
      title: "Selected User Groups deleted.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSelectGroup = (id: string) => {
    setSelectedGroups((prev) =>
      prev.includes(id)
        ? prev.filter((groupId) => groupId !== id)
        : [...prev, id]
    );
  };

  return (
    <Box>
      <Heading size="lg" mb={4}>
        User Groups
      </Heading>
      <HStack mb={4}>
        <Input
          placeholder="Search User Groups"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          onClick={handleBulkDelete}
          isDisabled={selectedGroups.length === 0}
        >
          Delete Selected
        </Button>
      </HStack>
      <Button onClick={onOpen} mb={4}>
        Add User Group
      </Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              <Checkbox
                isChecked={selectedGroups.length === userGroups.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedGroups(userGroups.map((group) => group.id));
                  } else {
                    setSelectedGroups([]);
                  }
                }}
              />
            </Th>
            <Th
              cursor="pointer"
              onClick={() => {
                /* Sorting logic */
              }}
            >
              Name
            </Th>
            <Th
              cursor="pointer"
              onClick={() => {
                /* Sorting logic */
              }}
            >
              Description
            </Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userGroups
            .filter(
              (group) =>
                group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (group.description &&
                  group.description
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()))
            )
            .slice((currentPage - 1) * 10, currentPage * 10) // Example pagination
            .map((group) => (
              <Tr key={group.id}>
                <Td>
                  <Checkbox
                    isChecked={selectedGroups.includes(group.id)}
                    onChange={() => handleSelectGroup(group.id)}
                  />
                </Td>
                <Td>{group.name}</Td>
                <Td>{group.description}</Td>
                <Td>
                  <HStack spacing={2}>
                    <IconButton
                      aria-label="Edit user group"
                      icon={<FiEdit />}
                      size="sm"
                      onClick={() => {
                        setEditingGroup(group);
                        onOpen();
                      }}
                    />
                    <IconButton
                      aria-label="Delete user group"
                      icon={<FiTrash2 />}
                      size="sm"
                      onClick={() => handleDeleteGroup(group.id)}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <UserGroupModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setEditingGroup(null);
        }}
        onSubmit={(group) => {
          if (editingGroup) {
            handleUpdateGroup(group);
          } else {
            handleAddGroup(group);
          }
          onClose();
          setEditingGroup(null);
        }}
        initialData={editingGroup}
      />
      <HStack mt={4} justifyContent="space-between">
        <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
          Previous
        </Button>
        <Text>Page {currentPage}</Text>
        <Button onClick={() => setCurrentPage((prev) => prev + 1)}>Next</Button>
      </HStack>
    </Box>
  );
};

export default UserGroups;
