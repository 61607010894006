import React, { useEffect } from "react";
import { Box, Button } from "@chakra-ui/react";
import { FlowgraphEditor } from "./components/FlowgraphEditor";
import { FlowgraphContext } from "../DescriptorEditor/components/editors/FlowgraphEditor/types/FlowgraphContext";
import { useFlowgraphStore } from "./stores/FlowgraphStore";
import { useAppDescriptorStore } from "../DescriptorEditor/stores/appDescriptorStore";

interface FlowgraphEditorUIProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

function FlowgraphEditorUI({
  keypath,
  flowgraphContext,
}: FlowgraphEditorUIProps) {
  const { $flowgraph, setValue } = useFlowgraphStore();
  const { getFragment, setFragment } = useAppDescriptorStore();

  useEffect(() => {
    const storedFlowgraph = getFragment(`${keypath}/flowgraph`);
    if (storedFlowgraph) {
      setValue("$flowgraph", storedFlowgraph);
    }
  }, [keypath, getFragment, setValue]);

  useEffect(() => {
    if ($flowgraph) {
      setFragment(`${keypath}/flowgraph`, $flowgraph);
    }
  }, [$flowgraph, keypath, setFragment]);

  return (
    <FlowgraphEditor keypath={keypath} flowgraphContext={flowgraphContext} />
  );
}

export default FlowgraphEditorUI;

/** DO NOT DELETE!
 * TODO: FlowgraphEditorUI should work with context.
 *
 * 1. Should create variable nodes from available variables automatically.
 * 2. Should save the new flowgraph to the context.
 * 3. UI should then pick up the new flowgraph and use it.
 * 4. FIX translation script to work with new flowgraph.
 *
 */
