import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  Heading,
  VStack,
  Flex,
  Badge,
  Input,
  Select,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import {
  getColorScheme,
  getHttpMethod,
} from "@/bundles/DescriptorEditor/components/descriptors/essentials/pageGroups/routeHelpers";

const Routing: React.FC = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { getFragment, setFragment, getPage } = useAppDescriptorStore();

  if (!pageId) {
    throw new Error("Page ID is required");
  }

  const page = getPage(pageId.replace("id:", ""));

  if (!page) {
    return <Box>Page not found</Box>;
  }

  const keypath = page.keypath + "/initializationLogic";
  const pageGroup = page.pageGroup;
  const relativePath = page.relativePath || "";
  const pageType = page?.pageType || "index";
  const httpMethod = getHttpMethod(pageType);

  const handleRelativePathChange = (value: string) => {
    setFragment(`${keypath}/relativePath`, value);
  };

  const handlePageTypeChange = (value: string) => {
    setFragment(`${keypath}/pageType`, value);
  };

  const actionTypesRequiringId = ["show", "edit", "update", "destroy"];
  const hasIdParameter = relativePath.includes("/:id");
  const showError =
    actionTypesRequiringId.includes(pageType) && !hasIdParameter;

  return (
    <Box overflow="scroll">
      <Heading size="lg" mb={4}>
        Routing
      </Heading>
      <Text fontSize="md" color="gray.600" mb={4}>
        Configure the page type and route path.
      </Text>
      <VStack align="stretch" spacing={4} mt={6}>
        <Flex alignItems="center">
          <Text fontWeight="bold" mr={2}>
            Page type:
          </Text>
          <Select
            value={pageType}
            onChange={(e) => handlePageTypeChange(e.target.value)}
            width="auto"
            size={"sm"}
          >
            <option value="index">Index</option>
            <option value="show">Show</option>
            <option value="new">New</option>
            <option value="edit">Edit</option>
            <option value="create">Create</option>
            <option value="update">Update</option>
            <option value="destroy">Destroy</option>
            <option value="custom">Custom</option>
          </Select>
        </Flex>
        <Flex alignItems="center">
          <Text fontWeight="bold" mr={2}>
            Route:
          </Text>
          <Badge colorScheme={getColorScheme(httpMethod)} mr={2}>
            {httpMethod}
          </Badge>
          <Text mr={2}>{pageGroup.basePath}</Text>
          <Input
            value={relativePath}
            onChange={(e) => handleRelativePathChange(e.target.value)}
            width="auto"
            mr={2}
          />
        </Flex>
        {showError && (
          <Alert status="error">
            <AlertIcon />
            The selected page type requires an ':id' parameter in the route.
          </Alert>
        )}
      </VStack>
    </Box>
  );
};

export default Routing;
