import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  HStack,
  Input,
  IconButton,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface CollectionTableConfigEditorProps {
  schema: any;
  keypath: string;
}

interface ColumnConfig {
  title: string;
  fieldKey: string;
}

const CollectionTableConfigEditor: React.FC<
  CollectionTableConfigEditorProps
> = ({ schema, keypath }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getFragment, setFragment, getPageByKeypath } =
    useAppDescriptorStore();
  const [selectedVariable, setSelectedVariable] = useState("");
  const [columns, setColumns] = useState<ColumnConfig[]>([]);
  const [modelFields, setModelFields] = useState<string[]>([]);
  const [selectedDataModel, setSelectedDataModel] = useState("");
  const [dataModels, setDataModels] = useState<{ id: string; name: string }[]>(
    []
  );

  const pageVariables = getPageByKeypath(keypath)?.declaredPageVariables || [];
  console.log("pageVariables", pageVariables);
  const collectionVariables = pageVariables.filter((variable) => {
    return variable.type === "_types.ActiveCollection";
  });

  useEffect(() => {
    const config = getFragment(`${keypath}/config/value`) || {};
    console.log("config", config);
    setSelectedVariable(config.records?.config?.variableName || "");
    setColumns(config.columns || []);
  }, [getFragment, keypath]);

  useEffect(() => {
    const models = getFragment("/essentials/dataModels") || [];
    setDataModels(
      models.map((model: any) => ({ id: model.id, name: model.name }))
    );
    if (models.length > 0) {
      handleDataModelChange(models[0].id);
    }
  }, [getFragment]);

  useEffect(() => {
    if (selectedDataModel) {
      const fields =
        getFragment(`/essentials/dataModels/id:${selectedDataModel}/fields`) ||
        [];
      setModelFields(fields.map((field: any) => field.name));
      if (fields.length > 0 && columns.length === 0) {
        handleColumnChange([
          { name: fields[0].name, fieldKey: fields[0].name },
        ]);
      }
    }
  }, [selectedDataModel, getFragment]);

  useEffect(() => {
    if (collectionVariables.length > 0 && !selectedVariable) {
      handleVariableChange(collectionVariables[0].name);
    }
  }, [collectionVariables, selectedVariable]);

  const updateConfig = () => {
    const newConfig = {
      type: "_types.Dictionary",
      value: {
        dataModelId: {
          directiveType: "literalValue",
          config: { value: selectedDataModel, type: "_types.String" },
        },
        records: {
          directiveType: "getPageVariable",
          config: { variableName: selectedVariable },
        },
        columns: {
          directiveType: "literalValue",
          config: {
            type: "_types.CollectionTableColumnsConfig",
            value: columns,
          },
        },
      },
    };
    console.log("newConfig", newConfig, keypath);
    setFragment(`${keypath}/config`, newConfig);
  };

  const handleVariableChange = (variable: string) => {
    setSelectedVariable(variable);
  };

  const handleColumnChange = (newColumns: ColumnConfig[]) => {
    setColumns(newColumns);
  };

  const handleDataModelChange = (modelId: string) => {
    setSelectedDataModel(modelId);
    setColumns([]);
  };

  const addColumn = () => {
    const availableFields = modelFields.filter(
      (field) => !columns.some((col) => col.fieldKey === field)
    );
    if (availableFields.length > 0) {
      const newField = availableFields[0];
      handleColumnChange([...columns, { title: newField, fieldKey: newField }]);
    }
  };

  const removeColumn = (index: number) => {
    const newColumns = columns.filter((_, i) => i !== index);
    handleColumnChange(newColumns);
  };

  const updateColumn = (
    index: number,
    field: "name" | "fieldKey",
    newValue: string
  ) => {
    const newColumns = columns.map((column, i) =>
      i === index ? { ...column, [field]: newValue } : column
    );
    handleColumnChange(newColumns);
  };

  const handleSave = () => {
    updateConfig();
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen}>Configure Collection Table</Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configure collection table</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontWeight="bold" mb={1}>
                  Select data model
                </Text>
                <Select
                  placeholder="Select data model"
                  value={selectedDataModel}
                  onChange={(e) => handleDataModelChange(e.target.value)}
                >
                  {dataModels.map((model) => (
                    <option key={model.id} value={model.id}>
                      {model.name}
                    </option>
                  ))}
                </Select>
              </Box>

              <Box>
                <Text fontWeight="bold" mb={1}>
                  Select page variable containing collection data
                </Text>
                {collectionVariables.length > 0 ? (
                  <Select
                    placeholder="Select page variable"
                    value={selectedVariable}
                    onChange={(e) => handleVariableChange(e.target.value)}
                  >
                    {collectionVariables.map((variable) => (
                      <option key={variable.name} value={variable.name}>
                        {variable.name}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Text color="red.500">
                    No ActiveCollection type variables found on this page. Go to
                    initization logic to declare one.
                  </Text>
                )}
              </Box>

              {selectedDataModel && (
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    Configure Columns
                  </Text>
                  <Table size="sm" variant="simple">
                    <Thead>
                      <Tr>
                        <Th p={1}>Field</Th>
                        <Th p={1}>Label</Th>
                        <Th p={1} width="40px"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {columns.map((column, index) => (
                        <Tr key={index}>
                          <Td p={1}>
                            <Select
                              size="sm"
                              value={column.fieldKey}
                              onChange={(e) =>
                                updateColumn(index, "fieldKey", e.target.value)
                              }
                            >
                              {modelFields
                                .filter(
                                  (field) =>
                                    field === column.fieldKey ||
                                    !columns.some(
                                      (col) => col.fieldKey === field
                                    )
                                )
                                .map((field) => (
                                  <option key={field} value={field}>
                                    {field}
                                  </option>
                                ))}
                            </Select>
                          </Td>
                          <Td p={1}>
                            <Input
                              size="sm"
                              value={column.title}
                              onChange={(e) =>
                                updateColumn(index, "title", e.target.value)
                              }
                              placeholder="Column title"
                            />
                          </Td>
                          <Td p={1}>
                            <IconButton
                              aria-label="Remove column"
                              icon={<DeleteIcon />}
                              onClick={() => removeColumn(index)}
                              size="xs"
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <Button
                    leftIcon={<AddIcon />}
                    onClick={addColumn}
                    size="sm"
                    mt={2}
                    isDisabled={columns.length === modelFields.length}
                  >
                    Add Column
                  </Button>
                </Box>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSave}
              isDisabled={
                !selectedVariable || !selectedDataModel || columns.length === 0
              }
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CollectionTableConfigEditor;
