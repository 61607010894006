import { z } from "zod";
import { v4 as uuidv4 } from "uuid";
import { DescriptorCollection, DescriptorRecord } from "../descriptorUtils";
import { componentInstanceSchema } from "../userInterface/componentsSchema";
import { ValidatorFunction } from "@/bundles/DescriptorEditor/components/editors/InlineEditableText";
import { TypedAppDescriptor } from "../appDescriptorSchema";

// Page schema
const parametersSchema = DescriptorCollection(
  DescriptorRecord(
    z.object({
      name: z.string(),
      dataType: z.string(),
      description: z.string().optional(),
    })
  )
);

const renderActionSchema = z.object({
  type: z.enum(["renderUserInterface", "redirectToPage"]),
  redirectToPageId: z.string().optional(),
});

const pageMethodSchema = DescriptorRecord(
  z.object({
    name: z.string(),
    description: z.string().optional(),
    parameters: parametersSchema.optional(),
    flowgraph: z.any().optional(),
    renderActions: z.array(renderActionSchema).optional(),
  })
);

const containerStructureSchema: z.ZodType<ContainerStructure> = z.lazy(() =>
  z.object({
    id: z.string(),
    domId: z.string().optional(),
    isRoot: z.boolean(),
    layoutDirection: z.enum(["horizontal", "vertical"]),
    size: z.object({
      value: z.number(),
      unit: z.enum(["px", "fr", "rem"]),
    }),
    subcontainers: z.array(containerStructureSchema),
    components: z.array(componentInstanceSchema),
  })
);

const pageSchema = DescriptorRecord(
  z.object({
    name: z.string(),
    description: z.string().optional(),
    pageType: z
      .enum([
        "index",
        "show",
        "new",
        "edit",
        "create",
        "update",
        "destroy",
        "custom",
      ])
      .optional(),
    dataModelId: z.string().optional(),
    title: z.string().optional(),
    relativePath: z.string(),
    initializationLogic: z.object({
      parameters: parametersSchema.optional(),
      flowgraph: z.array(z.any()).optional(),
      renderAction: renderActionSchema.optional(), // Add this line
    }),
    pageMethods: z.array(pageMethodSchema).optional(),
    userInterface: z.object({
      disabled: z.boolean().optional(),
      viewgraph: z.object({
        containerStructure: containerStructureSchema,
      }),
    }),
  })
);

// Update the pageGroupSchema to include pages
const pageGroupSchema = DescriptorRecord(
  z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    dataModelId: z.string().optional(),
    basePath: z.string(),
    pages: z.array(pageSchema), // Add pages array
  })
);

export const pageGroupsSchema = DescriptorCollection(pageGroupSchema);

// Update types
export type Page = z.infer<typeof pageSchema> & {
  pageType?:
    | "index"
    | "show"
    | "new"
    | "edit"
    | "create"
    | "update"
    | "destroy"
    | "custom";
  initializationLogic: {
    parameters?: Parameter[];
    flowgraph?: any[];
    renderAction?: {
      type: "renderUserInterface" | "redirectToPage";
      redirectTo: {
        pageId: string;
        arguments?: Record<string, any>;
      };
    };
  };
};

export type PageGroup = z.infer<typeof pageGroupSchema>;
export type PageMethod = z.infer<typeof pageMethodSchema>;
export type RenderAction = z.infer<typeof renderActionSchema>;

export type ContainerStructure = {
  id: string;
  isRoot?: boolean;
  layoutDirection: "horizontal" | "vertical";
  size: {
    value: number;
    unit: "px" | "fr" | "rem";
  };
  components: ComponentInstance[];
  subcontainers: ContainerStructure[];
};

export type ComponentInstance = z.infer<typeof componentInstanceSchema>;

export interface EnrichedPage extends Page {
  pageGroup: Omit<PageGroup, "pages">;
  keypath: string;
  route: {
    httpMethod: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
    path: string;
  };
  computedParameters: PageParameter[];
  allParameters: PageParameter[];
  declaredPageVariables: {
    name: string;
    type: string;
  }[];
}

export const getEnrichedPageDescriptor = (
  pageId: string,
  appDescriptor: TypedAppDescriptor
): EnrichedPage | null => {
  const pageGroups = appDescriptor.essentials?.pageGroups;
  if (!pageGroups) return null;

  let page: Page | null = null;
  let parentPageGroup: PageGroup | null = null;

  for (const pageGroup of pageGroups) {
    page = pageGroup.pages?.find((p) => p.id === pageId);
    if (page) {
      parentPageGroup = pageGroup;
      break;
    }
  }

  if (!page || !parentPageGroup) return null;

  const httpMethod = (() => {
    switch (page.pageType) {
      case "new":
      case "edit":
      case "show":
      case "index":
        return "GET";
      case "create":
        return "POST";
      case "update":
        return "PUT";
      case "destroy":
        return "DELETE";
      default:
        return "GET";
    }
  })();

  const fullPath = ("/" + parentPageGroup.basePath + "/" + page.relativePath)
    .replace(/\/+/g, "/")
    .replace(/\/$/, "");

  const computedParameters = getPageParameters(page, parentPageGroup);
  const allParameters = [
    ...computedParameters,
    ...(page.initializationLogic?.parameters || []),
  ];
  const declaredPageVariables = getDeclaredPageVariables(page);

  const enrichedPage: EnrichedPage = {
    ...page,
    pageGroup: { ...parentPageGroup, pages: undefined },
    keypath: `/essentials/pageGroups/id:${parentPageGroup.id}/pages/id:${page.id}`,
    route: {
      httpMethod,
      path: fullPath,
    },
    computedParameters,
    allParameters,
    declaredPageVariables,
  };

  return enrichedPage;
};

const getPageParameters = (
  page: Page,
  pageGroup: PageGroup
): PageParameter[] => {
  const relativePath = page.relativePath || "";

  const pathParams = (relativePath.match(/:[a-zA-Z_][a-zA-Z0-9_]*/g) || []).map(
    (param) => ({
      name: param.slice(1),
      dataType: "_types.String",
      description: "Path parameter",
    })
  );

  const actionTypesRequiringId = ["show", "edit", "update", "destroy"];
  if (
    actionTypesRequiringId.includes(page.pageType) &&
    !pathParams.some((param) => param.name === "id")
  ) {
    pathParams.push({
      name: "id",
      dataType: "_types.String",
      description: "ID parameter",
    });
  }

  return pathParams;
};

const getDeclaredPageVariables = (
  page: Page
): { name: string; type: string }[] => {
  const flowgraph = page.initializationLogic?.flowgraph;
  if (!flowgraph || !Array.isArray(flowgraph)) return [];

  return flowgraph
    .filter((node) => node.directiveType === "declarePageVariable")
    .map((node) => {
      return {
        name: node.config.name,
        type: node.config.type,
      };
    });
};

export const validatePath = (path: string): boolean => {
  const pathRegex = /^\/(?!.*\/\/)(?!.*\/$)[a-zA-Z0-9\-_/:]*$/;
  return pathRegex.test(path);
};

export const validatePathWithErrors: ValidatorFunction = (path: string) => {
  const errors: string[] = [];
  if (!path.startsWith("/")) {
    errors.push("Path must start with '/'");
  }
  if (path.endsWith("/") && path !== "/") {
    errors.push("Path must not end with '/' (except for root path)");
  }
  if (path.includes("//")) {
    errors.push("Path must not contain '//'");
  }
  if (!/^[a-zA-Z0-9\-_/:]*$/.test(path)) {
    errors.push(
      "Path can only contain letters, numbers, hyphens, underscores, colons, and forward slashes"
    );
  }
  return errors;
};

export const validateUniquePathInGroup = (
  basePath: string,
  relativePaths: string[]
): boolean => {
  const fullPaths = relativePaths.map((path) => `${basePath}${path}`);
  const uniquePaths = new Set(fullPaths);
  return uniquePaths.size === fullPaths.length;
};

export const generateDefaultPageDescriptor = (options: {
  name: string;
  relativePath: string;
  pageType:
    | "index"
    | "show"
    | "new"
    | "edit"
    | "create"
    | "update"
    | "destroy"
    | "custom";
}): Page => {
  const { name, relativePath, pageType } = options;
  const defaultPage: Page = {
    id: uuidv4(),
    name,
    pageType,
    relativePath,
    initializationLogic: {
      parameters: [],
      flowgraph: [],
    },
    userInterface: {
      viewgraph: {
        containerStructure: {
          id: "root",
          isRoot: true,
          layoutDirection: "vertical",
          size: { value: 1, unit: "fr" },
          components: [
            {
              id: uuidv4(),
              blueprintName: "Layout_CenteredWithinViewport",
              propertiesBindings: {},
              reactions: {},
              layout: {},
            },
          ],
          subcontainers: [],
          isScrollable: true,
          isLayoutApplied: true,
        },
      },
    },
  };

  return defaultPage;
};

export const generateControllerName = (pageGroupName: string): string => {
  return `${pageGroupName.replace(/\s+/g, "")}Controller`;
};

export const generateDefaultPageGroupDescriptor = (
  name: string,
  dataModelId?: string
): PageGroup => {
  const basePath = `/${name.toLowerCase().replace(/\s+/g, "-")}`;
  const singularName = name.endsWith("s") ? name.slice(0, -1) : name;

  // Only create Index, Show, and New pages
  const defaultPages: Page[] = [
    generateDefaultPageDescriptor({
      name: "Index",
      relativePath: "/",
      pageType: "index",
    }),
    generateDefaultPageDescriptor({
      name: "Show",
      relativePath: "/:id",
      pageType: "show",
    }),
    generateDefaultPageDescriptor({
      name: "New",
      relativePath: "/new",
      pageType: "new",
    }),
  ];

  const defaultPageGroup: PageGroup = {
    id: uuidv4(),
    name,
    dataModelId,
    basePath,
    controllerName: generateControllerName(name),
    pages: defaultPages.map((page) => ({
      ...page,
      name: `${singularName} ${page.name}`,
      dataModelId,
    })),
  };

  return defaultPageGroup;
};

export const validateGroupPath: ValidatorFunction = (
  path: string,
  pageGroups: PageGroup[],
  currentGroupId?: string
) => {
  const errors: string[] = [];
  if (!path.startsWith("/")) {
    errors.push('Path must start with "/"');
  }
  if (path.endsWith("/") && path !== "/") {
    errors.push('Path must not end with "/" (except for root path "/")');
  }
  if (
    pageGroups.some(
      (group) => group.id !== currentGroupId && group.basePath === path
    )
  ) {
    errors.push("Path conflicts with another group's base path");
  }
  return errors;
};

export const validatePagePath: ValidatorFunction = (
  path: string,
  pageGroup: PageGroup,
  currentPageId?: string
) => {
  const errors: string[] = [];
  if (!path.startsWith("/")) {
    errors.push('Path must start with "/"');
  }
  if (path.endsWith("/") && path !== "/") {
    errors.push('Path must not end with "/" (except for root path "/")');
  }
  if (
    pageGroup.pages.some(
      (page) => page.id !== currentPageId && page.relativePath === path
    )
  ) {
    errors.push("Path conflicts with another page in this group");
  }
  return errors;
};
