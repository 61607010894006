import { z } from 'zod';
import { DescriptorCollection, DescriptorRecord } from '../descriptorUtils';
import { coreDataTypes } from '../../types/coreDataTypes';

export const parameterSchema = DescriptorRecord(z.object({
  id: z.string(),
  name: z.string(),
  dataType: z.enum(coreDataTypes as [string, ...string[]]),
  description: z.string().optional(),
}));

export const parametersSchema = DescriptorCollection(parameterSchema);

export type Parameter = z.infer<typeof parameterSchema>;
export type Parameters = z.infer<typeof parametersSchema>;