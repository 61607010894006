import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  HStack,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface CommentConfigEditorProps {
  schema: any;
  keypath: string;
}

const CommentConfigEditor: React.FC<CommentConfigEditorProps> = ({
  schema,
  keypath,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [selectedDataModel, setSelectedDataModel] = useState("");
  const [dataModels, setDataModels] = useState<{ id: string; name: string }[]>(
    []
  );

  useEffect(() => {
    const config = getFragment(`${keypath}/config/value`) || {};
    setSelectedDataModel(config.dataModelId?.config?.value || "");
  }, [getFragment, keypath]);

  useEffect(() => {
    const models = getFragment("/essentials/dataModels") || [];
    setDataModels(
      models.map((model: any) => ({ id: model.id, name: model.name }))
    );
  }, [getFragment]);

  const updateConfig = () => {
    const newConfig = {
      type: "_types.Dictionary",
      value: {
        dataModelId: {
          directiveType: "literalValue",
          config: { value: selectedDataModel, type: "_types.String" },
        },
      },
    };
    setFragment(`${keypath}/config`, newConfig);
  };

  const handleDataModelChange = (modelId: string) => {
    setSelectedDataModel(modelId);
  };

  const handleSave = () => {
    updateConfig();
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen}>Configure Comments</Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configure Comments</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontWeight="bold" mb={1}>
                  Select data model
                </Text>
                <Select
                  placeholder="Select data model"
                  value={selectedDataModel}
                  onChange={(e) => handleDataModelChange(e.target.value)}
                >
                  {dataModels.map((model) => (
                    <option key={model.id} value={model.id}>
                      {model.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSave}
              isDisabled={!selectedDataModel}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CommentConfigEditor;
