import React, { useState } from "react";
import {
  Box,
  Input,
  IconButton,
  VStack,
  HStack,
  Text,
  Button,
  Select,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

interface JsonEditorProps {
  value: any;
  onChange: (value: any) => void;
}

const JsonEditor: React.FC<JsonEditorProps> = ({ value, onChange }) => {
  const [error, setError] = useState<string | null>(null);

  const handleKeyValueChange = (
    key: string,
    newValue: any,
    oldKey?: string
  ) => {
    const newObj = { ...value };
    if (oldKey && oldKey !== key) {
      delete newObj[oldKey];
    }
    newObj[key] = newValue;
    onChange(newObj);
    setError(null);
  };

  const handleAddField = () => {
    onChange({ ...value, "": "" });
  };

  const handleRemoveField = (key: string) => {
    const newObj = { ...value };
    delete newObj[key];
    onChange(newObj);
  };

  const renderValueInput = (key: string, val: any) => {
    const type = typeof val;

    if (Array.isArray(val)) {
      return (
        <Select
          value={type}
          onChange={(e) => {
            handleKeyValueChange(key, [], key);
          }}
        >
          <option value="array">Array</option>
        </Select>
      );
    }

    switch (type) {
      case "string":
        return (
          <Input
            value={val}
            onChange={(e) => handleKeyValueChange(key, e.target.value, key)}
            placeholder="Value"
          />
        );
      case "number":
        return (
          <Input
            type="number"
            value={val}
            onChange={(e) =>
              handleKeyValueChange(key, Number(e.target.value), key)
            }
            placeholder="Number"
          />
        );
      case "boolean":
        return (
          <Select
            value={val.toString()}
            onChange={(e) =>
              handleKeyValueChange(key, e.target.value === "true", key)
            }
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </Select>
        );
      case "object":
        if (val === null) {
          return (
            <Select
              value=""
              onChange={(e) => {
                const newValue =
                  e.target.value === "string"
                    ? ""
                    : e.target.value === "number"
                    ? 0
                    : e.target.value === "boolean"
                    ? false
                    : e.target.value === "array"
                    ? []
                    : {};
                handleKeyValueChange(key, newValue, key);
              }}
            >
              <option value="">Select type</option>
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="boolean">Boolean</option>
              <option value="array">Array</option>
              <option value="object">Object</option>
            </Select>
          );
        }
        return (
          <Box pl={4} pt={2} pb={2} borderLeft="2px" borderColor="gray.200">
            <JsonEditor
              value={val}
              onChange={(newValue) => handleKeyValueChange(key, newValue, key)}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <VStack align="stretch" spacing={2}>
      {Object.entries(value).map(([key, val]) => (
        <HStack key={key} spacing={2}>
          <Input
            value={key}
            onChange={(e) => handleKeyValueChange(e.target.value, val, key)}
            placeholder="Key"
            width="40%"
          />
          <Box flex={1}>{renderValueInput(key, val)}</Box>
          <IconButton
            aria-label="Remove field"
            icon={<DeleteIcon />}
            size="sm"
            onClick={() => handleRemoveField(key)}
          />
        </HStack>
      ))}
      <Button
        leftIcon={<AddIcon />}
        size="sm"
        onClick={handleAddField}
        variant="outline"
      >
        Add Field
      </Button>
      {error && (
        <Text color="red.500" fontSize="sm">
          {error}
        </Text>
      )}
    </VStack>
  );
};

export default JsonEditor;
