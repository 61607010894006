import type { DirectiveType } from "./directiveTypes";
import type { DataType } from "../types";

import { declareVariable, type DeclareVariableConfig } from "./declareVariable";
import { getVariable, type GetVariableConfig } from "./getVariable";
import { setVariable, type SetVariableConfig } from "./setVariable";
import { literalValue, type LiteralValueConfig } from "./literalValue";
import { loopForEach, type LoopForEachConfig } from "./loopForEach";
import { nativeCode, type NativeCodeConfig } from "./nativeCode";
import { callFunction, type CallFunctionConfig } from "./callFunction";
import {
  executeAllInParallel,
  type ExecuteAllInParallelConfig,
} from "./executeAllInParallel";
import {
  executeAnyInParallel,
  type ExecuteAnyInParallelConfig,
} from "./executeAnyInParallel";
import {
  executeSomeInParallel,
  type ExecuteSomeInParallelConfig,
} from "./executeSomeInParallel";
import { executeBranches, type ExecuteBranchesConfig } from "./executeBranches";
import { overRangeLoop, type OverRangeLoopConfig } from "./overrangeLoop";
import { whileLoop, type WhileLoopConfig } from "./whileLoop";
import { getArgument, type GetArgumentConfig } from "./getArgument";
import {
  getIteratorVariable,
  type GetIteratorVariableConfig,
} from "./getIteratorVariable";
import { returnDirective, type ReturnConfig } from "./return";
import {
  renderStringTemplate,
  type RenderStringTemplateConfig,
} from "./renderStringTemplate";
import { throwError, type ThrowErrorConfig } from "./throwError";
import { waitForDuration, type WaitForDurationConfig } from "./waitForDuration";
import { executeModelMethod, type ExecuteModelMethodConfig } from "./executeModelMethod";
import { declarePageVariable, type DeclarePageVariableConfig } from "./declarePageVariable";
import { setPageVariable, type SetPageVariableConfig } from "./setPageVariable";
import { getPageVariable, type GetPageVariableConfig } from "./getPageVariable";

export const directives = {
  loopForEach,
  whileLoop,
  overRangeLoop,
  declareVariable,
  declarePageVariable,
  executeAllInParallel,
  executeAnyInParallel,
  executeSomeInParallel,
  executeBranches,
  getVariable,
  setVariable,
  setPageVariable,
  literalValue,
  callFunction,
  getArgument,
  getIteratorVariable,
  nativeCode,
  return: returnDirective,
  renderStringTemplate,
  throwError,
  waitForDuration,
  executeModelMethod,
  getPageVariable,
};

type DirectiveConfigMap = {
  declareVariable: DeclareVariableConfig;
  declarePageVariable: DeclarePageVariableConfig;
  executeAllInParallel: ExecuteAllInParallelConfig;
  executeAnyInParallel: ExecuteAnyInParallelConfig;
  executeSomeInParallel: ExecuteSomeInParallelConfig;
  executeBranches: ExecuteBranchesConfig;
  getIteratorVariable: GetIteratorVariableConfig;
  getVariable: GetVariableConfig;
  setVariable: SetVariableConfig;
  setPageVariable: SetPageVariableConfig;
  literalValue: LiteralValueConfig;
  loopForEach: LoopForEachConfig;
  whileLoop: WhileLoopConfig;
  overRangeLoop: OverRangeLoopConfig;
  callFunction: CallFunctionConfig;
  getArgument: GetArgumentConfig;
  nativeCode: NativeCodeConfig;
  return: ReturnConfig;
  renderStringTemplate: RenderStringTemplateConfig;
  throwError: ThrowErrorConfig;
  waitForDuration: WaitForDurationConfig;
  executeModelMethod: ExecuteModelMethodConfig;
  getPageVariable: GetPageVariableConfig;
};

export const executeDirectiveFunctionForDirectiveType = <
  T extends DirectiveType,
>(
  directiveType: T,
) => {
  console.log(`Executing directive type: ${directiveType}`);
  const directive = directives[directiveType];
  if (!directive) {
    throw new Error(`Directive type '${directiveType}' is not implemented`);
  }
  if (!directive.execute) {
    throw new Error(
      `Directive '${directiveType}' does not have an 'execute' method`,
    );
  }
  return directive.execute as (
    config: DirectiveConfigMap[T],
    context: unknown,
  ) => Promise<unknown>;
};

export const availableDirectiveTypes = Object.keys(directives);

export const availableActionsForDirectiveType = (
  directiveType: DirectiveType,
) => {
  return directives[directiveType].availableActions;
};

export const defaultConfigForDirectiveType = (directiveType: DirectiveType) => {
  if (directiveType === "declarePageVariable") {
    return {
      name: "",
      type: "_types.String",
      initialValue: {
        directiveType: "literalValue",
        config: { type: "_types.String", value: "" }
      },
    };
  }
  if (directiveType === "setPageVariable") {
    return {
      name: "",
      value: {
        directiveType: "literalValue",
        config: { type: "_types.String", value: "" }
      },
    };
  }
  if (directiveType === "getPageVariable") {
    return {
      variableName: "",
    };
  }
  return directives[directiveType].defaultConfig();
};

export function getDefaultValueForType(
  type: LiteralValueConfig["type"],
): string | number | boolean | Record<string, unknown> | unknown[] {
  if (type === "_types.String") {
    return "";
  }
  if (type === "_types.Number") {
    return 0;
  }
  if (type === "_types.Boolean") {
    return false;
  }
  if (type === "_types.Dictionary") {
    return {};
  }
  if (type === "_types.List") {
    return [];
  }
  throw new Error(`Invalid type ${type} for LiteralValueDirective`);
}

export const getDefaultDirectiveForType = (dataType: DataType) => {
  return {
    directiveType: "literalValue",
    config: { type: dataType, value: getDefaultValueForType(dataType) },
  };
};

export const searchFunctionForDirectiveType = (
  directiveType: DirectiveType,
) => {
  const directive = directives[directiveType];
  if ("searchDirective" in directive) {
    return directive.searchDirective;
  }
  return undefined;
};