import React from "react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  Tooltip,
  VStack,
  Switch,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  List,
  ListItem,
  Select,
} from "@chakra-ui/react";
import {
  IconMinus,
  IconTrash,
  IconCube,
  IconChevronUp,
  IconChevronDown,
  IconList,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { ConditionalNode } from "../types";
import { useEffect, useState } from "react";
import { useAppDescriptorStore } from "../../DescriptorEditor/stores/appDescriptorStore";

interface VariableNodeProps {
  node: ConditionalNode;
  onDeleteNode: (nodeId: string) => void;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  onUpdateNode: (nodeId: string, updates: Partial<ConditionalNode>) => void;
  availableVariables?: string[];
  availableDataVariables: string[];
}

export function VariableNode({
  node,
  onDeleteNode,
  onReorder,
  isFirst,
  isLast,
  onUpdateNode,
  availableVariables = [],
  availableDataVariables,
}: VariableNodeProps) {
  const [isSetMode, setIsSetMode] = useState(node.parameters?.mode !== "Get");
  const [isDataVariable, setIsDataVariable] = useState(
    node.parameters?.isDataVariable || false
  );
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const iconColor = "rgb(138, 180, 248)";

  // Get available data models from the app descriptor store
  const { getFragment } = useAppDescriptorStore();
  const dataModels =
    (getFragment("/essentials/dataModels") as { name: string }[]) || [];
  const availableDataModels = dataModels.map((model) => model.name);

  useEffect(() => {
    setIsSetMode(node.parameters?.mode !== "Get");
  }, [node.parameters?.mode]);

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode = event.target.checked ? "Set" : "Get";
    setIsSetMode(event.target.checked);
    onUpdateNode(node.id, {
      parameters: { ...node.parameters, mode: newMode },
    });
  };

  const handleInputChange = (field: string, value: string) => {
    onUpdateNode(node.id, {
      parameters: { ...node.parameters, [field]: value },
    });
  };

  const handleVariableSelect = (variableName: string) => {
    handleInputChange("id", variableName);
    setIsVariableModalOpen(false);
  };

  const handleDataVariableChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isData = event.target.checked;
    setIsDataVariable(isData);
    onUpdateNode(node.id, {
      parameters: {
        ...node.parameters,
        isDataVariable: isData,
        value: isData ? "" : node.parameters?.value,
      },
    });
  };

  return (
    <Box
      borderWidth={1}
      borderColor={iconColor}
      borderRadius="md"
      bg="#202124"
      width="350px"
      overflow="hidden"
    >
      <HStack p={3} bg={`${iconColor}20`} justifyContent="space-between">
        <HStack>
          <IconButton
            icon={<IconMinus size={16} />}
            aria-label="Collapse"
            size="sm"
            variant="ghost"
            color={colors.grayDark.gray12}
          />
          <Text fontWeight="medium" color="white">
            Variable {isSetMode ? "Set" : "Get"}
          </Text>
        </HStack>
        <HStack>
          {!isFirst && (
            <Tooltip label="Move Up" aria-label="Move Up Tooltip">
              <IconButton
                icon={<IconChevronUp size={16} />}
                aria-label="Move Up"
                size="sm"
                variant="ghost"
                color={colors.grayDark.gray12}
                onClick={() => onReorder("up")}
              />
            </Tooltip>
          )}
          {!isLast && (
            <Tooltip label="Move Down" aria-label="Move Down Tooltip">
              <IconButton
                icon={<IconChevronDown size={16} />}
                aria-label="Move Down"
                size="sm"
                variant="ghost"
                color={colors.grayDark.gray12}
                onClick={() => onReorder("down")}
              />
            </Tooltip>
          )}
          <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
            <IconButton
              icon={<IconTrash size={16} />}
              aria-label="Delete Node"
              size="sm"
              variant="ghost"
              color={colors.red.red6}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteNode(node.id);
              }}
              _hover={{ bg: colors.red.red3 }}
            />
          </Tooltip>
        </HStack>
      </HStack>

      <VStack align="stretch" p={3} bg="#303134" spacing={3}>
        <HStack>
          <IconCube size={16} color={iconColor} />
          <Text color="white" fontWeight="medium">
            Mode:
          </Text>
          <Switch
            isChecked={isSetMode}
            onChange={handleModeChange}
            colorScheme="blue"
          />
          <Text color="white" fontSize="sm">
            {isSetMode ? "Set" : "Get"}
          </Text>
        </HStack>

        <FormControl>
          <FormLabel color={colors.grayDark.gray11}>Variable ID</FormLabel>
          <HStack>
            <Input
              value={node.parameters?.id || ""}
              onChange={(e) => handleInputChange("id", e.target.value)}
              placeholder="Enter variable ID"
              size="sm"
              bg={colors.grayDark.gray3}
              color={colors.grayDark.gray12}
            />
            {availableVariables.length > 0 && (
              <IconButton
                icon={<IconList size={16} />}
                aria-label="Select Variable"
                size="sm"
                onClick={() => setIsVariableModalOpen(true)}
                bg={colors.grayDark.gray3}
                color={colors.grayDark.gray12}
                _hover={{ bg: colors.grayDark.gray4 }}
              />
            )}
          </HStack>
        </FormControl>

        {isSetMode && (
          <>
            <HStack>
              <Text color="white" fontWeight="medium">
                Data Variable:
              </Text>
              <Switch
                isChecked={isDataVariable}
                onChange={handleDataVariableChange}
                colorScheme="blue"
              />
            </HStack>

            <FormControl>
              <FormLabel color={colors.grayDark.gray11}>
                {isDataVariable ? "Data Model" : "Value"}
              </FormLabel>
              {isDataVariable ? (
                <Select
                  value={node.parameters?.value || ""}
                  onChange={(e) => handleInputChange("value", e.target.value)}
                  placeholder="Select Data Model"
                  size="sm"
                  bg={colors.grayDark.gray3}
                  color={colors.grayDark.gray12}
                >
                  {availableDataModels.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </Select>
              ) : (
                <Input
                  value={node.parameters?.value || ""}
                  onChange={(e) => handleInputChange("value", e.target.value)}
                  placeholder="Enter variable value"
                  size="sm"
                  bg={colors.grayDark.gray3}
                  color={colors.grayDark.gray12}
                />
              )}
            </FormControl>
          </>
        )}

        <Modal
          isOpen={isVariableModalOpen}
          onClose={() => setIsVariableModalOpen(false)}
          size="sm"
        >
          <ModalOverlay />
          <ModalContent bg="#202124" color="white">
            <ModalHeader>Select Variable</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <List spacing={2}>
                {availableVariables.map((variable) => (
                  <ListItem
                    key={variable}
                    p={2}
                    cursor="pointer"
                    _hover={{ bg: "#303134" }}
                    borderRadius="md"
                    onClick={() => handleVariableSelect(variable)}
                  >
                    {variable}
                  </ListItem>
                ))}
              </List>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
    </Box>
  );
}
