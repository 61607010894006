import React, { useState } from "react";
import {
  Box,
  Button,
  SimpleGrid,
  Text,
  Image,
  VStack,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { FiEdit, FiTrash2, FiPlus } from "react-icons/fi";
import { FaFileImage, FaFilePdf, FaFile } from "react-icons/fa6";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import NewApplicationAssetModal from "./NewApplicationAssetModal";
import EditApplicationAssetModal from "./EditApplicationAssetModal";
import { ApplicationAsset } from "@/bundles/DescriptorEditor/schemas/userInterface/applicationAssetsSchema";

const getFileNameWithoutExtension = (fileName: string) => {
  return fileName.replace(/\.[^/.]+$/, "");
};

const getFileIcon = (fileName: string) => {
  const extension = fileName.split(".").pop()?.toLowerCase();
  if (extension === "png" || extension === "jpg" || extension === "jpeg") {
    return <FaFileImage />;
  } else if (extension === "pdf") {
    return <FaFilePdf />;
  } else {
    return <FaFile />;
  }
};

const ApplicationAssetsIndex: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [isNewAssetModalOpen, setIsNewAssetModalOpen] = useState(false);
  const [editingAsset, setEditingAsset] = useState<ApplicationAsset | null>(
    null
  );

  const assets = getFragment("/userInterface/applicationAssets") || [];

  const handleDeleteAsset = (assetId: string) => {
    setFragment(`/userInterface/applicationAssets/${assetId}`, undefined);
  };

  const handleAddAsset = (newAsset: ApplicationAsset) => {
    setFragment(`/userInterface/applicationAssets/${newAsset.id}`, newAsset);
    setIsNewAssetModalOpen(false);
  };

  return (
    <Box>
      <Button
        leftIcon={<FiPlus />}
        onClick={() => setIsNewAssetModalOpen(true)}
        mb={4}
      >
        Add New Image Asset
      </Button>
      {Array.isArray(assets) && assets.length > 0 ? (
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={2}>
          {assets
            .filter((asset): asset is ApplicationAsset => asset != null)
            .map((asset: ApplicationAsset) => (
              <Box
                key={asset.id}
                borderWidth={1}
                borderRadius="lg"
                p={4}
                width="75%"
              >
                <VStack spacing={2} align="stretch">
                  <Image
                    src={asset.fileUrl}
                    alt={asset.name}
                    objectFit="cover"
                    height="200px"
                    width="100%"
                  />
                  <HStack justifyContent="space-between" width="100%">
                    <Text fontWeight="bold">
                      {getFileNameWithoutExtension(asset.name)}
                    </Text>
                    {getFileIcon(asset.name)}
                  </HStack>
                  <Text fontSize="sm" fontWeight="bold" color="gray.500">
                    ID: {asset.id}
                  </Text>
                  <Text fontSize="sm">
                    {(asset.size / 1024 / 1024).toFixed(2)} MB
                  </Text>
                  <Box>
                    <IconButton
                      icon={<FiEdit />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => setEditingAsset(asset)}
                    />
                    <IconButton
                      icon={<FiTrash2 />}
                      aria-label="Delete"
                      onClick={() => handleDeleteAsset(asset.id)}
                      size="sm"
                    />
                  </Box>
                </VStack>
              </Box>
            ))}
        </SimpleGrid>
      ) : (
        <Text>
          No image assets found. Click "Add New Image Asset" to upload one.
        </Text>
      )}
      <NewApplicationAssetModal
        isOpen={isNewAssetModalOpen}
        onClose={() => setIsNewAssetModalOpen(false)}
        onSubmit={handleAddAsset}
      />
      {editingAsset && (
        <EditApplicationAssetModal
          isOpen={!!editingAsset}
          onClose={() => setEditingAsset(null)}
          asset={editingAsset}
        />
      )}
    </Box>
  );
};

export default ApplicationAssetsIndex;
