import React from "react";
import { Select, Text, VStack } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { PropertyEditorProps } from "../PropertyEditor";

const ActiveRecordEditor: React.FC<PropertyEditorProps> = ({
  schema,
  keypath,
}) => {
  const { getFragment, setFragment, getPageByKeypath } =
    useAppDescriptorStore();
  const currentValue = getFragment(keypath);
  const page = getPageByKeypath(keypath);

  const flowgraphSequence =
    page?.initializationLogic?.flowgraph?.sequence || [];
  const availableVariables = flowgraphSequence
    .filter(
      (node: any) => node.name === "Variable" && node.parameters?.mode === "Set"
    )
    .map((node: any) => node.parameters?.id)
    .filter(Boolean);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setFragment(keypath, {
      directiveType: "getPageVariable",
      config: {
        type: "_types.ActiveRecord",
        variableName: newValue,
      },
    });
  };

  if (availableVariables.length === 0) {
    return (
      <VStack align="stretch" spacing={2}>
        <Select isDisabled placeholder="No variables available" />
        <Text fontSize="xs" color="gray.500">
          Declare variables in the page's initialization logic
        </Text>
      </VStack>
    );
  }

  return (
    <Select
      value={currentValue?.config?.variableName || ""}
      onChange={handleChange}
      placeholder="Select a page variable"
    >
      {availableVariables.map((variableName: string) => (
        <option key={variableName} value={variableName}>
          {variableName}
        </option>
      ))}
    </Select>
  );
};

export default ActiveRecordEditor;
