import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Button,
  Center,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { IconCheck, IconX, IconTrash } from "@tabler/icons-react";
import { motion, AnimatePresence } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { ConditionalStatement } from "./ConditionalStatement";
import { SelectedField, ConditionalNode } from "../types";
import { SelectBindingModal } from "./SelectBindingModal";
import { FormulaFunctionNode } from "./FormulaFunctionNode";
import { StaticValueNode } from "./StaticValueNode";
import { PropertiesPanel } from "./PropertiesPanel";
import { ServiceCallNode } from "./ServiceCallNode";
import { ModelMethodNode } from "./ModelMethodNode";
import { VariableNode } from "./VariableNode";
import { ConstantNode } from "./ConstantNode";
import { useFlowgraphStore } from "../stores/FlowgraphStore";
import { generateFlowgraph } from "../utils/generateFlowgraph";
import { useAppDescriptorStore } from "../../DescriptorEditor/stores/appDescriptorStore";
import { FlowgraphContext } from "@/bundles/DescriptorEditor/components/editors/FlowgraphEditor/types/FlowgraphContext";
import { EventNode } from "./EventNode";
import ErrorBoundary from "@/bundles/DescriptorEditor/components/editors/ViewgraphEditor/ErrorBoundry";

const MotionBox = motion.create(Box);

interface FlattenedNode extends ConditionalNode {
  parentId?: string | null;
  branch?: "yes" | "no" | null;
}

const updateNode = (
  nodes: ConditionalNode[],
  updatedNode: ConditionalNode
): ConditionalNode[] => {
  return nodes.map((node) => {
    if (node.id === updatedNode.id) {
      return updatedNode;
    }
    return {
      ...node,
      childrenYes: updateNode(node.childrenYes, updatedNode),
      childrenNo: updateNode(node.childrenNo, updatedNode),
    };
  });
};

interface FlowgraphEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
  initialFlowgraph?: {
    sequence: any[];
    $variables: any[];
  };
  onFlowgraphChange?: (flowgraph: {
    sequence: any[];
    $variables: any[];
  }) => void;
}

export function FlowgraphEditor({
  keypath,
  flowgraphContext,
  initialFlowgraph,
  onFlowgraphChange,
}: FlowgraphEditorProps) {
  const [selectedField, setSelectedField] = useState<SelectedField | null>(
    null
  );
  const [conditionalNodes, setConditionalNodes] = useState<ConditionalNode[]>(
    []
  );
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [nodeToDelete, setNodeToDelete] = useState<string | null>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [isSelectBindingModalOpen, setIsSelectBindingModalOpen] =
    useState(false);

  const { $flowgraph, setValue } = useFlowgraphStore();
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const { getFragment, setFragment } = useAppDescriptorStore();
  const dataVariables = getFragment("/essentials/dataVariables") || [];
  const availableDataVariables = dataVariables.map((v) => v.name);

  const hasInitialized = useRef(false);

  const [variables, setVariables] = useState<any[]>(
    initialFlowgraph?.$variables || []
  );

  useEffect(() => {
    if (hasInitialized.current) return;

    const loadFlowgraph = () => {
      try {
        if (initialFlowgraph) {
          console.log("Using provided flowgraph:", initialFlowgraph);
          const updatedSequence = initialFlowgraph.sequence.map(
            (node: ConditionalNode) => ({
              ...node,
              comparisonOperator: node.comparisonOperator || "Greater than",
            })
          );

          setValue("$flowgraph", {
            sequence: updatedSequence,
            $variables: initialFlowgraph.$variables || [],
          });
          setConditionalNodes(updatedSequence);
          setVariables(initialFlowgraph.$variables || []);
        } else if (keypath) {
          const storedFlowgraph = getFragment(keypath);
          if (storedFlowgraph && !Array.isArray(storedFlowgraph)) {
            const updatedSequence =
              storedFlowgraph.sequence?.map((node: ConditionalNode) => ({
                ...node,
                comparisonOperator: node.comparisonOperator || "Greater than",
              })) || [];

            setValue("$flowgraph", {
              sequence: updatedSequence,
              $variables: storedFlowgraph.$variables || [],
            });
            setConditionalNodes(updatedSequence);
            setVariables(storedFlowgraph.$variables || []);
            console.log("Flowgraph loaded from database:", storedFlowgraph);
          } else {
            console.log(
              "No stored flowgraph found. Initializing empty flowgraph."
            );
            initializeEmptyFlowgraph();
          }
        }
      } catch (error) {
        console.error("Failed to load flowgraph:", error);
        toast({
          title: "Error Loading Flowgraph",
          description:
            "Failed to load the flowgraph. Initializing with an empty flowgraph.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        initializeEmptyFlowgraph();
      } finally {
        setIsInitialized(true);
      }
    };

    const initializeEmptyFlowgraph = () => {
      const emptyFlowgraph = { sequence: [], $variables: [] };
      setValue("$flowgraph", emptyFlowgraph);
      setConditionalNodes([]);
      try {
        setFragment(`${keypath}`, emptyFlowgraph);
        console.log("Empty flowgraph initialized and saved to database");
      } catch (error) {
        console.error("Failed to save empty flowgraph:", error);
      }
    };

    loadFlowgraph();
    hasInitialized.current = true;
  }, [keypath, setValue, toast, getFragment, setFragment, initialFlowgraph]);

  const saveFlowgraph = useCallback(() => {
    try {
      const currentFlowgraph = {
        sequence: conditionalNodes,
        $variables: variables,
      };

      console.log("FlowgraphEditor saving:", currentFlowgraph);

      setValue("$flowgraph", currentFlowgraph);

      if (keypath) {
        setFragment(keypath, {
          sequence: conditionalNodes,
          $variables: variables,
        });
      }

      if (onFlowgraphChange) {
        onFlowgraphChange(currentFlowgraph);
      }
    } catch (error) {
      console.error("Failed to save flowgraph:", error);
      toast({
        title: "Error Saving Flowgraph",
        description: "Failed to save the flowgraph changes.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [
    conditionalNodes,
    variables,
    onFlowgraphChange,
    setValue,
    setFragment,
    keypath,
    toast,
  ]);

  useEffect(() => {
    if (!isInitialized) return;

    const debounceTimer = setTimeout(saveFlowgraph, 1000);
    return () => clearTimeout(debounceTimer);
  }, [conditionalNodes, variables, isInitialized, saveFlowgraph]);

  const handleFieldUpdate = (
    nodeId: string,
    field: "Number1" | "Number2" | "name" | "parameters",
    value: string | Record<string, any>
  ) => {
    setConditionalNodes((prevNodes) =>
      updateNode(prevNodes, {
        ...findNodeById(nodeId)!,
        [field]: value,
      })
    );
  };

  const updateNodeField = (
    nodes: ConditionalNode[],
    nodeId: string,
    field: "Number1" | "Number2" | "name" | "comparisonOperator" | "id",
    value: string
  ): ConditionalNode[] => {
    return nodes.map((node) => {
      if (node.id === nodeId) {
        if (field === "id") {
          return {
            ...node,
            parameters: { ...node.parameters, id: value },
          };
        }
        return {
          ...node,
          [field === "Number1"
            ? "number1"
            : field === "Number2"
            ? "number2"
            : field === "name"
            ? "name"
            : "comparisonOperator"]: value,
        };
      }
      return {
        ...node,
        childrenYes: updateNodeField(node.childrenYes, nodeId, field, value),
        childrenNo: updateNodeField(node.childrenNo, nodeId, field, value),
      };
    });
  };

  const addNode = (
    parentId: string | null = null,
    branch: "yes" | "no" | null = null,
    nodeType: string,
    index: number = 0
  ) => {
    const newNode: ConditionalNode = {
      id: uuidv4(),
      parentId,
      branch,
      childrenYes: [],
      childrenNo: [],
      name: nodeType,
      number1: null,
      number2: null,
      functionCall: null,
      comparisonOperator: "Greater than",
      parameters: {},
    };

    if (nodeType === "Model Method") {
      newNode.parameters = { modelName: "" };
    }

    if (nodeType === "Static Value") {
      newNode.number1 = "123";
    } else if (nodeType === "Variable") {
      newNode.parameters = {
        mode: "Set",
        id: "",
        value: "",
        isDataVariable: false,
      };
    } else if (nodeType === "Constant") {
      newNode.parameters = { id: "" };
    } else if (nodeType === "Event") {
      newNode.parameters = {
        eventType: "NavigateToPage",
        page: "",
        param: "",
      };
    }

    let updatedNodes;
    if (parentId === null) {
      updatedNodes = [...conditionalNodes, newNode];
    } else {
      updatedNodes = updateNodeWithNewChild(
        conditionalNodes,
        parentId,
        newNode,
        branch,
        index
      );
    }
    setConditionalNodes(updatedNodes);
  };

  const updateNodeWithNewChild = (
    nodes: ConditionalNode[],
    parentId: string,
    newNode: ConditionalNode,
    branch: "yes" | "no" | null,
    index: number
  ): ConditionalNode[] => {
    return nodes.map((node) => {
      if (node.id === parentId) {
        return {
          ...node,
          childrenYes:
            branch === "yes"
              ? [
                  ...node.childrenYes.slice(0, index),
                  newNode,
                  ...node.childrenYes.slice(index),
                ]
              : node.childrenYes,
          childrenNo:
            branch === "no"
              ? [
                  ...node.childrenNo.slice(0, index),
                  newNode,
                  ...node.childrenNo.slice(index),
                ]
              : node.childrenNo,
        };
      }
      return {
        ...node,
        childrenYes: updateNodeWithNewChild(
          node.childrenYes,
          parentId,
          newNode,
          branch,
          index
        ),
        childrenNo: updateNodeWithNewChild(
          node.childrenNo,
          parentId,
          newNode,
          branch,
          index
        ),
      };
    });
  };

  const findNodeById = (nodeId: string): ConditionalNode | null => {
    return findNodeByIdHelper(conditionalNodes, nodeId);
  };

  const findNodeByIdHelper = (
    nodes: ConditionalNode[],
    nodeId: string
  ): ConditionalNode | null => {
    for (const node of nodes) {
      if (node.id === nodeId) return node;
      const foundInYes = findNodeByIdHelper(node.childrenYes, nodeId);
      if (foundInYes) return foundInYes;
      const foundInNo = findNodeByIdHelper(node.childrenNo, nodeId);
      if (foundInNo) return foundInNo;
    }
    return null;
  };

  const handleClosePropertiesPanel = () => {
    setSelectedField(null);
  };

  const confirmDeleteNode = (nodeId: string) => {
    setNodeToDelete(nodeId);
    setIsAlertOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (nodeToDelete) {
      const updatedNodes = deleteNode(conditionalNodes, nodeToDelete);
      setConditionalNodes(updatedNodes);
      setNodeToDelete(null);
    }
    setIsAlertOpen(false);
  };

  const deleteNode = (
    nodes: ConditionalNode[],
    nodeId: string
  ): ConditionalNode[] => {
    return nodes.reduce((acc: ConditionalNode[], node) => {
      if (node.id === nodeId) {
        return acc;
      }

      const updatedNode = {
        ...node,
        childrenYes: deleteNode(node.childrenYes, nodeId),
        childrenNo: deleteNode(node.childrenNo, nodeId),
      };

      return [...acc, updatedNode];
    }, []);
  };

  const handleSelectField = (
    nodeId: string,
    field: "Number1" | "Number2" | "name"
  ) => {
    setSelectedField({ nodeId, field });
  };

  const handleComparisonOperatorUpdate = useCallback(
    (nodeId: string, operator: string) => {
      setConditionalNodes((prevNodes) =>
        updateNodeField(prevNodes, nodeId, "comparisonOperator", operator)
      );
    },
    []
  );

  const reorderNode = (nodeId: string, direction: "up" | "down") => {
    setConditionalNodes((prevNodes) => {
      const flattenedNodes = flattenNodes(prevNodes);
      const currentIndex = flattenedNodes.findIndex(
        (node) => node.id === nodeId
      );

      if (currentIndex === -1) return prevNodes;

      const newIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;

      if (newIndex < 0 || newIndex >= flattenedNodes.length) return prevNodes;

      const newFlattenedNodes = [...flattenedNodes];
      [newFlattenedNodes[currentIndex], newFlattenedNodes[newIndex]] = [
        newFlattenedNodes[newIndex],
        newFlattenedNodes[currentIndex],
      ];

      return rebuildTree(newFlattenedNodes);
    });
  };

  const flattenNodes = (nodes: ConditionalNode[]): FlattenedNode[] => {
    if (!nodes || !Array.isArray(nodes)) {
      return [];
    }

    return nodes.reduce<FlattenedNode[]>((acc, node) => {
      const flatNode: FlattenedNode = {
        ...node,
        parentId: null,
        branch: null,
      };
      acc.push(flatNode);

      if (node.childrenYes && Array.isArray(node.childrenYes)) {
        const flattenedYes = node.childrenYes.map((child) => ({
          ...child,
          parentId: node.id,
          branch: "yes" as const,
        }));
        acc.push(...flattenNodes(flattenedYes));
      }

      if (node.childrenNo && Array.isArray(node.childrenNo)) {
        const flattenedNo = node.childrenNo.map((child) => ({
          ...child,
          parentId: node.id,
          branch: "no" as const,
        }));
        acc.push(...flattenNodes(flattenedNo));
      }

      return acc;
    }, []);
  };

  const rebuildTree = (flatNodes: ConditionalNode[]): ConditionalNode[] => {
    const nodeMap = new Map<string, ConditionalNode>();
    flatNodes.forEach((node) =>
      nodeMap.set(node.id, { ...node, childrenYes: [], childrenNo: [] })
    );

    const rootNodes: ConditionalNode[] = [];

    nodeMap.forEach((node) => {
      if (node.parentId === null) {
        rootNodes.push(node);
      } else {
        const parent = nodeMap.get(node.parentId);
        if (parent) {
          if (node.branch === "yes") {
            parent.childrenYes.push(node);
          } else if (node.branch === "no") {
            parent.childrenNo.push(node);
          }
        }
      }
    });

    return rootNodes;
  };

  const getAvailableVariables = (nodeId: string): string[] => {
    if (!conditionalNodes || !Array.isArray(conditionalNodes)) {
      return [];
    }

    const flattened = flattenNodes(conditionalNodes);
    if (!flattened || !Array.isArray(flattened)) {
      return [];
    }

    const currentNodeIndex = flattened.findIndex((node) => node.id === nodeId);
    if (currentNodeIndex === -1) {
      return [];
    }

    const nodesBeforeCurrent = flattened.slice(0, currentNodeIndex);

    return nodesBeforeCurrent
      .filter(
        (
          node
        ): node is FlattenedNode & {
          parameters: { mode: string; id: string };
        } =>
          node.name === "Variable" &&
          !!node.parameters?.mode &&
          node.parameters.mode === "Set" &&
          !!node.parameters?.id
      )
      .map((node) => node.parameters.id);
  };

  const renderNodes = (
    nodes: ConditionalNode[],
    parentId: string | null = null,
    branch: "yes" | "no" | null = null
  ) => {
    if (!nodes || !Array.isArray(nodes)) {
      console.warn("Received invalid nodes:", nodes);
      return null;
    }

    return nodes.map((node, index) => (
      <MotionBox
        key={node.id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
        mb={4}
      >
        {node.name === "Conditional Statement" && (
          <ConditionalStatement
            id={node.id}
            name={node.name}
            onSelectField={handleSelectField}
            number1={node.number1}
            number2={node.number2}
            functionCall={node.functionCall}
            onAddSubNode={(branchType, nodeType) =>
              addNode(node.id, branchType, nodeType, 0)
            }
            onDeleteNode={confirmDeleteNode}
            isSelected={selectedField?.nodeId === node.id}
            selectedField={selectedField}
            handleFieldUpdate={handleFieldUpdate}
            findNodeById={findNodeById}
            handleClosePropertiesPanel={handleClosePropertiesPanel}
            comparisonOperator={node.comparisonOperator || ""}
            handleComparisonOperatorUpdate={handleComparisonOperatorUpdate}
            onReorder={(direction) => reorderNode(node.id, direction)}
            isFirst={index === 0}
            isLast={index === nodes.length - 1}
            availableVariables={getAvailableVariables(node.id)}
            availableDataVariables={availableDataVariables}
          />
        )}
        {node.name === "Static Value" && (
          <>
            <StaticValueNode
              node={node}
              onSelectField={handleSelectField}
              onDeleteNode={confirmDeleteNode}
              isSelected={selectedField?.nodeId === node.id}
              onReorder={(direction) => reorderNode(node.id, direction)}
              isFirst={index === 0}
              isLast={index === nodes.length - 1}
              availableVariables={getAvailableVariables(node.id)}
              availableDataVariables={availableDataVariables}
            />

            {selectedField && selectedField.nodeId === node.id && (
              <PropertiesPanel
                selectedField={selectedField}
                fieldValue={node.number1}
                onClose={handleClosePropertiesPanel}
                onFieldUpdate={handleFieldUpdate}
                availableVariables={getAvailableVariables(node.id)}
                availableDataVariables={availableDataVariables}
              />
            )}
          </>
        )}
        {node.name === "Formula function" && (
          <FormulaFunctionNode
            node={node}
            onUpdate={(updatedNode) => {
              setConditionalNodes(updateNode(conditionalNodes, updatedNode));
            }}
            onDeleteNode={confirmDeleteNode}
            isSelected={selectedField?.nodeId === node.id}
            onReorder={(direction) => reorderNode(node.id, direction)}
            isFirst={index === 0}
            isLast={index === nodes.length - 1}
            availableVariables={getAvailableVariables(node.id)}
            availableDataVariables={availableDataVariables}
          />
        )}
        {node.name === "Service Call" && (
          <ServiceCallNode
            key={node.id}
            node={node}
            onDeleteNode={confirmDeleteNode}
            onReorder={(direction) => reorderNode(node.id, direction)}
            isFirst={index === 0}
            isLast={index === conditionalNodes.length - 1}
            onSelectField={handleSelectField}
            isSelected={selectedField?.nodeId === node.id}
            onFieldUpdate={handleFieldUpdate}
            onUpdateNode={handleUpdateNode}
            availableVariables={getAvailableVariables(node.id)}
            handleClosePropertiesPanel={handleClosePropertiesPanel}
            availableDataVariables={availableDataVariables}
          />
        )}
        {node.name === "Model Method" && (
          <ModelMethodNode
            node={node}
            onDeleteNode={confirmDeleteNode}
            onReorder={(direction) => reorderNode(node.id, direction)}
            isFirst={index === 0}
            isLast={index === nodes.length - 1}
            onSelectField={(nodeId, field) => handleSelectField(nodeId, field)}
            isSelected={selectedField?.nodeId === node.id}
            onFieldUpdate={(nodeId, field, value) => {
              const updatedNode = {
                ...node,
                parameters: {
                  ...node.parameters,
                  [field]: value,
                },
              };
              setConditionalNodes(updateNode(conditionalNodes, updatedNode));
            }}
            onUpdateNode={handleUpdateNode}
            availableVariables={getAvailableVariables(node.id)}
            availableDataVariables={availableDataVariables}
            handleClosePropertiesPanel={handleClosePropertiesPanel}
            flowgraphContext={flowgraphContext}
          />
        )}
        {node.name === "Variable" && (
          <VariableNode
            node={node}
            onDeleteNode={confirmDeleteNode}
            onReorder={(direction) => reorderNode(node.id, direction)}
            isFirst={index === 0}
            isLast={index === nodes.length - 1}
            onUpdateNode={(nodeId, updates) => {
              const updatedNode = { ...node, ...updates };
              setConditionalNodes(updateNode(conditionalNodes, updatedNode));
            }}
            availableVariables={Array.from(flowgraphContext.declaredVariables)}
            availableDataVariables={availableDataVariables}
          />
        )}
        {node.name === "Constant" && (
          <ConstantNode
            node={node}
            onDeleteNode={confirmDeleteNode}
            onReorder={(direction) => reorderNode(node.id, direction)}
            isFirst={index === 0}
            isLast={index === nodes.length - 1}
            onSelectField={handleSelectField}
            isSelected={selectedField?.nodeId === node.id}
            onFieldUpdate={handleFieldUpdate}
            availableVariables={getAvailableVariables(node.id)}
            availableDataVariables={availableDataVariables}
          />
        )}
        {node.name === "Event" && (
          <EventNode
            node={node}
            onDeleteNode={confirmDeleteNode}
            onReorder={(direction) => reorderNode(node.id, direction)}
            isFirst={index === 0}
            isLast={index === nodes.length - 1}
            onSelectField={(nodeId, field) => handleSelectField(nodeId, field)}
            isSelected={selectedField?.nodeId === node.id}
            onFieldUpdate={(nodeId, field, value) => {
              const updatedNode = {
                ...node,
                parameters: {
                  ...node.parameters,
                  [field]: value,
                },
              };
              setConditionalNodes(updateNode(conditionalNodes, updatedNode));
            }}
            onEventTypeChange={(value) => {
              const updatedNode = {
                ...node,
                parameters: {
                  ...node.parameters,
                  eventType: value,
                },
              };
              setConditionalNodes(updateNode(conditionalNodes, updatedNode));
            }}
            availableVariables={getAvailableVariables(node.id)}
            availableDataVariables={availableDataVariables}
          />
        )}
        {node.childrenYes?.length > 0 && (
          <Box pl={4} borderLeft="2px solid" borderColor="green.500">
            <HStack mb={2}>
              <IconCheck size={16} color="green" />
              <Text color="green" fontWeight="bold">
                Yes
              </Text>
            </HStack>
            <AnimatePresence>
              {renderNodes(node.childrenYes, node.id, "yes")}
            </AnimatePresence>
          </Box>
        )}
        {node.childrenNo?.length > 0 && (
          <Box pl={4} borderLeft="2px solid" borderColor="red.500">
            <AnimatePresence>
              {renderNodes(node.childrenNo, node.id, "no")}
            </AnimatePresence>
            <HStack mt={2}>
              <IconX size={16} color="red" />
              <Text color="red" fontWeight="bold">
                No
              </Text>
            </HStack>
          </Box>
        )}
      </MotionBox>
    ));
  };

  const handleAddNodeClick = () => {
    setIsSelectBindingModalOpen(true);
  };

  const handleExecute = useCallback(() => {
    const flowgraph = generateFlowgraph(conditionalNodes);
    console.log("Generated Flowgraph:", JSON.stringify(flowgraph, null, 2));
    // Here you would typically send this flowgraph to be executed
    // TODO: Implement execution later
  }, [conditionalNodes]);

  const handleDeleteAll = () => {
    const emptyFlowgraph = { sequence: [], $variables: [] };
    setConditionalNodes([]);
    setValue("$flowgraph", emptyFlowgraph);
    setFragment(`${keypath}`, emptyFlowgraph);
    console.log("All nodes deleted, flowgraph cleared in database");
    toast({
      title: "All Nodes Deleted",
      description: "Your flowgraph has been cleared.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleUpdateNode = (nodeId: string, updatedNode: ConditionalNode) => {
    setConditionalNodes((prevNodes) => updateNode(prevNodes, updatedNode));
  };

  return (
    <Box
      bg="#202124"
      p={4}
      width="500px"
      margin="0 auto"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.500"
    >
      <VStack spacing={4} width="100%" position="relative">
        {conditionalNodes.length > 1 && (
          <Box
            position="absolute"
            left="50%"
            top="100px"
            bottom={`${100 + (conditionalNodes.length - 1) * 100}px`}
            width="2px"
            bg="gray.600"
            transform="translateX(-50%)"
            zIndex={0}
          />
        )}

        <HStack spacing={4} width="100%" justifyContent="left">
          <IconButton
            onClick={handleDeleteAll}
            aria-label="Delete All Nodes"
            icon={<IconTrash />}
            colorScheme="gray"
          />
        </HStack>

        <ErrorBoundary>
          <AnimatePresence>
            {conditionalNodes && Array.isArray(conditionalNodes) ? (
              renderNodes(conditionalNodes)
            ) : (
              <Text color="gray.500">No nodes available</Text>
            )}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <Center
                w="200px"
                h="150px"
                borderWidth="2px"
                borderStyle="dashed"
                borderColor="blue.500"
                borderRadius="md"
                cursor="pointer"
                onClick={handleAddNodeClick}
                bg="#303134"
              >
                <VStack>
                  <Button size="lg" colorScheme="blue" borderRadius="full">
                    +
                  </Button>
                  <Box color="white">Add node</Box>
                </VStack>
              </Center>
            </motion.div>
          </AnimatePresence>
        </ErrorBoundary>
      </VStack>

      <SelectBindingModal
        options={{
          mode: "addStep",
          keypath: "$flowgraph/sequence",
          newStepIndex: conditionalNodes.length,
        }}
        isOpen={isSelectBindingModalOpen}
        onClose={() => setIsSelectBindingModalOpen(false)}
        onSelect={(selectedOption) => {
          if (selectedOption === "Conditional Statement") {
            addNode(null, null, selectedOption);
          } else if (selectedOption === "Static Value") {
            addNode(null, null, selectedOption);
          } else if (selectedOption === "Formula function") {
            addNode(null, null, selectedOption);
          } else if (selectedOption === "Service Call") {
            addNode(null, null, selectedOption);
          } else if (selectedOption === "Model Method") {
            addNode(null, null, selectedOption);
          } else if (selectedOption === "Variable") {
            addNode(null, null, selectedOption);
          } else if (selectedOption === "Constant") {
            addNode(null, null, selectedOption);
          } else if (selectedOption === "Event") {
            addNode(null, null, selectedOption);
          }
          setIsSelectBindingModalOpen(false);
        }}
      />

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color={"black"}>
              Delete Node
            </AlertDialogHeader>

            <AlertDialogBody color={"black"}>
              Are you sure you want to delete this node? This action cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirmed} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box width="100%" display="flex" justifyContent="flex-end" mt={10}>
        <Button
          colorScheme="purple"
          variant="solid"
          size="md"
          onClick={handleExecute}
          style={{ opacity: 0 }}
        >
          Execute
        </Button>
      </Box>
    </Box>
  );
}
