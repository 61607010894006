import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import { Page } from "@/bundles/DescriptorEditor/schemas/essentials/pagesSchema";
import { generateDefaultPageDescriptor } from "@/bundles/DescriptorEditor/schemas/essentials/pagesSchema";

interface NewPageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (pageData: Page) => void;
  groupType: "custom" | "data";
  existingPages: Page[];
}

const NewPageModal: React.FC<NewPageModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  groupType,
  existingPages = [],
}) => {
  const [name, setName] = useState("");
  const [relativePath, setRelativePath] = useState("");
  const [actionType, setActionType] = useState("");
  const [isRelativePathTouched, setIsRelativePathTouched] = useState(false);
  const [showIdRequiredError, setShowIdRequiredError] = useState(false);
  const [showNameExistsError, setShowNameExistsError] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);

  console.log("NewPageModal rendered with groupType:", groupType);

  useEffect(() => {
    if (isOpen) {
      setName("");
      setRelativePath("");
      setActionType("");
      setIsRelativePathTouched(false);
      setShowIdRequiredError(false);
      setShowNameExistsError(false);
      setTimeout(() => nameInputRef.current?.focus(), 0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isRelativePathTouched) {
      setRelativePath(pathify(name));
    }
  }, [name, isRelativePathTouched]);

  useEffect(() => {
    const actionsRequiringId = ["show", "edit", "update", "destroy"];
    const isIdRequired = actionsRequiringId.includes(actionType);
    const hasIdInPath = relativePath.includes(":id");
    setShowIdRequiredError(isIdRequired && !hasIdInPath);
  }, [actionType, relativePath]);

  useEffect(() => {
    const nameExists = existingPages.some((page) => page.name === name);
    setShowNameExistsError(nameExists);
  }, [name, existingPages]);

  const pathify = (str: string): string => {
    return (
      "/" +
      str
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-|-$/g, "")
    );
  };

  const handleSubmit = () => {
    const pageType = groupType === "data" ? actionType : "custom";
    const pageData = generateDefaultPageDescriptor({
      name,
      relativePath,
      pageType: pageType as
        | "index"
        | "show"
        | "new"
        | "edit"
        | "create"
        | "update"
        | "destroy"
        | "custom",
    });
    onSubmit(pageData);
    onClose();
  };

  const actionsRequiringId = ["show", "edit", "update", "destroy"];
  const isIdRequired =
    groupType === "data" && actionsRequiringId.includes(actionType);
  const hasIdInPath = relativePath.includes(":id");
  const isFormValid =
    name &&
    relativePath &&
    !showNameExistsError &&
    (groupType === "custom" || (actionType && (!isIdRequired || hasIdInPath)));

  console.log("Form state:", {
    name,
    relativePath,
    groupType,
    actionType,
    isIdRequired,
    hasIdInPath,
    isFormValid,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Page</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Page Name</FormLabel>
              <Input
                ref={nameInputRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter page name"
              />
              {showNameExistsError && (
                <Text color="red.500">
                  Page name already exists. Please choose a different name.
                </Text>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Relative Path</FormLabel>
              <Input
                value={relativePath}
                onChange={(e) => {
                  setRelativePath(e.target.value);
                  setIsRelativePathTouched(true);
                }}
                placeholder="Enter relative path"
              />
            </FormControl>
            {groupType === "data" && (
              <FormControl isRequired>
                <FormLabel>Action Type</FormLabel>
                <Select
                  value={actionType}
                  onChange={(e) => setActionType(e.target.value)}
                  placeholder="Select action type"
                >
                  <option value="index">Index</option>
                  <option value="show">Show</option>
                  <option value="new">New</option>
                  <option value="edit">Edit</option>
                  <option value="create">Create</option>
                  <option value="update">Update</option>
                  <option value="destroy">Destroy</option>
                </Select>
              </FormControl>
            )}
            {showIdRequiredError && (
              <Box>
                <Text color="red.500">
                  ID path parameter required for {actionType} pages, please
                  include a :id part in the relative path.
                </Text>
              </Box>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isDisabled={!isFormValid}
          >
            Add Page
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewPageModal;
