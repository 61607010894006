import { FlowFunctionDescriptor } from "@/runtime-js/src/flowFunctionStore";
import functions from "../../../../../../functions/lib/functions.json";
import { getFlowgraphEditorConfigurations } from "../utils";

class FunctionRegistry {
  injectedFunctions = getFlowgraphEditorConfigurations().functions;

  getFunction(name: string) {
    return functions.find((f) => f.name === name);
  }

  getAllFunctions() {
    return (functions as unknown as FlowFunctionDescriptor[]).concat(
      this.injectedFunctions,
    );
  }

  getDefaultFunction() {
    return this.getFunction("_functions.Utility.Log");
  }
}

export const functionRegistry = new FunctionRegistry();
