import {
  EditorFlowFunctionParameter,
  FlowFunctionDescriptor,
  FlowFunctionParameter,
} from "@flowgraph/runtime/src/flowFunctionStore";

export type FlowgraphEditorConfiguration = {
  functions?: FlowFunctionDescriptor[];
  config?: {
    enabledDirectives?: string[];
    parameters?: EditorFlowFunctionParameter[];
  };
};

export function getFlowgraphEditorConfigurations() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const configuration: FlowgraphEditorConfiguration = (globalThis as any)
    .__fgEditorConfiguration;
  return {
    functions: configuration?.functions ?? [],
    config: {
      enabledDirectives: configuration?.config?.enabledDirectives ?? [],
      parameters: configuration?.config?.parameters ?? [],
    },
  };
}

export function flowgraphEditorParametersToExportParameters(
  parameters: EditorFlowFunctionParameter[],
): FlowFunctionParameter[] {
  return parameters.map((parameter) => ({
    name: parameter.name,
    type: parameter.type,
    description: parameter.description,
  }));
}
