import { functionRegistry } from "../../services/functionRegistry";

export interface FunctionData {
  name: string;
  description: string;
  parameters: Parameter[];
  flowgraph: FlowgraphDirective[];
  tests: {
    description: string;
    arguments: { [key: string]: unknown };
    expect: {
      returnValue: {
        toEqual: TypedValue;
      };
    };
  }[];
  examples?: Example[];
}

export interface Test {
  description: string;
  arguments: { [key: string]: TypedValue };
  expect: {
    returnValue: TypedValue;
  };
}

export interface TypedValue {
  type: string;
  value: unknown;
}

export interface Parameter {
  name: string;
  type: string;
  description?: string;
}

export interface FlowgraphDirective {
  directiveType: string;
  config: { [key: string]: unknown };
}

export interface Example {
  description: string;
  input: { [key: string]: unknown };
  expected: {
    type: string;
    description: string;
  };
}

export interface CategorizedFunctions {
  [type: string]: string[];
}

export const getCategorizedFunctions = (): CategorizedFunctions => {
  const categorizedFunctions: CategorizedFunctions = {};

  const allFunctions = getData();

  allFunctions.forEach((func: FunctionData) => {
    const [, type, name] = func.name.split(".");
    if (!categorizedFunctions[type]) {
      categorizedFunctions[type] = [];
    }
    categorizedFunctions[type].push(name);
  });

  return categorizedFunctions;
};

export const getData = (): FunctionData[] => {
  const functions = functionRegistry.getAllFunctions() as FunctionData[];
  return functions;
};

export function getFunctionName(name: string): string {
  return name.split(".").pop()!;
}

export const uid = () => Math.random().toString(36).substring(2, 15);
