import React from "react";
import { Input, Box } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { LiteralValueDirective } from "@/runtime-js/src/directives/literalValue";

interface LiteralStringValueEditorProps {
  keypath: string;
}

export function LiteralStringValueEditor({
  keypath,
}: LiteralStringValueEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as LiteralValueDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }
  const value = directive.config.value;

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFragment(keypath, {
      ...directive,
      config: { ...directive.config, value: newValue },
    });
  };

  return (
    <Box>
      <Input
        id="literalStringValue"
        value={value as string}
        onChange={handleValueChange}
        borderColor="gray.500"
        bg="gray.600"
        color="white"
      />
    </Box>
  );
}