import React from "react";
import { Box, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { DeclareVariableDirective } from "@/runtime-js/src/directives/declareVariable";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface DeclareVariableEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function DeclareVariableEditor({ keypath, flowgraphContext }: DeclareVariableEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as DeclareVariableDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDirective = {
      ...directive,
      config: { ...directive.config, name: e.target.value },
    };
    setFragment(keypath, updatedDirective);
    flowgraphContext.declaredVariables.add(e.target.value);
  };

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="variableName" fontSize="sm">
          Variable name
        </FormLabel>
        <Input
          id="variableName"
          value={directive.config.name}
          onChange={handleNameChange}
          borderColor="gray.500"
          bg="gray.600"
          color="white"
        />
      </FormControl>
    </Box>
  );
}
