import React, { useState, useCallback } from 'react';
import { Box, IconButton, Input, FormLabel, Select, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { AnyDirective } from "@/runtime-js/src/directives/directiveTypes";
import { DirectiveContainer } from "../../DirectiveContainer";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { LiteralValueDirective } from "@/runtime-js/src/directives/literalValue";

interface DictionaryStructureEditorProps {
  keypath: string;
  flowgraphContext: any;
}

export const DictionaryStructureEditor = ({
  keypath,
  flowgraphContext,
}: DictionaryStructureEditorProps) => {
  const setFragment = useAppDescriptorStore(state => state.setFragment);
  
  // Subscribe to changes in the directive at keypath
  const directive = useAppDescriptorStore(
    useCallback(state => state.getFragment(keypath) as LiteralValueDirective, [keypath])
  );

  const [key, setKey] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const properties = directive.config.properties || {};
  const allowCustomKeys = !directive.config.properties;
  const entriesSchema = directive.config.entries;

  const addDictionaryEntry = useCallback(() => {
    if ((allowCustomKeys && key) || (!allowCustomKeys && selectedProperty)) {
      const entryKey = allowCustomKeys ? key : selectedProperty;
      const propertySchema = properties[entryKey] || { type: "_types.String" };
      
      const newDirective: LiteralValueDirective = {
        directiveType: "literalValue",
        config: { type: propertySchema.type, value: null },
      };

      // Retrieve the current directive
      const prevDirective = useAppDescriptorStore.getState().getFragment(keypath) as LiteralValueDirective;
      const prevConfig = prevDirective?.config || {};
      const prevValue = (prevConfig.value as Record<string, AnyDirective>) || {};

      // Create the updated directive with the new entry
      const updatedDirective: LiteralValueDirective = {
        ...prevDirective,
        config: {
          ...prevConfig,
          value: {
            ...prevValue,
            [entryKey]: newDirective,
          },
        },
      };

      // Update the fragment with the new directive
      setFragment(keypath, updatedDirective);

      setKey("");
      setSelectedProperty("");
    }
  }, [allowCustomKeys, key, selectedProperty, properties, keypath, setFragment]);

  const removeDictionaryEntry = useCallback((entryKey: string) => {
    const prevDirective = useAppDescriptorStore.getState().getFragment(keypath) as LiteralValueDirective;
    const prevConfig = prevDirective?.config || {};
    const prevValue = (prevConfig.value as Record<string, AnyDirective>) || {};

    // Remove the specified entry
    const { [entryKey]: _, ...newValue } = prevValue;

    const updatedDirective: LiteralValueDirective = {
      ...prevDirective,
      config: { ...prevConfig, value: newValue },
    };

    setFragment(keypath, updatedDirective);
  }, [keypath, setFragment]);

  const directives = directive.config.value
    ? Object.entries(directive.config.value as Record<string, AnyDirective>)
    : [];

  return (
    <Box>
      {directives.map(([entryKey, childDirective]) => (
        <Box key={entryKey} mb={4}>
          <Text fontSize="xs" fontWeight="semibold" mb={1}>{entryKey}:</Text>
          <DirectiveContainer
            keypath={`${keypath}/config/value/${entryKey}`}
            onRemoveDirective={() => removeDictionaryEntry(entryKey)}
            flowgraphContext={flowgraphContext}
          />
        </Box>
      ))}
      {!entriesSchema && (
        <>
          <FormLabel>Add dictionary entry</FormLabel>
          <Box display="flex" mb={2}>
            {allowCustomKeys ? (
              <Input
                type="text"
                value={key}
                onChange={(e) => setKey(e.target.value)}
                placeholder="Key"
                mr={2}
                borderColor="gray.500"
                bg="gray.600"
                color="white"
              />
            ) : (
              <Select
                value={selectedProperty}
                onChange={(e) => setSelectedProperty(e.target.value)}
                placeholder="Select property"
                mr={2}
                borderColor="gray.500"
                bg="gray.600"
                color="white"
              >
                {Object.keys(properties).map((prop) => (
                  <option key={prop} value={prop}>
                    {prop}
                  </option>
                ))}
              </Select>
            )}
            <IconButton
              onClick={addDictionaryEntry}
              bg="blue.500"
              color="white"
              size="sm"
              aria-label="Add dictionary entry"
              icon={<AddIcon />}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

DictionaryStructureEditor.displayName = 'DictionaryStructureEditor';