import React from "react";
import {
  VStack,
  Box,
  Text,
  Input,
  Button,
  HStack,
  Divider,
  Checkbox,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";

interface Message {
  role: "user" | "assistant";
  content: string;
}

interface ChatSidebarProps {
  messages: Message[];
  onSubmit: (e: React.FormEvent) => void;
  onReset: () => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  isLoading: boolean;
  includeContext: boolean;
  setIncludeContext: (value: boolean) => void;
  includeComponents: boolean;
  setIncludeComponents: (value: boolean) => void;
}

const ChatMessage: React.FC<{ message: Message }> = ({ message }) => {
  const [mainContent, remainingContent] = message.content.split(
    "\n\nApp Descriptor Context:"
  );

  const hasContext = message.content.includes("App Descriptor Context:");
  const hasComponents = message.content.includes("Available Components:");

  return (
    <Box
      bg={message.role === "user" ? "blue.50" : "gray.50"}
      p={4}
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      alignSelf={message.role === "user" ? "flex-end" : "flex-start"}
      maxW="100%"
    >
      {message.role === "user" ? (
        <VStack align="stretch" spacing={2}>
          <Text>{mainContent}</Text>
          {(hasContext || hasComponents) && (
            <HStack spacing={2} fontSize="sm" color="gray.500">
              {hasContext && (
                <Text as="span" color="purple.500" fontWeight="bold">
                  📚 Context Included
                </Text>
              )}
              {hasComponents && (
                <Text as="span" color="blue.500" fontWeight="bold">
                  #️⃣ Components Included
                </Text>
              )}
            </HStack>
          )}
        </VStack>
      ) : (
        <Box className="markdown-content">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                  <Box my={4}>
                    <SyntaxHighlighter
                      style={tomorrow}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    >
                      {String(children).replace(/\n$/, "")}
                    </SyntaxHighlighter>
                  </Box>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
              p: ({ children }) => <Text mb={4}>{children}</Text>,
              ul: ({ children }) => (
                <Box as="ul" pl={4} mb={4}>
                  {children}
                </Box>
              ),
              li: ({ children }) => (
                <Box as="li" mb={1} style={{ listStyleType: "disc" }}>
                  {children}
                </Box>
              ),
              strong: ({ children }) => (
                <Text as="strong" fontWeight="bold">
                  {children}
                </Text>
              ),
            }}
          >
            {message.content}
          </ReactMarkdown>
        </Box>
      )}
    </Box>
  );
};

const ChatSidebar: React.FC<ChatSidebarProps> = ({
  messages,
  onSubmit,
  onReset,
  inputValue,
  setInputValue,
  isLoading,
  includeContext,
  setIncludeContext,
  includeComponents,
  setIncludeComponents,
}) => {
  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <VStack h="full" spacing={4}>
      <Button
        leftIcon={<DeleteIcon />}
        colorScheme="red"
        variant="ghost"
        size="sm"
        onClick={onReset}
        alignSelf="flex-end"
      >
        Clear Chat
      </Button>

      <VStack
        flex={1}
        w="full"
        overflowY="auto"
        spacing={4}
        alignItems="stretch"
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.200",
            borderRadius: "4px",
          },
        }}
      >
        {messages.map((message, index) => (
          <ChatMessage key={index} message={message} />
        ))}
        <div ref={messagesEndRef} />
      </VStack>

      <Divider />

      <form onSubmit={onSubmit} style={{ width: "100%" }}>
        <VStack spacing={2} align="stretch">
          <HStack>
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Type your message..."
              disabled={isLoading}
            />
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isLoading}
              isDisabled={!inputValue.trim()}
            >
              Send
            </Button>
          </HStack>
          <VStack align="stretch" spacing={1}>
            <Checkbox
              isChecked={includeContext}
              onChange={(e) => setIncludeContext(e.target.checked)}
            >
              Include context
            </Checkbox>
            <Checkbox
              isChecked={includeComponents}
              onChange={(e) => setIncludeComponents(e.target.checked)}
            >
              Include components
            </Checkbox>
          </VStack>
        </VStack>
      </form>
    </VStack>
  );
};

export default ChatSidebar;
