import { type Directive, isDirective } from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { getVariableNames, getDefaultVariable } from "../flowFunctionStore";
import type { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";

export type GetVariableDirective = Directive<"getVariable", GetVariableConfig>;

export interface GetVariableConfig {
  name: string;
}

export function isGetVariableDirective(
  directive: any,
): directive is GetVariableDirective {
  const getVariableConfigKeys: (keyof GetVariableConfig)[] = ["name"];
  return isDirective(directive, "getVariable", getVariableConfigKeys);
}

const execute = async (
  config: GetVariableConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { name } = config;
  const parts = name.split(".");

  let value = executionContext.getVariable(parts[0]);
  if (!value) {
    throw new Error(`Variable '${parts[0]}' does not exist`);
  }

  for (let i = 1; i < parts.length; i++) {
    if (typeof value !== "object" || value === null) {
      throw new Error(
        `Cannot access property '${parts[i]}' of ${typeof value}`,
      );
    }
    value = value[parts[i]];
    if (value === undefined) {
      throw new Error(`Property '${parts[i]}' does not exist`);
    }
  }

  return value as TypedValue;
};

function updateName(directive: GetVariableDirective, name: string) {
  if (!isGetVariableDirective(directive)) {
    throw new Error("Directive is not a getVariable directive");
  }
  const variableNames = getVariableNames();
  if (!variableNames.includes(name.split(".")[0])) {
    throw new Error(`Variable '${name.split(".")[0]}' has not been declared`);
  }
  directive.config.name = name;
}

export const getVariable = {
  execute,
  availableActions: {
    updateName,
  },
  defaultConfig: () =>
    ({
      name: getDefaultVariable()?.name || "",
    }) as GetVariableConfig,
};
