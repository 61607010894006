import React from 'react';
import { FormControl, FormLabel, Input, NumberInput, NumberInputField, Select } from '@chakra-ui/react';
import { ValidationFunction } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';

interface ValidationArgumentInputsProps {
  parameters: ValidationFunction['parameters'];
  args: Record<string, any>;
  setArgs: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const ValidationArgumentInputs: React.FC<ValidationArgumentInputsProps> = ({ parameters, args, setArgs }) => {
  const handleInputChange = (name: string, value: any) => {
    setArgs(prevArgs => ({ ...prevArgs, [name]: value }));
  };

  const renderInput = (param: ValidationFunction['parameters'][0]) => {
    switch (param.dataType) {
      case '_types.String':
        return (
          <Input
            value={args[param.name] || ''}
            onChange={(e) => handleInputChange(param.name, e.target.value)}
          />
        );
      case '_types.Integer':
        return (
          <NumberInput
            value={args[param.name] || ''}
            onChange={(valueString) => handleInputChange(param.name, parseInt(valueString, 10))}
          >
            <NumberInputField />
          </NumberInput>
        );
      case '_types.Array':
        return (
          <Input
            value={args[param.name] ? JSON.stringify(args[param.name]) : ''}
            onChange={(e) => {
              try {
                const parsedValue = JSON.parse(e.target.value);
                handleInputChange(param.name, parsedValue);
              } catch (error) {
                // Handle parsing error if needed
              }
            }}
            placeholder="Enter JSON array"
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {parameters.map((param) => (
        <FormControl key={param.name} mb={4}>
          <FormLabel>{param.name}</FormLabel>
          {renderInput(param)}
        </FormControl>
      ))}
    </>
  );
};

export default ValidationArgumentInputs;