import React, { useState, useEffect, useRef } from "react";
import { Text, Textarea, VStack } from "@chakra-ui/react";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";

interface RecordDescriptionEditorProps {
  keypath: string; // This keypath now points directly to the .description property
}

const RecordDescriptionEditor: React.FC<RecordDescriptionEditorProps> = ({
  keypath,
}) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [description, setDescription] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const descriptionValue = getFragment(keypath) as
      | string
      | string[]
      | undefined;
    setDescription(
      Array.isArray(descriptionValue)
        ? descriptionValue.join("")
        : descriptionValue || ""
    );
  }, [keypath, getFragment]);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      const textarea = textareaRef.current;
      const adjustHeight = () => {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      };
      adjustHeight();
      textarea.addEventListener("input", adjustHeight);
      return () => textarea.removeEventListener("input", adjustHeight);
    }
  }, [isEditing]);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleSave = () => {
    setFragment(keypath, description);
    setIsEditing(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSave();
    } else if (event.key === "Escape") {
      setIsEditing(false);
    }
  };

  return (
    <VStack align="flex-start" spacing={1}>
      {!isEditing ? (
        <Text
          color="gray.500"
          cursor="pointer"
          onClick={() => setIsEditing(true)}
        >
          {description || "Add a description..."}
        </Text>
      ) : (
        <Textarea
          ref={textareaRef}
          value={description}
          onChange={handleDescriptionChange}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          placeholder="Enter description"
          autoFocus
          rows={1}
          resize="none"
          overflow="hidden"
          minHeight="unset"
          style={{ height: "auto" }}
        />
      )}
    </VStack>
  );
};

export default RecordDescriptionEditor;
