import { z } from 'zod';
import { DescriptorCollection, DescriptorRecord } from '../descriptorUtils';
import { v4 as uuidv4 } from 'uuid';

export const navigationMenuItemSchema = DescriptorRecord(z.object({
  label: z.string(),
  icon: z.string().optional(),
  targetPageId: z.string().optional(),
  targetPageArguments: z.string().optional(),
  navigationMenuId: z.string(), // Added this line
}));

export const navigationMenuSchema = DescriptorRecord(z.object({
  name: z.string(),
  description: z.string().optional(),
}));

export const navigationMenusSchema = DescriptorCollection(navigationMenuSchema);
export const navigationMenuItemsSchema = DescriptorCollection(navigationMenuItemSchema);

export type NavigationMenuItem = z.infer<typeof navigationMenuItemSchema>;
export type NavigationMenu = z.infer<typeof navigationMenuSchema>;

export const generateDefaultNavigationMenu = (): NavigationMenu => ({
  id: uuidv4(),
  name: 'New Navigation Menu',
  description: '',
});

export const generateDefaultNavigationMenuItem = (navigationMenuId: string): NavigationMenuItem => ({
  id: uuidv4(),
  label: 'New Menu Item',
  icon: '',
  targetPageId: '',
  targetPageArguments: '',
  navigationMenuId, // Added this line
});