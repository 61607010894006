import React, { useCallback } from "react";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { KeypathBuilder } from "./KeypathBuilder";
import { GetVariableDirective } from "@/runtime-js/src/directives/getVariable";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface GetVariableEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function GetVariableEditor({ keypath, flowgraphContext }: GetVariableEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as GetVariableDirective;

  const handleKeypathChange = useCallback(
    (newKeypath: string) => {
      setFragment(keypath, {
        ...directive,
        config: { ...directive.config, name: newKeypath },
      });
    },
    [keypath, directive, setFragment],
  );

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="variableName" fontSize="sm">
          Variable to Get
        </FormLabel>
        <KeypathBuilder
          baseOptions={Array.from(flowgraphContext.declaredVariables)}
          initialKeypath={directive.config.name}
          onChange={handleKeypathChange}
        />
      </FormControl>
    </Box>
  );
}
