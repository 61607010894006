import React from "react";
import { Box } from "@chakra-ui/react";
import { DirectiveEditors } from "./helpers/directiveEditors";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { AnyDirective, DirectiveType } from "@/runtime-js/src/directives/directiveTypes";
import { FlowgraphContext } from "../types/FlowgraphContext";

interface DirectiveEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export const DirectiveEditor: React.FC<DirectiveEditorProps> = ({ keypath, flowgraphContext }) => {
  const { getFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as AnyDirective;

  if (!directive?.directiveType) {
    return <Box color="red.300">No directive selected</Box>;
  }
  const DirectiveEditorComponent = DirectiveEditors[directive.directiveType];

  if (!DirectiveEditorComponent) {
    return (
      <Box color="red.300">
        Editor not found for directive type: {directive.directiveType}
      </Box>
    );
  }

  return <DirectiveEditorComponent keypath={keypath} directive={directive} flowgraphContext={flowgraphContext} />;
};