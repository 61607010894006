import {
  type Directive,
  type NestableDirective,
  type AnyDirective,
} from "./directiveTypes";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";

// Define the OverRangeLoopDirective type
export type OverRangeLoopDirective = Directive<
  "overRangeLoop",
  OverRangeLoopConfig
>;

// Define the OverRangeLoopConfig interface
export interface OverRangeLoopConfig {
  loopingFlowgraph: AnyDirective[];
  start: NestableDirective;
  end: NestableDirective;
  step: NestableDirective;
  startInclusive?: NestableDirective;
  endInclusive?: NestableDirective;
}

// Define the execute function
const execute = async (
  config: OverRangeLoopConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<void> => {
  const { loopingFlowgraph, start, end, step, startInclusive, endInclusive } =
    config;

  const startValue = await executionContext.executeDirective(start);
  const endValue = await executionContext.executeDirective(end);
  const stepValue = await executionContext.executeDirective(step);
  const startInclusiveValue = startValue
    ? await executionContext.executeDirective(startInclusive)
    : { type: "_types.Boolean", value: true };
  const endInclusiveValue = endValue
    ? await executionContext.executeDirective(endInclusive)
    : { type: "_types.Boolean", value: true };

  if (typeof startValue.value !== "number") {
    throw new Error("The start must resolve to a number value");
  }
  if (typeof endValue.value !== "number") {
    throw new Error("The end must resolve to a number value");
  }
  if (typeof stepValue.value !== "number") {
    throw new Error("The step must resolve to a number value");
  }
  if (typeof startInclusiveValue.value !== "boolean") {
    throw new Error("The startInclusive must resolve to a boolean value");
  }
  if (typeof endInclusiveValue.value !== "boolean") {
    throw new Error("The endInclusive must resolve to a boolean value");
  }

  let num = startInclusiveValue.value ? startValue.value : startValue.value + 1;
  const endNum = endInclusiveValue.value ? endValue.value : endValue.value - 1;

  while (num <= endNum) {
    // Execute the looping flowgraph
    for (const directive of loopingFlowgraph) {
      await executionContext.executeDirective(directive);
    }
    num += stepValue.value;
  }
};

// Export the overRangeLoop object
export const overRangeLoop = {
  execute,
  availableActions: {},
  defaultConfig: () => ({
    start: {
      directiveType: "literalValue",
      config: { type: "_types.Number", value: 0 },
    },
    end: {
      directiveType: "literalValue",
      config: { type: "_types.Number", value: 10 },
    },
    step: {
      directiveType: "literalValue",
      config: { type: "_types.Number", value: 1 },
    },
    startInclusive: {
      directiveType: "literalValue",
      config: { type: "_types.Boolean", value: true },
    },
    endInclusive: {
      directiveType: "literalValue",
      config: { type: "_types.Boolean", value: true },
    },
    loopingFlowgraph: [
      {
        directiveType: "literalValue",
        config: { type: "_types.String", value: "" },
      },
    ],
  }),
};
