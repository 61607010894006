import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { DirectiveContainer } from "../DirectiveContainer";
import { ExecuteModelMethodDirective } from "@/runtime-js/src/directives/executeModelMethod";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface ExecuteModelMethodEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

const methodDescriptions = {
  new: "Create a new model instance that can be used to create a new record",
  find: "Retrieve a specific record by its ID",
  all: "Retrieve all records from the model",
  create: "Create a new record in the model",
  update: "Update an existing record in the model",
  destroy: "Delete a specific record from the model",
  destroy_all: "Delete all records from the model",
  count: "Get the total number of records in the model",
  first: "Retrieve the first record from the model",
  last: "Retrieve the last record from the model",
  find_by: "Find a specific record by its ID",
  where: "Find records that match specific criteria"
};

export function ExecuteModelMethodEditor({ keypath, flowgraphContext }: ExecuteModelMethodEditorProps) {
  const { getFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as ExecuteModelMethodDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        {directive.config.method && (
          <>
            <Text fontSize="sm">
              {methodDescriptions[directive.config.method as keyof typeof methodDescriptions]}
            </Text>
            <FormControl>
              <FormLabel fontSize="sm" fontWeight="semibold">Arguments</FormLabel>
              {Object.keys(directive.config.arguments || {}).length === 0 ? (
                <Text fontSize="xs" color="gray.500">Method takes no arguments</Text>
              ) : (
                Object.entries(directive.config.arguments || {}).map(([key, value]) => (
                  <Box key={key} mt={2}>
                    <FormLabel fontSize="xs">{key}</FormLabel>
                    <DirectiveContainer
                      keypath={`${keypath}/config/arguments/${key}`}
                      flowgraphContext={flowgraphContext}
                    />
                  </Box>
                ))
              )}
            </FormControl>
          </>
        )}
      </VStack>
    </Box>
  );
}