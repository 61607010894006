import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';

export const DescriptorRecord = <T extends z.ZodRawShape>(schema: z.ZodObject<T>) => schema.extend({
  id: z.string().optional(),
}).transform(({ id, ...rest }) => ({
  ...rest,
  id: id || uuidv4(),
}));

export type DescriptorRecord<T> = T & { id?: string };

export const DescriptorCollection = <T extends z.ZodTypeAny>(itemSchema: T) => z.array(itemSchema);

export type DescriptorCollection<T> = Array<DescriptorRecord<T>>;