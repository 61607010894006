import React from 'react';
import { Box, VStack, Divider, Button } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi'; // Replace ChevronLeftIcon with FiChevronLeft
import { useAppDescriptorStore } from '../../stores/appDescriptorStore';
import { DescriptorRecordField } from './DynamicField';
import RecordHeader from './RecordHeader';
import DynamicField from './DynamicField';
import { useNavigate, useParams } from 'react-router-dom';

interface RecordEditorProps {
  keypath: string;
  fields: DescriptorRecordField[];
  tabs: string[];
  parentName: string;
}

const RecordEditor: React.FC<RecordEditorProps> = ({ keypath, fields, tabs, parentName }) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const record = getFragment(keypath) as Record<string, any>;
  const { projectId } = useParams();
  const navigate = useNavigate();

  const handleFieldChange = (fieldKeypath: string, value: any) => {
    const fullKeypath = `${keypath}/${fieldKeypath}`;
    setFragment(fullKeypath, value);
  };

  const handleBackNavigation = () => {
    const baseUrl = `/projects/${projectId}/editor/`;
    const descriptorPath = keypath.replace('/', 'descriptor/').split('/').slice(0, -1).join('/');
    navigate(`${baseUrl}${descriptorPath}`);
  };

  console.log("RecordEditor fields", fields);

  return (
    <Box maxWidth="768px" width="100%" mx="auto" mt={4}>
      <Button
        leftIcon={<FiChevronLeft />}
        onClick={handleBackNavigation}
        mb={4}
        size="xs"
        variant="outline"
      >
        {parentName}
      </Button>
      <RecordHeader keypath={keypath} tabs={tabs} />
      <VStack spacing={4} mt={4} align="stretch">
        {fields.map((field) => (
          <DynamicField
            key={field.keypath}
            field={field}
            value={record[field.keypath]}
            onChange={(value) => handleFieldChange(field.keypath, value)}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default RecordEditor;