import React from 'react';
import { useParams } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import CollectionEditor, { Column } from '../editors/CollectionEditor';
import NewRecordModal from '../editors/NewRecordModal';
import RecordEditor from '../editors/RecordEditor';
import { DescriptorRecordField } from '../editors/DynamicField';

interface DescriptorConfig {
  name: string;
  getKeypath: (params: Record<string, string>) => string;
  recordIdKey: string;
  fields: DescriptorRecordField[] | (() => DescriptorRecordField[]);
  columns: Column[];
  addButtonText: string;
  parentName: string;
}

const createDescriptorComponent = (config: DescriptorConfig) => {
  const NewItemModal: React.FC<{isOpen: boolean; onClose: () => void; onSubmit: (newItem: any) => void}> = 
    ({ isOpen, onClose, onSubmit }) => {
      const fields = typeof config.fields === 'function' ? config.fields() : config.fields;
      return (
        <NewRecordModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          fields={fields}
          title={`New ${config.name}`}
        />
      );
    };

  const IndexComponent: React.FC = () => {
    const params = useParams<{[key: string]: string}>();
    const keypath = config.getKeypath(params as Record<string, string>);

    return (
      <CollectionEditor
        keypath={keypath}
        collectionItemType={config.name.toLowerCase()}
        columns={config.columns}
        addButtonText={config.addButtonText}
        NewItemModal={NewItemModal}
      />
    );
  };

  const EditorComponent: React.FC = () => {
    const params = useParams<{[key: string]: string}>();
    const keypath = `${config.getKeypath(params as Record<string, string>)}/${params[config.recordIdKey]}`;
    const fields = typeof config.fields === 'function' ? config.fields() : config.fields;

    console.log("Initing editor at", keypath);

    return (
      <RecordEditor
        keypath={keypath}
        fields={fields}
        tabs={[]} // Added an empty array for tabs if not used
        parentName={config.parentName} // Added this line
      />
    );
  };

  const DescriptorComponent: React.FC = () => (
    <Routes>
      <Route index element={<IndexComponent />} />
      <Route path={`:${config.recordIdKey}`} element={<EditorComponent />} />
    </Routes>
  );

  return DescriptorComponent;
};

export default createDescriptorComponent;