import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Card,
  CardBody,
  Heading,
  Text,
  Icon,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { FiFolder } from "react-icons/fi";
import { IconBrain } from "@tabler/icons-react";
import {
  useParams,
  Route,
  Routes,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useAppDescriptorStore } from "../../../../stores/appDescriptorStore";
import RecordHeader from "../../../editors/RecordHeader";
import Methods from "./tabs/Methods";
import Validations from "./tabs/Validations";
import Fields from "./tabs/Fields";
import Associations from "./tabs/Associations";
import Callbacks from "./tabs/Callbacks";
import Scopes from "./tabs/Scopes";
import Tests from "./tabs/Tests";
import { AIModelAssistant } from "./AIModelAssistant";

const DataModelEditor: React.FC = () => {
  const { dataModelId } = useParams<{ dataModelId: string }>();
  const { getFragment } = useAppDescriptorStore();
  const location = useLocation();
  const [isAIAssistantOpen, setIsAIAssistantOpen] = useState(false);

  const keypath = `/essentials/dataModels/${dataModelId}`;
  const dataModel = getFragment(keypath);

  if (!dataModel) {
    return <Box>Data model not found</Box>;
  }

  const tabs = [
    { attribute: "methods", name: "Methods" },
    { attribute: "validations", name: "Validations" },
    { attribute: "fields", name: "Fields" },
    { attribute: "associations", name: "Associations" },
    { attribute: "callbacks", name: "Callbacks" },
    { attribute: "scopes", name: "Scopes" },
    { attribute: "tests", name: "Tests" },
  ];

  const isIndexRoute = location.pathname.split("/").length === 5;

  return (
    <Box>
      <RecordHeader keypath={keypath} tabs={tabs} />
      <IconButton
        aria-label="AI Assistant"
        icon={<IconBrain />}
        position="fixed"
        bottom={4}
        right={4}
        colorScheme="purple"
        size="lg"
        onClick={() => setIsAIAssistantOpen(true)}
      />
      <AIModelAssistant
        isOpen={isAIAssistantOpen}
        onClose={() => setIsAIAssistantOpen(false)}
        dataModel={dataModel}
        keypath={keypath}
      />
      {isIndexRoute && (
        <Box maxWidth="768px" mx="auto" mt={4}>
          <SimpleGrid columns={[1, 2, 3]} spacing={4}>
            {tabs.map((tab) => (
              <Card
                key={tab.name}
                as={Link}
                to={tab.name.toLowerCase()}
                _hover={{ shadow: "md" }}
              >
                <CardBody>
                  <VStack spacing={3}>
                    <Icon as={FiFolder} boxSize={8} color="gray.500" />
                    <Heading size="sm">{tab.name}</Heading>
                  </VStack>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </Box>
      )}
      <Routes>
        <Route index element={<Navigate to="methods" replace />} />
        <Route path="methods/*" element={<Methods />} />
        <Route
          path="validations/*"
          element={<Validations keypath={keypath + "/fields"} />}
        />
        <Route path="fields/*" element={<Fields />} />
        <Route
          path="associations/*"
          element={<Associations keypath={keypath + "/associations"} />}
        />
        <Route
          path="callbacks/*"
          element={<Callbacks keypath={keypath + "/callbacks"} />}
        />
        <Route path="scopes/*" element={<Scopes />} />
        <Route path="tests/*" element={<Tests />} />
      </Routes>
    </Box>
  );
};

export default DataModelEditor;
