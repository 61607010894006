import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from '@chakra-ui/react';
import { useAppDescriptorStore } from '../stores/appDescriptorStore';
import { applyPatch, Operation } from '../utils/bjsonPatch';

interface PatchPreviewProps {
  patch: Operation;
  onClose: () => void;
}

const PatchPreview: React.FC<PatchPreviewProps> = ({ patch, onClose }) => {
  const { appDescriptor } = useAppDescriptorStore();

  const generatePreview = () => {
    const prettyPatch = JSON.stringify(patch, null, 2);
    return prettyPatch;
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Patch preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box 
            overflowX="auto" 
            maxHeight="60vh" 
            overflowY="auto" 
            whiteSpace="pre-wrap" 
            fontFamily="monospace"
          >
            {generatePreview()}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PatchPreview;
