import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  VStack,
  Text,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import { ComponentBlueprint } from "./ViewgraphEditor";
import DOMPurify from "dompurify";

interface ComponentLibraryModalProps {
  isOpen: boolean;
  onClose: () => void;
  componentBlueprints: ComponentBlueprint[];
  onAddComponent: (blueprintName: string) => void;
}

interface PreviewStyle {
  scale: number;
  translateX: number;
  height: number;
}

const ComponentLibraryModal: React.FC<ComponentLibraryModalProps> = ({
  isOpen,
  onClose,
  componentBlueprints,
  onAddComponent,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedComponent, setSelectedComponent] = useState<string | null>(
    null
  );
  const [activeTab, setActiveTab] = useState<0 | 1 | 2>(0);
  const [previewStyles, setPreviewStyles] = useState<PreviewStyle[]>([]);
  const previewRefs = useRef<(HTMLDivElement | null)[]>([]);

  const filteredBlueprints = useMemo(() => {
    return componentBlueprints.filter((blueprint) =>
      blueprint.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [componentBlueprints, searchTerm]);

  const modules = useMemo(
    () => filteredBlueprints.filter((blueprint) => blueprint.isModule),
    [filteredBlueprints]
  );

  const components = useMemo(
    () =>
      filteredBlueprints.filter(
        (blueprint) =>
          !blueprint.isModule && blueprint.category !== "HTML Elements"
      ),
    [filteredBlueprints]
  );

  const htmlElements = useMemo(
    () =>
      filteredBlueprints.filter(
        (blueprint) => blueprint.category === "HTML Elements"
      ),
    [filteredBlueprints]
  );

  const useKeyPress = (
    targetKey: string,
    onKeyPress: () => void,
    enabled: boolean = true
  ) => {
    useEffect(() => {
      if (!enabled) return;

      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === targetKey) {
          onKeyPress();
        }
      };

      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, [targetKey, onKeyPress, enabled]);
  };

  useEffect(() => {
    if (searchTerm) {
      if (modules.length === 0 && components.length > 0) {
        setActiveTab(0);
      } else if (components.length === 0 && modules.length > 0) {
        setActiveTab(1);
      } else if (
        components.length === 0 &&
        modules.length === 0 &&
        htmlElements.length > 0
      ) {
        setActiveTab(2);
      }
    }
  }, [searchTerm, modules.length, components.length, htmlElements.length]);

  useEffect(() => {
    const calculatePreviewStyles = (blueprints: ComponentBlueprint[]) => {
      const newPreviewStyles = blueprints.map((_, index) => {
        const previewRef = previewRefs.current[index];
        if (!previewRef) return { scale: 1, translateX: 0, height: 0 };

        const content = previewRef.querySelector(".preview-content");
        if (!content) return { scale: 1, translateX: 0, height: 0 };

        const containerWidth = previewRef.clientWidth - 16;
        const contentWidth = content.scrollWidth;
        const contentHeight = content.scrollHeight;

        let scale = 1;
        let translateX = 0;

        if (contentWidth <= containerWidth) {
          translateX = (containerWidth - contentWidth) / 2;
        } else {
          scale = containerWidth / contentWidth;
        }

        const height = Math.ceil(contentHeight * scale) + 16;

        return { scale, translateX, height };
      });

      setPreviewStyles(newPreviewStyles);
    };

    calculatePreviewStyles(
      activeTab === 0 ? modules : activeTab === 1 ? components : htmlElements
    );
  }, [modules, components, htmlElements, activeTab]);

  const sanitizePreviewHtml = (html: string) => {
    const config = {
      FORBID_ATTR: ["onload", "onerror", "onclick", "onmouseover"],
      FORBID_TAGS: ["script", "style"],
      ADD_ATTR: ["data-sanitized"],
    };
    return DOMPurify.sanitize(html, config);
  };

  const renderBlueprintList = (blueprints: ComponentBlueprint[]) => (
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      {blueprints.map((blueprint, index) => (
        <Box
          key={blueprint.name}
          borderWidth={2}
          borderRadius="md"
          overflow="hidden"
          cursor="pointer"
          onClick={() => setSelectedComponent(blueprint.name)}
          borderColor={
            selectedComponent === blueprint.name ? "blue.500" : "gray.200"
          }
          transition="border-color 0.2s"
        >
          <Box
            ref={(el) => (previewRefs.current[index] = el)}
            height={`${previewStyles[index]?.height || 100}px`}
            position="relative"
            overflow="hidden"
          >
            <Box
              className="preview-content"
              position="absolute"
              dangerouslySetInnerHTML={{
                __html: sanitizePreviewHtml(
                  blueprint.previewHtml ||
                    `No preview available for ${blueprint.name}`
                ),
              }}
              style={{
                transform: `translate(${previewStyles[index]?.translateX}px, 0) scale(${previewStyles[index]?.scale})`,
                transformOrigin: "top left",
              }}
            />
          </Box>
          <Text fontWeight="bold" p={2} textAlign="center">
            {blueprint.name}
          </Text>
        </Box>
      ))}
    </Grid>
  );

  const noResultsMessage = (
    <Text color="gray.500" textAlign="center" mt={4}>
      Data Module or Component not found
    </Text>
  );

  const handleAddComponent = () => {
    if (selectedComponent) {
      onAddComponent(selectedComponent);
      onClose();
    }
  };

  useKeyPress("Enter", handleAddComponent, isOpen);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth="800px">
        <ModalHeader>Element Library</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Search components/modules..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb={4}
          />
          <Tabs
            isFitted
            variant="enclosed"
            index={activeTab}
            onChange={(index) => setActiveTab(index as 0 | 1 | 2)}
          >
            <TabList mb="1em">
              <Tab>
                <HStack>
                  <Text>Components</Text>
                  <Tooltip label="Components are reusable building blocks of the app.">
                    <Text as="span" fontSize="sm" color="gray.500">
                      ⓘ
                    </Text>
                  </Tooltip>
                </HStack>
              </Tab>
              <Tab>
                <HStack>
                  <Text>Data Modules</Text>
                  <Tooltip label="Data Modules are data-centric components that work with your data models.">
                    <Text as="span" fontSize="sm" color="gray.500">
                      ⓘ
                    </Text>
                  </Tooltip>
                </HStack>
              </Tab>
              <Tab>
                <HStack>
                  <Text>HTML Elements</Text>
                  <Tooltip label="Basic HTML elements like div, span, p, etc.">
                    <Text as="span" fontSize="sm" color="gray.500">
                      ⓘ
                    </Text>
                  </Tooltip>
                </HStack>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {components.length > 0
                  ? renderBlueprintList(components)
                  : searchTerm &&
                    modules.length === 0 &&
                    htmlElements.length === 0
                  ? noResultsMessage
                  : null}
              </TabPanel>
              <TabPanel>
                {modules.length > 0
                  ? renderBlueprintList(modules)
                  : searchTerm &&
                    components.length === 0 &&
                    htmlElements.length === 0
                  ? noResultsMessage
                  : null}
              </TabPanel>
              <TabPanel>
                {htmlElements.length > 0
                  ? renderBlueprintList(htmlElements)
                  : searchTerm &&
                    modules.length === 0 &&
                    components.length === 0
                  ? noResultsMessage
                  : null}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleAddComponent}
            isDisabled={!selectedComponent}
          >
            Add{" "}
            {activeTab === 0
              ? "Module"
              : activeTab === 1
              ? "Component"
              : "Element"}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ComponentLibraryModal;
