import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Divider, VStack, Switch, FormControl, FormLabel, Select, Input, Button, FormErrorMessage, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import InlineEditableText from '@/bundles/DescriptorEditor/components/editors/InlineEditableText';
import ParameterEditor from '@/bundles/DescriptorEditor/components/editors/ParameterEditor';
import FunctionEditor from '@/bundles/DescriptorEditor/components/editors/FunctionEditor';
import CodeEditor from '@/bundles/DescriptorEditor/components/editors/CodeEditor';
import { ApiEndpoint, ApiNamespace } from '@/bundles/DescriptorEditor/schemas/essentials/apiEndpointsSchema';
import { extractPathParameters, isValidPath } from '@/bundles/DescriptorEditor/utils/pathUtils';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const ApiEndpointEditor: React.FC = () => {
  const { endpointId } = useParams();
  const { getFragment, setFragment } = useAppDescriptorStore();
  
  const endpoint = getFragment(`/essentials/apiEndpoints/${endpointId}`) as ApiEndpoint;
  if (!endpoint) {
    return <Box>API Endpoint not found</Box>;
  }
  const apiNamespace = getFragment(`/essentials/apiNamespaces/id:${endpoint.namespaceId}`) as ApiNamespace;

  if (!apiNamespace) {
    return <Box>API Namespace not found</Box>;
  }

  const updateEndpoint = (key: keyof ApiEndpoint, value: any) => {
    setFragment(`/essentials/apiEndpoints/${endpointId}/${key}`, value);
  };

  const pathParameters = extractPathParameters(endpoint.relativePath);

  const [pathError, setPathError] = useState<string | null>(null);
  const [missingPathParams, setMissingPathParams] = useState<string[]>([]);

  useEffect(() => {
    validatePath(endpoint.relativePath);
  }, [endpoint.relativePath]);

  const validatePath = (path: string) => {
    if (!isValidPath(path)) {
      setPathError("Invalid path format");
      setMissingPathParams([]);
      return;
    }

    const extractedParams = extractPathParameters(path);
    const definedParams = endpoint.pathParametersSchema?.map(p => p.name) || [];
    const missing = extractedParams.filter(p => !definedParams.includes(p));

    if (missing.length > 0) {
      setPathError(`Missing path parameter${missing.length > 1 ? 's' : ''}: ${missing.join(', ')}`);
      setMissingPathParams(missing);
    } else {
      setPathError(null);
      setMissingPathParams([]);
    }
  };

  const createMissingPathParams = () => {
    const newParams = missingPathParams.map(param => ({ id: uuidv4(), name: param, dataType: '_types.String' }));
    const updatedParams = [...(endpoint.pathParametersSchema || []), ...newParams];
    updateEndpoint('pathParametersSchema', updatedParams);
    setPathError(null);
    setMissingPathParams([]);
  };

  const basePath = apiNamespace.basePath || '';

  return (
    <Box maxWidth="1200px" margin="0 auto">
      <Heading size="md" mb={4}>{endpoint.name}</Heading>
      <InlineEditableText
        value={endpoint.description || ''}
        onSave={(value) => updateEndpoint('description', value)}
        placeholder="Endpoint description"
      />
      <Divider my={4} />
      
      <VStack align="stretch" spacing={6}>
        <Box>
          <Heading size="sm" mb={2}>Path parameters</Heading>
          <ParameterEditor 
            keypath={`/essentials/apiEndpoints/${endpointId}/pathParametersSchema`}
            itemName="path parameter"
            itemNamePlural="path parameters"
          />
          {missingPathParams.length > 0 && (
            <Box mt={2}>
              <FormErrorMessage>
                Missing path parameters: {missingPathParams.join(', ')}
              </FormErrorMessage>
              <Button size="sm" mt={2} onClick={createMissingPathParams}>
                Create missing path parameter{missingPathParams.length > 1 ? 's' : ''}
              </Button>
            </Box>
          )}
        </Box>

        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="auth-required" mb="0">
            Authentication Required
          </FormLabel>
          <Switch
            id="auth-required"
            isChecked={apiNamespace.authenticationRequired || endpoint.authenticationRequired}
            isDisabled={apiNamespace.authenticationRequired}
            onChange={(e) => updateEndpoint('authenticationRequired', e.target.checked)}
          />
        </FormControl>

        <Box display="flex" justifyContent="space-between">
          <FormControl flex="0 0 120px" mr={4}>
            <FormLabel>HTTP Method</FormLabel>
            <Select
              value={endpoint.httpMethod}
              onChange={(e) => updateEndpoint('httpMethod', e.target.value)}
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
              <option value="PATCH">PATCH</option>
            </Select>
          </FormControl>

          <FormControl flex="1" isInvalid={!!pathError}>
            <FormLabel>Path</FormLabel>
            <InputGroup>
              <InputLeftAddon>{basePath}</InputLeftAddon>
              <Input
                value={endpoint.relativePath}
                onChange={(e) => {
                  updateEndpoint('relativePath', e.target.value);
                  validatePath(e.target.value);
                }}
                placeholder="/endpoint-path"
              />
            </InputGroup>
            <FormErrorMessage>
              {pathError}
            </FormErrorMessage>
          </FormControl>
        </Box>

        <Box>
          <ParameterEditor 
            keypath={`/essentials/apiEndpoints/${endpointId}/queryParametersSchema`}
            itemName="query parameter"
            itemNamePlural="Query parameters"
          />
        </Box>

        <Box>
          <ParameterEditor 
            keypath={`/essentials/apiEndpoints/${endpointId}/headersSchema`}
            itemName="header"
            itemNamePlural="Headers"
          />
        </Box>

        <Box>
          <Heading size="sm" mb={2}>Request body schema</Heading>
          <CodeEditor
            keypath={`/essentials/apiEndpoints/${endpointId}/requestBodySchema`}
            language="json"
          />
        </Box>

        <Box>
          <Heading size="sm" mb={2}>Flowgraph</Heading>
          <FunctionEditor 
            keypath={`/essentials/apiEndpoints/${endpointId}`}
            staticParameters={[
              { name: 'request', dataType: '_types.ApiRequest', description: 'The incoming API request' },
              { name: 'response', dataType: '_types.ApiResponse', description: 'The API response object' },
            ]}
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default ApiEndpointEditor;