import React from 'react'
import { useState } from "react";
import {
  Box,
  Text,
  Input,
  FormLabel,
  IconButton,
  Textarea,
  Divider,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { DirectiveContainer } from "../DirectiveContainer";
import { RenderStringTemplateDirective } from "@/runtime-js/src/directives/renderStringTemplate";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface RenderStringTemplateEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function RenderStringTemplateEditor({
  keypath,
  flowgraphContext,
}: RenderStringTemplateEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as RenderStringTemplateDirective;

  const [variableName, setVariableName] = useState("");

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const addVariable = () => {
    if (
      variableName &&
      !directive.config.variables.some(
        (variable) => variable.key === variableName,
      )
    ) {
      const newVariable = {
        key: variableName,
        directive: {
          directiveType: "literalValue",
          config: { type: "_types.String", value: "" },
        },
      };
      const newVariables = [...directive.config.variables, newVariable];
      setFragment(keypath, {
        ...directive,
        config: { ...directive.config, variables: newVariables },
      });
      setVariableName("");
    }
  };

  const removeVariable = (key: string) => {
    const newVariables = directive.config.variables.filter(
      (variable) => variable.key !== key,
    );
    setFragment(keypath, {
      ...directive,
      config: { ...directive.config, variables: newVariables },
    });
  };

  return (
    <Box>
      <FormLabel>Text to render</FormLabel>
      <Text mb={2} fontSize="xs">
        Placeholders like {"{{"}variableName{"}}"} will be replaced with the
        return values of the variables' directives.
      </Text>
      <Textarea
        value={directive.config.template}
        onChange={(e) =>
          setFragment(keypath, {
            ...directive,
            config: { ...directive.config, template: e.target.value },
          })
        }
        borderColor="gray.500"
        bg="gray.600"
        color="white"
      />
      <Divider my={2} />
      <FormLabel>Variables</FormLabel>
      {directive.config &&
        directive.config.variables &&
        directive.config.variables.map((variable, index) => (
          <Box key={index} className="flex justify-center mb-2">
            <FormLabel>{variable.key}:</FormLabel>
            <DirectiveContainer
              keypath={`${keypath}.config.variables.${index}.directive`}
              onRemoveDirective={() => removeVariable(variable.key)}
              flowgraphContext={flowgraphContext}
            />
          </Box>
        ))}
      <FormLabel>Add variable</FormLabel>
      <Box display="flex" mb={2}>
        <Input
          type="text"
          value={variableName}
          onChange={(e) => setVariableName(e.target.value)}
          placeholder="Variable name"
          mr={2}
          borderColor="gray.500"
          bg="gray.600"
          color="white"
        />
        <IconButton
          onClick={addVariable}
          bg="blue.500"
          color="white"
          size="sm"
          aria-label="Add variable"
          isDisabled={directive.config.variables.some(
            (variable) => variable.key === variableName,
          )}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
