import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FiBox, FiCode, FiDatabase, FiPower, FiList, FiRepeat, FiType } from "react-icons/fi";
import { FlowFunctionDescriptor } from "@/runtime-js/src/flowFunctionStore";
import { getCategorizedFunctions, getData } from "./pickers/functionLibraryUtils";
import { defaultConfigForDirectiveType } from "@/runtime-js/src/directives/directiveFactory";

type MenuItem = CategoryItem | DirectiveItem;

interface CategoryItem {
  type: 'category';
  name: string;
  icon: React.ReactElement;
  children: MenuItem[];
  emptyStateText?: string;
}

interface DirectiveItem {
  type: 'directive';
  name: string;
  directiveType: DirectiveType;
  config: any;
  description?: string;
  parameters?: FlowFunctionDescriptor['parameters'];
  examples?: string[];
  resolvedValueSchema: SchemaType;
}

interface SchemaType {
  type: string;
  items?: SchemaType;
  entries?: { [key: string]: SchemaType };
}

// Add this type definition
type ModelMethod = "new" | "all" | "create" | "update" | "destroy" | "destroy_all" | "count" | "first" | "last" | "find";

const generateUniqueId = (): string => {
  return uuidv4();
};

export const createMenuStructure = (getFragment: any): MenuItem[] => {
  const categorizedFunctions = getCategorizedFunctions();
  const functionData = getData();
  const dataModels = getFragment(`/essentials/dataModels`) as Array<{ id: string; name: string; methods: Array<{ name: string }>, schema: any }> || [];

  const defaultMethods: ModelMethod[] = ["new", "find", "all", "create", "update", "destroy", "destroy_all", "count", "first", "last"];

  return [
    {
      type: 'directive',
      name: 'Get argument',
      directiveType: 'getArgument',
      config: defaultConfigForDirectiveType('getArgument'),
      description: 'Retrieve an argument passed to this flow.',
      resolvedValueSchema: { type: '_types.Any' },
    },
    {
      type: 'category',
      name: 'Static values',
      icon: <FiBox />,
      children: [
        {
          type: 'directive',
          name: 'String value',
          directiveType: 'literalValue',
          config: { type: '_types.String', value: '' },
          description: 'Enter a static text value.',
          resolvedValueSchema: { type: '_types.String' },
        },
        {
          type: 'directive',
          name: 'Numeric value',
          directiveType: 'literalValue',
          config: { type: '_types.Number', value: 0 },
          description: 'Declare a numeric value.',
          resolvedValueSchema: { type: '_types.Number' },
        },
        {
          type: 'directive',
          name: 'Boolean value',
          directiveType: 'literalValue',
          config: { type: '_types.Boolean', value: false },
          description: 'Declare a boolean value.',
          resolvedValueSchema: { type: '_types.Boolean' },
        },
        {
          type: 'directive',
          name: 'List of items',
          directiveType: 'literalValue',
          config: { type: '_types.List', value: [] },
          description: 'Declare a static list of items (though the items themselves can be dynamic).',
          resolvedValueSchema: { type: '_types.List', items: { type: '_types.Any' } },
        },
        {
          type: 'directive',
          name: 'Dictionary structure',
          directiveType: 'literalValue',
          config: { type: '_types.Dictionary', value: {} },
          description: 'Declare a static key-value pair structure (though the values themselves can be dynamic).',
          resolvedValueSchema: { type: '_types.Dictionary', entries: { key: { type: '_types.Any' } } },
        },
      ],
    },
    {
      type: 'category',
      name: 'Formulas',
      icon: <FiPower />,
      children: Object.entries(categorizedFunctions)?.map(([category, functionNames]) => ({
        type: 'category' as const,
        name: category,
        icon: <FiCode />,
        children: functionNames.map(name => {
          const func = functionData.find(f => f.name.split('.').pop() === name) as FlowFunctionDescriptor;
          return {
            type: 'directive' as const,
            name: func.name.replace('_functions.', ''),
            directiveType: 'callFunction',
            config: {
              functionName: func.name,
              arguments: Object.fromEntries(func.parameters.map(param => [param.name, null])),
            },
            description: func.description,
            parameters: func.parameters,
            examples: createExamples(func.name, func.tests),
            resolvedValueSchema: { type: func.returnType },
          };
        }),
      })),
    },
    {
      type: 'category',
      name: 'Models',
      icon: <FiDatabase />,
      children: dataModels.map(model => ({
        type: 'category' as const,
        name: model.name,
        icon: <FiDatabase />,
        children: defaultMethods.map(method => {
          let resolvedValueSchema: SchemaType = { type: '_types.Any' };
          
          switch (method) {
            case 'new':
              resolvedValueSchema = { type: '_types.ModelInstance', modelName: model.name };
              break;
            case 'all':
            case 'where':
              resolvedValueSchema = {
                type: '_types.List',
                items: { type: model.name }
              };
              break;
            case 'create':
            case 'update':
            case 'first':
            case 'last':
            case 'find_by':
              resolvedValueSchema = { type: model.name };
              break;
            case 'destroy':
            case 'destroy_all':
              resolvedValueSchema = { type: '_types.Boolean' };
              break;
            case 'count':
              resolvedValueSchema = { type: '_types.Number' };
              break;
          }

          return {
            type: 'directive' as const,
            name: `${model.name}.${method}`,
            directiveType: 'executeModelMethod',
            config: { 
              ...defaultConfigForDirectiveType('executeModelMethod'), 
              modelName: model.name,
              method: method,
            },
            resolvedValueSchema,
          };
        }),
      })),
      emptyStateText: 'No data models available',
    },
    {
      type: 'category',
      name: 'Variables',
      icon: <FiType />,
      children: [
        {
          type: 'directive',
          name: 'Declare variable',
          directiveType: 'declareVariable',
          config: defaultConfigForDirectiveType('declareVariable'),
          description: 'Define a new variable to use within this flow.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Get variable value',
          directiveType: 'getVariable',
          config: defaultConfigForDirectiveType('getVariable'),
          description: 'Retrieve the current value of a declared variable.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Set variable value',
          directiveType: 'setVariable',
          config: defaultConfigForDirectiveType('setVariable'),
          description: 'Assign a new value to a declared variable.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Declare page variable',
          directiveType: 'declarePageVariable',
          config: defaultConfigForDirectiveType('declarePageVariable'),
          description: 'Declare a page variable that components on this page can use.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Get page variable value',
          directiveType: 'getPageVariable',
          config: defaultConfigForDirectiveType('getPageVariable'),
          description: 'Retrieve the value of a declared page variable.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Set page variable value',
          directiveType: 'setPageVariable',
          config: defaultConfigForDirectiveType('setPageVariable'),
          description: 'Assign a new value to a declared page variable.',
          resolvedValueSchema: { type: '_types.Any' },
        },
      ],
    },
    {
      type: 'category',
      name: 'Flow control',
      icon: <FiRepeat />,
      children: [
        {
          type: 'directive',
          name: 'Execute in parallel',
          directiveType: 'executeAllInParallel',
          config: defaultConfigForDirectiveType('executeAllInParallel'),
          description: 'Execute multiple actions simultaneously.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Execute in parallel until one succeeds',
          directiveType: 'executeAnyInParallel',
          config: defaultConfigForDirectiveType('executeAnyInParallel'),
          description: 'Execute actions in parallel until one succeeds.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Branch logic',
          directiveType: 'executeBranches',
          config: defaultConfigForDirectiveType('executeBranches'),
          description: 'Execute different actions based on conditions.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Execute in parallel until specified number succeed',
          directiveType: 'executeSomeInParallel',
          config: defaultConfigForDirectiveType('executeSomeInParallel'),
          description: 'Execute a specific number of actions in parallel.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Repeat for each item in list',
          directiveType: 'loopForEach',
          config: defaultConfigForDirectiveType('loopForEach'),
          description: 'Perform actions for each item in a list.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Repeat while a condition is true',
          directiveType: 'whileLoop',
          config: defaultConfigForDirectiveType('whileLoop'),
          description: 'Repeat actions as long as a condition is true.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Repeat over range',
          directiveType: 'overRangeLoop',
          config: defaultConfigForDirectiveType('overRangeLoop'),
          description: 'Repeat actions for a specific number of times.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'End execution and return a value',
          directiveType: 'return',
          config: defaultConfigForDirectiveType('return'),
          description: 'End the current flow and return a value.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Wait',
          directiveType: 'waitForDuration',
          config: defaultConfigForDirectiveType('waitForDuration'),
          description: 'Pause the execution for a specified duration.',
          resolvedValueSchema: { type: '_types.Any' },
        },
      ],
    },
    {
      type: 'category',
      name: 'Utilities',
      icon: <FiList />,
      children: [
        {
          type: 'directive',
          name: 'Run Custom Code',
          directiveType: 'nativeCode',
          config: defaultConfigForDirectiveType('nativeCode'),
          description: 'Execute custom JavaScript or Ruby code.',
          resolvedValueSchema: { type: '_types.Any' },
        },
        {
          type: 'directive',
          name: 'Raise Error',
          directiveType: 'throwError',
          config: defaultConfigForDirectiveType('throwError'),
          description: 'Trigger an error with a custom message.',
          resolvedValueSchema: { type: '_types.Any' },
        },
      ],
    },
  ];

  // Add type checking for all menu items
  const validateMenuItem = (item: MenuItem): MenuItem => {
    if (item.type === 'category' && Array.isArray(item.children)) {
      item.children = item.children.map(validateMenuItem);
    }
    return item;
  };

  return menuItems.map(validateMenuItem);
};

// Add this helper function
const createExamples = (
  functionName: string,
  tests: FlowFunctionDescriptor['tests'],
): string[] => {
  if (!tests) return [];

  return tests.map((test) => {
    const inputs = Object.entries(test.arguments)
      .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
      .join(", ");
    return `${functionName}({${inputs}}) => ${JSON.stringify(test?.expect?.returnValue?.toEqual)}`;
  });
};

const isSchemaCompatible = (itemSchema: SchemaType, targetSchema: SchemaType): { compatible: boolean; warning?: string } => {
  if (itemSchema.type !== targetSchema.type) {
    return { compatible: true, warning: `Type mismatch: ${itemSchema.type} vs ${targetSchema.type}` };
  }

  if (itemSchema.type === '_types.List' && targetSchema.type === '_types.List') {
    if (itemSchema.items && targetSchema.items) {
      const { compatible, warning } = isSchemaCompatible(itemSchema.items, targetSchema.items);
      return { compatible, warning: warning ? `List items: ${warning}` : undefined };
    }
    return { compatible: true };
  }

  if (itemSchema.type === '_types.Dictionary' && targetSchema.type === '_types.Dictionary') {
    if (!itemSchema.entries || !targetSchema.entries) return { compatible: true };
    const warnings: string[] = [];
    for (const key in targetSchema.entries) {
      if (itemSchema.entries[key]) {
        const { compatible, warning } = isSchemaCompatible(itemSchema.entries[key], targetSchema.entries[key]);
        if (!compatible) return { compatible: false };
        if (warning) warnings.push(`${key}: ${warning}`);
      } else {
        warnings.push(`Missing key: ${key}`);
      }
    }
    return { compatible: true, warning: warnings.length > 0 ? `Dictionary entries: ${warnings.join(', ')}` : undefined };
  }

  return { compatible: true };
};