import React, { useMemo } from "react";
import { Box, HStack, VStack, Heading, Divider } from "@chakra-ui/react";
import { DirectiveListEditor } from "./FlowgraphEditor/components/DirectiveListEditor";
import { FlowgraphExecutor } from "./FlowgraphEditor/components/FlowgraphExecutor";
import { FunctionTestBench } from "./FunctionTestBench";
import ParameterEditor from "../editors/ParameterEditor";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";
import { Function } from "../../schemas/shared/functionSchema";
import { FlowgraphContext } from "./FlowgraphEditor/types/FlowgraphContext";
import { DirectiveType } from "@/runtime-js/src/directives/directiveTypes";
import FlowgraphEditorUI from "@/bundles/FlowgraphEditor/FlowgraphEditorUI";

interface FunctionEditorProps {
  keypath: string;
  staticParameters?: { name: string; dataType: string; description: string }[];
  enabledDirectives?: DirectiveType[];
  defaultDirective?: DirectiveType; // Add this line
}

const FunctionEditor: React.FC<FunctionEditorProps> = ({
  keypath,
  staticParameters = [],
  enabledDirectives,
  defaultDirective = "declareVariable", // Add this line
}) => {
  const { getFragment } = useAppDescriptorStore();

  const functionFragment = getFragment(keypath) as Function;

  const flowgraphContext: FlowgraphContext = useMemo(() => {
    const declaredVariables = new Set<string>();
    const parameters: {
      name: string;
      dataType: string;
      description: string;
    }[] = [];

    // Add static parameters to declared variables and parameters
    staticParameters.forEach((param) => {
      parameters.push({
        name: param.name,
        dataType: param.dataType,
        description: param.description,
      });
    });

    // Add function parameters to declared variables and parameters
    functionFragment?.parameters?.forEach((param) => {
      parameters.push({
        name: param.name,
        dataType: param.dataType,
        description: param.description,
      });
    });

    return {
      declaredVariables,
      declaredPageVariables: new Set<string>(),
      enabledDirectives,
      resolvedValueSchema: undefined,
      parameters,
    };
  }, [functionFragment?.parameters, staticParameters, enabledDirectives]);

  return (
    <VStack align="stretch" spacing={4} width="100%">
      <ParameterEditor
        keypath={`${keypath}/parameters`}
        staticParameters={staticParameters}
      />
      <Divider />
      <Heading size="md">Flowgraph</Heading>
      <HStack align="start" spacing={4} width="100%">
        <Box flex={1}>
          {/* <DirectiveListEditor
            keypath={`${keypath}/flowgraph`}
            flowgraphContext={flowgraphContext}
            defaultDirective={defaultDirective} // Add this line
          /> */}
          <FlowgraphEditorUI
            keypath={`${keypath}/flowgraph`}
            flowgraphContext={flowgraphContext}
          />
        </Box>
        <VStack flex={1} align="stretch">
          <FunctionTestBench
            keypath={keypath}
            flowgraphContext={flowgraphContext}
          />
        </VStack>
      </HStack>
    </VStack>
  );
};

export default FunctionEditor;
