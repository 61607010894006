import React, { useState, useMemo } from "react";
import { Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { SelectDirectiveModal } from "./SelectDirectiveModal";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import type { AnyDirective } from "@/runtime-js/src/directives/directiveTypes";
import { createMenuStructure } from "./createMenuStructure";
import { FlowgraphContext } from "../types/FlowgraphContext";

interface DirectiveSelectorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
  onSelect: (newDirective: AnyDirective) => void;
}

export function DirectiveSelector({
  keypath,
  flowgraphContext,
}: DirectiveSelectorProps) {
  console.log("DirectiveSelector", keypath, flowgraphContext);
  const [isOpen, setIsOpen] = useState(false);
  const { getFragment } = useAppDescriptorStore();

  const menuStructure = useMemo(() => createMenuStructure(getFragment), [getFragment]);

  const currentDirective = getFragment(keypath) as AnyDirective;
  const currentDirectiveType = currentDirective?.directiveType;

  const filteredMenuStructure = useMemo(() => {
    //TODO: remove this once we have a way to resolve the value schema
    if (true || !flowgraphContext?.resolvedValueSchema) return menuStructure;

    const filterItems = (items: MenuItem[]): MenuItem[] => {
      return items.filter((item) => {
        if (item.type === 'category') {
          const filteredChildren = filterItems(item.children);
          return filteredChildren.length > 0;
        } else {
          return item.resolvedValueSchema === flowgraphContext.resolvedValueSchema;
        }
      });
    };

    return filterItems(menuStructure);
  }, [menuStructure, flowgraphContext?.resolvedValueSchema]);

  const getDirectiveTitle = (directive: AnyDirective) => {
    if (directive?.directiveType === 'literalValue') {
      const type = directive?.config?.type;
      switch (type) {
        case '_types.String':
          return 'String value';
        case '_types.Number':
          return 'Numeric value';
        case '_types.Boolean':
          return 'Boolean value';
        case '_types.List':
          return 'List of items';
        case '_types.Dictionary':
          return 'Dictionary structure';
        default:
          return 'Literal value';
      }
    } else if (directive?.directiveType === 'executeModelMethod') {
      return `${directive.config.modelName}.${directive.config.method}`;
    } else if (directive?.directiveType === 'callFunction') {
      return directive.config?.name?.replace('_functions.', '') || "Call Function";
    }
    return menuStructure
      .flatMap((category) => category.children)
      .find((item) => item?.type === 'directive' && item?.directiveType === currentDirectiveType)?.name || "Select Directive";
  };


  return (
    <>
      <Button
        rightIcon={<ChevronDownIcon />}
        onClick={() => setIsOpen(true)}
        size="xs"
        ml={1}
        variant="ghost"
        colorScheme="gray"
        color="gray.200"
        _hover={{
          color: "gray.300",
        }}
      >
        {getDirectiveTitle(currentDirective)}
      </Button>

      <SelectDirectiveModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        menuStructure={filteredMenuStructure}
        currentDirectiveType={currentDirectiveType}
        keypath={keypath}
        flowgraphContext={flowgraphContext}
      />
    </>
  );
}
