import React, { useState, useEffect, useCallback } from 'react';
import { Box, Input, Popover, PopoverTrigger, PopoverContent, PopoverBody, VStack, Text, Flex, Badge, Card, CardBody, IconButton, FormControl, FormLabel } from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { getColorScheme } from '@/bundles/DescriptorEditor/components/descriptors/essentials/pageGroups/routeHelpers';

interface PageSelectorProps {
  keypath: string;
}

const PageSelector: React.FC<PageSelectorProps> = ({ keypath }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { appDescriptor, getPage, getFragment, setFragment } = useAppDescriptorStore();

  const redirectTo = getFragment(keypath) as { pageId: string; pageArguments?: Record<string, any> } | undefined;
  const selectedPageId = redirectTo?.pageId || null;
  const redirectArguments = redirectTo?.pageArguments || {};

  const pages = appDescriptor.essentials?.pageGroups?.flatMap(group => group.pages) || [];
  const selectedPage = selectedPageId ? getPage(selectedPageId) : null;

  const updateRedirectTo = useCallback((pageId: string | null, args?: Record<string, any>) => {
    if (pageId) {
      setFragment(keypath, {
        pageId,
        pageArguments: args || {}
      });
    } else {
      setFragment(keypath, undefined);
    }
  }, [keypath, setFragment]);

  const handleArgumentChange = (paramName: string, value: string) => {
    updateRedirectTo(selectedPageId, { ...redirectArguments, [paramName]: value });
  };

  const handleClearSelection = () => {
    updateRedirectTo(null);
  };

  const filteredPages = pages.filter(page =>
    page.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    page.relativePath.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const highlightText = (text: string, highlight: string) => {
    if (!text || !highlight) return text; // Add this check
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? <mark key={index}>{part}</mark> : part
    );
  };

  return (
    <Box maxWidth="400px">
      {selectedPage ? (
        <Card>
          <CardBody>
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Text fontWeight="bold">{selectedPage.name}</Text>
              <IconButton
                aria-label="Clear selection"
                icon={<FiTrash2 />}
                size="sm"
                onClick={handleClearSelection}
              />
            </Flex>
            <Text fontSize="sm" color="gray.600" mb={2}>{selectedPage.fullRoute}</Text>
            <Badge colorScheme={getColorScheme(selectedPage.pageType)} mb={4}>
              {selectedPage.pageType}
            </Badge>
            {selectedPage.allParameters?.map(param => (
              <FormControl key={param.name} mb={2}>
                <FormLabel fontSize="sm">{param.name}</FormLabel>
                <Input
                  size="sm"
                  value={redirectArguments[param.name] || ''}
                  onChange={(e) => handleArgumentChange(param.name, e.target.value)}
                  placeholder="Enter key of flowgraph execution result"
                />
              </FormControl>
            ))}
          </CardBody>
        </Card>
      ) : (
        <Popover
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          placement="bottom-start"
        >
          <PopoverTrigger>
            <Input
              placeholder="Search pages..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </PopoverTrigger>
          <PopoverContent maxWidth="400px">
            <PopoverBody p={0}>
              <VStack align="stretch" maxHeight="300px" overflowY="auto">
                {filteredPages.map(page => (
                  <Box
                    key={page.id}
                    p={2}
                    _hover={{ bg: 'gray.100' }}
                    cursor="pointer"
                    onClick={() => {
                      updateRedirectTo(page.id);
                      setIsOpen(false);
                    }}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text fontWeight="bold">{highlightText(page.name, searchTerm)}</Text>
                        <Text fontSize="sm" color="gray.600">{highlightText(page.relativePath, searchTerm)}</Text>
                      </Box>
                      <Flex direction="column" alignItems="flex-end">
                        <Badge colorScheme={getColorScheme(page.pageType)} mb={1}>
                          {highlightText(page.pageType, searchTerm)}
                        </Badge>
                        <Text fontSize="xs" color="gray.500">{highlightText(page.pageGroupName, searchTerm)}</Text>
                      </Flex>
                    </Flex>
                  </Box>
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Box>
  );
};

export default PageSelector;