import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';
import { useAppDescriptorStore } from '../../../../stores/appDescriptorStore';
import RecordHeader from '../../../editors/RecordHeader';
import FunctionEditor from '../../../editors/FunctionEditor';
import RenderActionsEditor from '../../../editors/FlowgraphEditor/directives/RenderActionsEditor';

const PageMethodDetail: React.FC = () => {
  const { pageId, methodId } = useParams<{ pageId: string; methodId: string }>();
  const { getFragment } = useAppDescriptorStore();

  const keypath = `/essentials/pages/${pageId}/pageMethods/${methodId}`;
  const pageMethod = getFragment(keypath);

  if (!pageMethod) {
    return <Box>Page method not found</Box>;
  }

  return (
    <Box>
      <RecordHeader keypath={keypath} />
      <Box mt={6}>
        <Heading size="md" mb={4}>Function</Heading>
        <FunctionEditor keypath={keypath} />
      </Box>
      <Box mt={6}>
        <Heading size="md" mb={4}>Render Actions</Heading>
        <RenderActionsEditor keypath={`${keypath}/renderActions`} />
      </Box>
    </Box>
  );
};

export default PageMethodDetail;