import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Input,
  useToast,
  VStack,
  Text,
  Switch,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";
import { JSONTree } from "react-json-tree";
import _ from "lodash";

const DescriptorDebugger: React.FC = () => {
  const { appDescriptor, seedDescriptor, getFragment, applyPatch } =
    useAppDescriptorStore();
  const toast = useToast();
  const [keypath, setKeypath] = useState("");
  const [retrievedFragment, setRetrievedFragment] = useState<any>(null);
  const [showWholeDescriptor, setShowWholeDescriptor] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showCustomDescriptorModal, setShowCustomDescriptorModal] =
    useState(false);
  const [customDescriptor, setCustomDescriptor] = useState("");

  const theme = {
    scheme: "monokai",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(JSON.stringify(appDescriptor, null, 2))
      .then(() => {
        toast({
          title: "Copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast({
          title: "Failed to copy",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const copyKeypathToClipboard = () => {
    navigator.clipboard
      .writeText(JSON.stringify(retrievedFragment, null, 2))
      .then(() => {
        toast({
          title: "Keypath content copied to clipboard",
          status: "success",
        });
      });
  };

  const consoleLogDescriptor = () => {
    console.log(appDescriptor);
  };

  const retrieveFragment = () => {
    try {
      const result = getFragment(keypath);
      setRetrievedFragment(result);
    } catch (error) {
      console.error("Error getting fragment:", error);
      toast({
        title: "Invalid keypath",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleCustomDescriptorSubmit = () => {
    try {
      const parsedDescriptor = JSON.parse(customDescriptor);

      if (
        !parsedDescriptor.name ||
        !parsedDescriptor.essentials ||
        !parsedDescriptor.userInterface
      ) {
        throw new Error("Invalid descriptor structure");
      }

      const newDescriptor = {
        name: parsedDescriptor.name,
        description: parsedDescriptor.description || "",
        essentials: {
          dataTypes: parsedDescriptor.essentials.dataTypes || [],
          dataModels: parsedDescriptor.essentials.dataModels || [],
          pageGroups: parsedDescriptor.essentials.pageGroups || [],
          apiEndpoints: parsedDescriptor.essentials.apiEndpoints || [],
          dataServices: parsedDescriptor.essentials.dataServices || [],
          apiNamespaces: parsedDescriptor.essentials.apiNamespaces || [],
          dataVariables: parsedDescriptor.essentials.dataVariables || [],
        },
        userInterface: {
          applicationLayouts:
            parsedDescriptor.userInterface.applicationLayouts || [],
          componentBlueprints:
            parsedDescriptor.userInterface.componentBlueprints || [],
        },
      };

      const patch = { op: "replace", path: "/", value: newDescriptor };
      applyPatch(patch);
      setShowCustomDescriptorModal(false);
      toast({
        title: "Descriptor updated successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error parsing or applying custom descriptor:", error);
      toast({
        title: "Invalid JSON or descriptor structure",
        description: "Please check your input and try again",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "d" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setIsOpen((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // Update keypath when location changes
    const pathname = location.pathname;
    const filteredPath = pathname.replace(
      /^\/projects\/\d+\/editor\/descriptor/,
      ""
    );
    setKeypath(filteredPath);
  }, [location]);

  useEffect(() => {
    retrieveFragment();
  }, [keypath, appDescriptor, getFragment]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Descriptor Debugger</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box borderWidth={1} borderRadius="sm" fontSize="xs">
              <VStack align="stretch" spacing={2} mb={4}>
                <Button size="sm" onClick={seedDescriptor} mr={2}>
                  Overwrite descriptor with seed data
                </Button>
                <Button
                  size="sm"
                  onClick={() => setShowCustomDescriptorModal(true)}
                >
                  Paste Custom Descriptor
                </Button>
                <Button size="sm" onClick={copyToClipboard}>
                  Copy FULL Descriptor to Clipboard
                </Button>
                <Button size="sm" onClick={copyKeypathToClipboard}>
                  Copy CURRENT Keypath content to Clipboard
                </Button>
                <Button size="sm" onClick={consoleLogDescriptor}>
                  Console Log
                </Button>
                <Text>Keypath: {keypath}</Text>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="show-whole-descriptor" mb="0">
                    Show whole descriptor
                  </FormLabel>
                  <Switch
                    id="show-whole-descriptor"
                    isChecked={showWholeDescriptor}
                    onChange={(e) => setShowWholeDescriptor(e.target.checked)}
                  />
                </FormControl>
              </VStack>

              <Text fontWeight="bold">
                {showWholeDescriptor
                  ? "Full Descriptor:"
                  : "Current Keypath Contents:"}
              </Text>
              <JSONTree
                data={showWholeDescriptor ? appDescriptor : retrievedFragment}
                theme={theme}
                invertTheme={false}
                shouldExpandNodeInitially={() => true}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showCustomDescriptorModal}
        onClose={() => setShowCustomDescriptorModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Paste Custom Descriptor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} mb={4}>
              <Textarea
                value={customDescriptor}
                onChange={(e) => setCustomDescriptor(e.target.value)}
                placeholder="Paste your JSON descriptor here..."
                height="300px"
                fontFamily="monospace"
              />
              <Button colorScheme="blue" onClick={handleCustomDescriptorSubmit}>
                Update Descriptor
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DescriptorDebugger;
