import React, { useState } from 'react';
import {
  Button,
  HStack,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  VStack,
  Text,
} from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';

interface StringEditorProps {
  schema: any;
  keypath: string;
}

const StringEditor: React.FC<StringEditorProps> = ({ schema, keypath }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getFragment, setFragment } = useAppDescriptorStore();

  const binding = getFragment(keypath);
  const [localValue, setLocalValue] = useState(binding?.config?.value || '');

  const handleSave = () => {
    setFragment(`${keypath}/config/value`, localValue);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalValue(e.target.value);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    handleSave();
  };

  return (
    <>
      <HStack spacing={2} alignItems="flex-start">
        <Input
          value={localValue}
          onChange={handleChange}
          onBlur={handleSave}
          placeholder={schema.placeholder || 'Enter value'}
          size="sm"
        />
        <Button size="sm" onClick={() => setIsModalOpen(true)}>
          Expand
        </Button>
      </HStack>
      <Modal isOpen={isModalOpen} onClose={handleModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{schema.name || 'Edit String'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              {schema.description && (
                <Text fontSize="sm" color="gray.600">
                  {schema.description}
                </Text>
              )}
              <Textarea
                value={localValue}
                onChange={handleChange}
                placeholder={schema.placeholder || 'Enter value'}
                rows={6}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StringEditor;