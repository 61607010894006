import React, { useMemo } from 'react'
import { Box, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface GetArgumentEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function GetArgumentEditor({ keypath, flowgraphContext }: GetArgumentEditorProps) {
  const { getFragment, setFragment, getPageByKeypath } = useAppDescriptorStore();
  const directive = getFragment(keypath) as any;
  const page = getPageByKeypath(keypath);

  const argumentNames = useMemo(() => page?.allParameters.map((param) => param.name) || [], [page]);

  console.log("Debug: GetArgumentEditor directive", directive, keypath);

  const handleArgumentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newArgument = event.target.value;
    setFragment(keypath + "/config/name", newArgument);
  };

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="argumentName" fontSize="sm">
          Argument name
        </FormLabel>
        <Select
          id="argumentName"
          value={directive?.config?.name || ''}
          onChange={handleArgumentChange}
        >
          {argumentNames.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}