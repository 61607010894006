type Resolver<T> = (value: T | PromiseLike<T>) => void;
type Rejector = (reason?: any) => void;
type Executor<T> = (
  resolve: Resolver<T>,
  reject: Rejector,
  onCancel: { isCancelled: boolean },
) => void;

export class CancellablePromise<T> implements Promise<T> {
  private promise: Promise<T>;
  private onCancel: { isCancelled: boolean } = { isCancelled: false };

  constructor(executor: Executor<T>) {
    this.promise = new Promise<T>((resolve, reject) => {
      executor(resolve, reject, this.onCancel);
    });
  }

  cancel() {
    this.onCancel.isCancelled = true;
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null,
  ): Promise<TResult1 | TResult2> {
    return this.promise.then(onfulfilled, onrejected);
  }

  catch<TResult = never>(
    onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | null,
  ): Promise<T | TResult> {
    return this.promise.catch(onrejected);
  }

  finally(onfinally?: (() => void) | null): Promise<T> {
    return this.promise.finally(onfinally);
  }

  [Symbol.toStringTag] = "Promise";
}
