import React, { useState, useEffect } from "react";
import { Box, Button, Text, Select, IconButton } from "@chakra-ui/react";
import pluralize from "pluralize";
import { FiPlus } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import { IconBrain } from "@tabler/icons-react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import {
  type Page,
  type PageGroup,
  generateDefaultPageDescriptor,
  generateDefaultPageGroupDescriptor,
} from "@/bundles/DescriptorEditor/schemas/essentials/pagesSchema";
import { type DataModel } from "@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema";
import CollectionHeader from "@/bundles/DescriptorEditor/components/editors/CollectionHeader";
import NewPageGroupModal from "./NewPageGroupModal";
import PageGroupEditor from "./PageGroupEditor";
import FlowgraphEditorUI from "../../../../../FlowgraphEditor/FlowgraphEditorUI";
import { AIPageGroupsAssistant } from "./AIPageGroupsAssistant";

const PageGroupsIndex: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allPages, setAllPages] = useState<{ id: string; name: string }[]>([]);
  const [isAIAssistantOpen, setIsAIAssistantOpen] = useState(false);

  const pageGroups =
    (getFragment("/essentials/pageGroups") as PageGroup[]) || [];
  const pages = (getFragment("/essentials/pages") as Page[]) || [];
  const dataModels =
    (getFragment("/essentials/dataModels") as DataModel[]) || [];
  const rootPageId = getFragment("/essentials/routes/rootPageId") as
    | string
    | undefined;

  useEffect(() => {
    // Flatten all pages from all page groups
    const flattenedPages = pageGroups.flatMap((group) =>
      group.pages.map((page) => ({
        id: page.id,
        name: `${group.name} - ${page.name}`,
      }))
    );
    setAllPages(flattenedPages);
  }, [pageGroups]);

  const handleRootPageChange = (pageId: string) => {
    setFragment("/essentials/routes/rootPageId", pageId);
  };

  const addNewGroup = (
    groupType: "custom" | "data",
    name: string,
    dataModelId?: string
  ) => {
    console.log("Adding new group", groupType, name, dataModelId);
    let groupName = name;
    let basePath = `/${name.toLowerCase().replace(/ /g, "-")}`;

    if (groupType === "data" && dataModelId) {
      const dataModel = dataModels.find((model) => model.id === dataModelId);
      if (dataModel) {
        groupName = pluralize(dataModel.name);
        basePath = `/${groupName.toLowerCase()}`;
      }
    }

    const newGroup = generateDefaultPageGroupDescriptor(groupName, dataModelId);
    newGroup.basePath = basePath;

    // Ensure the new group's base path is unique
    let counter = 1;
    while (pageGroups.some((g) => g.basePath === newGroup.basePath)) {
      newGroup.basePath = `${basePath}-${counter}`;
      counter++;
    }

    // Set description for data page groups
    if (groupType === "data" && dataModelId) {
      const dataModel = dataModels.find((model) => model.id === dataModelId);
      if (dataModel) {
        newGroup.description = `Data page group for data model ${dataModel.name}`;
      }
    }

    setFragment("/essentials/pageGroups", [...pageGroups, newGroup]);

    if (groupType === "custom") {
      addNewPage(newGroup.id, {
        name: "Home",
        relativePath: "/",
        pageType: "custom",
      });
    } else if (groupType === "data" && dataModelId) {
      addDataPages(newGroup.id, groupName, dataModelId);
    }
  };

  const addNewPage = (groupId: string, pageData: Partial<Page>) => {
    const group = pageGroups.find((g) => g.id === groupId);
    if (!group) return;

    const newPage = generateDefaultPageDescriptor(pageData);

    setFragment(`/essentials/pageGroups/id:${groupId}/pages`, [
      ...(group.pages || []),
      newPage,
    ]);
  };

  const addDataPages = (groupId: string, groupName: string) => {
    const newPages: Page[] = [
      { pageType: "index", relativePath: "/" },
      { pageType: "show", relativePath: "/:id" },
      { pageType: "new", relativePath: "/new" },
    ].map(({ pageType, relativePath }) =>
      generateDefaultPageDescriptor({
        name: `${groupName} ${pageType}`,
        relativePath,
        pageType,
      })
    );

    setFragment(`/essentials/pageGroups/id:${groupId}/pages`, newPages);
  };

  const getHttpMethodFromAction = (action: string) => {
    // ... implementation of getHttpMethodFromAction ...
  };

  return (
    <Box maxW="container.lg" mx="auto">
      <CollectionHeader
        title="Page Groups"
        description="Manage your application's page groups"
        keypath="/essentials/pageGroups"
      />
      <IconButton
        aria-label="AI Assistant"
        icon={<IconBrain />}
        position="fixed"
        bottom={4}
        right={4}
        colorScheme="purple"
        size="lg"
        onClick={() => setIsAIAssistantOpen(true)}
      />
      <AIPageGroupsAssistant
        isOpen={isAIAssistantOpen}
        onClose={() => setIsAIAssistantOpen(false)}
      />
      <Box mb={4}>
        <Text mb={2}>Select Root Page:</Text>
        <Select
          value={rootPageId || ""}
          onChange={(e) => handleRootPageChange(e.target.value)}
          placeholder="Select a root page"
        >
          {allPages.map((page) => (
            <option key={page.id} value={page.id}>
              {page.name}
            </option>
          ))}
        </Select>
      </Box>
      {pageGroups.length === 0 ? (
        <Box textAlign="center" py={10}>
          <Text mb={4}>No page groups found.</Text>
          <Button leftIcon={<FiPlus />} onClick={() => setIsModalOpen(true)}>
            Add new page group
          </Button>
        </Box>
      ) : (
        <>
          {pageGroups.map((group) => (
            <PageGroupEditor
              key={group.id}
              keypath={`/essentials/pageGroups/id:${group.id}`}
            />
          ))}
          <Button
            leftIcon={<FiPlus />}
            onClick={() => setIsModalOpen(true)}
            mt={4}
          >
            Add new page group
          </Button>
        </>
      )}
      <NewPageGroupModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={addNewGroup}
        dataModels={dataModels}
      />
    </Box>
  );
};

export default PageGroupsIndex;
