import {
  type Directive,
  type NestableDirective,
  type AnyDirective,
  isDirective,
} from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";

export type WaitForDurationDirective = Directive<
  "waitForDuration",
  WaitForDurationConfig
>;

export interface WaitForDurationConfig {
  duration: NestableDirective;
}

const execute = async (
  config: WaitForDurationConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { duration } = config;

  // Resolve the duration
  const resolvedDuration = await executionContext.executeDirective(duration);

  // Pause execution for the specified duration
  await new Promise((resolve) => setTimeout(resolve, resolvedDuration.value));

  return {
    type: "_types.Void",
    value: null,
  };
};

export const searchDirective = (): AnyDirective | undefined => {
  return undefined;
};

export const waitForDuration = {
  execute,
  availableActions: {},
  defaultConfig: () =>
    ({
      duration: {
        id: "",
        directiveType: "literalValue",
        config: {
          type: "_types.Number",
          value: 100,
        },
      },
    }) as WaitForDurationConfig,
};

export function isWaitForDurationDirective(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directive: any,
): directive is WaitForDurationDirective {
  const waitForDurationConfigKeys: (keyof WaitForDurationConfig)[] = [
    "duration",
  ];
  return isDirective(directive, "waitForDuration", waitForDurationConfigKeys);
}
