import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';
import CollectionEditor from '../../../editors/CollectionEditor';
import NewRecordModal, { Field } from '../../../editors/NewRecordModal';

interface NewPageMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newItem: any) => void;
}

const NewPageMethodModal: React.FC<NewPageMethodModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const fields: Field[] = [
    { name: 'Name', keypath: 'name', component: 'Text' },
    { name: 'Description', keypath: 'description', component: 'TextArea' },
  ];

  return (
    <NewRecordModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      fields={fields}
      title="New Page Method"
    />
  );
};

const PageMethods: React.FC = () => {
  const { pageId } = useParams<{ pageId: string }>();

  return (
    <Box>
      <Heading size="lg" mb={4}>Page Methods</Heading>
      <CollectionEditor
        keypath={`/essentials/pages/${pageId}/pageMethods`}
        collectionItemType="PageMethod"
        columns={[
          { 
            name: 'Name', 
            render: (row) => row.name || 'N/A'
          },
          { 
            name: 'Description', 
            render: (row) => row.description || 'No description'
          },
        ]}
        addButtonText="Add New Page Method"
        NewItemModal={NewPageMethodModal}
      />
    </Box>
  );
};

export default PageMethods;