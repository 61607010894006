import React, { useCallback } from "react";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { KeypathBuilder } from "./KeypathBuilder";
import { SetVariableDirective } from "@/runtime-js/src/directives/setVariable";
import { DirectiveContainer } from "../DirectiveContainer";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface SetVariableEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function SetVariableEditor({ keypath, flowgraphContext }: SetVariableEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as SetVariableDirective;

  const handleVariableNameChange = useCallback(
    (newName: string) => {
      setFragment(keypath, {
        ...directive,
        config: { ...directive.config, name: newName },
      });
    },
    [keypath, directive, setFragment],
  );

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="variableName" fontSize="sm">
          Variable to Set
        </FormLabel>
        <KeypathBuilder
          baseOptions={Array.from(flowgraphContext.declaredVariables)}
          initialKeypath={directive.config.name}
          onChange={handleVariableNameChange}
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontSize="sm">New Value</FormLabel>
        <DirectiveContainer
          keypath={`${keypath}.config.newValue`}
          onRemoveDirective={() => {}}
          flowgraphContext={flowgraphContext}
          isNested={true}
        />
      </FormControl>
    </Box>
  );
}
