import React, { useState } from 'react';
import { useParams, Route, Routes, useNavigate } from 'react-router-dom';
import { 
  Box, Heading, Button, Flex, Table, Thead, Tbody, Tr, Th, Td, IconButton, Text, Center,
  Divider, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton
} from '@chakra-ui/react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import RecordNameEditor from '@/bundles/DescriptorEditor/components/editors/RecordNameEditor';
import RecordDescriptionEditor from '@/bundles/DescriptorEditor/components/editors/RecordDescriptionEditor';
import FunctionEditor from '@/bundles/DescriptorEditor/components/editors/FunctionEditor';

const MethodEditor = () => {
  const { dataModelId, methodId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { removeRecordFromCollectionFragment } = useAppDescriptorStore();
  const keypath = `/essentials/dataModels/${dataModelId}/methods/${methodId}`;

  const handleDelete = () => {
    removeRecordFromCollectionFragment(`/essentials/dataModels/${dataModelId}/methods`, methodId);
    navigate('..');
  };

  return (
    <Box maxWidth="800px" margin="0 auto">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading size="md">Edit Method</Heading>
        <IconButton
          aria-label="Delete method"
          icon={<FiTrash2 />}
          onClick={onOpen}
          colorScheme="red"
        />
      </Flex>
      <RecordNameEditor keypath={keypath + '/name'} />
      <RecordDescriptionEditor keypath={keypath + '/description'} />
      <Divider my={6} />
      <FunctionEditor keypath={keypath} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this method? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const MethodsTable: React.FC = () => {
  const { dataModelId } = useParams();
  const navigate = useNavigate();
  const { getFragment, addRecordToCollectionFragment, removeRecordFromCollectionFragment } = useAppDescriptorStore();

  const methodsKeypath = `/essentials/dataModels/${dataModelId}/methods`;
  const methods = getFragment(methodsKeypath) as any[] || [];

  // New: Get associations for the current data model
  const associationsKeypath = `/essentials/dataModels/${dataModelId}/associations`;
  const associations = getFragment(associationsKeypath) as any[] || [];
  console.log(associations);
  const belongsToAssociations = associations.filter(assoc => assoc.associationType === 'belongs_to').map(assoc => {
    return {
      ...assoc,
      name: getFragment(`/essentials/dataModels/id:${assoc.targetModelId}/name`) as string || ''
    }
  });
  console.log(belongsToAssociations);

  const addMethod = () => {
    const newMethod = {
      name: `new_method_${methods.length + 1}`,
      flowgraph: [],
      parameters: [],
      // Add other default properties as needed
    };
    addRecordToCollectionFragment(methodsKeypath, newMethod);
  };

  const removeMethod = (event: React.MouseEvent, methodId: string) => {
    event.stopPropagation();
    removeRecordFromCollectionFragment(methodsKeypath, methodId);
  };

  const editMethod = (methodId: string) => {
    if (methodId.startsWith('auto_')) {
      // Prevent editing automatic methods
      return;
    }
    navigate(`./id:${methodId}`);
  };

  return (
    <Box maxWidth="800px" margin="0 auto">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading size="md">Methods</Heading>
        <Button leftIcon={<FiPlus />} onClick={addMethod}>Add Method</Button>
      </Flex>
      <Divider my={6} />
      {methods.length === 0 && belongsToAssociations.length === 0 ? (
        <Center bg="gray.50" h="200px" borderRadius="md">
          <Box textAlign="center">
            <Text mb={4}>No methods have been added yet.</Text>
            <Button leftIcon={<FiPlus />} onClick={addMethod}>Add Your First Method</Button>
          </Box>
        </Center>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th width="50px"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {belongsToAssociations.map(assoc => (
              <Tr 
                key={`auto_${assoc.id}`}
                bg="gray.50"
              >
                <Td>{assoc?.name}</Td>
                <Td color="gray.500">Auto-generated method to fetch the related {assoc?.name} record</Td>
                <Td></Td>
              </Tr>
            ))}
            {methods.map(method => (
              <Tr 
                key={method.id} 
                onClick={() => editMethod(method.id)}
                cursor="pointer"
                _hover={{ bg: 'gray.50' }}
              >
                <Td>{method.name}</Td>
                <Td color={method.description ? 'inherit' : 'gray.500'}>
                  {method.description || 'No description'}
                </Td>
                <Td>
                  <IconButton
                    aria-label="Remove method"
                    icon={<FiTrash2 />}
                    size="sm"
                    onClick={(e) => removeMethod(e, method.id)}
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

const Methods: React.FC = () => {
  return (
    <Routes>
      <Route index element={<MethodsTable />} />
      <Route path=":methodId" element={<MethodEditor />} />
    </Routes>
  );
};

export default Methods;