import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  VStack,
  Button,
  Box,
} from "@chakra-ui/react";
import { IconChevronRight } from "@tabler/icons-react";

interface ComparisonOperatorModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentOperator: string;
  onSelect: (operator: string) => void;
}

const operators = [
  "Greater than",
  "Less than",
  "Greater than or equal",
  "Less than or equal",
];

export function ComparisonOperatorModal({
  isOpen,
  onClose,
  currentOperator,
  onSelect,
}: ComparisonOperatorModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent bg="gray.800" color="white">
        <ModalHeader>
          <Text fontSize="xl">Select Comparison Operator</Text>
        </ModalHeader>
        <ModalBody pb={6}>
          <VStack spacing={2} align="stretch">
            {operators.map((operator) => (
              <Button
                key={operator}
                onClick={() => {
                  onSelect(operator);
                  onClose();
                }}
                variant="ghost"
                justifyContent="space-between"
                bg={currentOperator === operator ? "blue.500" : "gray.700"}
                _hover={{
                  bg: currentOperator === operator ? "blue.600" : "gray.600",
                }}
                color="white"
                fontWeight="normal"
                height="auto"
                py={2}
              >
                <Text>{operator}</Text>
                <Box>
                  <IconChevronRight size={18} />
                </Box>
              </Button>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
