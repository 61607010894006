import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Input,
  Text,
  useToast,
  Spinner,
  Box,
} from "@chakra-ui/react";
import OpenAI from "openai";
import { useAppDescriptorStore } from "../../../../stores/appDescriptorStore";
import { JSONTree } from "react-json-tree";

interface AIPageGroupsAssistantProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AIPageGroupsAssistant: React.FC<AIPageGroupsAssistantProps> = ({
  isOpen,
  onClose,
}) => {
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedFragment, setSuggestedFragment] = useState<any>(null);
  const toast = useToast();
  const { setFragment, getFragment } = useAppDescriptorStore();

  const handleAskAI = async () => {
    setIsLoading(true);
    try {
      const openai = new OpenAI({
        apiKey: process.env.OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const allPageGroups = getFragment("/essentials/pageGroups");
      const allDataModels = getFragment("/essentials/dataModels");

      const messages = [
        {
          role: "system" as const,
          content: `You are an AI assistant helping with page group design. You will be given all existing page groups and data models, and asked to modify or create page groups.

          Rules:
          1. Respond ONLY with the complete page groups array as a JSON array
          2. Keep existing IDs of groups and pages
          3. Follow this exact format for page groups:
          [{
            "id": "string",
            "name": "string",
            "pages": [{
              "id": "string",
              "name": "string",
              "pageType": "index|show|new|edit|custom",
              "description": "string",
              "relativePath": "string",
              "dataModelId": "string",
              "userInterface": {
                "viewgraph": {
                  "enabled": true,
                  "containerStructure": { "id": "root", "components": [] }
                }
              }
            }],
            "basePath": "string",
            "description": "string",
            "dataModelId": "string"
          }]
          4. Generate new UUIDs for any new groups or pages
          5. Do not add any explanations or markdown formatting
          6. Valid pageTypes are: index, show, new, edit, custom
          
          Example response:
          [{"id": "group1", "name": "Car Pages", "pages": [{"id": "page1", "name": "CarIndex", "pageType": "index", "description": "List all cars", "relativePath": "/", "dataModelId": "car1"}], "basePath": "/cars", "description": "Car management pages", "dataModelId": "car1"}]`,
        },
        {
          role: "user" as const,
          content:
            `All page groups:\n` +
            `${JSON.stringify(allPageGroups, null, 2)}\n\n` +
            `Available data models:\n` +
            `${JSON.stringify(allDataModels, null, 2)}\n\n` +
            `Request: ${prompt}`,
        },
      ];

      const completion = await openai.chat.completions.create({
        model: "gpt-4",
        messages,
        temperature: 0.2,
      });

      const response = completion.choices[0].message.content;

      try {
        const suggestedChanges = JSON.parse(response || "null");
        if (!Array.isArray(suggestedChanges)) {
          throw new Error("Invalid page groups structure");
        }
        setSuggestedFragment(suggestedChanges);
      } catch (error) {
        toast({
          title: "Invalid AI Response",
          description: "The AI returned an invalid page groups structure",
          status: "error",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to get AI response",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyChanges = () => {
    if (suggestedFragment) {
      try {
        setFragment("/essentials/pageGroups", suggestedFragment);
        toast({
          title: "Changes Applied",
          description:
            "The suggested changes have been applied to your page groups",
          status: "success",
        });
        onClose();
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to apply changes",
          status: "error",
        });
      }
    }
  };

  const theme = {
    scheme: "monokai",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>AI Page Groups Assistant</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Text>Ask me to modify or create page groups</Text>
            <Input
              placeholder="e.g., Create CRUD pages for Cars, Add an admin dashboard group..."
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
            <Button
              colorScheme="purple"
              onClick={handleAskAI}
              isLoading={isLoading}
              leftIcon={isLoading ? <Spinner size="sm" /> : undefined}
            >
              Ask AI
            </Button>

            {suggestedFragment && (
              <VStack align="stretch" width="100%" spacing={2}>
                <Text fontWeight="bold">Suggested Changes:</Text>
                <Box
                  bg="gray.900"
                  p={4}
                  borderRadius="md"
                  maxHeight="400px"
                  overflow="auto"
                >
                  <JSONTree
                    data={suggestedFragment}
                    theme={theme}
                    invertTheme={false}
                    shouldExpandNodeInitially={() => true}
                  />
                </Box>
                <Button colorScheme="green" onClick={handleApplyChanges}>
                  Apply Changes
                </Button>
              </VStack>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
