import { getCsrfToken } from './csrfToken';

interface ApiCallOptions {
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  endpoint: string;
  body?: object | FormData;
  headers?: HeadersInit;
}

export const railsApiCall = async <T>({ method, endpoint, body, headers: customHeaders }: ApiCallOptions): Promise<{ data: T; ok: boolean; status: number }> => {
  let csrfToken;
  try {
    csrfToken = getCsrfToken();
  } catch (error) {
    throw new Error('Failed to get CSRF token: ' + (error instanceof Error ? error.message : String(error)));
  }

  const defaultHeaders: HeadersInit = {
    'X-CSRF-Token': csrfToken,
  };

  if (!(body instanceof FormData)) {
    defaultHeaders['Content-Type'] = 'application/json';
  }

  const headers = { ...defaultHeaders, ...customHeaders };

  const options: RequestInit = {
    method,
    headers,
    credentials: 'include',
  };

  if (body) {
    options.body = body instanceof FormData ? body : JSON.stringify(body);
  }

  const response = await fetch(`/api/v1${endpoint}`, options);

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'An error occurred while making the API call');
  }

  return {
    data: await response.json() as T,
    ok: response.ok,
    status: response.status,
  };
};

// Example usage:
// const getAppDescriptor = (projectId: string) => 
//   railsApiCall<TypedAppDescriptor>({ method: 'GET', endpoint: `/projects/${projectId}/app_descriptor` });

// const patchAppDescriptor = (projectId: string, patches: Operation[]) => 
//   railsApiCall<{ message: string }>({ 
//     method: 'PATCH', 
//     endpoint: `/projects/${projectId}/app_descriptor`, 
//     body: { patches } 
//   });