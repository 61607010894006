import React from "react";

const NotFound: React.FC = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <p>
        Please check `AppDescriptor` for all valid paths and add missing ones if
        necessary.
      </p>
    </div>
  );
};

export default NotFound;
