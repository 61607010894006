import React from 'react';
import { Input, Select, Textarea, FormControl, FormLabel, Box, VStack, Text, Switch, Button, HStack } from '@chakra-ui/react';
import ParameterEditor from './ParameterEditor';

export interface DescriptorRecordField {
  name: string;
  keypath: string;
  component: string;
  options?: any[];
  language?: string;
}

interface DynamicFieldProps {
  field: DescriptorRecordField;
  value: any;
  onChange: (value: any) => void;
  sectionTitle?: string;
}

const DynamicField: React.FC<DynamicFieldProps> = ({ field, value, onChange, sectionTitle }) => {
  const renderField = () => {
    const commonProps = {
      value,
      onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => onChange(e.target.value),
      size: "md",
      bg: "white",
      border: "1px solid",
      borderColor: "gray.200",
      _hover: { borderColor: "gray.300" },
      _focus: { borderColor: "blue.500", boxShadow: "0 0 0 1px #3182CE" },
    };

    switch (field.component) {
      case 'Text':
        return <Input {...commonProps} />;
      case 'Select':
        return (
          <Select {...commonProps}>
            {field.options?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        );
      case 'TextArea':
        return <Textarea {...commonProps} />;
      case 'ParameterEditor':
        return <ParameterEditor value={value} onChange={onChange} placeholder={field.name} />;
      case 'Switch':
        return (
          <Switch
            isChecked={value === field.options?.[0].value}
            onChange={(e) => onChange(e.target.checked ? field.options?.[0].value : field.options?.[1].value)}
          >
            {value === field.options?.[0].value ? field.options?.[0].label : field.options?.[1].label}
          </Switch>
        );
      case 'TabButtonSelect':
        return (
          <HStack spacing={2}>
            {field.options?.map((option) => (
              <Button
                key={option.value}
                onClick={() => onChange(option.value)}
                variant={value === option.value ? 'solid' : 'outline'}
                colorScheme="blue"
                size="sm"
              >
                {option.label}
              </Button>
            ))}
          </HStack>
        );
      case 'CodeEditor':
        return <Box>Code Editor not implemented</Box>;
      default:
        return <Box>Unsupported field type</Box>;
    }
  };

  const fieldComponent = (
    <FormControl display="flex" alignItems="start" mb={6}>
      <FormLabel htmlFor={field.name} mb="0" ml={4} fontWeight="normal" fontSize="sm" width="128px" flexShrink={0}>
        {field.name}
      </FormLabel>
      <Box flex="1">{renderField()}</Box>
    </FormControl>
  );

  if (sectionTitle) {
    return (
      <VStack align="stretch" spacing={4} mb={8}>
        <Text fontSize="lg" fontWeight="medium" color="gray.200" mb={2}>
          {sectionTitle}
        </Text>
        {fieldComponent}
      </VStack>
    );
  }

  return fieldComponent;
};

export default DynamicField;