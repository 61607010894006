import React from "react";
import { Route, Routes } from "react-router-dom";
import ApplicationAssetsIndex from "./ApplicationAssetsIndex";

const ApplicationAssets: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ApplicationAssetsIndex />} />
    </Routes>
  );
};

export default ApplicationAssets;
