import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, FormLabel, Select, Text } from '@chakra-ui/react';
import { DataModelField, DataModelValidation, ValidationFunction, coreValidationFunctions } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';
import ValidationArgumentInputs from './ValidationArgumentInputs';

interface AddValidationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddValidation: (newValidation: DataModelValidation) => void;
  selectedFieldId: string;
  fields: DataModelField[];
}

const AddValidationModal: React.FC<AddValidationModalProps> = ({
  isOpen,
  onClose,
  onAddValidation,
  selectedFieldId,
  fields,
}) => {
  const [selectedValidationFunction, setSelectedValidationFunction] = useState<ValidationFunction>({
    name: '',
    description: '',
    applicableDataTypes: [],
    parameters: []
  });
  const [validationArgs, setValidationArgs] = useState<Record<string, any>>({});

  const getAvailableValidations = () => {
    const selectedField = fields.find(field => field.id === selectedFieldId);
    if (!selectedField) return [];

    const existingValidationNames = selectedField.validations?.map(v => v.validationFunctionName) || [];
    return coreValidationFunctions.filter(validation => !existingValidationNames.includes(validation.name));
  };

  const handleAddValidation = () => {
    const newValidation: DataModelValidation = {
      validationFunctionName: selectedValidationFunction.name,
      arguments: validationArgs
    };
    onAddValidation(newValidation);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Validation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Validator</FormLabel>
            <Select
              placeholder="Select validation"
              value={selectedValidationFunction.name}
              onChange={(e) => {
                const selectedValidation = coreValidationFunctions.find(v => v.name === e.target.value);
                if (selectedValidation) {
                  setSelectedValidationFunction(selectedValidation);
                  setValidationArgs({});
                }
              }}
            >
              {getAvailableValidations().map((validation) => (
                <option key={validation.name} value={validation.name}>{validation.name}</option>
              ))}
            </Select>
          </FormControl>
          {selectedValidationFunction.description && (
            <Text mb={4}>{selectedValidationFunction.description}</Text>
          )}
          <ValidationArgumentInputs
            parameters={selectedValidationFunction.parameters || []}
            args={validationArgs}
            setArgs={setValidationArgs}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleAddValidation}>
            Add
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddValidationModal;