import React, { useEffect, useState } from 'react'
import {
  VStack,
  Text,
  Divider,
  Box,
} from "@chakra-ui/react";
import { DirectiveContainer } from "../DirectiveContainer";
import { functionRegistry } from "../../services/functionRegistry";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { CallFunctionDirective } from "@/runtime-js/src/directives/callFunction";
import { FlowFunctionDescriptor } from "@/runtime-js/src/flowFunctionStore";

interface CallFunctionEditorProps {
  keypath: string;
}

export function CallFunctionEditor({ keypath }: CallFunctionEditorProps) {
  const { getFragment } = useAppDescriptorStore();
  const [directive, setDirective] = useState<CallFunctionDirective | null>(null);
  const [funcDeclaration, setFuncDeclaration] = useState<FlowFunctionDescriptor | null>(null);

  useEffect(() => {
    const currentDirective = getFragment(keypath) as CallFunctionDirective;
    setDirective(currentDirective);

    if (currentDirective?.config?.functionName) {
      const func = functionRegistry.getFunction(currentDirective.config.functionName);
      setFuncDeclaration(func || functionRegistry.getDefaultFunction());
    } else {
      setFuncDeclaration(functionRegistry.getDefaultFunction());
    }
  }, [keypath, getFragment]);

  if (!directive || !funcDeclaration) {
    console.error("Directive or function declaration not provided");
    return <Box>Error: Directive or function declaration not provided</Box>;
  }

  return (
    <VStack p={0} align="start" justify="start" color="white" borderRadius="md">
      <Text fontSize="sm">{funcDeclaration.description}</Text>
      <Divider />
      {funcDeclaration.parameters.map((param) => (
        <VStack key={param.name} align="stretch">
          <Text fontSize="sm" fontWeight="medium">
            {param.name} ({param.type})
          </Text>
          <Text fontSize="sm">{param.description}</Text>
          <DirectiveContainer
            keypath={`${keypath}/config/arguments/${param.name}`}
            onRemoveDirective={() => {}}
            declaredVariables={new Set()}
            isNested={true}
          />
        </VStack>
      ))}
    </VStack>
  );
}