import React from "react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import {
  IconMinus,
  IconTrash,
  IconCone,
  IconChevronUp,
  IconChevronDown,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { ConditionalNode } from "../types";
import { PropertiesPanel } from "./PropertiesPanel";
import { useState } from "react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface ConstantNodeProps {
  node: ConditionalNode;
  onDeleteNode: (nodeId: string) => void;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  onSelectField: (nodeId: string, field: "id") => void;
  isSelected: boolean;
  onFieldUpdate: (nodeId: string, field: "id", value: string) => void;
  availableVariables: string[];
}

export function ConstantNode({
  node,
  onDeleteNode,
  onReorder,
  isFirst,
  isLast,
  onSelectField,
  isSelected,
  onFieldUpdate,
  availableVariables,
}: ConstantNodeProps) {
  const { getFragment } = useAppDescriptorStore();
  const dataVariables = getFragment("/essentials/dataVariables") || [];
  const availableDataVariables = dataVariables.map((v) => v.name);
  const [showPropertiesPanel, setShowPropertiesPanel] = useState(false);
  const iconColor = "rgb(255, 165, 0)";

  const handleClick = () => {
    onSelectField(node.id, "id");
    setShowPropertiesPanel(true);
  };

  const displayValue = node.parameters?.id?.startsWith("$")
    ? `Variable: ${node.parameters.id.substring(1)}`
    : node.parameters?.id || "(not set)";

  return (
    <Box position="relative">
      <Box
        borderWidth={1}
        borderColor={iconColor}
        borderRadius="md"
        bg="#202124"
        width="350px"
        overflow="hidden"
        position="relative"
        boxShadow={isSelected ? "0 0 10px rgba(66, 153, 225, 0.6)" : "none"}
        _hover={{ boxShadow: "0 0 10px rgba(66, 153, 225, 0.3)" }}
      >
        <HStack p={3} bg={`${iconColor}20`} justifyContent="space-between">
          <HStack>
            <IconButton
              icon={<IconMinus size={16} />}
              aria-label="Collapse"
              size="sm"
              variant="ghost"
              color={colors.grayDark.gray12}
            />
            <Text fontWeight="medium" color="white">
              Constant Get
            </Text>
          </HStack>
          <HStack>
            {!isFirst && (
              <Tooltip label="Move Up" aria-label="Move Up Tooltip">
                <IconButton
                  icon={<IconChevronUp size={16} />}
                  aria-label="Move Up"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("up")}
                />
              </Tooltip>
            )}
            {!isLast && (
              <Tooltip label="Move Down" aria-label="Move Down Tooltip">
                <IconButton
                  icon={<IconChevronDown size={16} />}
                  aria-label="Move Down"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("down")}
                />
              </Tooltip>
            )}
            <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
              <IconButton
                icon={<IconTrash size={16} />}
                aria-label="Delete Node"
                size="sm"
                variant="ghost"
                color={colors.red.red6}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteNode(node.id);
                }}
                _hover={{ bg: colors.red.red3 }}
              />
            </Tooltip>
          </HStack>
        </HStack>

        <VStack align="stretch" p={3} bg="#303134" spacing={3}>
          <HStack>
            <IconCone size={16} color={iconColor} />
            <Text color="white" fontWeight="medium">
              Get Constant
            </Text>
          </HStack>

          <Box
            p={2}
            bg="#3c4043"
            cursor="pointer"
            onClick={handleClick}
            _hover={{ bg: "#4a4d51" }}
            borderRadius="md"
          >
            <HStack justifyContent="space-between">
              <Text color="white" fontWeight="medium">
                {displayValue}
              </Text>
              <Text color={colors.grayDark.gray11}>Constant ID</Text>
            </HStack>
          </Box>
        </VStack>
      </Box>

      {showPropertiesPanel && (
        <PropertiesPanel
          selectedField={{ nodeId: node.id, field: "id" }}
          fieldValue={node.parameters?.id || ""}
          onClose={() => setShowPropertiesPanel(false)}
          onFieldUpdate={onFieldUpdate}
          availableVariables={availableDataVariables}
          allowNumberInput={false}
        />
      )}
    </Box>
  );
}
