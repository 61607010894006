import { create } from "zustand";
import { useAppDescriptorStore } from "./appDescriptorStore";

interface DataModelField {
  id: string;
  name: string;
  dataType: string;
  description?: string;
}

interface DataModel {
  id: string;
  name: string;
  fields: DataModelField[];
  description?: string;
}

interface DataModelStore {
  getDataModels: () => DataModel[];
  getDataModelFields: (modelName: string) => DataModelField[];
}

export const useDataModelStore = create<DataModelStore>((set, get) => ({
  getDataModels: () => {
    const { getFragment } = useAppDescriptorStore.getState();
    return getFragment("/essentials/dataModels") || [];
  },

  getDataModelFields: (modelName: string) => {
    const { getFragment } = useAppDescriptorStore.getState();
    const dataModels = getFragment("/essentials/dataModels") as DataModel[];

    const model = dataModels.find((m) => m.name === modelName);
    if (!model) {
      console.warn(`Data model '${modelName}' not found`);
      return [];
    }

    return model.fields || [];
  },
}));
