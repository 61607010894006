import React from "react";
import {
  Box,
  Text,
  useDisclosure,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import { FunctionPickerModal } from "./FunctionPickerModal";
import {
  ConditionalNode,
  FunctionDescriptor,
  ModelMethodChain,
  ServiceCallChain,
} from "../types";
import {
  IconFunction,
  IconTrash,
  IconEdit,
  IconChevronUp,
  IconChevronDown,
  IconPlus,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { useState } from "react";
import { PropertiesPanel } from "./PropertiesPanel";
import { useFlowgraphStore } from "../stores/FlowgraphStore";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface FormulaFunctionNodeProps {
  node: ConditionalNode;
  onUpdate: (updatedNode: ConditionalNode) => void;
  onDeleteNode: (nodeId: string) => void;
  isSelected: boolean;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  availableVariables: string[];
}

export function FormulaFunctionNode({
  node,
  onUpdate,
  onDeleteNode,
  isSelected,
  onReorder,
  isFirst,
  isLast,
  availableVariables,
}: FormulaFunctionNodeProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedField, setSelectedField] = useState<{
    paramName: string;
    value: string;
    isChainParam?: boolean;
    chainIndex?: number;
  } | null>(null);
  const [isChaining, setIsChaining] = useState(false);
  const [editingChainIndex, setEditingChainIndex] = useState<number | null>(
    null
  );
  const [chainParameters, setChainParameters] = useState<
    Record<string, string>
  >({});
  const { getAllFunctions, canFunctionBeChained } = useFlowgraphStore();
  const { getFragment } = useAppDescriptorStore();
  const dataVariables = getFragment("/essentials/dataVariables") || [];
  const availableDataVariables = dataVariables.map((v) => v.name);

  const iconColor = "rgb(138, 180, 248)";

  const handleSelectFunction = (func: FunctionDescriptor) => {
    if (isChaining) {
      const updatedChains = [...(node.chains || [])];
      if (editingChainIndex !== null) {
        // Update existing chain
        updatedChains[editingChainIndex] = {
          functionCall: func.name,
          parameters: chainParameters,
        };
      } else {
        // Add new chain
        updatedChains.push({
          functionCall: func.name,
          parameters: chainParameters,
        });
      }

      const updatedNode = {
        ...node,
        chains: updatedChains,
      };
      onUpdate(updatedNode);
      setIsChaining(false);
      setEditingChainIndex(null);
    } else {
      const updatedNode = {
        ...node,
        functionCall: func.name,
        parameters: {
          id: node.parameters.id,
          value: node.parameters.value,
          ...func.parameters.reduce((acc, param) => {
            acc[param.name] = "";
            return acc;
          }, {} as Record<string, string>),
        },
      };
      onUpdate(updatedNode);
    }
    onClose();
  };

  const handleChainClick = () => {
    setIsChaining(true);
    setEditingChainIndex(null);
    setChainParameters({});
    onOpen();
  };

  const handleParameterClick = (
    paramName: string,
    value: string,
    isChainParam = false,
    chainIndex?: number
  ) => {
    setSelectedField({ paramName, value, isChainParam, chainIndex });
  };

  const handleFieldUpdate = (nodeId: string, field: string, value: string) => {
    if (selectedField?.isChainParam && selectedField.chainIndex !== undefined) {
      const updatedChains = [...(node.chains || [])];
      const updatedChainParams = {
        ...updatedChains[selectedField.chainIndex].parameters,
        [field]: value,
      };
      updatedChains[selectedField.chainIndex] = {
        ...updatedChains[selectedField.chainIndex],
        parameters: updatedChainParams,
      };
      const updatedNode = {
        ...node,
        chains: updatedChains,
      };
      onUpdate(updatedNode);
    } else {
      const updatedNode = {
        ...node,
        parameters: {
          ...node.parameters,
          value: {
            ...node.parameters.value,
            [field]: value,
          },
        },
      };
      onUpdate(updatedNode);
    }
  };

  const handleChainFunctionEdit = (chainIndex: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setIsChaining(true);
    setEditingChainIndex(chainIndex);
    setChainParameters(node.chains?.[chainIndex].parameters || {});
    onOpen();
  };

  const handleMainFunctionEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsChaining(false);
    setEditingChainIndex(null);
    onOpen();
  };

  const currentFunction = getAllFunctions().find(
    (func) => func.name === node.functionCall
  );

  const chainedFunctions =
    node.chains?.map((chain) =>
      getAllFunctions().find((func) => func.name === chain.functionCall)
    ) || [];

  const getParameterValue = (paramName: string) => {
    return node.parameters.value?.[paramName] || "";
  };

  const showChainButton = node.functionCall
    ? canFunctionBeChained(node.functionCall)
    : false;

  const getChainedFunctionTemplate = (functionName: string) => {
    const func = getAllFunctions().find((f) => f.name === functionName);
    return func?.chainedEditorTemplate;
  };

  const renderChainedElements = (chain: any, index: number) => {
    const template = getChainedFunctionTemplate(chain.functionCall);
    if (!template) return null;

    return template.elements.map((element: any, elementIndex: number) => {
      if (element.type === "Text") {
        return (
          <Text
            key={`text-${elementIndex}`}
            fontWeight={elementIndex === 0 ? "medium" : "normal"}
            color="white"
          >
            {element.text}
          </Text>
        );
      } else if (element.type === "BindingEditor") {
        const paramName = element.forArgument;
        return (
          <Flex
            key={`param-${elementIndex}`}
            width="100%"
            bg="#3c4043"
            color="white"
            borderRadius="md"
            alignItems="center"
            onClick={() =>
              handleParameterClick(
                paramName,
                chain.parameters[paramName] || "",
                true,
                index
              )
            }
            cursor="pointer"
            _hover={{ bg: "#4c5054" }}
          >
            <Text flex={1} p={2}>
              {paramName}:
            </Text>
            <Text p={2} color={iconColor}>
              {chain.parameters[paramName]?.startsWith("$")
                ? `Variable: ${chain.parameters[paramName].substring(1)}`
                : chain.parameters[paramName] || "(empty)"}
            </Text>
          </Flex>
        );
      }
      return null;
    });
  };

  return (
    <Box position="relative" role="group">
      <Box
        borderWidth={1}
        borderColor={iconColor}
        borderRadius="md"
        bg="#202124"
        width="350px"
        overflow="hidden"
        boxShadow={isSelected ? "0 0 10px rgba(66, 153, 225, 0.6)" : "none"}
        _hover={{ boxShadow: "0 0 10px rgba(66, 153, 225, 0.3)" }}
      >
        <Flex
          alignItems="center"
          p={3}
          bg={`${iconColor}20`}
          justifyContent="space-between"
        >
          <HStack>
            <Icon as={IconFunction} color={iconColor} boxSize={5} mr={2} />
            <Text fontWeight="medium" color="white">
              Formula Function
            </Text>
          </HStack>
          <HStack>
            {!isFirst && (
              <Tooltip label="Move Up" aria-label="Move Up Tooltip">
                <IconButton
                  icon={<IconChevronUp size={16} />}
                  aria-label="Move Up"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("up")}
                />
              </Tooltip>
            )}
            {!isLast && (
              <Tooltip label="Move Down" aria-label="Move Down Tooltip">
                <IconButton
                  icon={<IconChevronDown size={16} />}
                  aria-label="Move Down"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("down")}
                />
              </Tooltip>
            )}
            <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
              <IconButton
                icon={<IconTrash size={16} />}
                aria-label="Delete Node"
                size="sm"
                variant="ghost"
                color={colors.red.red6}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteNode(node.id);
                }}
                _hover={{ bg: colors.red.red3 }}
              />
            </Tooltip>
          </HStack>
        </Flex>

        <VStack align="stretch" p={3} bg="#303134" spacing={2}>
          <Flex
            width="100%"
            bg="#3c4043"
            color={iconColor}
            borderRadius="md"
            alignItems="center"
            position="relative"
            role="group"
            onClick={!node.functionCall ? onOpen : undefined}
            cursor={!node.functionCall ? "pointer" : "default"}
          >
            <Text flex={1} p={2}>
              {node.functionCall || "Pick function"}
            </Text>
            {node.functionCall && (
              <Tooltip label="Edit Function" aria-label="Edit Function Tooltip">
                <IconButton
                  icon={<IconEdit size={16} />}
                  aria-label="Edit Function"
                  size="sm"
                  variant="ghost"
                  color={iconColor}
                  onClick={handleMainFunctionEdit}
                  position="absolute"
                  right={2}
                  opacity={0}
                  _groupHover={{ opacity: 1 }}
                />
              </Tooltip>
            )}
          </Flex>
          {currentFunction &&
            currentFunction.parameters.map((param) => (
              <Flex
                key={param.name}
                width="100%"
                bg="#3c4043"
                color="white"
                borderRadius="md"
                alignItems="center"
                onClick={() =>
                  handleParameterClick(
                    param.name,
                    getParameterValue(param.name),
                    false
                  )
                }
                cursor="pointer"
                _hover={{ bg: "#4c5054" }}
              >
                <Text flex={1} p={2}>
                  {param.name}:
                </Text>
                <Text p={2} color={iconColor}>
                  {getParameterValue(param.name)?.startsWith("$")
                    ? `Variable: ${getParameterValue(param.name).substring(1)}`
                    : getParameterValue(param.name) || "(empty)"}
                </Text>
              </Flex>
            ))}
        </VStack>

        {node.chains?.map((chain, index) => {
          const chainedFunction = getAllFunctions().find(
            (f) => f.name === chain.functionCall
          );
          if (!chainedFunction) return null;

          return (
            <React.Fragment key={index}>
              <Box
                borderTopWidth={1}
                borderColor={iconColor}
                my={2}
                opacity={0.5}
              />
              <VStack
                align="stretch"
                p={3}
                bg="#303134"
                spacing={2}
                role="group"
              >
                <Flex
                  width="100%"
                  bg="#3c4043"
                  color={iconColor}
                  borderRadius="md"
                  alignItems="center"
                  position="relative"
                  role="group"
                  cursor="default"
                  display="none"
                  _groupHover={{ display: "flex" }}
                >
                  <Text flex={1} p={2}>
                    {chain.functionCall || "Pick function"}
                  </Text>
                  <HStack spacing={2} pr={2}>
                    <IconButton
                      icon={<IconTrash size={16} />}
                      aria-label="Remove Chain"
                      size="sm"
                      variant="ghost"
                      color={colors.red.red6}
                      opacity={0}
                      _groupHover={{ opacity: 1 }}
                      onClick={(e) => {
                        const updatedChains = node.chains?.filter(
                          (_, i) => i !== index
                        );
                        const updatedNode = {
                          ...node,
                          chains: updatedChains,
                        };
                        onUpdate(updatedNode);
                        e.stopPropagation();
                      }}
                    />
                    <Tooltip
                      label="Edit Function"
                      aria-label="Edit Function Tooltip"
                    >
                      <IconButton
                        icon={<IconEdit size={16} />}
                        aria-label="Edit Function"
                        size="sm"
                        variant="ghost"
                        color={iconColor}
                        onClick={(e) => handleChainFunctionEdit(index, e)}
                        opacity={0}
                        _groupHover={{ opacity: 1 }}
                      />
                    </Tooltip>
                  </HStack>
                </Flex>

                <VStack align="stretch" spacing={2}>
                  {renderChainedElements(chain, index)}
                </VStack>
              </VStack>
            </React.Fragment>
          );
        })}
      </Box>

      {showChainButton && (
        <Button
          leftIcon={<IconPlus size={14} />}
          size="sm"
          position="absolute"
          bottom="-8"
          right="0"
          opacity="0"
          _groupHover={{ opacity: 1 }}
          bg="#202124"
          color="white"
          _hover={{ bg: "#303134" }}
          borderWidth={1}
          borderColor={iconColor}
          onClick={handleChainClick}
        >
          Chain
        </Button>
      )}

      <FunctionPickerModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setIsChaining(false);
          setEditingChainIndex(null);
        }}
        onSelect={handleSelectFunction}
      />

      {selectedField && !isChaining && (
        <PropertiesPanel
          selectedField={{ nodeId: node.id, field: selectedField.paramName }}
          fieldValue={selectedField.value}
          onClose={() => setSelectedField(null)}
          onFieldUpdate={handleFieldUpdate}
          availableVariables={availableVariables}
          availableDataVariables={availableDataVariables}
        />
      )}
    </Box>
  );
}
