import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "../../../../stores/appDescriptorStore";

interface NewDataModelModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newItem: { name: string }) => void;
}

const NewDataModelModal: React.FC<NewDataModelModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const { getFragment } = useAppDescriptorStore();

  const validateName = (value: string) => {
    if (!value) return "Name is required";

    // Check if the name is a valid Ruby constant name
    if (!/^[A-Z][a-zA-Z0-9]*$/.test(value)) {
      return "Name must start with an uppercase letter and contain only letters and numbers";
    }

    // Check if the name is singular
    if (value.endsWith("s")) {
      return 'Name should be singular (e.g., "Car" instead of "Cars")';
    }

    const dataModels =
      (getFragment("/essentials/dataModels") as { name: string }[]) || [];
    if (
      dataModels.some(
        (model) => model.name.toLowerCase() === value.toLowerCase()
      )
    ) {
      return "A data model with this name already exists";
    }

    return "";
  };

  useEffect(() => {
    setError(validateName(name));
  }, [name]);

  const handleSubmit = () => {
    if (!error && name.trim()) {
      onSubmit({ name });
      setName("");
      setError("");
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New data model</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!error}>
            <FormLabel>Name</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter data model name (e.g., Car, CustomerInvoice)"
            />
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={!name.trim() || !!error}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewDataModelModal;
