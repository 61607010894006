import React, { useState, useMemo, useEffect } from "react";
import { Box, Flex, VStack, Tooltip, IconButton, Text } from "@chakra-ui/react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiCpu,
  FiRefreshCcw,
} from "react-icons/fi";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { menuStructure } from "./menuStructure";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";

// MenuItem component
const MenuItem = ({
  icon,
  label,
  to,
  onClick,
  isCompact,
}: {
  icon: React.ReactNode;
  label: string;
  to: string;
  onClick: (to: string) => void;
  isCompact: boolean;
}) => {
  const location = useLocation();
  const isActive = location.pathname.endsWith(to);

  return (
    <Tooltip label={isCompact ? label : ""} placement="right">
      <Flex
        align="center"
        justify={isCompact ? "center" : "flex-start"}
        gap={2}
        p={2}
        bg={isActive ? "gray.700" : "transparent"}
        _hover={{ bg: "gray.600" }}
        borderRadius="md"
        cursor="pointer"
        onClick={() => onClick(to)}
      >
        {icon}
        {!isCompact && <Box>{label}</Box>}
      </Flex>
    </Tooltip>
  );
};

// New SectionTitle component
const SectionTitle: React.FC<{ title: string }> = ({ title }) => (
  <Text
    fontSize="xs"
    fontWeight="bold"
    color="gray.500"
    textTransform="uppercase"
    mb={2}
    mt={4}
  >
    {title}
  </Text>
);

// NavigationMenu component
interface NavigationMenuProps {
  onItemSelect: (to: string) => void;
  onToggleCompact: (isCompact: boolean) => void;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  onItemSelect,
  onToggleCompact,
}) => {
  const [isCompact, setIsCompact] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { appDescriptor } = useAppDescriptorStore();

  // Move the currentMenuStructure outside of useMemo
  const currentMenuStructure = menuStructure(projectId as string)?.map(
    (item) => {
      if (item?.dynamicChildren) {
        return {
          ...item,
          children: item.dynamicChildren(appDescriptor),
        };
      }
      return item;
    }
  );

  const handleItemClick = (to: string) => {
    navigate(`/projects/${projectId}/editor${to}`);
    setSelectedItem(to);
    onItemSelect(to);
  };

  const toggleCompact = () => {
    const newCompactState = !isCompact;
    setIsCompact(newCompactState);
    onToggleCompact(newCompactState);
  };

  // New function to find the best matching menu item
  const findBestMatchingItem = (
    path: string,
    items: typeof currentMenuStructure
  ) => {
    let bestMatch = null;
    let longestMatchLength = 0;

    items.forEach((item) => {
      if (path.startsWith(item.to) && item.to.length > longestMatchLength) {
        bestMatch = item;
        longestMatchLength = item.to.length;
      }
    });

    return bestMatch;
  };

  // Update the useEffect hook
  useEffect(() => {
    const currentPath = location.pathname.replace(
      `/projects/${projectId}/editor`,
      ""
    );
    const matchingItem = findBestMatchingItem(
      currentPath,
      currentMenuStructure
    );
    if (matchingItem) {
      setSelectedItem(matchingItem.to);
    }
  }, [location, projectId, currentMenuStructure]);

  const menuItems = useMemo(() => {
    const essentialsItems = currentMenuStructure.filter((item) =>
      [
        "pageGroups",
        "dataModels",
        "serviceMethods",
        "apiEndpoints",
        "dataTypes",
      ].includes(item.id)
    );
    const applicationAssetsItems = currentMenuStructure.filter((item) =>
      ["applicationAssets"].includes(item.id)
    );
    const userInterfaceItems = currentMenuStructure.filter((item) =>
      ["componentBlueprints", "applicationLayouts"].includes(item.id)
    );
    const usersAndSecurityItems = currentMenuStructure.filter((item) =>
      ["userGroups"].includes(item.id)
    );
    const devOpsItems = currentMenuStructure.filter((item) =>
      ["appDeployments"].includes(item.id)
    );
    const overviewItem = currentMenuStructure.find(
      (item) => item.id === "overview"
    );

    return {
      essentialsItems,
      applicationAssetsItems,
      userInterfaceItems,
      usersAndSecurityItems,
      devOpsItems,
      overviewItem,
    };
  }, [currentMenuStructure]);

  return (
    <Box
      w={isCompact ? "64px" : "200px"}
      bg="gray.900"
      color="white"
      h="100%"
      transition="width 0.3s"
      flexShrink={0}
      position="relative"
      borderRight="1px"
      borderColor="gray.700"
      display="flex"
      flexDirection="column"
    >
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        h="64px"
        pl={2}
        borderBottom="1px"
        borderColor="gray.700"
      >
        <IconButton
          icon={isCompact ? <FiChevronRight /> : <FiChevronLeft />}
          aria-label={isCompact ? "Expand menu" : "Collapse menu"}
          size="sm"
          onClick={toggleCompact}
          bg="gray.700"
          color="white"
          _hover={{ bg: "gray.600" }}
        />
      </Flex>
      <VStack spacing={2} align="stretch" p={2} flex={1} overflowY="auto">
        {!isCompact && <SectionTitle title="Essentials" />}
        {menuItems.essentialsItems.map((item) => (
          <MenuItem
            key={item.id}
            icon={item.icon ? <item.icon /> : null}
            label={item.label}
            to={item.to}
            onClick={handleItemClick}
            isCompact={isCompact}
          />
        ))}
        {!isCompact && <SectionTitle title="Application Assets" />}
        {menuItems.applicationAssetsItems.map((item) => (
          <MenuItem
            key={item.id}
            icon={item.icon ? <item.icon /> : null}
            label={item.label}
            to={item.to}
            onClick={handleItemClick}
            isCompact={isCompact}
          />
        ))}
        {!isCompact && <SectionTitle title="User Interface" />}
        {menuItems.userInterfaceItems.map((item) => (
          <MenuItem
            key={item.id}
            icon={item.icon ? <item.icon /> : null}
            label={item.label}
            to={item.to}
            onClick={handleItemClick}
            isCompact={isCompact}
          />
        ))}
        {!isCompact && <SectionTitle title="Users and Security" />}
        {menuItems.usersAndSecurityItems.map((item) => (
          <MenuItem
            key={item.id}
            icon={item.icon ? <item.icon /> : null}
            label={item.label}
            to={item.to}
            onClick={handleItemClick}
            isCompact={isCompact}
          />
        ))}
        {!isCompact && <SectionTitle title="DevOps" />}
        {menuItems.devOpsItems.map((item) => (
          <MenuItem
            key={item.id}
            icon={item.icon ? <item.icon /> : null}
            label={item.label}
            to={item.to}
            onClick={handleItemClick}
            isCompact={isCompact}
          />
        ))}
      </VStack>
      {menuItems.overviewItem && (
        <VStack spacing={2} align="stretch" p={2} mt="auto">
          <MenuItem
            key={`/projects/${projectId}/editor/descriptor/convert`}
            icon={<FiCpu />}
            label="AI"
            to="/descriptor/convert"
            onClick={handleItemClick}
            isCompact={isCompact}
          />
          <MenuItem
            key={menuItems.overviewItem.id}
            icon={
              menuItems.overviewItem.icon ? (
                <menuItems.overviewItem.icon />
              ) : null
            }
            label={menuItems.overviewItem.label}
            to={menuItems.overviewItem.to}
            onClick={handleItemClick}
            isCompact={isCompact}
          />
        </VStack>
      )}
    </Box>
  );
};

export default NavigationMenu;
