import {
  type Directive,
  type NestableDirective,
  type AnyDirective,
  isDirective,
} from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import { searchAnyDirective } from "../flowFunctionStore";

export type CallFunctionDirective = Directive<
  "callFunction",
  CallFunctionConfig
>;

export interface CallFunctionConfig {
  name: string;
  arguments: Record<string, NestableDirective>;
}

const execute = async (
  config: CallFunctionConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { name, arguments: functionArguments } = config;

  // Resolve the arguments first
  const resolvedArguments: Record<string, TypedValue> = {};
  for (const [argName, argDirective] of Object.entries(functionArguments)) {
    resolvedArguments[argName] =
      await executionContext.executeDirective(argDirective);
  }

  // Find the function declaration in external functions
  const functionDeclaration = executionContext.getExternalFunction(name);
  if (!functionDeclaration) {
    throw new Error(`Function ${name} not found`);
  }

  // Create a new FunctionExecutionInstance with a new scope
  const newFunctionExecutionContext = new FlowFunctionExecutionContext(
    functionDeclaration,
    { resolvedArguments },
  );

  // Initialize and execute the function
  const returnValue = await newFunctionExecutionContext.executeFunction();

  if (!returnValue) {
    throw new Error(
      `Function ${name} returned undefined instead of a TypedValue`,
    );
  }

  return returnValue;
};

export const searchDirective = (
  directive: CallFunctionDirective,
  id: string,
): AnyDirective | undefined => {
  return searchAnyDirective(Object.values(directive.config.arguments), id);
};

export const callFunction = {
  execute,
  availableActions: {},
  defaultConfig: () =>
    ({
      name: "",
      arguments: {},
    }) as CallFunctionConfig,
};

export function isCallFunctionDirective(
  directive: any,
): directive is CallFunctionDirective {
  const callFunctionConfigKeys: (keyof CallFunctionConfig)[] = [
    "name",
    "arguments",
  ];
  return isDirective(directive, "callFunction", callFunctionConfigKeys);
}
