import React, { useState } from "react";
import { Box, Button, VStack, Text, SimpleGrid } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { ContainerStructure } from "./ViewgraphEditor";
import { v4 as uuidv4 } from "uuid";

interface LayoutModeEditorProps {
  keypath: string;
  onLayoutChange: (layout: ContainerStructure) => void;
}

const LayoutModeEditor: React.FC<LayoutModeEditorProps> = ({
  keypath,
  onLayoutChange,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>("centered");
  const { getFragment } = useAppDescriptorStore();

  const applyTemplate = () => {
    let newLayout: ContainerStructure = {
      id: "root",
      size: { value: 1, unit: "fr" },
      isRoot: true,
      components: [],
      isScrollable: true,
      subcontainers: [],
      isLayoutApplied: true,
      layoutDirection: "vertical",
    };

    switch (selectedTemplate) {
      case "centered":
        newLayout.components = [
          {
            id: uuidv4(),
            blueprintName: "Layout_CenteredWithinViewport",
            propertiesBindings: {},
            reactions: {},
            layout: {},
            $slots: {
              content: {
                $children: [],
              },
            },
          },
        ];
        break;
      case "spread":
        newLayout.components = [
          {
            id: uuidv4(),
            blueprintName: "Layout_SpreadHorizontally",
            propertiesBindings: {},
            reactions: {},
            layout: {},
            $slots: {
              leftContent: {
                $children: [],
              },
              rightContent: {
                $children: [],
              },
            },
          },
        ];
        break;
      case "stack":
        newLayout.components = [
          {
            id: uuidv4(),
            blueprintName: "Layout_Stack",
            propertiesBindings: {},
            reactions: {},
            layout: {},
            $slots: {
              content: {
                $children: [],
              },
            },
          },
        ];
        break;
      case "two-columns":
        newLayout.components = [
          {
            id: uuidv4(),
            blueprintName: "Layout_TwoColumns",
            propertiesBindings: {},
            reactions: {},
            layout: {},
            $slots: {
              leftColumn: {
                $children: [],
              },
              rightColumn: {
                $children: [],
              },
            },
          },
        ];
        break;
    }

    onLayoutChange(newLayout);
  };

  return (
    <Box p={4}>
      <VStack spacing={4} align="stretch">
        <Text fontSize="lg" fontWeight="bold">
          Choose a Layout Template
        </Text>

        <SimpleGrid columns={2} spacing={4}>
          <LayoutTemplateCard
            title="Centered Within Viewport"
            description="Centers content with max width constraint"
            value="centered"
            isSelected={selectedTemplate === "centered"}
            onClick={() => setSelectedTemplate("centered")}
          />

          <LayoutTemplateCard
            title="Spread Horizontally"
            description="Spreads content horizontally with space between"
            value="spread"
            isSelected={selectedTemplate === "spread"}
            onClick={() => setSelectedTemplate("spread")}
          />

          <LayoutTemplateCard
            title="Stack Layout"
            description="Stacks content vertically with consistent spacing"
            value="stack"
            isSelected={selectedTemplate === "stack"}
            onClick={() => setSelectedTemplate("stack")}
          />

          <LayoutTemplateCard
            title="Two Columns"
            description="Equal width two-column layout"
            value="two-columns"
            isSelected={selectedTemplate === "two-columns"}
            onClick={() => setSelectedTemplate("two-columns")}
          />
        </SimpleGrid>

        <Button colorScheme="blue" onClick={applyTemplate} size="lg" mt={4}>
          Apply Layout
        </Button>
      </VStack>
    </Box>
  );
};

interface LayoutTemplateCardProps {
  title: string;
  description: string;
  value: string;
  isSelected: boolean;
  onClick: () => void;
}

const LayoutTemplateCard: React.FC<LayoutTemplateCardProps> = ({
  title,
  description,
  isSelected,
  onClick,
}) => (
  <Box
    borderWidth={2}
    borderColor={isSelected ? "blue.500" : "gray.200"}
    borderRadius="md"
    p={4}
    cursor="pointer"
    onClick={onClick}
    _hover={{ borderColor: "blue.300" }}
    bg={isSelected ? "blue.50" : "white"}
  >
    <VStack align="start" spacing={2}>
      <Text fontWeight="bold">{title}</Text>
      <Text fontSize="sm" color="gray.600">
        {description}
      </Text>
    </VStack>
  </Box>
);

export default LayoutModeEditor;
