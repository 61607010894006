import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Divider,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Select,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Route, Routes } from "react-router-dom";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import InlineEditableText from "@/bundles/DescriptorEditor/components/editors/InlineEditableText";
import {
  ApiEndpoint,
  ApiEndpointVersion,
  generateDefaultApiEndpointDescriptor,
  generateDefaultApiEndpointVersion,
  generateDefaultApiEndpointGroup,
  validateApiEndpointPath,
  validateGroupPath,
  ApiEndpointGroup,
} from "@/bundles/DescriptorEditor/schemas/essentials/apiEndpointsSchema";
import ApiEndpointEditor from "./ApiEndpointEditor";
import { FlowgraphEditor } from "@/bundles/FlowgraphEditor/components/FlowgraphEditor";
import { IconCode } from "@tabler/icons-react";

const HTTP_METHODS = [
  "GET",
  "POST",
  "PUT",
  "DELETE",
  "PATCH",
  "OPTIONS",
  "HEAD",
];

const ApiVersionEditor: React.FC<{
  version: ApiEndpointVersion;
  onDeleteVersion: () => void;
  onAddGroup: (versionId: string) => void;
}> = ({ version, onDeleteVersion, onAddGroup }) => {
  const { setFragment } = useAppDescriptorStore();
  const {
    isOpen: isFlowOpen,
    onOpen: onFlowOpen,
    onClose: onFlowClose,
  } = useDisclosure();
  const [selectedEndpoint, setSelectedEndpoint] = useState<ApiEndpoint | null>(
    null
  );
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | null>(
    null
  );
  const [selectedEndpointIndex, setSelectedEndpointIndex] = useState<
    number | null
  >(null);

  const updateVersion = (key: keyof ApiEndpointVersion, value: any) => {
    setFragment(`/essentials/apiEndpoints/id:${version.id}/${key}`, value);
  };

  const updateGroup = (
    groupIndex: number,
    key: keyof ApiEndpointGroup,
    value: any
  ) => {
    setFragment(
      `/essentials/apiEndpoints/id:${version.id}/groups/${groupIndex}/${key}`,
      value
    );
  };

  const handleFlowgraphChange = (flowgraph: {
    sequence: any[];
    $variables: any[];
  }) => {
    if (selectedGroupIndex === null || selectedEndpointIndex === null) return;

    const updatedEndpoints = [...version.groups[selectedGroupIndex].endpoints];
    updatedEndpoints[selectedEndpointIndex] = {
      ...updatedEndpoints[selectedEndpointIndex],
      flowgraph: {
        sequence: [...flowgraph.sequence],
        $variables: [...flowgraph.$variables],
      },
    };

    updateGroup(selectedGroupIndex, "endpoints", updatedEndpoints);
  };

  const handleEditLogic = (
    groupIndex: number,
    endpoint: ApiEndpoint,
    endpointIndex: number
  ) => {
    const endpointWithFlowgraph = {
      ...endpoint,
      flowgraph: endpoint.flowgraph || {
        sequence: [],
        $variables: [],
      },
    };

    setSelectedEndpoint(endpointWithFlowgraph);
    setSelectedGroupIndex(groupIndex);
    setSelectedEndpointIndex(endpointIndex);
    onFlowOpen();
  };

  const handleSaveLogic = () => {
    onFlowClose();
    setSelectedEndpoint(null);
    setSelectedGroupIndex(null);
    setSelectedEndpointIndex(null);
  };

  return (
    <Box borderWidth={1} borderRadius="md" p={4} mb={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading size="md">
          <InlineEditableText
            value={version.id}
            onSave={(value) => updateVersion("id", value)}
            placeholder="Version ID"
            fontWeight="bold"
          />
        </Heading>
        <IconButton
          aria-label="Delete version"
          icon={<FiTrash2 />}
          size="sm"
          variant="ghost"
          colorScheme="gray"
          onClick={onDeleteVersion}
        />
      </Flex>

      <InlineEditableText
        value={version.description}
        onSave={(value) => updateVersion("description", value)}
        placeholder="Version description"
      />

      <Divider my={4} />

      {version.groups.map((group, groupIndex) => (
        <Box key={group.name} mb={4}>
          <Heading size="sm" mb={2}>
            <InlineEditableText
              value={group.name}
              onSave={(value) => updateGroup(groupIndex, "name", value)}
              placeholder="Group name"
            />
          </Heading>

          <InlineEditableText
            value={group.path}
            onSave={(value) => updateGroup(groupIndex, "path", value)}
            placeholder="Group path"
            validator={(value) => validateGroupPath(value, version, group.name)}
          />

          <Table variant="simple" size="sm" mt={4}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Method</Th>
                <Th>Path</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {group.endpoints.map((endpoint, endpointIndex) => (
                <Tr key={endpoint.name}>
                  <Td>
                    <InlineEditableText
                      value={endpoint.name}
                      onSave={(value) => {
                        const updatedEndpoints = [...group.endpoints];
                        updatedEndpoints[endpointIndex] = {
                          ...endpoint,
                          name: value,
                        };
                        updateGroup(groupIndex, "endpoints", updatedEndpoints);
                      }}
                      placeholder="Endpoint name"
                    />
                  </Td>
                  <Td>
                    <Select
                      value={endpoint.method}
                      onChange={(e) => {
                        const updatedEndpoints = [...group.endpoints];
                        updatedEndpoints[endpointIndex] = {
                          ...endpoint,
                          method: e.target.value,
                        };
                        updateGroup(groupIndex, "endpoints", updatedEndpoints);
                      }}
                      size="sm"
                    >
                      {HTTP_METHODS.map((method) => (
                        <option key={method} value={method}>
                          {method}
                        </option>
                      ))}
                    </Select>
                  </Td>
                  <Td>
                    <InlineEditableText
                      value={endpoint.path}
                      onSave={(value) => {
                        const updatedEndpoints = [...group.endpoints];
                        updatedEndpoints[endpointIndex] = {
                          ...endpoint,
                          path: value,
                        };
                        updateGroup(groupIndex, "endpoints", updatedEndpoints);
                      }}
                      placeholder="Endpoint path"
                      validator={(value) =>
                        validateApiEndpointPath(
                          value,
                          group.path,
                          group.endpoints,
                          endpoint.name,
                          endpoint.method
                        )
                      }
                    />
                  </Td>
                  <Td>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Tooltip label="Edit Logic">
                        <IconButton
                          icon={<IconCode size={16} />}
                          aria-label="Edit logic"
                          size="sm"
                          variant="ghost"
                          onClick={() =>
                            handleEditLogic(groupIndex, endpoint, endpointIndex)
                          }
                        />
                      </Tooltip>
                      <IconButton
                        aria-label="Delete endpoint"
                        icon={<FiTrash2 />}
                        size="sm"
                        variant="ghost"
                        onClick={() => {
                          const updatedEndpoints = group.endpoints.filter(
                            (_, idx) => idx !== endpointIndex
                          );
                          updateGroup(
                            groupIndex,
                            "endpoints",
                            updatedEndpoints
                          );
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Button
            leftIcon={<FiPlus />}
            size="sm"
            onClick={() => {
              const newEndpoint = generateDefaultApiEndpointDescriptor(
                group.path,
                `New Endpoint ${group.endpoints.length + 1}`
              );
              updateGroup(groupIndex, "endpoints", [
                ...group.endpoints,
                newEndpoint,
              ]);
            }}
            mt={2}
          >
            Add Endpoint
          </Button>
        </Box>
      ))}

      <Button
        leftIcon={<FiPlus />}
        size="sm"
        onClick={() => onAddGroup(version.id)}
        mt={4}
      >
        Add Group
      </Button>

      <Modal isOpen={isFlowOpen} onClose={onFlowClose} size="6xl">
        <ModalOverlay />
        <ModalContent maxWidth="90vw">
          <ModalHeader>Edit Logic for {selectedEndpoint?.name}</ModalHeader>
          <ModalBody>
            {selectedEndpoint &&
              selectedGroupIndex !== null &&
              selectedEndpointIndex !== null && (
                <FlowgraphEditor
                  keypath={`/essentials/apiEndpoints/id:${version.id}/groups/${selectedGroupIndex}/endpoints/${selectedEndpointIndex}/flowgraph`}
                  flowgraphContext={{
                    declaredVariables: new Set(),
                    declaredPageVariables: new Set(),
                    parameters: [
                      {
                        name: "request",
                        dataType: "_types.ApiRequest",
                        description: "The incoming API request",
                      },
                      {
                        name: "response",
                        dataType: "_types.ApiResponse",
                        description: "The API response object",
                      },
                    ],
                  }}
                  initialFlowgraph={{
                    sequence: selectedEndpoint.flowgraph?.sequence || [],
                    $variables: selectedEndpoint.flowgraph?.$variables || [],
                  }}
                  onFlowgraphChange={handleFlowgraphChange}
                />
              )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveLogic}>
              Save
            </Button>
            <Button onClick={onFlowClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const ApiEndpointsIndex: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const apiVersions =
    (getFragment("/essentials/apiEndpoints") as ApiEndpointVersion[]) || [];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [namespaceToDelete, setNamespaceToDelete] = React.useState<
    string | null
  >(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const addNewVersion = () => {
    const newVersion = generateDefaultApiEndpointVersion();
    setFragment("/essentials/apiEndpoints", [...apiVersions, newVersion]);
  };

  const addNewGroup = (versionId: string) => {
    const versionIndex = apiVersions.findIndex((v) => v.id === versionId);
    if (versionIndex === -1) return;

    const newGroup = generateDefaultApiEndpointGroup(
      `New Group ${apiVersions[versionIndex].groups.length + 1}`
    );
    const updatedVersion = {
      ...apiVersions[versionIndex],
      groups: [...apiVersions[versionIndex].groups, newGroup],
    };

    const updatedVersions = [...apiVersions];
    updatedVersions[versionIndex] = updatedVersion;
    setFragment("/essentials/apiEndpoints", updatedVersions);
  };

  const addNewEndpoint = (versionId: string, groupIndex: number) => {
    const versionIndex = apiVersions.findIndex((v) => v.id === versionId);
    if (versionIndex === -1) return;

    const newEndpoint = generateDefaultApiEndpointDescriptor(
      apiVersions[versionIndex].groups[groupIndex].path,
      `New Endpoint ${
        apiVersions[versionIndex].groups[groupIndex].endpoints.length + 1
      }`
    );

    const updatedVersions = [...apiVersions];
    updatedVersions[versionIndex].groups[groupIndex].endpoints.push(
      newEndpoint
    );
    setFragment("/essentials/apiEndpoints", updatedVersions);
  };

  const deleteApiNamespace = () => {
    if (namespaceToDelete) {
      const updatedVersions = apiVersions.filter(
        (v) => v.id !== namespaceToDelete
      );
      setFragment("/essentials/apiEndpoints", updatedVersions);
      onClose();
      setNamespaceToDelete(null);
    }
  };

  const handleDeleteVersion = (versionId: string) => {
    setNamespaceToDelete(versionId);
    onOpen();
  };

  if (apiVersions.length === 0) {
    return (
      <Box textAlign="center" py={10}>
        <Text mb={4}>
          No API namespaces found. Click the button below to create your first
          API namespace.
        </Text>
        <Button leftIcon={<FiPlus />} onClick={addNewVersion}>
          Create API Namespace
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Button
        leftIcon={<FiPlus />}
        colorScheme="blue"
        onClick={addNewVersion}
        mb={6}
      >
        Add New API Namespace
      </Button>
      {apiVersions.map((version) => (
        <ApiVersionEditor
          key={version.id}
          version={version}
          onDeleteVersion={() => handleDeleteVersion(version.id)}
          onAddGroup={addNewGroup}
        />
      ))}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete API Version
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? This will delete the API version and all its
              associated groups and endpoints. This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteApiNamespace} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

const ApiEndpoints: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ApiEndpointsIndex />} />
      <Route path="/:endpointId" element={<ApiEndpointEditor />} />
    </Routes>
  );
};

export default ApiEndpoints;
