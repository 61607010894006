import {
  type Directive,
  type AnyDirective,
  isDirective,
} from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import { searchAnyDirective } from "../flowFunctionStore";
import { CancellablePromise } from "../utils/CancellablePromise";

export type ExecuteAnyInParallelDirective = Directive<
  "executeAnyInParallel",
  ExecuteAnyInParallelConfig
>;

export interface ExecuteAnyInParallelConfig {
  tracks: Array<{
    trackName: string;
    flowgraph: AnyDirective[];
  }>;
}

interface SuccessResult {
  trackName: string;
  returnValue: TypedValue;
}

interface ErrorResult {
  trackName: string;
  error: Error;
}

type TrackResult = SuccessResult | ErrorResult;

const execute = async (
  config: ExecuteAnyInParallelConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { tracks } = config;

  const trackPromises = tracks.map((track) => {
    const { trackName, flowgraph } = track;
    return new CancellablePromise<TrackResult>(async (resolve, _, onCancel) => {
      try {
        let result: TypedValue | undefined;
        for (const directive of flowgraph) {
          if (onCancel.isCancelled) {
            return;
          }
          result = await executionContext.executeDirective(directive);
        }
        resolve({ trackName, returnValue: result as TypedValue });
      } catch (error) {
        resolve({
          trackName,
          error: error instanceof Error ? error : new Error(String(error)),
        });
      }
    });
  });

  try {
    const result = await Promise.race(trackPromises);

    // Cancel other tracks
    trackPromises.forEach((p) => p.cancel());

    if ("error" in result) {
      throw result.error;
    }

    return {
      type: "_types.Dictionary",
      value: result,
    };
  } finally {
    // Ensure all promises are cancelled in case of any errors
    trackPromises.forEach((p) => p.cancel());
  }
};

export const searchDirective = (
  directive: ExecuteAnyInParallelDirective,
  id: string,
): AnyDirective | undefined => {
  if (directive.id === id) {
    return directive;
  }

  for (const track of directive.config.tracks) {
    for (const nestedDirective of track.flowgraph) {
      const found = searchAnyDirective([nestedDirective], id);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};

export const executeAnyInParallel = {
  execute,
  availableActions: {},
  defaultConfig: () =>
    ({
      tracks: [],
    }) as ExecuteAnyInParallelConfig,
};

export function isExecuteAnyInParallelDirective(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directive: any,
): directive is ExecuteAnyInParallelDirective {
  const executeAnyInParallelConfigKeys: (keyof ExecuteAnyInParallelConfig)[] = [
    "tracks",
  ];
  return isDirective(
    directive,
    "executeAnyInParallel",
    executeAnyInParallelConfigKeys,
  );
}
