import React from 'react'
import { Box, Divider, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { DirectiveContainer } from "../DirectiveContainer";
import { DirectiveListEditor } from "../DirectiveListEditor";
import { LoopForEachDirective } from "@/runtime-js/src/directives/loopForEach";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface LoopForEachEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function LoopForEachEditor({ keypath, flowgraphContext }: LoopForEachEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as LoopForEachDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const handleIterableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFragment(keypath, {
      ...directive,
      config: { ...directive.config, iterable: e.target.value },
    });
  };

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="iterable" fontSize="sm">
          Iterable
        </FormLabel>
        <DirectiveContainer
          keypath={`${keypath}.config.iterable`}
          onRemoveDirective={() => {}}
          flowgraphContext={flowgraphContext}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="loopExecution" fontSize="sm">
          Loop execution
        </FormLabel>
        <DirectiveListEditor
          keypath={`${keypath}/config/loopingFlowgraph`}
          flowgraphContext={flowgraphContext}
        />
      </FormControl>
      <Divider my={4} borderColor="whiteAlpha.400" borderStyle="dotted" />
      <fieldset>
        <legend>Iteration variables</legend>
        <FormControl>
          <FormLabel htmlFor="iterable" fontSize="sm">
            Current item in the loop
          </FormLabel>
          <Input
            id="iterable-variable-currItem"
            value={directive.config.iteratorVariables?.currentItem}
            onChange={handleIterableChange}
            borderColor="gray.500"
            bg="gray.600"
            color="white"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="iterable" fontSize="sm">
            Current index in the loop
          </FormLabel>
          <Input
            id="iterable-variable-currItem"
            value={directive.config.iteratorVariables?.currentIndex}
            onChange={handleIterableChange}
            borderColor="gray.500"
            bg="gray.600"
            color="white"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="iterable" fontSize="sm">
            Iterator length
          </FormLabel>
          <Input
            id="iterable-variable-currItem"
            value={directive.config.iteratorVariables?.itemCount}
            onChange={handleIterableChange}
            borderColor="gray.500"
            bg="gray.600"
            color="white"
          />
        </FormControl>
      </fieldset>
    </Box>
  );
}
