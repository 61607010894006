import { Directive, AnyDirective } from "./directiveTypes";
import { CallFunctionDirective } from "./callFunction";
import { TypedValue } from "../types/typedValue";

export type ModelMethod = "all" | "create" | "update" | "destroy" | "find";

export interface ExecuteModelMethodConfig {
  modelName: string;
  method: ModelMethod;
  arguments: Record<string, AnyDirective>;
}

export type ExecuteModelMethodDirective = Directive<
  "executeModelMethod",
  ExecuteModelMethodConfig
>;

function generateMockData(count: number = 10): any[] {
  return Array.from({ length: count }, (_, index) => {
    return {
      id: index + 1,
      name: `Mock Item ${index + 1}`,
      description: `This is a mock item with ID ${index + 1}`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  });
}

export const executeModelMethod = {
  execute: async (
    config: ExecuteModelMethodConfig,
    context: any
  ): Promise<TypedValue> => {
    console.log(`Executing model class method: ${config.method} on ${config.modelName}`);
    
    const { method, arguments: args } = config;
    let mockData = generateMockData();

    switch (method) {
      case "all":
        return {
          type: "_types.List",
          value: mockData,
        };
      case "create":
        const newId = mockData.length + 1;
        const newRecord = { id: newId, ...args, createdAt: new Date().toISOString(), updatedAt: new Date().toISOString() };
        mockData.push(newRecord);
        return {
          type: "_types.Dictionary",
          value: newRecord,
        };
      case "update":
        const recordToUpdate = mockData[0]; // Always update the first record
        Object.assign(recordToUpdate, args, { updatedAt: new Date().toISOString() });
        return {
          type: "_types.Dictionary",
          value: recordToUpdate,
        };
      case "destroy":
        const removedRecord = mockData.shift(); // Always remove the first record
        return {
          type: "_types.Dictionary",
          value: removedRecord,
        };
      case "find":
        return {
          type: "_types.Dictionary",
          value: mockData[0], // Always return the first record
        };
      default:
        throw new Error(`Unsupported method: ${method}`);
    }
  },
  defaultConfig: (): ExecuteModelMethodConfig => ({
    modelName: "",
    method: "all",
    arguments: {},
  }),
  availableActions: [],
};

// Helper function to transform the directive into a CallFunctionDirective
export const transformToCallFunction = (
  config: ExecuteModelMethodConfig
): CallFunctionDirective => ({
  id: "executeModelMethod",
  directiveType: "callFunction",
  config: {
    name: "_functions.DataModel.ExecuteModelMethod",
    arguments: {
      modelName: { id: "modelName", directiveType: "literalValue", config: { type: "_types.String", value: config.modelName } },
      method: { id: "method", directiveType: "literalValue", config: { type: "_types.String", value: config.method } },
      arguments: { id: "arguments", directiveType: "literalValue", config: { type: "_types.Dictionary", value: config.arguments } },
    },
  },
});