import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  VStack,
  HStack,
  Text,
  Box,
  List,
  ListItem,
  Flex,
  Tooltip,
  Code,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";
import { FunctionDescriptor } from "../types";
import { IconArrowRight, IconFunction, IconLink } from "@tabler/icons-react";
import { useFlowgraphStore } from "../stores/FlowgraphStore";

interface FunctionPickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (func: FunctionDescriptor) => void;
}

export function FunctionPickerModal({
  isOpen,
  onClose,
  onSelect,
}: FunctionPickerModalProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFunction, setSelectedFunction] =
    useState<FunctionDescriptor | null>(null);
  const { getAllFunctions } = useFlowgraphStore();

  const functions = getAllFunctions();

  const filteredFunctions = functions.filter((func) =>
    func.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const generateFunctionSignature = (func: FunctionDescriptor) => {
    if (!func.parameters) return func.name + "()";
    const parameterList = func.parameters.map((param) => param.name).join(", ");
    return `${func.name}(${parameterList})`;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent bg="gray.800" color="white">
        <ModalHeader>Select a Formula Function</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Box flex="1" mr={4}>
              <Input
                placeholder="Search functions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                mb={4}
                bg="gray.700"
                border="none"
              />
              <List spacing={2} maxHeight="500px" overflowY="auto">
                {filteredFunctions.map((func) => (
                  <ListItem
                    key={func.name}
                    p={2}
                    borderRadius="md"
                    cursor="pointer"
                    onClick={() => setSelectedFunction(func)}
                    bg={
                      selectedFunction?.name === func.name
                        ? "blue.600"
                        : "gray.700"
                    }
                    _hover={{ bg: "gray.600" }}
                  >
                    <HStack>
                      <Box as={IconFunction} color="blue.300" />
                      <Text>{func.name}</Text>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box flex="2">
              {selectedFunction ? (
                <VStack
                  align="start"
                  spacing={4}
                  bg="gray.700"
                  p={4}
                  borderRadius="md"
                >
                  <Text fontSize="xl" fontWeight="bold">
                    {selectedFunction.name}
                  </Text>
                  <Text fontFamily="monospace" fontSize="sm">
                    {generateFunctionSignature(selectedFunction)}
                  </Text>
                  {selectedFunction.description && (
                    <Text>{selectedFunction.description}</Text>
                  )}
                  {selectedFunction.parameters &&
                    selectedFunction.parameters.length > 0 && (
                      <>
                        <Text fontWeight="bold">Parameters:</Text>
                        <List spacing={2} width="100%">
                          {selectedFunction.parameters.map((param) => (
                            <ListItem key={param.name}>
                              <Flex justifyContent="space-between">
                                <Text fontWeight="semibold">{param.name}</Text>
                                <Text color="gray.300">
                                  {param.schema?.type || "Any"}
                                </Text>
                              </Flex>
                              {param.description && (
                                <Text color="gray.400">
                                  {param.description}
                                </Text>
                              )}
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )}
                  {selectedFunction.returnValue && (
                    <>
                      <Text fontWeight="bold">Returns:</Text>
                      <Text>
                        {selectedFunction.returnValue.description}{" "}
                        {selectedFunction.returnValue.schema && (
                          <Text as="span" fontFamily="monospace">
                            ({selectedFunction.returnValue.schema.type})
                          </Text>
                        )}
                      </Text>
                    </>
                  )}
                  {selectedFunction.chainedEditorTemplate && (
                    <HStack spacing={1}>
                      <Text fontWeight="bold">Can be chained</Text>
                      <IconLink size={16} />
                    </HStack>
                  )}
                </VStack>
              ) : (
                <Box bg="gray.700" p={4} borderRadius="md">
                  <Text>Select a function to see details.</Text>
                </Box>
              )}
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} textColor="white">
            Cancel
          </Button>
          <Tooltip
            label="Select a function first"
            isDisabled={!!selectedFunction}
          >
            <Button
              colorScheme="blue"
              textColor="white"
              onClick={() => {
                if (selectedFunction) {
                  onSelect(selectedFunction);
                }
              }}
              isDisabled={!selectedFunction}
            >
              Select
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
