import React, { useState, useEffect } from 'react';
import {
  Button,
  VStack,
  HStack,
  Input,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';

interface TableColumnsConfigEditorProps {
  schema: any;
  keypath: string;
}

interface ColumnConfig {
  name: string;
  fieldKey: string;
}

const TableColumnsConfigEditor: React.FC<TableColumnsConfigEditorProps> = ({ schema, keypath }) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [columns, setColumns] = useState<ColumnConfig[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const binding = getFragment(keypath);
    setColumns(binding?.config?.value || []);
  }, [getFragment, keypath]);

  const handleEdit = (newValue: ColumnConfig[]) => {
    setColumns(newValue);
    setFragment(`${keypath}/config/value`, newValue);
  };

  const addColumn = () => {
    handleEdit([...columns, { name: '', fieldKey: '' }]);
  };

  const removeColumn = (index: number) => {
    const newValue = [...columns];
    newValue.splice(index, 1);
    handleEdit(newValue);
  };

  const updateColumn = (index: number, field: 'name' | 'fieldKey', newValue: string) => {
    const newColumns = [...columns];
    newColumns[index][field] = newValue;
    handleEdit(newColumns);
  };

  const InlineEditor = () => (
    <Button size="sm" onClick={() => setIsModalOpen(true)}>
      Edit Table Columns ({columns.length})
    </Button>
  );

  return (
    <>
      <InlineEditor />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{schema.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              {columns.map((column, index) => (
                <HStack key={index}>
                  <Input
                    placeholder="Column Name"
                    value={column.name}
                    onChange={(e) => updateColumn(index, 'name', e.target.value)}
                  />
                  <Input
                    placeholder="Field Key"
                    value={column.fieldKey}
                    onChange={(e) => updateColumn(index, 'fieldKey', e.target.value)}
                  />
                  <IconButton
                    aria-label="Remove column"
                    icon={<DeleteIcon />}
                    onClick={() => removeColumn(index)}
                    size="sm"
                  />
                </HStack>
              ))}
              <Button leftIcon={<AddIcon />} onClick={addColumn} size="sm">
                Add Column
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TableColumnsConfigEditor;