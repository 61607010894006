import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { DirectiveContainer } from '@/bundles/DescriptorEditor/components/editors/FlowgraphEditor/components/DirectiveContainer';

interface PageArgumentsEditorProps {
  pageId: string;
  currentArguments: string;
  onSave: (newArguments: string) => void;
}

export const PageArgumentsEditor: React.FC<PageArgumentsEditorProps> = ({ pageId, currentArguments, onSave }) => {
  const { getFragment, setFragment, getPage } = useAppDescriptorStore();
  const page = getPage(pageId);
  const initializerParameters = page?.initializationLogic?.parameters || [];

  const handleSave = () => {
    const newArguments = JSON.stringify(getFragment('tempPageArguments'));
    onSave(newArguments);
    setFragment('tempPageArguments', null);
  };

  React.useEffect(() => {
    setFragment('tempPageArguments', JSON.parse(currentArguments || '{}'));
  }, [currentArguments]);

  if (initializerParameters.length === 0) {
    return <Box>This page does not have any initializer parameters.</Box>;
  }

  return (
    <Box>
      {initializerParameters.map((param: any, index: number) => (
        <DirectiveContainer
          key={index}
          keypath={`tempPageArguments/${param.name}`}
          onRemoveDirective={() => {}}
          declaredVariables={new Set()}
        />
      ))}
      <Button onClick={handleSave} mt={4}>Save Arguments</Button>
    </Box>
  );
};