import {
  type Directive,
  type AnyDirective,
  isDirective,
} from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import { searchAnyDirective } from "../flowFunctionStore";
import { CancellablePromise } from "../utils/CancellablePromise";

export type ExecuteBranchesDirective = Directive<
  "executeBranches",
  ExecuteBranchesConfig
>;

export interface ExecuteBranchesConfig {
  branches: BranchConfig[];
  maxBranchesToExecute?: number;
  requiredCompletionCount?: number;
  throwOnBranchError?: boolean;
}

interface BranchConfig {
  name: string;
  condition?: AnyDirective;
  flowgraph: AnyDirective[];
}

interface BranchResult {
  name: string;
  returnValue?: TypedValue;
  error?: Error;
}

const execute = async (
  config: ExecuteBranchesConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const {
    branches,
    maxBranchesToExecute,
    requiredCompletionCount,
    throwOnBranchError = false,
  } = config;

  const branchesToExecute: BranchConfig[] = [];
  const skippedBranches: BranchConfig[] = [];

  for (const branch of branches) {
    // If maxBranchesToExecute is reached, immediately skip remaining branches
    if (
      maxBranchesToExecute &&
      branchesToExecute.length >= maxBranchesToExecute
    ) {
      skippedBranches.push(branch);
      continue;
    }

    // If the branch has no condition or the condition return value is true, add the branch to the readyForExecution array
    if (
      !branch.condition ||
      (await executionContext.executeDirective(branch.condition)).value === true
    ) {
      branchesToExecute.push(branch);
    } else {
      // Here the branch had a condition and it was not met, so skip the branch
      skippedBranches.push(branch);
    }
  }

  return executeBranchesInParallel(
    branchesToExecute,
    skippedBranches,
    requiredCompletionCount,
    throwOnBranchError,
    executionContext,
  );
};

const executeBranchesInParallel = async (
  branchesToExecute: BranchConfig[],
  skippedBranches: BranchConfig[],
  requiredCompletionCount: number | undefined,
  throwOnBranchError: boolean,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  let executingBranches: Array<{
    name: string;
    promise: CancellablePromise<BranchResult>;
  }> = [];
  const completedBranches: BranchResult[] = [];
  let abortedBranches: BranchResult[] = [];
  const erroredBranches: BranchResult[] = [];

  const startBranch = (branch: BranchConfig) => {
    const branchPromise = new CancellablePromise<BranchResult>(
      async (resolve) => {
        try {
          const branchContext = new FlowFunctionExecutionContext(
            {
              name: branch.name,
              flowgraph: branch.flowgraph,
            },
            {
              resolvedArguments: executionContext.getArguments(),
              variables: executionContext.getVariables(),
              externalFunctions: executionContext.getExternalFunctions(),
            },
          );
          const returnValue = await branchContext.executeFunction();
          resolve({ name: branch.name, returnValue });
        } catch (error) {
          resolve({
            name: branch.name,
            error: error instanceof Error ? error : new Error(String(error)),
          });
        }
      },
    );
    executingBranches.push({ name: branch.name, promise: branchPromise });
  };

  try {
    // Start initial branches
    branchesToExecute.forEach(startBranch);

    while (executingBranches.length > 0) {
      const completedBranch = await Promise.race(
        executingBranches.map((bp) => bp.promise),
      );
      executingBranches = executingBranches.filter(
        (bp) => bp.name !== completedBranch.name,
      );

      if (completedBranch.error) {
        erroredBranches.push(completedBranch);
        if (throwOnBranchError) {
          throw completedBranch.error;
        }
      } else {
        completedBranches.push(completedBranch);
      }

      if (
        requiredCompletionCount &&
        completedBranches.length >= requiredCompletionCount
      ) {
        break;
      }
    }

    // Cancel remaining executing branches
    executingBranches.forEach((bp) => bp.promise.cancel());
    abortedBranches = executingBranches.map((bp) => ({ name: bp.name }));

    if (
      requiredCompletionCount &&
      completedBranches.length < requiredCompletionCount
    ) {
      throw new Error(
        `Not enough branches completed successfully. Required: ${requiredCompletionCount}, Completed: ${completedBranches.length}`,
      );
    }

    return {
      type: "_types.Dictionary",
      value: {
        completed: {
          type: "_types.List",
          value: completedBranches.map((r) => ({
            type: "_types.Dictionary",
            value: {
              branchName: { type: "_types.String", value: r.name },
              returnValue: r.returnValue,
            },
          })),
        },
        aborted: {
          type: "_types.List",
          value: abortedBranches.map((b) => ({
            type: "_types.Dictionary",
            value: {
              branchName: { type: "_types.String", value: b.name },
            },
          })),
        },
        errored: {
          type: "_types.List",
          value: erroredBranches.map((r) => ({
            type: "_types.Dictionary",
            value: {
              branchName: { type: "_types.String", value: r.name },
              errorMessage: { type: "_types.String", value: r.error?.message },
            },
          })),
        },
        skipped: {
          type: "_types.List",
          value: skippedBranches.map((b) => ({
            type: "_types.Dictionary",
            value: {
              branchName: { type: "_types.String", value: b.name },
            },
          })),
        },
      },
    };
  } finally {
    executingBranches.forEach((bp) => bp.promise.cancel());
  }
};

export const executeBranches = {
  execute,
  availableActions: {},
  defaultConfig: () =>
    ({
      branches: [
        {
          name: "Branch 1",
          condition: undefined,
          flowgraph: [],
        },
      ],
      throwOnError: false,
    }) as ExecuteBranchesConfig,
};

export function isExecuteBranchesDirective(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directive: any,
): directive is ExecuteBranchesDirective {
  const executeBranchesConfigKeys: (keyof ExecuteBranchesConfig)[] = [
    "branches",
  ];
  return isDirective(directive, "executeBranches", executeBranchesConfigKeys);
}

export const searchDirective = (
  directive: ExecuteBranchesDirective,
  id: string,
): AnyDirective | undefined => {
  if (directive.id === id) {
    return directive;
  }

  for (const branch of directive.config.branches) {
    if (branch.condition && branch.condition.id === id) {
      return branch.condition;
    }

    for (const nestedDirective of branch.flowgraph) {
      const found = searchAnyDirective([nestedDirective], id);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};
