import React from 'react'
import { Box, Button, IconButton } from "@chakra-ui/react";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";

import { DirectiveContainer } from "../../DirectiveContainer";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { AnyDirective } from "@/runtime-js/src/directives/directiveTypes";
import { LiteralValueDirective } from "@/runtime-js/src/directives/literalValue";

interface ListStructureEditorProps {
  keypath: string;
}

export function ListStructureEditor({ keypath }: ListStructureEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as LiteralValueDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const directives = Array.isArray(directive.config.value)
    ? directive.config.value
    : [];

  const addDirective = () => {
    const itemsSchema = directive.config.items;
    let newDirective: AnyDirective;

    if (itemsSchema) {
      newDirective = {
        directiveType: "literalValue",
        config: { type: itemsSchema.type, value: null },
      };
      
      if (itemsSchema.type === "_types.Dictionary" && itemsSchema.properties) {
        newDirective.config.value = {};
        Object.entries(itemsSchema.properties).forEach(([key, propSchema]) => {
          newDirective.config.value[key] = {
            directiveType: "literalValue",
            config: { type: propSchema.type, value: null },
          };
        });
      }
    } else {
      newDirective = {
        directiveType: "literalValue",
        config: { type: "_types.String", value: "" },
      };
    }

    const newDirectives = [...directives, newDirective];
    setFragment(keypath, {
      ...directive,
      config: { ...directive.config, value: newDirectives },
    });
  };

  const removeDirective = (index: number) => {
    const newDirectives = directives.filter(
      (_: AnyDirective, i: number) => i !== index,
    );
    setFragment(keypath, {
      ...directive,
      config: { ...directive.config, value: newDirectives },
    });
  };

  const reorderDirective = (oldIndex: number, newIndex: number) => {
    const clampedNewIndex = Math.max(
      0,
      Math.min(newIndex, directives.length - 1),
    );
    const newDirectives = [...directives];
    const [movedDirective] = newDirectives.splice(oldIndex, 1);
    newDirectives.splice(clampedNewIndex, 0, movedDirective);
      setFragment(keypath, {
      ...directive,
      config: { ...directive.config, value: newDirectives },
    });
  };

  return (
    <Box>
      {directives.length > 0
        ? directives.map((_: AnyDirective, index: number) => (
            <Box key={index} className="flex mb-2">
              <DirectiveContainer
                keypath={`${keypath}/config/value/${index}`}
                onRemoveDirective={() => removeDirective(index)}
                declaredVariables={new Set()}
                enabledDirectives={[]}
                resolvedValueSchema={JSON.stringify(directive.config.items)}
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                ml={2}
              >
                <IconButton
                  aria-label="Move up"
                  icon={<ArrowUpIcon />}
                  onClick={() => reorderDirective(index, index - 1)}
                  bg="gray.500"
                  color="white"
                  size="sm"
                  mb={1}
                  isDisabled={index === 0}
                />
                <IconButton
                  aria-label="Move down"
                  icon={<ArrowDownIcon />}
                  onClick={() => reorderDirective(index, index + 1)}
                  bg="gray.500"
                  color="white"
                  size="sm"
                  isDisabled={index === directives.length - 1}
                />
              </Box>
            </Box>
          ))
        : null}
      <Button onClick={addDirective} bg="blue.500" color="white" size="sm">
        Add directive to list
      </Button>
    </Box>
  );
}