import React from 'react'
import { Box, Divider, FormControl, FormLabel } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { DirectiveContainer } from "../DirectiveContainer";
import { DirectiveListEditor } from "../DirectiveListEditor";
import { OverRangeLoopDirective } from "@/runtime-js/src/directives/overrangeLoop";

interface OverRangeLoopEditorProps {
  keypath: string;
}

export function OverRangeLoopEditor({ keypath }: OverRangeLoopEditorProps) {
  const { getFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as OverRangeLoopDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="start" fontSize="sm">
          Start
        </FormLabel>
        <DirectiveContainer
          keypath={`${keypath}/config/start`}
          onRemoveDirective={() => {}}
          declaredVariables={new Set()}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="end" fontSize="sm">
          End
        </FormLabel>
        <DirectiveContainer
          keypath={`${keypath}/config/end`}
          onRemoveDirective={() => {}}
          declaredVariables={new Set()}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="step" fontSize="sm">
          Step
        </FormLabel>
        <DirectiveContainer
          keypath={`${keypath}/config/step`}
          onRemoveDirective={() => {}}
          declaredVariables={new Set()}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="startInclusive" fontSize="sm">
          Start inclusive
        </FormLabel>
        <DirectiveContainer
          keypath={`${keypath}/config/startInclusive`}
          onRemoveDirective={() => {}}
          declaredVariables={new Set()}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="endInclusive" fontSize="sm">
          End inclusive
        </FormLabel>
        <DirectiveContainer
          keypath={`${keypath}/config/endInclusive`}
          onRemoveDirective={() => {}}
          declaredVariables={new Set()}
        />
      </FormControl>
      <Divider my={4} borderColor="whiteAlpha.400" borderStyle="dotted" />
      <FormControl>
        <FormLabel htmlFor="loopExecution" fontSize="sm">
          Loop execution
        </FormLabel>
        <DirectiveListEditor keypath={`${keypath}/config/loopingFlowgraph`} />
      </FormControl>
    </Box>
  );
}
