import CollectionTableConfigEditor from "./CollectionTableConfigEditor";
import TableColumnsConfigEditor from "./TableColumnsConfigEditor";
import ActiveCollectionEditor from "./ActiveCollectionEditor";
import ActiveRecordEditor from "./ActiveRecordEditor";
import BooleanEditor from "./BooleanEditor";
import NumberEditor from "./NumberEditor";
import StringEditor from "./StringEditor";
import CreatePageEditor from "./CreatePageEditor";
import DestroyPageEditor from "./DestroyPageEditor";
import UpdatePageEditor from "./UpdatePageEditor";
import CommentConfigEditor from "./CommentConfigEditor";

const editors: Record<string, React.ComponentType<any>> = {
  Boolean: BooleanEditor,
  Number: NumberEditor,
  String: StringEditor,
  CollectionTableConfig: CollectionTableConfigEditor,
  TableColumnsConfig: TableColumnsConfigEditor,
  ActiveCollection: ActiveCollectionEditor,
  ActiveRecord: ActiveRecordEditor,
  CreatePage: CreatePageEditor,
  DestroyPage: DestroyPageEditor,
  UpdatePage: UpdatePageEditor,
  CommentConfig: CommentConfigEditor,
};

export default editors;

export type EditorComponents = typeof editors;
