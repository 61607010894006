import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from "@chakra-ui/react";

interface Column {
  name: string;
  render: (row: any) => React.ReactNode;
}

interface DataTableProps {
  columns: Column[];
  data: any[];
  onRowClick: (item: any, index: number) => void;
}

const DataTable: React.FC<DataTableProps> = ({ columns, data, onRowClick }) => {
  const tableData = Array.isArray(data) ? data : [];

  return (
    <Box overflowX="auto">
      <Table variant="simple" colorScheme="gray" size="sm">
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th key={index} p={2}>
                {column.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tableData.length === 0 ? (
            <Tr>
              <Td colSpan={columns.length} textAlign="center" py={4}>
                <Text color="gray.500">No data available</Text>
              </Td>
            </Tr>
          ) : (
            tableData.map((row, rowIndex) => (
              <Tr
                key={rowIndex}
                onClick={() => onRowClick(row, rowIndex)}
                _hover={{ cursor: "pointer", backgroundColor: "gray.100" }}
                p={2}
              >
                {columns.map((column, colIndex) => (
                  <Td key={colIndex} p={2}>
                    {column.render(row)}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DataTable;
