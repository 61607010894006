import React, { useState } from "react";
import {
  Box,
  Text,
  VStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { useParams } from "react-router-dom";

interface DataVariable {
  id: string;
  name: string;
  type: string;
  dataModel: string;
  description: string;
  pageId?: string;
}

const DataVariables: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const { pageId } = useParams<{ pageId: string }>();
  const allDataVariables = getFragment("/essentials/dataVariables") || [];

  const variables = (allDataVariables as DataVariable[]).filter(
    (v) => !v.pageId || v.pageId === pageId?.replace("id:", "")
  );

  // Get available data models from the app descriptor store
  const dataModels =
    (getFragment("/essentials/dataModels") as { name: string }[]) || [];
  const availableDataModels = dataModels.map((model) => model.name);

  const variableTypes = [
    "Collection",
    "Record",
    "SingleValue",
    "Array",
    "Dictionary",
  ];

  const handleAddVariable = () => {
    const newVariable: DataVariable = {
      id: Math.random().toString(36).substr(2, 9),
      name: "",
      type: "Collection",
      dataModel: availableDataModels[0] || "",
      description: "",
      pageId: pageId?.replace("id:", ""),
    };
    const updatedVariables = [...allDataVariables, newVariable];
    setFragment("/essentials/dataVariables", updatedVariables);
  };

  const handleDeleteVariable = (id: string) => {
    const updatedVariables = variables.filter((v) => v.id !== id);
    setFragment("/essentials/dataVariables", updatedVariables);
  };

  const handleUpdateVariable = (
    id: string,
    field: keyof DataVariable,
    value: string
  ) => {
    const updatedVariables = variables.map((v) =>
      v.id === id ? { ...v, [field]: value } : v
    );
    setFragment("/essentials/dataVariables", updatedVariables);
  };

  return (
    <Box p={4}>
      <VStack align="stretch" spacing={4}>
        <HStack justify="space-between">
          <Text fontSize="xl" fontWeight="bold">
            Data Variables
          </Text>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            size="sm"
            onClick={handleAddVariable}
          >
            Add Variable
          </Button>
        </HStack>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Type</Th>
              <Th>Data Model</Th>
              <Th>Description</Th>
              <Th>Scope</Th>
              <Th width="50px"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {variables.map((variable) => (
              <Tr key={variable.id}>
                <Td>
                  <Input
                    size="sm"
                    value={variable.name}
                    onChange={(e) =>
                      handleUpdateVariable(variable.id, "name", e.target.value)
                    }
                    placeholder="Variable name"
                  />
                </Td>
                <Td>
                  <Select
                    size="sm"
                    value={variable.type}
                    onChange={(e) =>
                      handleUpdateVariable(variable.id, "type", e.target.value)
                    }
                  >
                    {variableTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <Select
                    size="sm"
                    value={variable.dataModel}
                    onChange={(e) =>
                      handleUpdateVariable(
                        variable.id,
                        "dataModel",
                        e.target.value
                      )
                    }
                    placeholder="Select data model"
                  >
                    {availableDataModels.map((model) => (
                      <option key={model} value={model}>
                        {model}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <Input
                    size="sm"
                    value={variable.description}
                    onChange={(e) =>
                      handleUpdateVariable(
                        variable.id,
                        "description",
                        e.target.value
                      )
                    }
                    placeholder="Description"
                  />
                </Td>
                <Td>
                  <Select
                    size="sm"
                    value={variable.pageId ? "page" : "global"}
                    onChange={(e) =>
                      handleUpdateVariable(
                        variable.id,
                        "pageId",
                        e.target.value === "page"
                          ? pageId?.replace("id:", "")
                          : undefined
                      )
                    }
                  >
                    <option value="global">Global</option>
                    <option value="page">Page-specific</option>
                  </Select>
                </Td>
                <Td>
                  <IconButton
                    aria-label="Delete variable"
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => handleDeleteVariable(variable.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {variables.length === 0 && (
          <Text color="gray.500" textAlign="center" py={4}>
            No variables defined. Click "Add Variable" to create one.
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default DataVariables;
