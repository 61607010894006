import React from 'react';
import { Box, Button, Card, CardBody, CardHeader, Flex, Heading, IconButton, Table, Tbody, Td, Th, Thead, Tr, VStack, Text, Center, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, Tooltip } from '@chakra-ui/react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import * as Icons from 'react-icons/fi';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { NavigationMenu, NavigationMenuItem, generateDefaultNavigationMenu, generateDefaultNavigationMenuItem } from '@/bundles/DescriptorEditor/schemas/userInterface/navigationMenusSchema';
import InlineEditableText from '@/bundles/DescriptorEditor/components/editors/InlineEditableText';
import { IconSelector } from './IconSelector';
import { PageSelector } from './PageSelector';
import { PageArgumentsEditor } from './PageArgumentsEditor';

const NavigationMenuItemRow: React.FC<{
  item: NavigationMenuItem;
  menuId: string;
  updateMenuItem: (itemId: string, key: keyof NavigationMenuItem, value: any) => void;
  deleteMenuItem: (itemId: string) => void;
}> = ({ item, menuId, updateMenuItem, deleteMenuItem }) => {
  const { isOpen: isIconSelectorOpen, onOpen: onIconSelectorOpen, onClose: onIconSelectorClose } = useDisclosure();
  const { isOpen: isPageArgumentsOpen, onOpen: onPageArgumentsOpen, onClose: onPageArgumentsClose } = useDisclosure();
  const { getFragment } = useAppDescriptorStore();

  const pages = getFragment('/essentials/pages') as any[] || [];
  const selectedPage = pages.find(page => page.id === item.targetPageId);
  const isInvalidPage = item.targetPageId && !selectedPage;

  const handleIconSelect = (icon: string) => {
    updateMenuItem(item.id, 'icon', icon);
    onIconSelectorClose();
  };

  const handlePageSelect = (pageId: string) => {
    updateMenuItem(item.id, 'targetPageId', pageId);
    // Reset page arguments when changing the target page
    updateMenuItem(item.id, 'targetPageArguments', '');
  };

  const handlePageArgumentsUpdate = (newArguments: string) => {
    updateMenuItem(item.id, 'targetPageArguments', newArguments);
    onPageArgumentsClose();
  };

  const IconComponent = item.icon ? Icons[item.icon as keyof typeof Icons] : null;

  return (
    <Tr>
      <Td>
        <InlineEditableText
          value={item.label}
          onSave={(value) => updateMenuItem(item.id, 'label', value)}
          placeholder="Item label"
        />
      </Td>
      <Td>
        <Tooltip label={item.icon || 'Select Icon'}>
          <IconButton
            aria-label="Select Icon"
            icon={IconComponent ? <IconComponent size={24} /> : <FiPlus size={24} />}
            onClick={onIconSelectorOpen}
            variant="ghost"
            _hover={{ bg: 'gray.100' }}
            size="lg"
          />
        </Tooltip>
        <Modal isOpen={isIconSelectorOpen} onClose={onIconSelectorClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select Icon</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <IconSelector onSelect={handleIconSelect} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Td>
      <Td>
        <PageSelector
          value={item.targetPageId || ''}
          onChange={handlePageSelect}
          pages={pages}
          isInvalid={isInvalidPage}
        />
      </Td>
      <Td>
        <Button onClick={onPageArgumentsOpen} size="sm" isDisabled={!selectedPage || selectedPage.logic?.initializer?.parameters?.length === 0}>
          {selectedPage?.logic?.initializer?.parameters?.length > 0 ? 'Edit Arguments' : 'N/A'}
        </Button>
        <Modal isOpen={isPageArgumentsOpen} onClose={onPageArgumentsClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Page Arguments</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <PageArgumentsEditor
                pageId={item.targetPageId}
                currentArguments={item.targetPageArguments}
                onSave={handlePageArgumentsUpdate}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Td>
      <Td>
        <IconButton
          aria-label="Delete menu item"
          icon={<FiTrash2 />}
          size="sm"
          variant="ghost"
          colorScheme="red"
          onClick={() => deleteMenuItem(item.id)}
        />
      </Td>
    </Tr>
  );
};

const NavigationMenuEditor: React.FC<{ 
  menu: NavigationMenu; 
  menuItems: NavigationMenuItem[];
  onDeleteMenu: () => void;
  updateMenu: (menuId: string, key: keyof NavigationMenu, value: any) => void;
  updateMenuItem: (itemId: string, key: keyof NavigationMenuItem, value: any) => void;
  deleteMenuItem: (itemId: string) => void;
  addMenuItem: (menuId: string) => void;
}> = ({ menu, menuItems, onDeleteMenu, updateMenu, updateMenuItem, deleteMenuItem, addMenuItem }) => {
  return (
    <Card mb={4}>
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">
            <InlineEditableText
              value={menu.name}
              onSave={(value) => updateMenu(menu.id, 'name', value)}
              placeholder="Menu name"
            />
          </Heading>
          <IconButton
            aria-label="Delete menu"
            icon={<FiTrash2 />}
            size="sm"
            variant="ghost"
            colorScheme="red"
            onClick={onDeleteMenu}
          />
        </Flex>
      </CardHeader>
      <CardBody>
        <VStack align="stretch" spacing={4}>
          <InlineEditableText
            value={menu.description || ''}
            onSave={(value) => updateMenu(menu.id, 'description', value)}
            placeholder="Menu description"
          />
          {menuItems.length === 0 ? (
            <Box textAlign="center" py={6}>
              <Text mb={4} color="gray.500">
                This menu has no items. Add your first item to get started!
              </Text>
              <Button leftIcon={<FiPlus />} onClick={() => addMenuItem(menu.id)} colorScheme="blue" size="sm">
                Add Menu Item
              </Button>
            </Box>
          ) : (
            <>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Label</Th>
                    <Th>Icon</Th>
                    <Th>Target Page</Th>
                    <Th>Page Arguments</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {menuItems.map((item) => (
                    <NavigationMenuItemRow
                      key={item.id}
                      item={item}
                      menuId={menu.id}
                      updateMenuItem={updateMenuItem}
                      deleteMenuItem={deleteMenuItem}
                    />
                  ))}
                </Tbody>
              </Table>
              <Button leftIcon={<FiPlus />} size="sm" onClick={() => addMenuItem(menu.id)}>
                Add Menu Item
              </Button>
            </>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

const NavigationMenus: React.FC = () => {
  const { getFragment, setFragment, addRecordToCollectionFragment, removeRecordFromCollectionFragment, updateRecordInCollectionFragment } = useAppDescriptorStore();
  const navigationMenus = getFragment('/userInterface/navigationMenus') as NavigationMenu[] || [];
  const navigationMenuItems = getFragment('/userInterface/navigationMenuItems') as NavigationMenuItem[] || [];

  const addNewMenu = () => {
    const newMenu = generateDefaultNavigationMenu();
    setFragment('/userInterface/navigationMenus', [...navigationMenus, newMenu]);
  };

  const deleteMenu = (menuId: string) => {
    // Remove the navigation menu
    setFragment('/userInterface/navigationMenus', navigationMenus.filter(menu => menu.id !== menuId));

    // Remove all menu items associated with this menu
    setFragment('/userInterface/navigationMenuItems', navigationMenuItems.filter(item => item.navigationMenuId !== menuId));
  };

  const updateMenu = (menuId: string, key: keyof NavigationMenu, value: any) => {
    setFragment('/userInterface/navigationMenus', navigationMenus.map(menu => 
      menu.id === menuId ? { ...menu, [key]: value } : menu
    ));
  };

  const updateMenuItem = (itemId: string, key: keyof NavigationMenuItem, value: any) => {
    setFragment('/userInterface/navigationMenuItems', navigationMenuItems.map(item => 
      item.id === itemId ? { ...item, [key]: value } : item
    ));
  };

  const deleteMenuItem = (itemId: string) => {
    setFragment('/userInterface/navigationMenuItems', navigationMenuItems.filter(item => item.id !== itemId));
  };

  const addMenuItem = (menuId: string) => {
    const newItem = generateDefaultNavigationMenuItem(menuId);
    setFragment('/userInterface/navigationMenuItems', [...navigationMenuItems, newItem]);
  };

  if (navigationMenus.length === 0) {
    return (
      <Box textAlign="center" py={10}>
        <Text mb={4} fontSize="lg" color="gray.600">
          No navigation menus found. Create your first menu to get started!
        </Text>
        <Button leftIcon={<FiPlus />} onClick={addNewMenu} colorScheme="blue">
          Add New Navigation Menu
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      {navigationMenus.map((menu) => (
        <NavigationMenuEditor 
          key={menu.id} 
          menu={menu} 
          menuItems={navigationMenuItems.filter(item => item.navigationMenuId === menu.id)}
          onDeleteMenu={() => deleteMenu(menu.id)}
          updateMenu={updateMenu}
          updateMenuItem={updateMenuItem}
          deleteMenuItem={deleteMenuItem}
          addMenuItem={addMenuItem}
        />
      ))}
      <Button leftIcon={<FiPlus />} onClick={addNewMenu} mt={4} colorScheme="blue">
        Add New Navigation Menu
      </Button>
    </Box>
  );
};

export default NavigationMenus;