import React from 'react';
import { Box } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { LiteralValueDirective } from '@/runtime-js/src/directives/literalValue';
import { LiteralBooleanValueEditor } from './literalValueEditors/LiteralBooleanValueEditor';
import { LiteralStringValueEditor } from './literalValueEditors/LiteralStringValueEditor';
import { LiteralNumberValueEditor } from './literalValueEditors/LiteralNumberValueEditor';
import { DictionaryStructureEditor } from './literalValueEditors/DictionaryStructureEditor';
import { ListStructureEditor } from './literalValueEditors/ListStructureEditor';

interface LiteralValueEditorProps {
  keypath: string;
}

export function LiteralValueEditor({ keypath }: LiteralValueEditorProps) {
  const { getFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as LiteralValueDirective;
  if (!directive) {
    console.error(`Directive not provided for keypath: ${keypath}`);
    return <Box>Error: Directive not provided</Box>;
  }

  const type = directive.config?.type || '_types.Unknown';

  const renderValueEditor = () => {
    switch (type) {
      case '_types.Boolean':
        return <LiteralBooleanValueEditor keypath={keypath} />;
      case '_types.String':
        return <LiteralStringValueEditor keypath={keypath} />;
      case '_types.Number':
        return <LiteralNumberValueEditor keypath={keypath} />;
      case '_types.Dictionary':
        return <DictionaryStructureEditor keypath={keypath} />;
      case '_types.List':
        return <ListStructureEditor keypath={keypath} />;
      case '_types.Unknown':
        return <Box>Unknown type</Box>;
      default:
        return <Box>Unsupported type: {type}</Box>;
    }
  };

  return (
    <Box>
      {renderValueEditor()}
    </Box>
  );
}