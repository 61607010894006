import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Heading,
  Textarea,
  HStack,
  Badge,
  Tooltip,
  Divider,
  useToast,
  Grid,
  Select,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { FlowgraphEditor } from "@/bundles/FlowgraphEditor/components/FlowgraphEditor";
import { IconPlus, IconEdit, IconTrash, IconCode } from "@tabler/icons-react";

interface Parameter {
  name: string;
  description: string;
  schema: {
    type: string;
    dataModel?: string;
  };
}

interface Method {
  name: string;
  description: string;
  parameters: Parameter[];
  flowgraph: {
    sequence: any[];
    $variables: any[];
  };
}

const ServiceMethods: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const {
    isOpen: isFlowOpen,
    onOpen: onFlowOpen,
    onClose: onFlowClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const toast = useToast();

  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<Method | null>(null);
  const [editingMethod, setEditingMethod] = useState<Method | null>(null);

  const dataModels = getFragment("/essentials/dataModels") || [];
  const dataServices = getFragment("/essentials/dataServices") || [];

  const handleEditLogic = (serviceName: string, method: Method) => {
    console.log(
      `Opening logic editor for method ${method.name} in service ${serviceName}`
    );
    console.log("Current method flowgraph:", method.flowgraph);

    setSelectedService(serviceName);
    setSelectedMethod(method);
    onFlowOpen();
  };

  const handleFlowgraphChange = (flowgraph: {
    sequence: any[];
    $variables: any[];
  }) => {
    console.log("Received flowgraph update:", flowgraph);

    if (!selectedService || !selectedMethod) return;

    const service = dataServices.find((s) => s.name === selectedService);
    if (!service) return;

    const updatedMethods = service.methods.map((m) =>
      m.name === selectedMethod.name
        ? {
            ...m,
            flowgraph: {
              sequence: [...flowgraph.sequence],
              $variables: [...flowgraph.$variables],
            },
          }
        : m
    );

    // Update the services array
    const services = dataServices.map((s) =>
      s.name === selectedService ? { ...s, methods: updatedMethods } : s
    );

    setFragment("/essentials/dataServices", services);
  };

  const handleSaveMethod = () => {
    onFlowClose();
    toast({
      title: "Logic saved",
      description: `Updated logic for method ${selectedMethod?.name}`,
      status: "success",
      duration: 2000,
    });
  };

  const handleCreateService = (modelName: string) => {
    const newService = {
      name: modelName,
      description: `A data service for the ${modelName} model`,
      methods: [],
    };

    const services = [...dataServices];
    services.push(newService);
    setFragment("/essentials/dataServices", services);

    toast({
      title: "Service created",
      description: `Created new service for ${modelName}`,
      status: "success",
      duration: 2000,
    });
  };

  const handleCreateMethod = (serviceName: string) => {
    const defaultMethod: Method = {
      name: "newMethod",
      description: "Description of the method",
      parameters: [],
      flowgraph: { sequence: [], $variables: [] },
    };

    setSelectedService(serviceName);
    setEditingMethod(defaultMethod);
    onEditOpen();
  };

  const handleEditMethod = (serviceName: string, method: Method) => {
    setSelectedService(serviceName);
    setEditingMethod({ ...method });
    onEditOpen();
  };

  const handleSaveMethodDetails = () => {
    if (!selectedService || !editingMethod) return;

    const service = dataServices.find((s) => s.name === selectedService);
    if (!service) return;

    const existingMethodIndex = service.methods.findIndex(
      (m) => m.name === editingMethod.name
    );
    let updatedMethods = [...service.methods];

    if (existingMethodIndex >= 0) {
      updatedMethods[existingMethodIndex] = editingMethod;
    } else {
      updatedMethods.push(editingMethod);
    }

    const services = dataServices.map((s) =>
      s.name === selectedService ? { ...s, methods: updatedMethods } : s
    );

    setFragment("/essentials/dataServices", services);
    onEditClose();

    toast({
      title: "Method saved",
      description: `${
        existingMethodIndex >= 0 ? "Updated" : "Created"
      } method ${editingMethod.name}`,
      status: "success",
      duration: 2000,
    });
  };

  const handleDeleteMethod = (serviceName: string, methodName: string) => {
    const service = dataServices.find((s) => s.name === serviceName);
    if (!service) return;

    const updatedMethods = service.methods.filter((m) => m.name !== methodName);
    const services = dataServices.map((s) =>
      s.name === serviceName ? { ...s, methods: updatedMethods } : s
    );

    setFragment("/essentials/dataServices", services);

    toast({
      title: "Method deleted",
      description: `Deleted method ${methodName}`,
      status: "info",
      duration: 2000,
    });
  };

  const handleAddParameter = () => {
    if (!editingMethod) return;

    const newParam: Parameter = {
      name: `param${editingMethod.parameters.length + 1}`,
      description: "Parameter description",
      schema: { type: "String" },
    };

    setEditingMethod({
      ...editingMethod,
      parameters: [...editingMethod.parameters, newParam],
    });
  };

  const handleUpdateParameter = (
    index: number,
    field: keyof Parameter,
    value: string
  ) => {
    if (!editingMethod) return;

    const updatedParams = [...editingMethod.parameters];
    if (field === "schema.type" || field === "schema.dataModel") {
      const [schemaField, subField] = field.split(".");
      updatedParams[index] = {
        ...updatedParams[index],
        schema: {
          ...updatedParams[index].schema,
          [subField]: value,
        },
      };
    } else {
      updatedParams[index] = {
        ...updatedParams[index],
        [field]: value,
      };
    }

    setEditingMethod({
      ...editingMethod,
      parameters: updatedParams,
    });
  };

  const handleDeleteParameter = (index: number) => {
    if (!editingMethod) return;

    const updatedParams = editingMethod.parameters.filter(
      (_, i) => i !== index
    );
    setEditingMethod({
      ...editingMethod,
      parameters: updatedParams,
    });
  };

  return (
    <Box p={8} maxW="1200px" mx="auto">
      <Heading size="lg" mb={6}>
        Data Services
      </Heading>

      <VStack align="stretch" spacing={6}>
        {dataModels.map((model) => {
          const service = dataServices.find((s) => s.name === model.name);

          return (
            <Box
              key={model.name}
              p={6}
              borderWidth={1}
              borderRadius="lg"
              borderColor="gray.200"
              bg="white"
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              <Flex justify="space-between" align="center" mb={4}>
                <Heading size="md">{model.name}</Heading>
                {!service && (
                  <Button
                    leftIcon={<IconPlus size={16} />}
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleCreateService(model.name)}
                  >
                    Create Service
                  </Button>
                )}
              </Flex>

              {service && (
                <VStack align="stretch" spacing={3}>
                  <Text color="gray.600" fontSize="sm">
                    {service.description}
                  </Text>

                  <Divider my={2} />

                  <Flex justify="space-between" align="center">
                    <Text fontWeight="medium" color="gray.700">
                      Methods
                    </Text>
                    <Button
                      size="sm"
                      leftIcon={<IconPlus size={16} />}
                      onClick={() => handleCreateMethod(service.name)}
                      colorScheme="blue"
                      variant="ghost"
                    >
                      Add Method
                    </Button>
                  </Flex>

                  {service.methods?.map((method) => (
                    <Box
                      key={method.name}
                      p={4}
                      borderWidth={1}
                      borderRadius="md"
                      borderColor="gray.100"
                      bg="gray.50"
                      _hover={{ bg: "gray.100" }}
                    >
                      <Flex justify="space-between" align="center">
                        <VStack align="start" spacing={1}>
                          <HStack>
                            <Text fontWeight="medium">{method.name}</Text>
                            <Badge colorScheme="blue">
                              {method.parameters.length} params
                            </Badge>
                          </HStack>
                          <Text fontSize="sm" color="gray.600">
                            {method.description}
                          </Text>
                        </VStack>

                        <HStack>
                          <Tooltip label="Edit Method">
                            <IconButton
                              icon={<IconEdit size={16} />}
                              aria-label="Edit method"
                              size="sm"
                              variant="ghost"
                              onClick={() =>
                                handleEditMethod(service.name, method)
                              }
                            />
                          </Tooltip>
                          <Tooltip label="Edit Logic">
                            <IconButton
                              icon={<IconCode size={16} />}
                              aria-label="Edit logic"
                              size="sm"
                              variant="ghost"
                              onClick={() =>
                                handleEditLogic(service.name, method)
                              }
                            />
                          </Tooltip>
                          <Tooltip label="Delete Method">
                            <IconButton
                              icon={<IconTrash size={16} />}
                              aria-label="Delete method"
                              size="sm"
                              variant="ghost"
                              colorScheme="red"
                              onClick={() =>
                                handleDeleteMethod(service.name, method.name)
                              }
                            />
                          </Tooltip>
                        </HStack>
                      </Flex>
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>
          );
        })}
      </VStack>

      {/* Method Editor Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingMethod?.name ? "Edit Method" : "New Method"}
          </ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Method Name</FormLabel>
                <Input
                  value={editingMethod?.name || ""}
                  onChange={(e) =>
                    setEditingMethod((prev) =>
                      prev ? { ...prev, name: e.target.value } : null
                    )
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={editingMethod?.description || ""}
                  onChange={(e) =>
                    setEditingMethod((prev) =>
                      prev ? { ...prev, description: e.target.value } : null
                    )
                  }
                />
              </FormControl>

              <Box w="full">
                <Flex justify="space-between" align="center" mb={2}>
                  <FormLabel mb={0}>Parameters</FormLabel>
                  <Button
                    size="sm"
                    leftIcon={<IconPlus size={16} />}
                    onClick={handleAddParameter}
                  >
                    Add Parameter
                  </Button>
                </Flex>

                <VStack align="stretch" spacing={3}>
                  {editingMethod?.parameters.map((param, index) => (
                    <Box
                      key={index}
                      p={3}
                      borderWidth={1}
                      borderRadius="md"
                      position="relative"
                    >
                      <Grid templateColumns="1fr 1fr auto" gap={3}>
                        <FormControl>
                          <FormLabel fontSize="sm">Name</FormLabel>
                          <Input
                            size="sm"
                            value={param.name}
                            onChange={(e) =>
                              handleUpdateParameter(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel fontSize="sm">Type</FormLabel>
                          <Select
                            size="sm"
                            value={param.schema.type}
                            onChange={(e) =>
                              handleUpdateParameter(
                                index,
                                "schema.type",
                                e.target.value
                              )
                            }
                          >
                            <option value="String">String</option>
                            <option value="Number">Number</option>
                            <option value="Boolean">Boolean</option>
                            <option value="Record">Record</option>
                            <option value="Collection">Collection</option>
                          </Select>
                        </FormControl>

                        <IconButton
                          icon={<IconTrash size={16} />}
                          aria-label="Delete parameter"
                          size="sm"
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => handleDeleteParameter(index)}
                        />
                      </Grid>

                      <FormControl mt={2}>
                        <FormLabel fontSize="sm">Description</FormLabel>
                        <Input
                          size="sm"
                          value={param.description}
                          onChange={(e) =>
                            handleUpdateParameter(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </FormControl>

                      {(param.schema.type === "Record" ||
                        param.schema.type === "Collection") && (
                        <FormControl mt={2}>
                          <FormLabel fontSize="sm">Data Model</FormLabel>
                          <Select
                            size="sm"
                            value={param.schema.dataModel}
                            onChange={(e) =>
                              handleUpdateParameter(
                                index,
                                "schema.dataModel",
                                e.target.value
                              )
                            }
                          >
                            {dataModels.map((model) => (
                              <option key={model.name} value={model.name}>
                                {model.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  ))}
                </VStack>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onEditClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSaveMethodDetails}>
              Save Method
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Flowgraph Editor Modal */}
      <Modal isOpen={isFlowOpen} onClose={onFlowClose} size="6xl">
        <ModalOverlay />
        <ModalContent maxWidth="90vw">
          <ModalHeader>Edit Logic for {selectedMethod?.name}</ModalHeader>
          <ModalBody>
            {selectedService && selectedMethod && (
              <FlowgraphEditor
                keypath={`/essentials/dataServices/${selectedService}/methods/${selectedMethod.name}/flowgraph`}
                flowgraphContext={{
                  declaredVariables: new Set(),
                  declaredPageVariables: new Set(),
                  parameters: selectedMethod.parameters.map((p) => ({
                    name: p.name,
                    dataType: p.schema.type,
                    description: p.description,
                  })),
                }}
                initialFlowgraph={selectedMethod.flowgraph}
                onFlowgraphChange={handleFlowgraphChange}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveMethod}>
              Save
            </Button>
            <Button onClick={onFlowClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ServiceMethods;
