import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { ApplicationAsset } from "@/bundles/DescriptorEditor/schemas/userInterface/applicationAssetsSchema";

interface EditApplicationAssetModalProps {
  isOpen: boolean;
  onClose: () => void;
  asset: ApplicationAsset;
}

const EditApplicationAssetModal: React.FC<EditApplicationAssetModalProps> = ({
  isOpen,
  onClose,
  asset,
}) => {
  const { setFragment } = useAppDescriptorStore();
  const [name, setName] = useState(asset.name);

  const handleSubmit = () => {
    setFragment(`/userInterface/applicationAssets/${asset.id}/name`, name);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Asset</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditApplicationAssetModal;
