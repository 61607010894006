import { type Directive, isDirective } from "./directiveTypes";
import { type FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import { type TypedValue } from "../types/typedValue";

export type ReturnDirective = Directive<"return", ReturnConfig>;

export interface ReturnConfig {
  value: any;
}

export function isReturnDirective(
  directive: any,
): directive is ReturnDirective {
  const returnConfigKeys: (keyof ReturnConfig)[] = ["value"];
  return isDirective(directive, "return", returnConfigKeys);
}

const execute = async (
  config: ReturnConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { value } = config;
  return executionContext.executeDirective(value);
};

export const returnDirective = {
  execute,
  availableActions: {},
  defaultConfig: () =>
    ({
      value: {
        type: "literalValue",
        config: {
          value: {
            type: "_types.String",
            value: "",
          },
        },
      },
    }) as ReturnConfig,
};
