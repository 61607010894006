import React, { useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Input,
  Select,
} from "@chakra-ui/react";
import { DeleteIcon, ChevronDownIcon, AddIcon } from "@chakra-ui/icons";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";
import { coreDataTypes } from "../../types/coreDataTypes";

interface Parameter {
  id: string;
  name: string;
  dataType: string;
  description: string;
  paramType: "path";
}

interface StaticParameter {
  name: string;
  dataType: string;
  description: string;
}

interface ParameterEditorProps {
  keypath: string;
  staticParameters?: StaticParameter[];
}

const ParameterEditor: React.FC<ParameterEditorProps> = ({
  keypath,
  staticParameters = [],
}) => {
  const {
    getFragment,
    setFragment,
    addRecordToCollectionFragment,
    updateRecordInCollectionFragment,
    removeRecordFromCollectionFragment,
  } = useAppDescriptorStore();

  const baseKeypath = keypath.split("/parameters")[0];

  const parameters = (getFragment(keypath) as Parameter[]) || [];
  const initParams = getFragment(`${baseKeypath}/params`) || [];

  useEffect(() => {
    const formattedParams = parameters.map((param) => ({
      name: param.name,
      description: param.description,
      paramType: param.paramType || "path",
      schema: {
        type: param.dataType.replace("_types.", ""),
      },
    }));

    setFragment(`${baseKeypath}/params`, formattedParams);
  }, [parameters]);

  const handleAddParameter = () => {
    const newParameter = {
      name: "",
      dataType: "String",
      description: "",
      paramType: "path",
    };
    addRecordToCollectionFragment(keypath, newParameter);
  };

  const handleUpdateParameter = (
    id: string,
    field: keyof Parameter,
    value: string
  ) => {
    updateRecordInCollectionFragment(keypath, id, { [field]: value });
  };

  const handleRemoveParameter = (id: string) => {
    removeRecordFromCollectionFragment(keypath, id);
  };

  return (
    <Box>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Data Type</Th>
              <Th>Parameter Type</Th>
              <Th>Description</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {/* {staticParameters.map((param, index) => (
              <Tr key={`static-${index}`}>
                <Td>{param.name}</Td>
                <Td>{param.dataType}</Td>
                <Td></Td>
                <Td>{param.description}</Td>
                <Td></Td>
              </Tr>
            ))} */}
            {parameters.map((param) => (
              <Tr key={param.id}>
                <Td>
                  <Input
                    value={param.name}
                    onChange={(e) =>
                      handleUpdateParameter(param.id, "name", e.target.value)
                    }
                  />
                </Td>
                <Td>
                  <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                      {param.dataType}
                    </MenuButton>
                    <MenuList>
                      {coreDataTypes.map((type) => (
                        <MenuItem
                          key={type}
                          onClick={() =>
                            handleUpdateParameter(param.id, "dataType", type)
                          }
                        >
                          {type}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Td>
                <Td>
                  <Select
                    value={param.paramType || "path"}
                    onChange={(e) =>
                      handleUpdateParameter(
                        param.id,
                        "paramType",
                        e.target.value
                      )
                    }
                  >
                    <option value="path">Path</option>
                  </Select>
                </Td>
                <Td>
                  <Input
                    value={param.description}
                    onChange={(e) =>
                      handleUpdateParameter(
                        param.id,
                        "description",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td>
                  <IconButton
                    aria-label="Delete parameter"
                    icon={<DeleteIcon />}
                    onClick={() => handleRemoveParameter(param.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Button leftIcon={<AddIcon />} onClick={handleAddParameter} mt={4}>
        Add Parameter
      </Button>
    </Box>
  );
};

export default ParameterEditor;
