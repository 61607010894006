import React, { ReactNode, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useAppDescriptorStore } from "../stores/appDescriptorStore";

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const toast = useToast();
  const setShowSaveToast = useAppDescriptorStore(
    (state) => state.showSaveToast
  );

  useEffect(() => {
    setShowSaveToast((message: string) => {
      toast({
        title: message,
        status: message.toLowerCase().includes("error") ? "error" : "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
    });
  }, [setShowSaveToast, toast]);

  return <>{children}</>;
};
