import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import AIPatchGenerator from "../AIPatchGenerator";
import DescriptorDebugger from "./DescriptorDebugger";
import Header from "./Header";
import NavigationMenu from "./NavigationMenu";
import SubMenu from "./SubMenu";
import ChatSidebar from "../descriptors/ChatSidebar";
import OpenAI from "openai";
import { useAppDescriptorStore } from "../../stores/appDescriptorStore";

interface LayoutProps {
  children: React.ReactNode;
}

interface Message {
  role: "user" | "assistant";
  content: string;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [selectedResource, setSelectedResource] = useState<string | null>(null);
  const [isNavigationMenuCompact, setIsNavigationMenuCompact] = useState(true);
  const location = useLocation();
  const [inputValue, setInputValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [includeContext, setIncludeContext] = useState(false);
  const [includeComponents, setIncludeComponents] = useState(false);
  const { appDescriptor, chatHistory, setChatHistory, clearChatHistory } =
    useAppDescriptorStore();
  const { getComponentBlueprints } = useAppDescriptorStore();

  const handleMainMenuSelect = (to: string) => {
    setSelectedResource(to);
  };

  const handleToggleCompact = (isCompact: boolean) => {
    setIsNavigationMenuCompact(isCompact);
  };

  const isOverviewPage = location.pathname.endsWith("/descriptor/overview");

  useEffect(() => {
    if (!isOverviewPage && !selectedResource) {
      const currentPath = location.pathname.split("/editor")[1];
      setSelectedResource(currentPath);
    }
  }, [location, isOverviewPage, selectedResource]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    let userMessageContent = inputValue;
    if (includeContext) {
      userMessageContent +=
        "\n\nApp Descriptor Context:\n```json\n" +
        JSON.stringify(appDescriptor, null, 2) +
        "\n```";
    }

    if (includeComponents) {
      const blueprints = getComponentBlueprints();
      userMessageContent +=
        "\n\nAvailable Components:\n```json\n" +
        JSON.stringify(blueprints, null, 2) +
        "\n```";
    }

    const userMessage = {
      role: "user" as const,
      content: userMessageContent,
    };
    setChatHistory([...chatHistory, userMessage]);
    setInputValue("");
    setIsLoading(true);

    try {
      const openai = new OpenAI({
        apiKey: process.env.OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const completion = await openai.chat.completions.create({
        model: "gpt-4-turbo-preview",
        messages: [
          {
            role: "system",
            content:
              "You are an AI assistant helping developers understand and modify their application descriptor. You can explain the structure and suggest changes.",
          },
          ...chatHistory,
          userMessage,
        ],
      });

      const assistantMessage = completion.choices[0].message;
      setChatHistory([
        ...chatHistory,
        userMessage,
        {
          role: "assistant",
          content: assistantMessage.content || "No response",
        },
      ]);
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to get AI response",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    clearChatHistory();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "l" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        onOpen();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onOpen]);

  return (
    <Box height="100vh" overflow="hidden">
      <Header />
      <Flex height="calc(100vh - 64px)">
        <NavigationMenu
          onItemSelect={handleMainMenuSelect}
          onToggleCompact={handleToggleCompact}
        />
        {!isOverviewPage && selectedResource && (
          <SubMenu selectedResource={selectedResource} isVisible={true} />
        )}
        <Flex flexDirection="column" flex={1} overflow="hidden">
          <Box id="content-container" overflow="auto" flex={1} p={4}>
            {children}
          </Box>
          <AIPatchGenerator />
        </Flex>
        <DescriptorDebugger />
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>AI Chat</DrawerHeader>
          <DrawerBody>
            <ChatSidebar
              messages={chatHistory}
              onSubmit={handleSubmit}
              onReset={handleReset}
              inputValue={inputValue}
              setInputValue={setInputValue}
              isLoading={isLoading}
              includeContext={includeContext}
              setIncludeContext={setIncludeContext}
              includeComponents={includeComponents}
              setIncludeComponents={setIncludeComponents}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Layout;
