import type { CallFunctionDirective } from "./callFunction";
import type { DeclareVariableDirective } from "./declareVariable";
import type { DeclarePageVariableDirective } from "./declarePageVariable";
import type { SetPageVariableDirective } from "./setPageVariable";
import type { GetPageVariableDirective } from "./getPageVariable";
import type { ExecuteAllInParallelDirective } from "./executeAllInParallel";
import type { ExecuteAnyInParallelDirective } from "./executeAnyInParallel";
import type { ExecuteBranchesDirective } from "./executeBranches";
import type { ExecuteSomeInParallelDirective } from "./executeSomeInParallel";
import type { ExecuteModelMethodDirective } from "./executeModelMethod";
import type { GetArgumentDirective } from "./getArgument";
import type { GetIteratorVariableDirective } from "./getIteratorVariable";
import type { GetVariableDirective } from "./getVariable";
import type { LiteralValueDirective } from "./literalValue";
import type { LoopForEachDirective } from "./loopForEach";
import type { SetVariableDirective } from "./setVariable";
import type { NativeCodeDirective } from "./nativeCode";
import type { ReturnDirective } from "./return";
import type { RenderStringTemplateDirective } from "./renderStringTemplate";
import type { ThrowErrorDirective } from "./throwError";
import type { WaitForDurationDirective } from "./waitForDuration";
import { WhileLoopDirective } from "./whileLoop";
import { OverRangeLoopDirective } from "./overrangeLoop";

export type AnyDirective =
  | DeclareVariableDirective
  | DeclarePageVariableDirective
  | SetPageVariableDirective
  | GetPageVariableDirective
  | SetVariableDirective
  | ReturnDirective
  | NestableDirective
  | ExecuteModelMethodDirective;

export type NestableDirective =
  | ExecuteAllInParallelDirective
  | ExecuteAnyInParallelDirective
  | ExecuteBranchesDirective
  | ExecuteSomeInParallelDirective
  | GetArgumentDirective
  | GetIteratorVariableDirective
  | GetVariableDirective
  | LiteralValueDirective
  | LoopForEachDirective
  | WhileLoopDirective
  | OverRangeLoopDirective
  | CallFunctionDirective
  | NativeCodeDirective
  | RenderStringTemplateDirective
  | ThrowErrorDirective
  | WaitForDurationDirective
  | SetPageVariableDirective
  | GetPageVariableDirective;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type Directive<
  T extends DirectiveType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  C extends PartialRecord<string, any>,
> = {
  id: string;
  directiveType: T;
  config: C;
};

export type DirectiveType =
  | "callFunction"
  | "declareVariable"
  | "declarePageVariable"
  | "setPageVariable"
  | "getPageVariable"
  | "executeAllInParallel"
  | "executeAnyInParallel"
  | "executeBranches"
  | "executeSomeInParallel"
  | "executeModelMethod"
  | "getArgument"
  | "getIteratorVariable"
  | "getVariable"
  | "literalValue"
  | "loopForEach"
  | "overRangeLoop"
  | "whileLoop"
  | "nativeCode"
  | "renderStringTemplate"
  | "return"
  | "setVariable"
  | "throwError"
  | "waitForDuration";

export function isDirective<
  T extends DirectiveType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  C extends PartialRecord<string, any>,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directive: any,
  type: T,
  configKeys: (keyof C)[],
): directive is Directive<T, C> {
  return (
    typeof directive === "object" &&
    directive.directiveType === type &&
    "id" in directive &&
    "config" in directive &&
    typeof directive.config === "object" &&
    configKeys.every((key) => key in directive.config)
  );
}