import React from "react";
import { Box, Divider, HStack, IconButton, Text } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { DirectiveSelector } from "./DirectiveSelector";
import { DirectiveEditor } from "./DirectiveEditor";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { FlowgraphContext } from "../types/FlowgraphContext";

interface DirectiveContainerProps {
  keypath: string;
  onRemoveDirective?: () => void;
  flowgraphContext: FlowgraphContext;
  isNested?: boolean;
}

export function DirectiveContainer({
  keypath,
  onRemoveDirective,
  flowgraphContext,
  isNested = false,
}: DirectiveContainerProps) {
  const { getFragment } = useAppDescriptorStore();

  console.log("DirectiveContainer", keypath, flowgraphContext);

  const directive = getFragment(keypath) as AnyDirective;

  const nestingLevel = (keypath.match(/config/g) || []).length;
  const bgColor = nestingLevel % 2 === 0 ? "gray.700" : "gray.750";

  return (
    <Box
      bg={bgColor}
      color="white"
      borderColor="gray.500"
      borderWidth={1}
      borderRadius="md"
      minWidth="320px"
      width="fit-content"
      maxWidth="100%"
    >
      <HStack justify="space-between" align="center" p={2}>
        <DirectiveSelector
          keypath={keypath}
          flowgraphContext={flowgraphContext}
        />
        {!isNested && onRemoveDirective && (
          <IconButton
            aria-label="Delete directive"
            icon={<DeleteIcon />}
            colorScheme="red"
            variant="ghost"
            onClick={onRemoveDirective}
            size="xs"
          />
        )}
      </HStack>
      {directive && (
        <>
          <Divider />
          <Box p={2}>
            <DirectiveEditor
              keypath={keypath}
              flowgraphContext={flowgraphContext}
            />
          </Box>
        </>
      )}
    </Box>
  );
}