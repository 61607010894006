
import React from "react";
import { HStack, Text, Tooltip } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { WarningIcon } from "@chakra-ui/icons";

interface MenuItemProps {
  item: MenuItem;
  selectedItem: DirectiveItem | null;
  handleItemSelect: (item: MenuItem) => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({ item, selectedItem, handleItemSelect }) => (
  <HStack
    justify="space-between"
    p={2}
    borderRadius="md"
    bg={selectedItem?.name === item.name ? "blue.100" : "transparent"}
    _hover={{ bg: "gray.100" }}
    cursor="pointer"
    onClick={() => handleItemSelect(item)}
  >
    <HStack>
      {item.type === 'category' && item.icon}
      <Text>{item.name}</Text>
      {item.type === 'directive' && item.compatibility && !item.compatibility.compatible && (
        <Tooltip label={item.compatibility.warning}>
          <WarningIcon color="red.500" />
        </Tooltip>
      )}
    </HStack>
    {item.type === 'category' && <ChevronRightIcon />}
  </HStack>
);