
import React from 'react';
import { Select } from '@chakra-ui/react';
import { coreDataTypes } from '../../types/coreDataTypes';

interface DataTypeDropdownProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const DataTypeDropdown: React.FC<DataTypeDropdownProps> = ({ name, value, onChange }) => {
  return (
    <Select name={name} value={value} onChange={onChange}>
      {coreDataTypes.map((dataType) => (
        <option key={dataType} value={dataType}>
          {dataType}
        </option>
      ))}
    </Select>
  );
};

export default DataTypeDropdown;
