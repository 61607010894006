import { extendTheme } from '@chakra-ui/react';
import { gray, blue, red, green } from '@radix-ui/colors';

const theme = extendTheme({
  colors: {
    gray: {
      ...gray,
    },
    blue: {
      ...blue,
    },
    red: {
      ...red,
    },
    green: {
      ...green,
    },
  },
});

export default theme;