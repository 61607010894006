import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Image,
  Text,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { railsApiCall } from "@/bundles/DescriptorEditor/utils/railsApiCall";
import { ApplicationAsset } from "@/bundles/DescriptorEditor/schemas/userInterface/applicationAssetsSchema";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
// import CryptoJS from "crypto-js";

interface NewApplicationAssetModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newAsset: ApplicationAsset) => void;
}

const NewApplicationAssetModal: React.FC<NewApplicationAssetModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { setFragment, getFragment } = useAppDescriptorStore();
  const [newAsset, setNewAsset] = useState({
    id: uuidv4(),
    name: "",
    fileUrl: "",
    size: 0,
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (field: string, value: string | number) => {
    setNewAsset({ ...newAsset, [field]: value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setNewAsset({ ...newAsset, name: file.name, size: file.size });
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  // const computeHash = async (file: File): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       const data = e.target?.result;
  //       if (data) {
  //         const wordArray = CryptoJS.lib.WordArray.create(data as ArrayBuffer);
  //         const hash = CryptoJS.SHA256(wordArray).toString();
  //         resolve(hash);
  //       } else {
  //         reject("Error reading file.");
  //       }
  //     };
  //     reader.onerror = function () {
  //       reject("Error reading file.");
  //     };
  //     reader.readAsArrayBuffer(file);
  //   });
  // };

  const handleSubmit = async () => {
    if (selectedFile) {
      // const fileHash = await computeHash(selectedFile);

      const assetsObj = getFragment("/userInterface/applicationAssets") || {};
      const assetsArray = Object.values(assetsObj).filter(
        (asset): asset is ApplicationAsset => asset != null
      );

      // const duplicateAsset = assetsArray.find(
      //   (asset: ApplicationAsset) => asset.hash === fileHash
      // );

      // if (duplicateAsset) {
      //   setErrorMessage(
      //     `This image is already uploaded with name "${duplicateAsset.name}" and id "${duplicateAsset.id}".`
      //   );
      //   return;
      // }

      const formData = new FormData();
      formData.append("asset[image]", selectedFile);

      try {
        const response = await railsApiCall<{ id: string; fileUrl: string }>({
          method: "POST",
          endpoint: "/assets",
          body: formData,
        });

        if (response.ok) {
          const updatedAsset: ApplicationAsset = {
            id: response.data.id,
            name: newAsset.name,
            fileUrl: response.data.fileUrl,
            size: newAsset.size,
            // hash: fileHash,
          };
          setFragment(
            `/userInterface/applicationAssets/${updatedAsset.id}`,
            updatedAsset
          );
          onSubmit(updatedAsset);
          onClose();
        } else {
          console.error("Failed to upload image:", response.data);
          setErrorMessage("Failed to upload image.");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        setErrorMessage("Error uploading image.");
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Image Asset</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Image</FormLabel>
              <Input type="file" accept="image/*" onChange={handleFileChange} />
            </FormControl>
            {previewUrl && (
              <Image
                src={previewUrl}
                alt="Preview"
                maxH="200px"
                objectFit="contain"
              />
            )}
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                value={newAsset.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </FormControl>
            {errorMessage && <Text color="red.500">{errorMessage}</Text>}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isDisabled={!selectedFile}
          >
            Add Asset
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewApplicationAssetModal;
