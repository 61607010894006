import React, { useState } from 'react';
import { SimpleGrid, Button, Input, VStack, Tooltip } from '@chakra-ui/react';
import * as Icons from 'react-icons/fi';

const allIcons = Object.entries(Icons).filter(([name]) => name.startsWith('Fi'));

export const IconSelector: React.FC<{ onSelect: (icon: string) => void }> = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredIcons = allIcons.filter(([name]) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <VStack spacing={4}>
      <Input
        placeholder="Search icons..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <SimpleGrid columns={5} spacing={2} maxHeight="400px" overflowY="auto">
        {filteredIcons.map(([name, Icon]) => (
          <Tooltip key={name} label={name}>
            <Button onClick={() => onSelect(name)} size="lg" p={2}>
              <Icon size={24} />
            </Button>
          </Tooltip>
        ))}
      </SimpleGrid>
    </VStack>
  );
};