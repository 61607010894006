import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Flex, Container } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { type DataModelField } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';
import InlineEditableText from '@/bundles/DescriptorEditor/components/editors/InlineEditableText';
import DataTypeDropdown from '@/bundles/DescriptorEditor/components/editors/DataTypeDropdown';
import { useToast } from '@chakra-ui/react';

const Fields: React.FC = () => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const { dataModelId } = useParams();
  const keypath = `/essentials/dataModels/${dataModelId}/fields`;
  const fields = getFragment(keypath) as DataModelField[] || [];
  const associations = getFragment(`/essentials/dataModels/${dataModelId}/associations`) as any[] || [];
  const modelName = getFragment(`${keypath}/name`) as string || '';
  const processedAssociations = associations.map((association: any) => {
    const targetModel = getFragment(`/essentials/dataModels/id:${association.targetModelId}/name`) as string || '';
    return {
      ...association,
      name: targetModel,
      associationType: association.associationType // Make sure this property exists in your association object
    };
  });

  const toast = useToast();

  const isValidRubyFieldName = (name: string): boolean => {
    return /^[a-z][a-zA-Z0-9_]*$/.test(name);
  };

  const isFieldNameUnique = (name: string, currentFieldId?: string): boolean => {
    return !fields.some(field => field.name === name && field.id !== currentFieldId);
  };

  const validateFieldName = (name: string, currentFieldId?: string): string | null => {
    if (!isValidRubyFieldName(name)) {
      return "Field name must start with lowercase letter and contain only letters, numbers, and underscores";
    }
    if (!isFieldNameUnique(name, currentFieldId)) {
      return "Field name must be unique";
    }
    return null;
  };

  const updateField = (fieldId: string, key: string, value: any) => {
    if (key === 'name') {
      const errorMessage = validateFieldName(value, fieldId);
      if (errorMessage) {
        toast({
          title: "Invalid field name",
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }
    setFragment(`${keypath}/id:${fieldId}/${key}`, value);
  };

  const addField = () => {
    const baseFieldName = "new_field";
    let uniqueName = baseFieldName;
    let counter = 1;
    while (!isFieldNameUnique(uniqueName)) {
      uniqueName = `${baseFieldName}_${counter}`;
      counter++;
    }
    const newField = {
      id: `field_${Date.now()}`,
      name: uniqueName,
      dataType: '_types.String',
      description: ''
    };
    setFragment(keypath, [...fields, newField]);
  };

  const deleteField = (fieldId: string) => {
    setFragment(keypath, fields.filter((field: any) => field.id !== fieldId));
  };

  return (
    <Container maxW="container.lg" centerContent>
      <Box width="100%" overflowX="auto">
        <Table variant="simple" size="sm" style={{ tableLayout: 'fixed', width: '100%' }}>
          <Thead>
            <Tr>
              <Th width="25%">Name</Th>
              <Th width="20%">Data Type</Th>
              <Th width="45%">Description</Th>
              <Th width="40px"></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>id</Td>
              <Td>integer</Td>
              <Td>Auto-generated primary key</Td>
              <Td></Td>
            </Tr>
            {processedAssociations.map((association: any) => (
              association.associationType === 'belongs_to' && (
                <Tr key={association.id}>
                  <Td>{`${association.name.toLowerCase()}_id`}</Td>
                  <Td>integer</Td>
                  <Td>{`Foreign key for ${association.name} association`}</Td>
                  <Td></Td>
                </Tr>
              )
            ))}
            {fields.map((field: any) => (
              <Tr key={field.id}>
                <Td width="25%">
                  <InlineEditableText
                    value={field.name}
                    onSave={(value) => updateField(field.id, 'name', value)}
                  />
                </Td>
                <Td width="20%">
                  <DataTypeDropdown
                    name={`dataType-${field.id}`}
                    value={field.dataType}
                    onChange={(e) => updateField(field.id, 'dataType', e.target.value)}
                  />
                </Td>
                <Td width="45%">
                  <InlineEditableText
                    value={field.description}
                    onSave={(value) => updateField(field.id, 'description', value)}
                  />
                </Td>
                <Td width="40px">
                  <IconButton
                    aria-label="Delete field"
                    icon={<FiTrash2 />}
                    size="sm"
                    onClick={() => deleteField(field.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justifyContent="flex-end" mt={4} width="100%">
        <Button leftIcon={<FiPlus />} onClick={addField} size="sm">
          Add field
        </Button>
      </Flex>
    </Container>
  );
};

export default Fields;