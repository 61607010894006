import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Input,
  Text,
  useToast,
  Spinner,
  Box,
} from "@chakra-ui/react";
import OpenAI from "openai";
import { useAppDescriptorStore } from "../../../../stores/appDescriptorStore";
import { JSONTree } from "react-json-tree";

interface AIModelAssistantProps {
  isOpen: boolean;
  onClose: () => void;
  dataModel: any;
  keypath: string;
}

export const AIModelAssistant: React.FC<AIModelAssistantProps> = ({
  isOpen,
  onClose,
  dataModel,
  keypath,
}) => {
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedFragment, setSuggestedFragment] = useState<any>(null);
  const toast = useToast();
  const { setFragment, getFragment } = useAppDescriptorStore();

  const handleAskAI = async () => {
    setIsLoading(true);
    try {
      const openai = new OpenAI({
        apiKey: process.env.OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const allDataModels = getFragment("/essentials/dataModels");

      const messages = [
        {
          role: "system" as const,
          content: `You are an AI assistant helping with data model design. You will be given all existing data models and asked to modify a specific one. 
          
          Rules:
          1. Respond ONLY with the modified data model as a JSON object
          2. Keep the existing ID of the model
          3. Follow this exact format: {"id": "string", "name": "string", "fields": [{"id": "string", "name": "string", "dataType": "_types.String", "description": "string"}], "description": "string"}
          4. Valid dataTypes are: _types.String, _types.Number, _types.Boolean, _types.Date, _types.Array, _types.Object
          5. Do not add any explanations or markdown formatting
          6. Generate new IDs for any new fields using UUID v4 format
          
          Example response:
          {"id": "5", "name": "Car", "fields": [{"id": "1", "name": "make", "dataType": "_types.String", "description": "The make of the car"}], "description": "A model to store cars"}`,
        },
        {
          role: "user" as const,
          content:
            `All data models:\n` +
            `${JSON.stringify(allDataModels, null, 2)}\n\n` +
            `Model to modify/create: ${dataModel.name}\n` +
            `Request: ${prompt}`,
        },
      ];

      const completion = await openai.chat.completions.create({
        model: "gpt-4",
        messages,
        temperature: 0.2,
      });

      const response = completion.choices[0].message.content;

      try {
        const suggestedChanges = JSON.parse(response || "null");
        if (
          !suggestedChanges?.id ||
          !suggestedChanges?.name ||
          !Array.isArray(suggestedChanges?.fields)
        ) {
          throw new Error("Invalid model structure");
        }
        setSuggestedFragment(suggestedChanges);
      } catch (error) {
        toast({
          title: "Invalid AI Response",
          description: "The AI returned an invalid data model structure",
          status: "error",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to get AI response",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyChanges = () => {
    if (suggestedFragment) {
      try {
        setFragment(keypath, suggestedFragment);
        toast({
          title: "Changes Applied",
          description:
            "The suggested changes have been applied to your data model",
          status: "success",
        });
        onClose();
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to apply changes",
          status: "error",
        });
      }
    }
  };

  const theme = {
    scheme: "monokai",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>AI Model Assistant</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Text>Ask me to modify your data model "{dataModel?.name}"</Text>
            <Input
              placeholder="e.g., Add user authentication fields, Make it e-commerce ready..."
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
            <Button
              colorScheme="purple"
              onClick={handleAskAI}
              isLoading={isLoading}
              leftIcon={isLoading ? <Spinner size="sm" /> : undefined}
            >
              Ask AI
            </Button>

            {suggestedFragment && (
              <VStack align="stretch" width="100%" spacing={2}>
                <Text fontWeight="bold">Suggested Changes:</Text>
                <Box
                  bg="gray.900"
                  p={4}
                  borderRadius="md"
                  maxHeight="400px"
                  overflow="auto"
                >
                  <JSONTree
                    data={suggestedFragment}
                    theme={theme}
                    invertTheme={false}
                    shouldExpandNodeInitially={() => true}
                  />
                </Box>
                <Button colorScheme="green" onClick={handleApplyChanges}>
                  Apply Changes
                </Button>
              </VStack>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
