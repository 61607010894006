import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, VStack, Heading, Wrap, Tag, TagLabel, TagCloseButton, Box, Select, FormControl, FormLabel } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { DataModelField, DataModelValidation, ValidationFunction, coreValidationFunctions } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';
import ValidationArgumentInputs from './ValidationArgumentInputs';

interface MassEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (validatedFields: DataModelField[], nonValidatedFields: DataModelField[], updatedValidation: DataModelValidation) => void;
  validationName: string;
  fields: DataModelField[];
  fieldsPerValidation: Record<string, DataModelField[]>;
}

const MassEditModal: React.FC<MassEditModalProps> = ({
  isOpen,
  onClose,
  onSave,
  validationName,
  fields,
  fieldsPerValidation,
}) => {
  const [validatedFields, setValidatedFields] = useState<DataModelField[]>([]);
  const [nonValidatedFields, setNonValidatedFields] = useState<DataModelField[]>([]);
  const [selectedValidationFunction, setSelectedValidationFunction] = useState<ValidationFunction | null>(null);
  const [validationArgs, setValidationArgs] = useState<Record<string, any>>({});

  useEffect(() => {
    if (isOpen) {
      const validationFunction = coreValidationFunctions.find(v => v.name === validationName);
      setSelectedValidationFunction(validationFunction || null);
      setValidatedFields(fieldsPerValidation[validationName] || []);
      setNonValidatedFields(fields.filter(field => !fieldsPerValidation[validationName]?.some(f => f.id === field.id)));
      
      // Set initial arguments
      if (validationFunction && fieldsPerValidation[validationName]?.length > 0) {
        const sampleField = fieldsPerValidation[validationName][0];
        const sampleValidation = sampleField.validations?.find(v => v.validationFunctionName === validationName);
        setValidationArgs(sampleValidation?.arguments || {});
      } else {
        setValidationArgs({});
      }
    }
  }, [isOpen, validationName, fields, fieldsPerValidation]);

  const moveField = (field: DataModelField, fromList: DataModelField[], toList: DataModelField[]) => {
    setValidatedFields(fromList === validatedFields ? validatedFields.filter(f => f.id !== field.id) : [...validatedFields, field]);
    setNonValidatedFields(fromList === nonValidatedFields ? nonValidatedFields.filter(f => f.id !== field.id) : [...nonValidatedFields, field]);
  };

  const handleSave = () => {
    if (selectedValidationFunction) {
      const updatedValidation: DataModelValidation = {
        validationFunctionName: selectedValidationFunction.name,
        arguments: validationArgs
      };
      onSave(validatedFields, nonValidatedFields, updatedValidation);
    }
  };

  const handleRemoveFromAll = () => {
    setValidatedFields([]);
    setNonValidatedFields([...fields]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mass Edit: {validationName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="stretch" spacing={4}>
            <Box>
              <FormControl>
                <FormLabel>Validation Function</FormLabel>
                <Select
                  value={selectedValidationFunction?.name || ''}
                  onChange={(e) => {
                    const newValidationFunction = coreValidationFunctions.find(v => v.name === e.target.value);
                    setSelectedValidationFunction(newValidationFunction || null);
                    setValidationArgs({});
                  }}
                >
                  {coreValidationFunctions.map((validation) => (
                    <option key={validation.name} value={validation.name}>{validation.name}</option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {selectedValidationFunction && (
              <Box>
                <ValidationArgumentInputs
                  parameters={selectedValidationFunction.parameters || []}
                  args={validationArgs}
                  setArgs={setValidationArgs}
                />
              </Box>
            )}
            <Heading as="h3" size="sm" mb={2}>
              Validate {validationName} of:
            </Heading>
            <Wrap>
              {validatedFields.map(field => (
                <Tag key={field.id} size="md" borderRadius="full" variant="solid" colorScheme="green" cursor="pointer" onClick={() => moveField(field, validatedFields, nonValidatedFields)}>
                  <TagLabel>{field.name}</TagLabel>
                </Tag>
              ))}
            </Wrap>
            <Heading as="h3" size="sm" mb={2}>
              Add fields to this validation:
            </Heading>
            <Wrap>
              {nonValidatedFields.map(field => (
                <Tag key={field.id} size="md" borderRadius="full" variant="outline" colorScheme="green" cursor="pointer" onClick={() => moveField(field, nonValidatedFields, validatedFields)}>
                  <TagLabel>{field.name}</TagLabel>
                </Tag>
              ))}
            </Wrap>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleRemoveFromAll}>
            Remove from all fields
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save Changes
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MassEditModal;