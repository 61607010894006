
import { createStore } from "zustand/vanilla";
import type { StoreApi } from "zustand";
import { v4 as uuidv4 } from "uuid";

import type {
  AnyDirective,
  NestableDirective,
  DirectiveType,
} from "./directives/directiveTypes";

import {
  isDeclareVariableDirective,
  type DeclareVariableConfig,
} from "./directives/declareVariable";
import {
  availableActionsForDirectiveType,
  defaultConfigForDirectiveType,
  searchFunctionForDirectiveType,
} from "./directives/directiveFactory";
import type { DataType } from "./types/dataType";

export type FlowFunctionParameter = {
  name: string;
  type: DataType;
  description?: string;
  default?: unknown;
};

export type EditorFlowFunctionParameter = FlowFunctionParameter & {
  readOnly: boolean;
};

export type FlowFunctionReturnValueSchema = {
  type: DataType;
  description?: string;
};

export type FlowFunctionTest = {
  description: string;
  arguments: Record<string, { value: unknown; type: DataType }>;
  expect: {
    returnValue: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      toEqual: any;
    };
  };
};

export interface FlowFunctionDescriptor {
  name: string;
  description?: string;
  parameters?: FlowFunctionParameter[];
  flowgraph: AnyDirective[];
  returnValueSchema?: FlowFunctionReturnValueSchema;
  tests?: FlowFunctionTest[];
}

interface FlowFunctionActions {
  setState: (state: FlowFunctionDescriptor) => void;

  setName: (name: string) => void;
  setDescription: (description: string) => void;

  addParameter: (parameter: FlowFunctionParameter) => void;
  removeParameter: (parameter: FlowFunctionParameter) => void;
  updateParameter: (parameter: FlowFunctionParameter) => void;
  getParameterNames: () => string[];

  getDirective: (id: string) => AnyDirective;
  changeDirectiveType: (
    directive: AnyDirective,
    newType: DirectiveType,
  ) => void;
  updateDirectiveConfig: (directive: AnyDirective) => void;

  addDefaultDirectiveToFlowgraph: (index: number) => void;
  addDirectiveToFlowgraph: (index: number, directive: AnyDirective) => void;
  removeDirectiveFromFlowgraph: (directive: AnyDirective) => void;
  moveDirectiveInFlowgraph: (directive: AnyDirective, newIndex: number) => void;

  getDefaultConfigForDirectiveType: (
    type: DirectiveType,
  ) => AnyDirective["config"];
  createDirective: (
    type: DirectiveType,
    id?: string,
    config?: AnyDirective["config"],
  ) => AnyDirective;
  createDefaultDirective: () => NestableDirective;

  setReturnValueSchema: (schema: FlowFunctionReturnValueSchema) => void;
  setTests: (tests: FlowFunctionTest[]) => void;

  getVariables: () => DeclareVariableConfig[];
  getVariableNames: () => string[];
  getDefaultVariable: () => DeclareVariableConfig | undefined;

  getAvailableActionsForDirective: (directive: AnyDirective) => {
    [key: string]: (...args: unknown[]) => void;
  };
}

export type FlowFunctionStore = FlowFunctionDescriptor & FlowFunctionActions;

export const useFlowFunctionStore: StoreApi<FlowFunctionStore> =
  createStore<FlowFunctionStore>((set, get) => ({
    name: "",
    description: "",
    parameters: [],
    flowgraph: [],
    tests: [],

    setState: (state) => set(state),

    setName: (name) => set({ name }),
    setDescription: (description) => set({ description }),

    addParameter: (parameter) =>
      set((state) => ({
        parameters: state.parameters
          ? [...state.parameters, parameter]
          : [parameter],
      })),
    removeParameter: (parameter) =>
      set((state) => ({
        parameters: state.parameters
          ? state.parameters.filter((p) => p.name !== parameter.name)
          : [],
      })),
    updateParameter: (parameter) =>
      set((state) => ({
        parameters: state.parameters
          ? state.parameters.map((p) =>
              p.name === parameter.name ? parameter : p,
            )
          : [],
      })),
    getParameterNames: () => {
      const parameters = get().parameters;
      return parameters ? parameters.map((p) => p.name) : [];
    },

    getDirective: (id: string): AnyDirective => {
      let foundDirective: AnyDirective | undefined = undefined;

      // Assign the result of searchAnyDirective to foundDirective
      foundDirective = searchAnyDirective(get().flowgraph, id);

      if (!foundDirective) {
        throw new Error(`Directive with ID ${id} not found`);
      }

      return foundDirective;
    },

    changeDirectiveType: (
      directive: AnyDirective,
      newDirectiveType: DirectiveType,
    ) =>
      set((state) => {
        const directiveDirective = get().getDirective(directive.id);
        if (directiveDirective) {
          Object.assign(
            directiveDirective,
            get().createDirective(newDirectiveType, directiveDirective.id),
          );
        }
        return state;
      }),

    updateDirectiveConfig: (directive: AnyDirective) =>
      set((state) => {
        const directiveDirective = get().getDirective(directive.id);
        if (directiveDirective) {
          Object.assign(directiveDirective, directive.config);
        }
        return state;
      }),

    getDefaultConfigForDirectiveType: (
      directiveType: DirectiveType,
    ): AnyDirective["config"] => {
      const config = defaultConfigForDirectiveType(directiveType);
      if (!config) {
        throw new Error(`Unknown directive type: ${directiveType}`);
      }
      return config as AnyDirective["config"];
    },

    createDirective: (
      type: DirectiveType,
      id?: string,
      config?: AnyDirective["config"],
    ): AnyDirective =>
      ({
        id: id || uuidv4(),
        directiveType: type,
        config: config || get().getDefaultConfigForDirectiveType(type),
      }) as AnyDirective,
    createDefaultDirective: () =>
      get().createDirective("literalValue") as NestableDirective,

    addDefaultDirectiveToFlowgraph: (index: number) =>
      get().addDirectiveToFlowgraph(index, get().createDefaultDirective()),
    addDirectiveToFlowgraph: (index: number, directive: AnyDirective) =>
      get().flowgraph.splice(index, 0, directive),
    removeDirectiveFromFlowgraph: (directive: AnyDirective) =>
      (get().flowgraph = get().flowgraph.filter((d) => d !== directive)),
    moveDirectiveInFlowgraph: (directive: AnyDirective, newIndex: number) =>
      set((state) => {
        const flowgraph = [...state.flowgraph];
        const oldIndex = flowgraph.indexOf(directive);
        if (oldIndex === -1) return state; // Directive not found

        // Remove the directive from the old position
        flowgraph.splice(oldIndex, 1);
        // Insert the directive at the new position
        flowgraph.splice(newIndex, 0, directive);

        return { flowgraph };
      }),

    setReturnValueSchema: (schema) => set({ returnValueSchema: schema }),
    setTests: (tests) => set({ tests }),

    getVariables: () =>
      get()
        .flowgraph.filter((directive) => isDeclareVariableDirective(directive))
        .map((directive) => directive.config as DeclareVariableConfig),
    getVariableNames: () =>
      get()
        .getVariables()
        .map((variable) => variable.name),
    getDefaultVariable: () =>
      get().getVariables().length > 0 ? get().getVariables()[0] : undefined,
    // @ts-expect-error fix later
    getAvailableActionsForDirective: (directive: AnyDirective) => {
      const typeActions = availableActionsForDirectiveType(
        directive.directiveType,
      );
      return {
        changeDirectiveType: (newDirectiveType: DirectiveType) =>
          get().changeDirectiveType(directive, newDirectiveType),
        removeDirectiveFromFlowgraph: () =>
          get().removeDirectiveFromFlowgraph(directive),
        moveDirectiveInFlowgraph: (newIndex: number) =>
          get().moveDirectiveInFlowgraph(directive, newIndex),
        ...typeActions,
      };
    },
  }));

export const getVariableNames = () => {
  return useFlowFunctionStore.getState().getVariableNames();
};

export const getDefaultVariable = () => {
  return useFlowFunctionStore.getState().getDefaultVariable();
};

export const createDirective = (type: DirectiveType, id?: string) => {
  return useFlowFunctionStore.getState().createDirective(type, id);
};

export const createDefaultDirective = () => {
  return useFlowFunctionStore.getState().createDefaultDirective();
};

export const getParameterNames = () => {
  return useFlowFunctionStore.getState().getParameterNames();
};

export const searchAnyDirective = (
  directives: AnyDirective[],
  id: string,
): AnyDirective | undefined => {
  let foundDirective: AnyDirective | undefined = undefined;

  for (const directive of directives) {
    if (directive.id === id) {
      foundDirective = directive;
    } else {
      const directiveType = directive.directiveType;
      const searchFunction = searchFunctionForDirectiveType(directiveType);
      if (searchFunction) {
        const found = searchFunction(directive as any, id);
        if (found) {
          foundDirective = found;
        }
      }
    }
  }
  return foundDirective;
};
