import { generateFlowgraph } from "@/bundles/FlowgraphEditor/utils/generateFlowgraph";
import { DataModelField } from "@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema";

export const generateConvertedDescriptor = (
  getFragment: any,
  getPage: any,
  getModelByKeypath: any
) => {
  const pages = getFragment("/essentials/pageGroups") || [];
  const firstPage = pages.length ? getPage(pages[0].pages[0].id) : null;

  if (!firstPage?.pageGroup) {
    return null;
  }

  const dataModel = firstPage?.pageGroup?.dataModelId
    ? getModelByKeypath(
        `/essentials/dataModels/id:${firstPage.pageGroup.dataModelId}`
      )
    : null;

  const allPagesInGroup =
    getFragment(`/essentials/pageGroups/id:${firstPage.pageGroup.id}/pages`) ||
    [];

  const existingDataServices = getFragment("/essentials/dataServices") || [];
  const dataVariables = getFragment("/essentials/dataVariables") || [];

  const transformedPages = allPagesInGroup
    .map((pageMeta: { id: string }) => {
      const fullPage = getPage(pageMeta.id);
      if (!fullPage) return null;

      const pageDataVariables = dataVariables
        .filter((variable: any) => variable.pageId === pageMeta.id)
        .map((variable: any) => ({
          name: variable.name,
          schema: {
            type: variable.type,
            dataModel: variable.dataModel,
          },
          description: variable.description,
        }));

      const flowgraphNodes =
        fullPage.initializationLogic?.flowgraph?.sequence || [];

      const viewgraphNodeTree = fullPage.userInterface?.viewgraph?.enabled
        ? convertViewgraphToNodeTree(
            fullPage.userInterface.viewgraph.containerStructure,
            dataModel?.fields || []
          )
        : null;

      const controllerAction: any = {
        name: fullPage.controllerAction?.name || fullPage.pageType,
        flowgraph: generateFlowgraph(flowgraphNodes),
      };

      if (fullPage.initializationLogic?.parameters?.length) {
        controllerAction.params = fullPage.initializationLogic.parameters.map(
          (param: any) => ({
            name: param.name,
            description: param.description,
            paramType: param.paramType,
            schema: {
              type: param.dataType.replace(/^_types\./, ""),
            },
          })
        );
      }

      return {
        name: fullPage.name,
        relativePath: fullPage.relativePath,
        pageType: fullPage.pageType,
        dataVariables: pageDataVariables,
        controllerAction,
        viewgraph: viewgraphNodeTree
          ? {
              nodeTree: viewgraphNodeTree,
            }
          : null,
      };
    })
    .filter(Boolean);

  return {
    id: firstPage.pageGroup.name.replace(/\s+/g, ""),
    version: "1.0.0", // TODO: Get version from descriptor
    description: `A simple app to manage ${
      dataModel?.name?.toLowerCase() || "items"
    }`,
    dataModels: dataModel ? [convertDataModel(dataModel)] : [],
    dataServices: existingDataServices.map(convertDataService),
    pageGroups: [
      {
        name: firstPage.pageGroup.name,
        description: firstPage.pageGroup.description,
        dataModel: dataModel?.name || "Untitled",
        basePath: firstPage.pageGroup.basePath,
        pages: transformedPages,
      },
    ],
  };
};

function convertViewgraphToNodeTree(
  container: any,
  dataModelFields: DataModelField[]
) {
  if (!container) return null;

  return {
    $element: "div",
    $styleClasses: "p-4 w-full max-w-screen-lg mx-auto flex flex-col flex-1",
    $children: container.components.map((component: any) =>
      convertComponent(component, dataModelFields)
    ),
  };
}

function convertComponent(component: any, dataModelFields: DataModelField[]) {
  const node: any = {
    $component: component.blueprintName,
    $props: {},
  };

  if (component.propertiesBindings) {
    Object.entries(component.propertiesBindings).forEach(
      ([key, binding]: [string, any]) => {
        if (binding.directiveType === "literalValue") {
          node.$props[key] = binding.config.value;
        } else if (binding.directiveType === "getPageVariable") {
          node.$props[key] = {
            $bindTo: `$data/${binding.config.variableName}`,
          };
        } else if (binding.directiveType === "getFormField") {
          node.$props[key] = {
            $bindTo: `$form/${binding.config.fieldName}`,
          };
        }
      }
    );
  }

  if (component.$slots) {
    node.$slots = {};
    Object.entries(component.$slots).forEach(
      ([slotName, slotContent]: [string, any]) => {
        node.$slots[slotName] = {
          $children: slotContent.$children.map((child: any) =>
            convertComponent(child, dataModelFields)
          ),
        };
      }
    );
  }

  if (component.$eventHandlers?.length) {
    node.$eventHandlers = component.$eventHandlers.map((handler: any) => {
      const convertedHandler: any = {
        $on: handler.$on,
      };

      if (handler.$preventDefault) {
        convertedHandler.$preventDefault = true;
      }

      if (handler.$includeFormData) {
        convertedHandler.$includeFormData = true;
      }

      if (handler.$execute?.$flowgraph) {
        convertedHandler.$execute = {
          $flowgraph: generateFlowgraph(handler.$execute.$flowgraph.sequence),
        };
      }

      return convertedHandler;
    });
  }

  return node;
}

function convertDataModel(model: any) {
  return {
    name: model.name,
    description: model.description || `A ${model.name.toLowerCase()}`,
    fields:
      model.fields?.map((field: DataModelField) => ({
        name: field.name,
        databaseType: field.dataType.replace(/^_types\./, "").toLowerCase(),
        description: field.description,
      })) || [],
  };
}

function convertDataService(service: any) {
  return {
    name: service.name,
    description:
      service.description || `A data service for the ${service.name} model`,
    methods: service.methods.map((method: any) => ({
      name: method.name,
      description:
        method.description || `${method.name} operation for ${service.name}`,
      parameters: method.parameters || [],
      flowgraph: {
        sequence: generateFlowgraph(
          method.flowgraph?.sequence || []
        ).sequence.filter(
          (step: any) => step.$call !== "KaseyOS.Controller.RenderCurrentPage"
        ),
      },
    })),
  };
}
