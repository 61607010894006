import { Directive } from "./directiveTypes";
import { AnyDirective } from "./directiveTypes";

export interface DeclarePageVariableConfig {
  name: string;
  type: string;
  initialValue?: AnyDirective;
}

export type DeclarePageVariableDirective = Directive<
  "declarePageVariable",
  DeclarePageVariableConfig
>;

export const declarePageVariable = {
  execute: async (
    config: DeclarePageVariableConfig,
    context: { declarePageVariable: (name: string, type: string, initialValue: any) => void }
  ): Promise<void> => {
    const initialValue = await context.executeDirective(config.initialValue);
    context.declarePageVariable(config.name, config.type, initialValue);
  },
  defaultConfig: (): DeclarePageVariableConfig => ({
    name: "",
    type: "_types.String",
    initialValue: {
      directiveType: "literalValue",
      config: { type: "_types.String", value: "" }
    },
  }),
  availableActions: [],
};