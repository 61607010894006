import React, { useState } from "react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  Tooltip,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Input,
  Switch,
  Button,
} from "@chakra-ui/react";
import {
  IconMinus,
  IconTrash,
  IconChevronUp,
  IconChevronDown,
  IconDatabase,
  IconPlus,
  IconEdit,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { ConditionalNode } from "../types";
import { PropertiesPanel } from "./PropertiesPanel";
import { useAppDescriptorStore } from "../../DescriptorEditor/stores/appDescriptorStore";
import { useDataModelStore } from "../../DescriptorEditor/stores/dataModelStore";
import { FlowgraphContext } from "../../DescriptorEditor/components/editors/FlowgraphEditor/types/FlowgraphContext";

interface ModelMethodNodeProps {
  node: ConditionalNode;
  onDeleteNode: (nodeId: string) => void;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  onSelectField: (nodeId: string, field: "name" | "returnVariable") => void;
  isSelected: boolean;
  onFieldUpdate: (nodeId: string, field: string, value: string) => void;
  onUpdateNode: (nodeId: string, updatedNode: ConditionalNode) => void;
  availableVariables: string[];
  availableDataVariables: string[];
  flowgraphContext: FlowgraphContext;
}

export function ModelMethodNode({
  node,
  onDeleteNode,
  onReorder,
  isFirst,
  isLast,
  onSelectField,
  isSelected,
  onFieldUpdate,
  onUpdateNode,
  availableVariables,
  availableDataVariables,
  handleClosePropertiesPanel,
  flowgraphContext,
}: ModelMethodNodeProps) {
  const iconColor = "rgb(138, 180, 248)";

  const [modelName, setModelName] = useState(node.parameters?.modelName || "");
  const [recordLimit, setRecordLimit] = useState(
    node.parameters?.recordLimit || 10
  );
  const [returnVariable, setReturnVariable] = useState(
    node.parameters?.returnVariable || ""
  );
  const [methodType, setMethodType] = useState(
    node.parameters?.methodType || "RetrieveAllRecords"
  );
  const [make, setMake] = useState(node.parameters?.make || "");
  const [model, setModel] = useState(node.parameters?.model || "");
  const [recordId, setRecordId] = useState(node.parameters?.recordId || "");
  const [useContext, setUseContext] = useState(
    node.parameters?.contextParam ? true : false
  );
  const [isChaining, setIsChaining] = useState(false);
  const [editingChainIndex, setEditingChainIndex] = useState<number | null>(
    null
  );
  const [chainParameters, setChainParameters] = useState<
    Record<string, string>
  >({});

  const { getFragment } = useAppDescriptorStore();
  const dataModels =
    (getFragment("/essentials/dataModels") as { name: string }[]) || [];
  const availableDataModels = dataModels.map((model) => model.name);
  const dataVariables = getFragment("/essentials/dataVariables") || [];

  const dataModelStore = useDataModelStore();
  const dataModelFields = modelName
    ? dataModelStore.getDataModelFields(modelName)
    : null;

  const handleModelNameUpdate = (value: string) => {
    setModelName(value);
    onFieldUpdate(node.id, "modelName", value);
  };

  const handleContextParamChange = (value: string) => {
    onFieldUpdate(node.id, "recordId", "");
    onFieldUpdate(node.id, "contextParam", value);
  };

  const handleUseContextChange = (checked: boolean) => {
    setUseContext(checked);
    if (checked) {
      onFieldUpdate(node.id, "recordId", "");
    } else {
      onFieldUpdate(node.id, "contextParam", "");
    }
  };

  const handleChainClick = () => {
    const updatedChains = [...(node.chains || [])];
    updatedChains.push({
      methodType: "RetrieveAllRecords",
      parameters: {
        modelName: "",
        recordLimit: "10",
        returnVariable: "",
      },
    });

    const updatedNode = {
      ...node,
      chains: updatedChains,
    };
    onUpdateNode(node.id, updatedNode);
    setIsChaining(true);
    setEditingChainIndex(updatedChains.length - 1);
    setChainParameters({});
  };

  const handleChainMethodTypeChange = (value: string, chainIndex: number) => {
    const updatedChains = [...(node.chains || [])];
    if (!updatedChains[chainIndex]) return;

    updatedChains[chainIndex] = {
      ...updatedChains[chainIndex],
      methodType: value,
    };

    const updatedNode = {
      ...node,
      chains: updatedChains,
    };
    onUpdateNode(node.id, updatedNode);
  };

  const handleChainParameterChange = (
    paramName: string,
    value: string,
    chainIndex: number
  ) => {
    const updatedChains = [...(node.chains || [])];
    if (!updatedChains[chainIndex]) return;

    const updatedChainParams = {
      ...updatedChains[chainIndex].parameters,
      [paramName]: value,
    };

    updatedChains[chainIndex] = {
      ...updatedChains[chainIndex],
      parameters: updatedChainParams,
    };

    const updatedNode = {
      ...node,
      chains: updatedChains,
    };
    onUpdateNode(node.id, updatedNode);
    if (chainIndex === editingChainIndex) {
      setChainParameters(updatedChainParams);
    }
  };

  const renderCreateRecordFields = () => {
    if (!modelName) return null;

    return (
      dataModelFields?.map((field) => (
        <HStack key={field.name}>
          <Text color="white" fontSize="sm">
            {field.name}:
          </Text>
          <Input
            value={node.parameters?.[field.name] || ""}
            onChange={(e) => {
              const value = e.target.value;
              onFieldUpdate(node.id, field.name, value);
            }}
            size="sm"
            textColor="white"
            placeholder={field.description || field.name}
          />
        </HStack>
      )) || (
        <Text color="gray.500" fontSize="sm">
          No fields defined for this data model
        </Text>
      )
    );
  };

  return (
    <Box position="relative" role="group">
      <Box
        borderWidth={1}
        borderColor={iconColor}
        borderRadius="md"
        bg="#202124"
        width="350px"
        overflow="hidden"
        boxShadow={isSelected ? "0 0 10px rgba(66, 153, 225, 0.6)" : "none"}
        _hover={{ boxShadow: "0 0 10px rgba(66, 153, 225, 0.3)" }}
      >
        <HStack p={2} bg={`${iconColor}20`} justifyContent="space-between">
          <HStack spacing={2}>
            <IconButton
              icon={<IconMinus size={16} />}
              aria-label="Collapse"
              size="sm"
              variant="ghost"
              color={colors.grayDark.gray12}
            />
            <Text fontWeight="medium" color="white">
              Model Method
            </Text>
          </HStack>
          <HStack spacing={1}>
            {!isFirst && (
              <Tooltip label="Move Up" aria-label="Move Up Tooltip">
                <IconButton
                  icon={<IconChevronUp size={16} />}
                  aria-label="Move Up"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("up")}
                />
              </Tooltip>
            )}
            {!isLast && (
              <Tooltip label="Move Down" aria-label="Move Down Tooltip">
                <IconButton
                  icon={<IconChevronDown size={16} />}
                  aria-label="Move Down"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={() => onReorder("down")}
                />
              </Tooltip>
            )}
            <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
              <IconButton
                icon={<IconTrash size={16} />}
                aria-label="Delete Node"
                size="sm"
                variant="ghost"
                color={colors.red.red6}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteNode(node.id);
                }}
                _hover={{ bg: colors.red.red3 }}
              />
            </Tooltip>
          </HStack>
        </HStack>

        <VStack align="stretch" p={2} bg="#303134" spacing={1}>
          <HStack
            justifyContent="space-between"
            cursor="pointer"
            onClick={() => onSelectField(node.id, "name")}
          >
            <HStack spacing={1}>
              <IconDatabase size={16} color={iconColor} />
              <Text color="white" fontWeight="medium">
                {modelName || "Select Data Model"}
              </Text>
            </HStack>
            <Text color="gray.900" fontSize="sm">
              Data model
            </Text>
          </HStack>
          <Select
            value={methodType}
            onChange={(e) => {
              const value = e.target.value;
              setMethodType(value);
              onFieldUpdate(node.id, "methodType", value);
            }}
            size="sm"
            textColor="white"
          >
            <option value="RetrieveAllRecords">Retrieve All Records</option>
            <option value="RetrieveRecord">Retrieve Single Record</option>
            <option value="CreateRecord">Create Record</option>
          </Select>
          {methodType === "RetrieveAllRecords" && (
            <VStack align="stretch" spacing={2}>
              <HStack>
                <Text color="white" fontSize="sm">
                  Limit Records:
                </Text>
                <NumberInput
                  value={recordLimit}
                  textColor="white"
                  onChange={(valueString) => {
                    const value = parseInt(valueString, 10);
                    setRecordLimit(value);
                    onFieldUpdate(node.id, "recordLimit", value.toString());
                  }}
                  min={1}
                  max={100}
                  width="80px"
                  size="sm"
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack>
              <HStack>
                <Text color="white" fontSize="sm">
                  Assign return value to:
                </Text>
                <Select
                  placeholder="Select variable"
                  value={node.parameters.returnVariable || ""}
                  textColor="white"
                  onChange={(e) => {
                    const value = e.target.value;
                    setReturnVariable(value);
                    onFieldUpdate(node.id, "returnVariable", value);
                  }}
                  size="sm"
                  width="150px"
                >
                  {dataVariables.map((variable) => (
                    <option key={variable.id} value={variable.name}>
                      {variable.name}
                    </option>
                  ))}
                </Select>
              </HStack>
            </VStack>
          )}
          {methodType === "RetrieveRecord" && (
            <VStack align="stretch" spacing={2}>
              <HStack>
                <Text color="white" fontSize="sm">
                  Use Context:
                </Text>
                <Switch
                  isChecked={useContext}
                  onChange={(e) => handleUseContextChange(e.target.checked)}
                  size="sm"
                />
              </HStack>

              {useContext ? (
                <Select
                  size="sm"
                  value={node.parameters?.contextParam || ""}
                  onChange={(e) => handleContextParamChange(e.target.value)}
                  placeholder="Select parameter"
                  textColor="white"
                >
                  {flowgraphContext.parameters?.map((param) => (
                    <option key={param.name} value={param.name}>
                      {param.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <HStack>
                  <Text color="white" fontSize="sm">
                    Record ID:
                  </Text>
                  <Select
                    placeholder="Select source"
                    value={node.parameters?.recordId || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      onFieldUpdate(node.id, "contextParam", "");
                      onFieldUpdate(node.id, "recordId", value);
                    }}
                    size="sm"
                    width="200px"
                    textColor="white"
                  >
                    <option value="KaseyOS/params/id">
                      URL Parameter (id)
                    </option>
                    <option value="$payload/id">Payload ID</option>
                  </Select>
                </HStack>
              )}

              <HStack>
                <Text color="white" fontSize="sm">
                  Assign return value to:
                </Text>
                <Select
                  placeholder="Select variable"
                  value={returnVariable}
                  textColor="white"
                  onChange={(e) => {
                    const value = e.target.value;
                    setReturnVariable(value);
                    onFieldUpdate(node.id, "returnVariable", value);
                  }}
                  size="sm"
                  width="150px"
                >
                  {dataVariables.map((variable) => (
                    <option key={variable.id} value={variable.name}>
                      {variable.name}
                    </option>
                  ))}
                </Select>
              </HStack>
            </VStack>
          )}
          {methodType === "CreateRecord" && (
            <VStack align="stretch" spacing={2}>
              <HStack>
                <Text color="white" fontSize="sm">
                  Use Context:
                </Text>
                <Switch
                  isChecked={useContext}
                  onChange={(e) => handleUseContextChange(e.target.checked)}
                  size="sm"
                />
              </HStack>

              {useContext ? (
                <Select
                  size="sm"
                  value={node.parameters?.contextParam || ""}
                  onChange={(e) => handleContextParamChange(e.target.value)}
                  placeholder="Select parameter"
                  textColor="white"
                >
                  {flowgraphContext.parameters?.map((param) => (
                    <option key={param.name} value={param.name}>
                      {param.name}
                    </option>
                  ))}
                </Select>
              ) : (
                modelName && renderCreateRecordFields()
              )}

              <HStack>
                <Text color="white" fontSize="sm">
                  Assign return value to:
                </Text>
                <Select
                  placeholder="Select variable"
                  value={node.parameters.returnVariable || ""}
                  textColor="white"
                  onChange={(e) => {
                    const value = e.target.value;
                    setReturnVariable(value);
                    onFieldUpdate(node.id, "returnVariable", value);
                  }}
                  size="sm"
                  width="150px"
                >
                  {dataVariables.map((variable) => (
                    <option key={variable.id} value={variable.name}>
                      {variable.name}
                    </option>
                  ))}
                </Select>
              </HStack>
            </VStack>
          )}
        </VStack>

        {node.chains?.map((chain, index) => (
          <React.Fragment key={index}>
            <Box
              borderTopWidth={1}
              borderColor={colors.blueDark.blue12}
              mx={3}
              my={2}
              opacity={0.5}
            />
            <VStack align="stretch" p={3} bg="#303134" spacing={2} role="group">
              <HStack width="100%" justifyContent="space-between">
                <Text fontWeight="medium" color="white">
                  Chain Model Method {index + 1}:
                </Text>
                <HStack>
                  <IconButton
                    icon={<IconTrash size={16} />}
                    aria-label="Remove Chain"
                    size="sm"
                    variant="ghost"
                    color={colors.red.red6}
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                    onClick={(e) => {
                      const updatedChains = node.chains?.filter(
                        (_, i) => i !== index
                      );
                      const updatedNode = {
                        ...node,
                        chains: updatedChains,
                      };
                      onUpdateNode(node.id, updatedNode);
                      e.stopPropagation();
                    }}
                  />
                </HStack>
              </HStack>
              <Select
                value={chain.methodType}
                onChange={(e) =>
                  handleChainMethodTypeChange(e.target.value, index)
                }
                size="sm"
                textColor="white"
              >
                <option value="RetrieveAllRecords">Retrieve All Records</option>
                <option value="RetrieveRecord">Retrieve Single Record</option>
              </Select>

              <HStack>
                <Text color="white" fontSize="sm">
                  Model:
                </Text>
                <Select
                  value={chain.parameters?.modelName || ""}
                  onChange={(e) =>
                    handleChainParameterChange(
                      "modelName",
                      e.target.value,
                      index
                    )
                  }
                  size="sm"
                  textColor="white"
                >
                  <option value="">Select Model</option>
                  {availableDataModels.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </Select>
              </HStack>

              {chain.methodType === "RetrieveAllRecords" && (
                <HStack>
                  <Text color="white" fontSize="sm">
                    Limit Records:
                  </Text>
                  <NumberInput
                    value={chain.parameters?.recordLimit || 10}
                    textColor="white"
                    onChange={(valueString) =>
                      handleChainParameterChange(
                        "recordLimit",
                        valueString,
                        index
                      )
                    }
                    min={1}
                    max={100}
                    size="sm"
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </HStack>
              )}

              <HStack>
                <Text color="white" fontSize="sm">
                  Assign return value to:
                </Text>
                <Select
                  placeholder="Select variable"
                  value={chain.parameters?.returnVariable || ""}
                  onChange={(e) =>
                    handleChainParameterChange(
                      "returnVariable",
                      e.target.value,
                      index
                    )
                  }
                  size="sm"
                  width="150px"
                  textColor="white"
                >
                  {dataVariables.map((variable) => (
                    <option key={variable.id} value={variable.name}>
                      {variable.name}
                    </option>
                  ))}
                </Select>
              </HStack>
            </VStack>
          </React.Fragment>
        ))}
      </Box>

      <Button
        leftIcon={<IconPlus size={14} />}
        size="sm"
        position="absolute"
        bottom="-8"
        right="0"
        opacity="0"
        _groupHover={{ opacity: 1 }}
        bg="#202124"
        color="white"
        _hover={{ bg: "#303134" }}
        borderWidth={1}
        borderColor={iconColor}
        onClick={handleChainClick}
      >
        +Query
      </Button>

      {isSelected && (
        <PropertiesPanel
          selectedField={{ nodeId: node.id, field: "name" }}
          fieldValue={modelName}
          onClose={handleClosePropertiesPanel}
          onFieldUpdate={(nodeId, field, value) => {
            handleModelNameUpdate(value);
            handleClosePropertiesPanel();
          }}
          availableVariables={availableVariables}
          availableDataModels={availableDataModels}
          availableDataVariables={availableDataVariables}
        />
      )}
    </Box>
  );
}
