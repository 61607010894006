import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Button, Select, VStack, HStack, Text, Center, IconButton } from '@chakra-ui/react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { useAppDescriptorStore } from '../../stores/appDescriptorStore';
import { LiteralValueEditor } from './FlowgraphEditor/components/directiveEditors/LiteralValueEditor';
import { Function, TestCase } from '../../schemas/shared/functionSchema';
import { useExecuteFlowgraph } from './FlowgraphEditor/services/executeFlowgraph';
import { railsApiCall } from '../../utils/railsApiCall';
import { useParams } from 'react-router-dom';
import { TypedValue } from '@/runtime-js/src/types';
import { FlowgraphContext } from './FlowgraphEditor/types/FlowgraphContext';
interface FunctionTestBenchProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export const FunctionTestBench: React.FC<FunctionTestBenchProps> = ({ keypath, flowgraphContext }) => {
  console.log("FunctionTestBench", flowgraphContext);
  const { getFragment, setFragment, addRecordToCollectionFragment, removeRecordFromCollectionFragment } = useAppDescriptorStore();
  const { projectId } = useParams();
  const [activeTestId, setActiveTestId] = useState<string | null>(null);
  const [serverOutput, setServerOutput] = useState<any>(null);
  const [serverError, setServerError] = useState<string | null>(null);
  const [executionMode, setExecutionMode] = useState<'browser' | 'server' | null>(null);
  const [testUpdateTrigger, setTestUpdateTrigger] = useState(0);

  const functionData = useMemo(() => getFragment(keypath) as Function, [getFragment, keypath, testUpdateTrigger]);
  const tests = useMemo(() => functionData?.tests || [], [functionData]);
  const flowgraph = useMemo(() => getFragment(`${keypath}/flowgraph`), [getFragment, keypath]);

  const { executeFlowgraph, output } = useExecuteFlowgraph({ keypath: `${keypath}/flowgraph` });

  useEffect(() => {
    if (tests.length > 0 && (!activeTestId || !tests.find(test => test.id === activeTestId))) {
      setActiveTestId(tests[0].id);
    } else if (tests.length === 0) {
      setActiveTestId(null);
    }
  }, [tests, activeTestId]);

  const handleCreateTest = useCallback(() => {
    const parameters = flowgraphContext.parameters || [];
    const defaultArgs = parameters.reduce((acc, param) => {
      acc[param.name] = {
        directiveType: 'literalValue',
        config: {
          type: param.dataType,
          value: param.defaultValue,
        },
      };
      return acc;
    }, {});
    const newTest: TestCase = {
      name: `Test ${tests.length + 1}`,
      arguments: defaultArgs,
    };
    const newTestId = addRecordToCollectionFragment(`${keypath}/tests`, newTest);
    setActiveTestId(newTestId);
    setTestUpdateTrigger(prev => prev + 1); // Trigger re-render
  }, [functionData, tests, addRecordToCollectionFragment, keypath]);

  const handleDeleteTest = useCallback(() => {
    if (activeTestId) {
      removeRecordFromCollectionFragment(`${keypath}/tests`, activeTestId);
      const remainingTests = tests.filter(test => test.id !== activeTestId);
      if (remainingTests.length > 0) {
        setActiveTestId(remainingTests[0].id);
      } else {
        setActiveTestId(null);
      }
      setTestUpdateTrigger(prev => prev + 1); // Trigger re-render
    }
  }, [activeTestId, removeRecordFromCollectionFragment, keypath, tests]);

  const handleExecuteInBrowser = useCallback(() => {
    if (activeTestId) {
      const currentTests = (getFragment(`${keypath}/tests`) || []) as TestCase[];
      const testArgs = currentTests.find(test => test.id === activeTestId)?.arguments as Record<string, TypedValue>;
      executeFlowgraph({ args: testArgs });
      setExecutionMode('browser');
    }
  }, [activeTestId, getFragment, keypath, executeFlowgraph]);

  const handleExecuteOnServer = useCallback(async () => {
    if (activeTestId) {
      const currentTests = (getFragment(`${keypath}/tests`) || []) as TestCase[];
      const testArgs = currentTests.find(test => test.id === activeTestId)?.arguments;

      try {
        const response = await railsApiCall<{ output: any }>({
          method: 'POST',
          endpoint: `/projects/${projectId}/execute_flowgraph`,
          body: { flowgraph, args: testArgs },
        });
        setServerOutput(response.data.output);
        setServerError(null);
        setExecutionMode('server');
      } catch (error) {
        setServerError(error instanceof Error ? error.message : String(error));
        setServerOutput(null);
        setExecutionMode('server');
      }
    }
  }, [activeTestId, getFragment, keypath, projectId, flowgraph]);

  if (tests.length === 0) {
    return (
      <Center p={8}>
        <VStack spacing={4}>
          <Text>No tests available for this function.</Text>
          <Button onClick={handleCreateTest} colorScheme="blue">Create Test</Button>
        </VStack>
      </Center>
    );
  }

  return (
    <Box>
      <HStack spacing={4} mb={4}>
        <Select
          value={activeTestId || ''}
          onChange={(e) => setActiveTestId(e.target.value)}
        >
          {tests.map((test) => (
            <option key={test.id} value={test.id}>{test.name}</option>
          ))}
        </Select>
        <IconButton
          aria-label="New Test"
          icon={<FiPlus />}
          onClick={handleCreateTest}
          variant="ghost"
        />
        <IconButton
          aria-label="Delete Test"
          icon={<FiTrash2 />}
          onClick={handleDeleteTest}
          isDisabled={tests.length === 0}
          variant="ghost"
        />
      </HStack>

      {activeTestId ? (
        <VStack align="start" spacing={4}>
          <Text fontWeight="bold">Test Arguments:</Text>
          {flowgraphContext.parameters?.map((param) => (
            <HStack key={param.name}>
              <Text>{param.name}:</Text>
              <LiteralValueEditor
                keypath={`${keypath}/tests/id:${activeTestId}/arguments/${param.name}`}
                allowedDirectiveTypes={['literalValue']}
              />
            </HStack>
          ))}
          <HStack>
            <Button onClick={handleExecuteInBrowser}>Execute in Browser</Button>
            <Button onClick={handleExecuteOnServer}>Execute on Server</Button>
          </HStack>
        </VStack>
      ) : (
        <Text>Select a test case or create a new one.</Text>
      )}

      {executionMode === 'browser' && output.execCount > 0 && (
        <Box mt={4}>
          <Text fontWeight="bold">Execution result:</Text>
          <Text>{output.error ? `Error: ${output.error.message}` : JSON.stringify(output.output, null, 2)}</Text>
        </Box>
      )}

      {executionMode === 'server' && (
        <Box mt={4}>
          <Text fontWeight="bold">Execution result:</Text>
          {serverError ? (
            <Text color="red">{serverError}</Text>
          ) : (
            <Text>{JSON.stringify(serverOutput, null, 2)}</Text>
          )}
        </Box>
      )}
    </Box>
  );
};