import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Heading,
  useDisclosure,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useAppDescriptorStore } from '@/bundles/DescriptorEditor/stores/appDescriptorStore';
import { ComponentBlueprint, ComponentInstance } from '@/bundles/DescriptorEditor/schemas/userInterface/componentsSchema';
import ViewgraphComponentInstance from './ViewgraphComponentInstance';
import PropertyEditor from './PropertyEditor/PropertyEditor';

interface ComponentInstanceEditorProps {
  keypath: string;
  availableComponentBlueprints: ComponentBlueprint[];
}

const ComponentInstanceEditor: React.FC<ComponentInstanceEditorProps> = ({
  keypath,
  availableComponentBlueprints,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [selectedComponent, setSelectedComponent] = useState<ComponentInstance | null>(null);

  useEffect(() => {
    const component = getFragment(keypath) as ComponentInstance;
    setSelectedComponent(component);
  }, [getFragment, keypath]);

  const handleComponentSelect = (blueprint: ComponentBlueprint) => {
    const newComponent: ComponentInstance = {
      id: `${blueprint.name}_${Date.now()}`,
      blueprintName: blueprint.name,
      propertiesBindings: {},
    };
    setSelectedComponent(newComponent);
    setFragment(keypath, newComponent);
    onClose();
  };

  const renderComponentSelector = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select a Component</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {availableComponentBlueprints.length === 0 ? (
            <Alert status="warning">
              <AlertIcon />
              No available component blueprints found.
            </Alert>
          ) : (
            <VStack spacing={4}>
              {availableComponentBlueprints.map((blueprint) => (
                <Button
                  key={blueprint.id}
                  onClick={() => handleComponentSelect(blueprint)}
                  width="100%"
                >
                  {blueprint.name}
                </Button>
              ))}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  if (!selectedComponent) {
    return (
      <Box>
        <Button onClick={onOpen}>Select Component</Button>
        {renderComponentSelector()}
      </Box>
    );
  }

  const componentBlueprint = availableComponentBlueprints.find(
    (bp) => bp.name === selectedComponent.blueprintName
  );

  return (
    <Box>
      <Heading size="md" mb={4}>
        {componentBlueprint?.name || 'Unknown Component'}
      </Heading>
      <Box mb={4}>
        <ViewgraphComponentInstance componentInstance={selectedComponent} />
      </Box>
      <VStack spacing={4} align="stretch">
        {componentBlueprint?.propertiesSchema?.map((property) => (
          <PropertyEditor
            key={property.name}
            schema={property}
            keypath={`${keypath}/propertiesBindings/${property.name}`}
          />
        ))}
      </VStack>
      {renderComponentSelector()}
    </Box>
  );
};

export default ComponentInstanceEditor;