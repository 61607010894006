import { Directive } from "./directiveTypes";

export type GetPageVariableConfig = {
  variableName: string;
};

export type GetPageVariableDirective = Directive<
  "getPageVariable",
  GetPageVariableConfig
>;

export const getPageVariable = {
  defaultConfig: (): GetPageVariableConfig => ({
    variableName: "",
  }),
  execute: async (
    config: GetPageVariableConfig,
    context: { pageVariables: Record<string, unknown> }
  ) => {
    return context.pageVariables[config.variableName];
  },
};