export function extractPathParameters(path: string): string[] {
  const paramRegex = /:(\w+)/g;
  const matches = path.match(paramRegex);
  return matches ? matches.map(match => match.slice(1)) : [];
}

export function isValidPath(path: string): boolean {
  // This regex allows for path parameters denoted by :paramName
  // It matches:
  // - Starts with a forward slash
  // - Can have multiple segments separated by forward slashes
  // - Each segment can be:
  //   - A combination of word characters and hyphens
  //   - OR a colon followed by word characters (for path parameters)
  // - Ends with either a word character, hyphen, or path parameter
  return /^\/(([\w-]+|:\w+)(\/(?!$))?)+$/.test(path);
}