import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box, Heading, Button, VStack, Badge, Text, Divider } from '@chakra-ui/react';
import { DataModelField, DataModelValidation } from '@/bundles/DescriptorEditor/schemas/essentials/dataModelsSchema';

interface ValidationsPerFieldProps {
  fieldsWithValidations: DataModelField[];
  fieldsWithoutValidations: DataModelField[];
  openNewModal: (fieldId: string) => void;
  openEditModal: (fieldId: string, validation: DataModelValidation) => void;
}

const ValidationsPerField: React.FC<ValidationsPerFieldProps> = ({
  fieldsWithValidations,
  fieldsWithoutValidations,
  openNewModal,
  openEditModal,
}) => {
  const renderValidationBadge = (field: DataModelField, validation: DataModelValidation) => (
    <Badge 
      colorScheme="blue" 
      p={2} 
      borderRadius="md" 
      cursor="pointer" 
      onClick={() => openEditModal(field.id, validation)}
    >
      <Text fontWeight="bold">{validation.validationFunctionName}</Text>
      {Object.entries(validation.arguments || {}).map(([key, value]) => (
        <Text key={key} fontSize="sm">{`${key}: ${JSON.stringify(value)}`}</Text>
      ))}
    </Badge>
  );

  return (
    <>
      <Heading as="h2" size="md" mb={4}>Fields with validations</Heading>
      {fieldsWithValidations.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Field name</Th>
              <Th>Field data type</Th>
              <Th>Validations</Th>
            </Tr>
          </Thead>
          <Tbody>
            {fieldsWithValidations.map(field => (
              <Tr key={field.name}>
                <Td verticalAlign="top">{field.name}</Td>
                <Td verticalAlign="top">{field.dataType}</Td>
                <Td verticalAlign="top">
                  <VStack align="start" spacing={2}>
                    {field.validations?.map(validation => renderValidationBadge(field, validation))}
                    <Button colorScheme="blue" size="sm" onClick={() => openNewModal(field.id)}>Add validation</Button>
                  </VStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Box p={4} bg="gray.100" borderRadius="md">
          No fields with validations yet.
        </Box>
      )}

      <Divider my={8} />

      <Heading as="h2" size="md" mb={4}>Fields without validations</Heading>
      {fieldsWithoutValidations.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Field name</Th>
              <Th>Field data type</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {fieldsWithoutValidations.map(field => (
              <Tr key={field.id}>
                <Td verticalAlign="top">{field.name}</Td>
                <Td verticalAlign="top">{field.dataType}</Td>
                <Td verticalAlign="top">
                  <Button colorScheme="blue" size="sm" onClick={() => openNewModal(field.id)}>Add validation</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Box p={4} bg="gray.100" borderRadius="md">
          All fields have validations. Great job!
        </Box>
      )}
    </>
  );
};

export default ValidationsPerField;