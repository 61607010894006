import React from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  IconButton,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import {
  IconMinus,
  IconBolt,
  IconCheck,
  IconX,
  IconTrash,
  IconScale,
  IconChevronUp,
  IconChevronDown,
} from "@tabler/icons-react";
import * as colors from "@radix-ui/colors";
import { PropertiesPanel } from "./PropertiesPanel";
import { SelectedField, ConditionalNode } from "../types";
import { SelectBindingModal } from "./SelectBindingModal";
import { useState } from "react";
import { ComparisonOperatorModal } from "./ComparisonOperatorModal";

interface ConditionalStatementProps {
  id: string;
  name: string;
  onSelectField: (
    nodeId: string,
    field: "Number1" | "Number2" | "name"
  ) => void;
  number1: string | null;
  number2: string | null;
  functionCall: string | null;
  onAddSubNode: (branch: "yes" | "no", nodeType: string) => void;
  onDeleteNode: (nodeId: string) => void;
  isSelected: boolean;
  selectedField: SelectedField | null;
  handleFieldUpdate: (
    nodeId: string,
    field: "Number1" | "Number2" | "name",
    value: string
  ) => void;
  findNodeById: (nodeId: string) => ConditionalNode | null;
  handleClosePropertiesPanel: () => void;
  comparisonOperator: string;
  handleComparisonOperatorUpdate: (nodeId: string, operator: string) => void;
  onReorder: (direction: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  availableVariables: string[];
  availableDataVariables: string[];
}

export function ConditionalStatement({
  id,
  name,
  onSelectField,
  number1,
  number2,
  functionCall,
  onAddSubNode,
  onDeleteNode,
  isSelected,
  selectedField,
  handleFieldUpdate,
  findNodeById,
  handleClosePropertiesPanel,
  comparisonOperator,
  handleComparisonOperatorUpdate,
  onReorder,
  isFirst,
  isLast,
  availableVariables,
  availableDataVariables,
}: ConditionalStatementProps) {
  const node = findNodeById(id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<"yes" | "no" | null>(
    null
  );
  const [isComparisonModalOpen, setIsComparisonModalOpen] = useState(false);

  const handleAddSubNode = (branch: "yes" | "no") => {
    setSelectedBranch(branch);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedBranch(null);
  };

  const handleComparisonOperatorClick = () => {
    setIsComparisonModalOpen(true);
  };

  return (
    <Box position="relative">
      <Box
        borderWidth={1}
        borderColor="rgb(138, 180, 248)"
        borderRadius="md"
        p={4}
        bg={colors.grayDark.gray2}
        width="350px"
        boxShadow={isSelected ? "0 0 10px rgba(66, 153, 225, 0.6)" : "none"}
        cursor="pointer"
        _hover={{ boxShadow: "0 0 10px rgba(66, 153, 225, 0.3)" }}
      >
        <VStack align="start" spacing={2}>
          <HStack width="100%" justify="space-between">
            <HStack>
              <Tooltip label="Collapse" aria-label="Collapse Tooltip">
                <IconButton
                  icon={<IconMinus size={16} />}
                  aria-label="Collapse"
                  size="sm"
                  variant="ghost"
                  color={colors.grayDark.gray12}
                  onClick={(e) => e.stopPropagation()}
                />
              </Tooltip>
              <Text
                color={colors.grayDark.gray12}
                fontWeight="bold"
                fontSize="md"
              >
                {name}
              </Text>
            </HStack>
            <HStack>
              {!isFirst && (
                <Tooltip label="Move Up" aria-label="Move Up Tooltip">
                  <IconButton
                    icon={<IconChevronUp size={16} />}
                    aria-label="Move Up"
                    size="sm"
                    variant="ghost"
                    color={colors.grayDark.gray12}
                    onClick={() => onReorder("up")}
                  />
                </Tooltip>
              )}
              {!isLast && (
                <Tooltip label="Move Down" aria-label="Move Down Tooltip">
                  <IconButton
                    icon={<IconChevronDown size={16} />}
                    aria-label="Move Down"
                    size="sm"
                    variant="ghost"
                    color={colors.grayDark.gray12}
                    onClick={() => onReorder("down")}
                  />
                </Tooltip>
              )}
              <Tooltip label="Delete Node" aria-label="Delete Node Tooltip">
                <IconButton
                  icon={<IconTrash size={16} />}
                  aria-label="Delete Node"
                  size="sm"
                  variant="ghost"
                  color={colors.red.red6}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteNode(id);
                  }}
                  _hover={{ bg: colors.red.red3 }}
                />
              </Tooltip>
            </HStack>
          </HStack>
          {name === "Static Value" ? (
            <Box
              borderWidth={1}
              borderColor={colors.grayDark.gray6}
              borderRadius="md"
              p={2}
              width="100%"
              bg={colors.grayDark.gray3}
              onClick={() => onSelectField(id, "Number1")}
              cursor="pointer"
            >
              <HStack>
                <Text color={colors.grayDark.gray11}>{number1 || "(x)"}</Text>
                <Text color={colors.grayDark.gray11} ml="auto">
                  Value
                </Text>
              </HStack>
            </Box>
          ) : (
            <>
              <Box
                borderWidth={1}
                borderColor={colors.grayDark.gray6}
                borderRadius="md"
                p={2}
                width="100%"
                bg={colors.grayDark.gray3}
                onClick={() => onSelectField(id, "Number1")}
                cursor="pointer"
              >
                <HStack>
                  <Text color={colors.grayDark.gray11}>{number1 || "(x)"}</Text>
                  <Text color={colors.grayDark.gray11} ml="auto">
                    Number 1
                  </Text>
                </HStack>
              </Box>
              <Box
                borderWidth={1}
                borderColor={colors.grayDark.gray6}
                borderRadius="md"
                p={2}
                width="100%"
                bg={colors.grayDark.gray3}
                onClick={handleComparisonOperatorClick}
                cursor="pointer"
              >
                <HStack>
                  <HStack spacing={2} color={colors.grayDark.gray11}>
                    <IconScale size={16} />
                    <Text>{comparisonOperator || "Select operator"}</Text>
                  </HStack>
                  <Text color={colors.grayDark.gray11} ml="auto">
                    Operator
                  </Text>
                </HStack>
              </Box>
              <Box
                borderWidth={1}
                borderColor={colors.grayDark.gray6}
                borderRadius="md"
                p={2}
                width="100%"
                bg={colors.grayDark.gray3}
                onClick={() => onSelectField(id, "Number2")}
                cursor="pointer"
              >
                <HStack>
                  {functionCall ? (
                    <>
                      <IconBolt size={16} color={colors.blue.blue11} />
                      <Text color={colors.blue.blue11}>{functionCall}</Text>
                    </>
                  ) : (
                    <Text color={colors.grayDark.gray11}>
                      {number2 || "(x)"}
                    </Text>
                  )}
                  <Text color={colors.grayDark.gray11} ml="auto">
                    Number 2
                  </Text>
                </HStack>
              </Box>
              <Text color={colors.grayDark.gray12}>?</Text>
              <HStack spacing={4} width="100%" align="start">
                {/* Yes Branch */}
                <VStack align="start" spacing={2} width="50%">
                  <Center
                    w="100%"
                    h="45px"
                    borderWidth="2px"
                    borderStyle="dashed"
                    borderColor="green.500"
                    borderRadius="md"
                    cursor="pointer"
                    _hover={{ bg: colors.grayDark.gray4 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddSubNode("yes");
                    }}
                  >
                    <HStack>
                      <Text color="green">Yes</Text>
                      <IconCheck size={16} color="green" />
                    </HStack>
                  </Center>
                </VStack>

                {/* No Branch */}
                <VStack align="start" spacing={2} width="50%">
                  <Center
                    w="100%"
                    h="45px"
                    borderWidth="2px"
                    borderStyle="dashed"
                    borderColor="red.500"
                    borderRadius="md"
                    cursor="pointer"
                    _hover={{ bg: colors.grayDark.gray4 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddSubNode("no");
                    }}
                  >
                    <HStack>
                      <Text color="red">No</Text>
                      <IconX size={16} color="red" />
                    </HStack>
                  </Center>
                </VStack>
              </HStack>
            </>
          )}
        </VStack>
      </Box>

      <SelectBindingModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        options={{
          mode: "addStep",
          keypath: `${id}/${selectedBranch}`,
          newStepIndex: 0,
        }}
        onSelect={(selectedOption) => {
          if (selectedBranch) {
            onAddSubNode(selectedBranch, selectedOption);
          }
          handleModalClose();
        }}
      />

      <ComparisonOperatorModal
        isOpen={isComparisonModalOpen}
        onClose={() => setIsComparisonModalOpen(false)}
        currentOperator={comparisonOperator}
        onSelect={(selectedOperator) => {
          handleComparisonOperatorUpdate(id, selectedOperator);
          setIsComparisonModalOpen(false);
        }}
      />

      {/* Render PropertiesPanel adjacent to the selected node */}
      {isSelected && selectedField && selectedField.nodeId === id && (
        <PropertiesPanel
          selectedField={selectedField}
          fieldValue={
            node
              ? selectedField.field === "Number1"
                ? node.number1
                : selectedField.field === "Number2"
                ? node.number2
                : node.name
              : null
          }
          onClose={handleClosePropertiesPanel}
          onFieldUpdate={handleFieldUpdate}
          availableVariables={availableVariables}
          availableDataVariables={availableDataVariables}
        />
      )}
    </Box>
  );
}
