import {
  type Directive,
  type NestableDirective,
  type AnyDirective,
} from "./directiveTypes";
import type { TypedValue } from "../types/typedValue";
import { getDefaultVariable, getVariableNames } from "../flowFunctionStore";
import { createLiteralValueDirectiveOfType } from "./literalValue";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import { searchAnyDirective } from "../flowFunctionStore";

export type SetVariableDirective = Directive<"setVariable", SetVariableConfig>;

export interface SetVariableConfig {
  name: string;
  newValue: NestableDirective;
}

export function isSetVariableDirective(
  directive: AnyDirective,
): directive is SetVariableDirective {
  return (
    directive &&
    directive.directiveType === "setVariable" &&
    directive.config &&
    "name" in directive.config &&
    "newValue" in directive.config
  );
}

const execute = async (
  config: SetVariableConfig,
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { name, newValue } = config;

  if (!executionContext.getVariableTypedValue(name)) {
    throw new Error(`Variable '${name}' has not been declared`);
  }

  const resolvedNewValue = await executionContext.executeDirective(newValue);

  executionContext.setVariable(name, resolvedNewValue);

  return resolvedNewValue;
};

function updateName(directive: SetVariableDirective, name: string) {
  if (!isSetVariableDirective(directive)) {
    throw new Error(
      `Directive is not a setVariable directive: ${JSON.stringify(directive)}`,
    );
  }

  if (!getVariableNames().includes(name)) {
    throw new Error(`Variable '${name}' has not been declared`);
  }

  directive.config.name = name;
}

function updateNewValue(
  directive: SetVariableDirective,
  newValue: NestableDirective,
) {
  directive.config.newValue = newValue;
}

function searchDirective(
  directive: SetVariableDirective,
  id: string,
): AnyDirective | undefined {
  if (directive.id === id) {
    return directive;
  }

  const { newValue } = directive.config;
  if (newValue) {
    const found = searchAnyDirective([newValue], id);
    if (found) {
      return found;
    }
  }

  return undefined;
}

export const setVariable = {
  execute,
  availableActions: {
    updateName,
    updateNewValue,
  },
  defaultConfig: (): SetVariableConfig => {
    const variable = getDefaultVariable();
    if (!variable) {
      return {
        name: "",
        newValue: createLiteralValueDirectiveOfType("_types.String"),
      };
    }

    const variableName = variable.name;
    const defaultValue = createLiteralValueDirectiveOfType(variable.type);
    return {
      name: variableName,
      newValue: defaultValue,
    };
  },
  searchDirective,
};
