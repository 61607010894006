import React from 'react';
import { Box, Flex, Button, HStack, Divider, VStack } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import RecordNameEditor from './RecordNameEditor';
import RecordDescriptionEditor from './RecordDescriptionEditor';
import Breadcrumbs from '../layout/Breadcrumbs';
import { IconType } from 'react-icons';

interface Tab {
  attribute: string;
  name: string;
  icon?: IconType;
  onClick?: () => void;
}

interface RecordHeaderProps {
  keypath: string;
  tabs: Tab[];
}

const RecordHeader: React.FC<RecordHeaderProps> = ({ keypath, tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <VStack align="stretch" spacing={0}>
          <RecordNameEditor keypath={keypath + '/name'} />
          <RecordDescriptionEditor keypath={keypath + '/description'} />
        </VStack>
      </Flex>
      <HStack spacing={1} justifyContent="flex-start" mb={4}>
        {tabs.map((tab) => (
          <Button
            key={tab.attribute}
            size="sm"
            variant={location.pathname.includes(tab.attribute) ? 'solid' : 'ghost'}
            onClick={() => tab.onClick ? tab.onClick() : navigate(tab.attribute)}
            bg={location.pathname.includes(tab.attribute) ? 'gray.700' : 'transparent'}
            color={location.pathname.includes(tab.attribute) ? 'white' : 'gray.700'}
            _hover={{ bg: location.pathname.includes(tab.attribute) ? 'gray.800' : 'gray.100' }}
            fontWeight="medium"
            px={4}
            py={2}
            borderRadius="md"
            leftIcon={tab.icon ? <tab.icon /> : undefined}
          >
            {tab.name}
          </Button>
        ))}
      </HStack>
      <Divider mb={4} />
    </Box>
  );
};

export default RecordHeader;