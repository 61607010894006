import { type Directive, isDirective } from "./directiveTypes";
import { FlowFunctionExecutionContext } from "../FlowFunctionExecutionContext";
import type { TypedValue } from "../types/typedValue";

export type GetIteratorVariableDirective = Directive<
  "getIteratorVariable",
  GetIteratorVariableConfig
>;

export interface GetIteratorVariableConfig {
  name: string;
}

export function isGetIteratorVariableDirective(
  directive: any,
): directive is GetIteratorVariableDirective {
  const getIteratorVariableConfigKeys: (keyof GetIteratorVariableConfig)[] = [
    "name",
  ];
  return isDirective(
    directive,
    "getIteratorVariable",
    getIteratorVariableConfigKeys,
  );
}

const execute = async (
  config: { name: string },
  executionContext: FlowFunctionExecutionContext,
): Promise<TypedValue> => {
  const { name } = config;
  return executionContext.getIteratorVariable(name);
};

export const getIteratorVariable = {
  execute,
  availableActions: {},
  defaultConfig: () => ({
    name: "",
  }),
};
