import React from "react";
import { Box, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

interface GetIteratorVariableEditorProps {
  keypath: string;
}

export function GetIteratorVariableEditor({
  keypath,
}: GetIteratorVariableEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as any;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const handleVariableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFragment(keypath, {
      ...directive,
      config: { ...directive.config, variable: e.target.value },
    });
  };

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="iteratorVariable" fontSize="sm">
          Iterator Variable
        </FormLabel>
        <Input
          id="iteratorVariable"
          value={directive.config.variable}
          onChange={handleVariableChange}
          borderColor="gray.500"
          bg="gray.600"
          color="white"
        />
      </FormControl>
      <Button
        onClick={() => directive.updateStore()}
        bg="blue.500"
        color="white"
        size="sm"
      >
        Update
      </Button>
    </Box>
  );
}
