import React, { useCallback, useMemo, useEffect } from "react";
import { Box, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { GetPageVariableDirective } from "@/runtime-js/src/directives/getPageVariable";
import { FlowgraphContext } from "../../types/FlowgraphContext";

interface GetPageVariableEditorProps {
  keypath: string;
  flowgraphContext: FlowgraphContext;
}

export function GetPageVariableEditor({ keypath, flowgraphContext }: GetPageVariableEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as GetPageVariableDirective;

  const handleVariableChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newVariableName = event.target.value;
      setFragment(keypath, {
        ...directive,
        config: { ...directive.config, variableName: newVariableName },
      });
    },
    [keypath, directive, setFragment]
  );

  const declaredPageVariables = flowgraphContext.declaredPageVariables || new Set();

  const pageVariables = useMemo(() => Array.from(declaredPageVariables), [declaredPageVariables]);

  // Set variableName to first available option if it's empty
  useEffect(() => {
    if (!directive.config?.variableName && pageVariables.length > 0) {
      setFragment(keypath, {
        ...directive,
        config: { ...directive.config, variableName: pageVariables[0] },
      });
    }
  }, [directive, keypath, pageVariables, setFragment]);

  if (!directive || !directive.config) {
    console.error("Directive or directive config not provided");
    return <Box>Error: Directive or directive config not provided</Box>;
  }

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="pageVariableName" fontSize="sm">
          Page Variable to Get
        </FormLabel>
        <Select
          id="pageVariableName"
          value={directive.config?.variableName || ''}
          onChange={handleVariableChange}
        >
          {pageVariables.map((variable) => (
            <option key={variable} value={variable}>
              {variable}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}