import React from "react";
import { FormControl, Switch, Box } from "@chakra-ui/react";

import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { LiteralValueDirective } from "@/runtime-js/src/directives/literalValue";

interface LiteralBooleanValueEditorProps {
  keypath: string;
}

export function LiteralBooleanValueEditor({
  keypath,
}: LiteralBooleanValueEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as LiteralValueDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }
  const value = directive.config.value;

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setFragment(keypath, {
      ...directive,
      config: { ...directive.config, value: newValue },
    });
  };

  return (
    <Box>
      <FormControl>
        <Switch
          id="literalBooleanValue"
          isChecked={value as boolean}
          onChange={handleValueChange}
          borderColor="gray.500"
          bg="gray.600"
          color="white"
        />
      </FormControl>
    </Box>
  );
}