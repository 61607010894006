import React from "react";
import { useState, useEffect } from "react";
import {
  VStack,
  Input,
  Button,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Icon,
  Switch,
} from "@chakra-ui/react";
import {
  IconCode,
  IconChevronLeft,
  IconCube,
  IconDatabase,
  IconCloud,
} from "@tabler/icons-react";

interface PropertiesPanelProps {
  selectedField: {
    nodeId: string;
    field: "Number1" | "Number2" | "name";
  };
  fieldValue: string | null;
  onClose: () => void;
  onFieldUpdate: (
    nodeId: string,
    field: "Number1" | "Number2" | "name" | "parameters",
    value: string | Record<string, any>
  ) => void;
  availableVariables: string[];
  availableDataVariables?: string[];
  availableDataModels?: string[];
  availableServiceMethods?: Array<{
    serviceName: string;
    methodName: string;
  }>;
}

export function PropertiesPanel({
  selectedField,
  fieldValue,
  onClose,
  onFieldUpdate,
  availableVariables = [],
  availableDataVariables = [],
  availableDataModels = [],
  availableServiceMethods = [],
}: PropertiesPanelProps) {
  const [value, setValue] = useState(fieldValue || "");
  const [isNumber, setIsNumber] = useState(false);
  const [isVariableBinding, setIsVariableBinding] = useState(
    fieldValue?.startsWith("$") || false
  );
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    setValue(fieldValue || "");
    setIsNumber(!isNaN(Number(fieldValue)));
    setIsVariableBinding(fieldValue?.startsWith("$") || false);
  }, [fieldValue]);

  const handleInputChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleNumberInputChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleSave = () => {
    onFieldUpdate(selectedField.nodeId, selectedField.field, value);
    onClose();
  };

  // const toggleInputType = () => {
  //   if (isVariableBinding) {
  //     setIsVariableBinding(false);
  //     setIsNumber(false);
  //     setValue("");
  //   } else if (isNumber) {
  //     setIsNumber(false);
  //     setIsVariableBinding(true);
  //     setValue(
  //       availableVariables.length > 0 ? `$${availableVariables[0]}` : ""
  //     );
  //   } else {
  //     setIsNumber(true);
  //     setValue("0");
  //   }
  // };

  const handleVariableSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(`$${e.target.value}`);
  };

  const renderCategories = () => (
    <VStack spacing={4} align="stretch">
      <Button
        onClick={() => {
          setSelectedCategory("static");
          setIsVariableBinding(false);
          setIsNumber(false);
          setValue("");
        }}
        justifyContent="flex-start"
        bg="#1C1C1E"
        _hover={{ bg: "#2C2C2E" }}
        height="60px"
      >
        <VStack align="flex-start" spacing={1}>
          <HStack>
            <Icon as={IconCube} boxSize={5} color="#8E8E93" />
            <Text fontSize="lg" color="white">
              Static values
            </Text>
          </HStack>
          <Text fontSize="sm" color="#6E6E73">
            Set exact values for this field
          </Text>
        </VStack>
      </Button>

      <Button
        onClick={() => {
          setSelectedCategory("variables");
          setIsVariableBinding(true);
          setValue(
            availableVariables.length > 0 ? `$${availableVariables[0]}` : ""
          );
        }}
        justifyContent="flex-start"
        bg="#1C1C1E"
        _hover={{ bg: "#2C2C2E" }}
        height="60px"
      >
        <VStack align="flex-start" spacing={1}>
          <HStack>
            <Icon as={IconCode} boxSize={5} color="#0A84FF" />
            <Text fontSize="lg" color="white">
              Variables
            </Text>
          </HStack>
          <Text fontSize="sm" color="#6E6E73">
            Bind to sequence variables
          </Text>
        </VStack>
      </Button>

      {availableDataVariables && availableDataVariables.length > 0 && (
        <Button
          onClick={() => {
            setSelectedCategory("dataVariables");
            setIsVariableBinding(true);
            setValue(
              availableDataVariables.length > 0
                ? `$${availableDataVariables[0]}`
                : ""
            );
          }}
          justifyContent="flex-start"
          bg="#1C1C1E"
          _hover={{ bg: "#2C2C2E" }}
          height="60px"
        >
          <VStack align="flex-start" spacing={1}>
            <HStack>
              <Icon as={IconDatabase} boxSize={5} color="#30D158" />
              <Text fontSize="lg" color="white">
                Data Variables
              </Text>
            </HStack>
            <Text fontSize="sm" color="#6E6E73">
              Bind to global data variables
            </Text>
          </VStack>
        </Button>
      )}

      {availableDataModels.length > 0 && (
        <Button
          onClick={() => {
            setSelectedCategory("dataModels");
            setIsVariableBinding(false);
            setIsNumber(false);
            setValue(availableDataModels[0] || "");
          }}
          justifyContent="flex-start"
          bg="#1C1C1E"
          _hover={{ bg: "#2C2C2E" }}
          height="60px"
        >
          <VStack align="flex-start" spacing={1}>
            <HStack>
              <Icon as={IconDatabase} boxSize={5} color="#30A46C" />
              <Text fontSize="lg" color="white">
                Data Models
              </Text>
            </HStack>
            <Text fontSize="sm" color="#6E6E73">
              Select from available data models
            </Text>
          </VStack>
        </Button>
      )}

      {availableServiceMethods && availableServiceMethods.length > 0 && (
        <Button
          onClick={() => {
            setSelectedCategory("serviceMethods");
            setIsVariableBinding(false);
            setIsNumber(false);
            setValue(
              availableServiceMethods.length > 0
                ? `${availableServiceMethods[0].serviceName}.${availableServiceMethods[0].methodName}`
                : ""
            );
          }}
          justifyContent="flex-start"
          bg="#1C1C1E"
          _hover={{ bg: "#2C2C2E" }}
          height="60px"
        >
          <VStack align="flex-start" spacing={1}>
            <HStack>
              <Icon as={IconCloud} boxSize={5} color="#FF9500" />
              <Text fontSize="lg" color="white">
                Data Services
              </Text>
            </HStack>
            <Text fontSize="sm" color="#6E6E73">
              Select from available Data Services
            </Text>
          </VStack>
        </Button>
      )}
    </VStack>
  );

  const renderInputField = () => (
    <VStack spacing={3} align="stretch">
      {selectedCategory === "serviceMethods" ? (
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          bg="#1C1C1E"
          color="white"
          border="none"
          borderRadius="sm"
          _focus={{ boxShadow: "none" }}
          _hover={{ bg: "#2C2C2E" }}
        >
          {availableServiceMethods?.map((method) => (
            <option
              key={`${method.serviceName}.${method.methodName}`}
              value={`${method.serviceName}.${method.methodName}`}
            >
              {method.serviceName}.{method.methodName}
            </option>
          ))}
        </Select>
      ) : isVariableBinding ? (
        <Select
          value={value.startsWith("$") ? value.substring(1) : value}
          onChange={handleVariableSelect}
          bg="#1C1C1E"
          color="white"
          border="none"
          borderRadius="sm"
          _focus={{ boxShadow: "none" }}
          _hover={{ bg: "#2C2C2E" }}
        >
          {selectedCategory === "dataVariables"
            ? availableDataVariables?.map((variable) => (
                <option key={variable} value={variable}>
                  {variable}
                </option>
              ))
            : availableVariables.map((variable) => (
                <option key={variable} value={variable}>
                  {variable}
                </option>
              ))}
        </Select>
      ) : (
        <HStack spacing={3} align="center">
          {isNumber ? (
            <NumberInput
              value={value}
              onChange={handleNumberInputChange}
              min={0}
              flex={1}
              borderRadius="md"
              borderColor="#2C2C2E"
            >
              <NumberInputField
                bg="#1C1C1E"
                color="white"
                borderRadius="md"
                _focus={{ boxShadow: "none" }}
                _hover={{ bg: "#2C2C2E" }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper borderColor="#2C2C2E" />
                <NumberDecrementStepper borderColor="#2C2C2E" />
              </NumberInputStepper>
            </NumberInput>
          ) : (
            <Input
              value={value}
              onChange={(e) => handleInputChange(e.target.value)}
              size="sm"
              bg="#1C1C1E"
              color="white"
              borderRadius="md"
              borderColor="#2C2C2E"
              _focus={{ boxShadow: "none" }}
              _hover={{ bg: "#2C2C2E" }}
              flex={1}
            />
          )}
          <HStack spacing={2}>
            <Text fontSize="sm" color="#6E6E73">
              Number
            </Text>
            <Switch
              isChecked={isNumber}
              onChange={() => {
                setIsNumber(!isNumber);
                setValue(isNumber ? "" : "0");
              }}
              size="sm"
            />
          </HStack>
        </HStack>
      )}
    </VStack>
  );

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#1C1C1E" color="white">
        <ModalHeader>
          {selectedCategory && (
            <Button
              variant="ghost"
              leftIcon={<IconChevronLeft color="#8E8E93" />}
              onClick={() => setSelectedCategory(null)}
              size="sm"
              position="absolute"
              left={4}
              top={3}
              color="#8E8E93"
              _hover={{ bg: "#2C2C2E" }}
            >
              Categories
            </Button>
          )}
          {selectedCategory ? (
            <Text textAlign="center">
              {selectedCategory === "static"
                ? "Static Value"
                : "Variable Binding"}
            </Text>
          ) : (
            "Categories"
          )}
        </ModalHeader>
        <ModalCloseButton color="#8E8E93" />
        <ModalBody>
          {selectedCategory ? renderInputField() : renderCategories()}
        </ModalBody>
        <ModalFooter>
          {selectedCategory && (
            <>
              <Button
                onClick={handleSave}
                size="sm"
                bg="#0A84FF"
                color="white"
                borderRadius="sm"
                _hover={{ bg: "#0070E0" }}
              >
                Select
              </Button>
              <Button
                onClick={onClose}
                size="sm"
                variant="outline"
                ml={3}
                borderRadius="sm"
                borderColor="#2C2C2E"
                color="#8E8E93"
                _hover={{ bg: "#2C2C2E" }}
              >
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
