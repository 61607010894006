import React, { useState, useEffect } from 'react';
import MonacoEditor from '@monaco-editor/react';
import { useAppDescriptorStore } from '../../stores/appDescriptorStore';

interface CodeEditorProps {
  keypath: string;
  language: string;
}

const CodeEditor: React.FC<CodeEditorProps> = ({ keypath, language }) => {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const [code, setCode] = useState('');

  useEffect(() => {
    const fragmentCode = getFragment(keypath) as string || '';
    setCode(fragmentCode);
  }, [keypath, getFragment]);

  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined) {
      setCode(value);
      setFragment(keypath, value);
    }
  };

  return (
    <MonacoEditor
      height="400px"
      language={language}
      theme="vs-dark"
      value={code}
      onChange={handleEditorChange}
      options={{
        minimap: { enabled: false },
        scrollBeyondLastLine: false,
        fontSize: 14,
      }}
    />
  );
};

export default CodeEditor;