import React from "react";
import { ModalBody as ChakraModalBody, Input, Flex, VStack, Text } from "@chakra-ui/react";
import { MenuItem } from "./MenuItem";
import { ExplanationPanel } from "./ExplanationPanel";

// ... add necessary imports and type definitions ...

export const ModalBody: React.FC<ModalBodyProps> = ({
  searchTerm,
  setSearchTerm,
  filteredItems,
  selectedItem,
  handleItemSelect,
  isExplanationOpen,
  currentItems,
}) => (
  <ChakraModalBody display="flex" flexDirection="column" overflow="hidden">
    <Input
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      mb={4}
    />
    <Flex flex={1} overflow="hidden">
      <VStack flex={1} align="stretch" spacing={2} overflowY="auto" pr={2}>
        {filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <MenuItem key={item.name} item={item} selectedItem={selectedItem} handleItemSelect={handleItemSelect} />
          ))
        ) : (
          <Text color="gray.500" fontStyle="italic">
            {(currentItems[0] as CategoryItem)?.emptyStateText || "No items found"}
          </Text>
        )}
      </VStack>
      {isExplanationOpen && (
        <ExplanationPanel selectedItem={selectedItem} />
      )}
    </Flex>
  </ChakraModalBody>
);