import React from "react";
import { Box, FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";
import { NativeCodeDirective } from "@/runtime-js/src/directives/nativeCode";

interface NativeCodeEditorProps {
  keypath: string;
}

export function NativeCodeEditor({ keypath }: NativeCodeEditorProps) {
  const { getFragment, setFragment } = useAppDescriptorStore();
  const directive = getFragment(keypath) as NativeCodeDirective;

  if (!directive) {
    console.error("Directive not provided");
    return <Box>Error: Directive not provided</Box>;
  }

  const handleCodeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedDirective = {
      ...directive,
      config: {
        ...directive.config,
        javascript: { ...directive.config.javascript, code: e.target.value },
      },
    };
    setFragment(keypath, updatedDirective);
  };

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="nativeCode" fontSize="sm">
          Code
        </FormLabel>
        <Textarea
          id="nativeCode"
          value={directive.config.javascript.code}
          onChange={handleCodeChange}
          borderColor="gray.500"
          bg="gray.600"
          color="white"
        />
      </FormControl>
    </Box>
  );
}
